import React, { FC, memo } from "react";

import { useTranslation } from "react-i18next";

import { Info, Price, Type } from "./PackingList.style";

import { PackingMethod } from "@/store/useServicesStore";

import { ListItem, Wrapper } from "../../styles";
import { ServicesLogo } from "../ServicesLogo";

interface PackingListProps {
  packingType: string;
  onSelect: (code: string) => void;
  onSubmit: (type: string) => Promise<void>;
  packingMethods: PackingMethod[];
}

const PackingList: FC<PackingListProps> = ({
  packingMethods,
  packingType,
  onSelect,
  onSubmit,
}) => {
  const { t } = useTranslation("common");
  return (
    <Wrapper>
      {packingMethods.map((method) => {
        const price = +method.price_in_cents / 100;
        const priceTitle = price > 0 ? `$${price}` : t("common.free");
        const isSelected = packingType === method.code;

        return (
          <ListItem
            key={method.id}
            $isSelected={isSelected}
            onClick={() => onSelect(method.code)}
            onDoubleClick={() => onSubmit(method.code)}
          >
            <ServicesLogo logoUrl={method.logo_url} />
            <Info>
              <Type>{method.title}</Type>
              {method.description}
            </Info>
            <Price>{priceTitle}</Price>
          </ListItem>
        );
      })}
    </Wrapper>
  );
};

export default memo(PackingList);
