import { FC, memo } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";

import { ButtonColor, ButtonVariant } from "@/enums";

import { Heading, Placeholder, Wrapper } from "./CancelPacking.styles";

interface CancelPackingProps {
  handleConfirmPacking: () => void;
  handleCancelPacking: () => void;
}

const CancelPacking: FC<CancelPackingProps> = ({
  handleConfirmPacking,
  handleCancelPacking,
}) => {
  const { t } = useTranslation("common");

  return (
    <Wrapper>
      <Heading>
        {t("common.areYouSureYouWantTo")}
        <span> {t("parcels.cancel")} </span>
        {t("parcels.packingInGenitive")}?
      </Heading>
      <Placeholder>{t("parcels.cancelPackingMessage")}</Placeholder>
      <Button
        onClick={handleCancelPacking}
        color={ButtonColor.Red}
        variant={ButtonVariant.Bordered}
      >
        {t("parcels.confirmCancelPacking")}
      </Button>
      <Button
        onClick={handleConfirmPacking}
        color={ButtonColor.Primary}
        variant={ButtonVariant.Bordered}
      >
        {t("parcels.continuePacking")}
      </Button>
    </Wrapper>
  );
};

export default memo(CancelPacking);
