import React, { FC, useCallback } from "react";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import {
  ButtonsWrapper,
  Content,
  ContentWrapper,
  Heading,
} from "@/containers/RightPanel/RightPanel.styles";
import { AddonsList } from "@/components/AddonsList";
import { Button } from "@/components/Button";
import { Icon } from "@/components/Icon";
import { PriceBlock } from "@/components/PriceBlock";
import { getAddonsCost } from "@/components/TotalCostHint/helpers";

import { Paragraph, PriceWrapper } from "@/styles/serviceRequest";

import {
  useAddonsMethods,
  useConsolidationAddonsMethods,
} from "@/hooks/react-query/services";
import { useStorageUpdateAddons } from "@/hooks/react-query/storage";

import useOutgoingStore from "@/store/useOutgoingStore";
import { RightPanelType } from "@/store/useRightPanelStore";
import { useRightPanelStore, useServicesStore, useStorageStore } from "@/store";
import { ButtonColor, ButtonType, ButtonVariant, IconType } from "@/enums";
import { PackOptionsProps } from "@/types/StorageForms/PackOptions";

import { packOptionsFormik } from "./helpers";
import {
  AdditionalTitle,
  AddonsForm,
  HelpButton,
  HelpIcon,
  Spinner,
  Title,
} from "./PackOptions.styles";

const PackOptions: FC<PackOptionsProps> = React.memo(({ panelData }) => {
  const { t } = useTranslation("common");
  const { mutateAsync: updateAddons } = useStorageUpdateAddons();
  const { openRightPanel } = useRightPanelStore();
  const { detailedShipment } = useStorageStore();
  const { detailedShipment: outgoingDetailedShipment } = useOutgoingStore();
  const { values, isSubmitting, setFieldValue, handleSubmit } = useFormik(
    packOptionsFormik(
      t,
      openRightPanel,
      detailedShipment,
      outgoingDetailedShipment,
      panelData,
      updateAddons,
    ),
  );

  const {
    getSelectAddons,
    getAddonsForInventory,
    consolidationAddonsMethods,
    isLoadingServices,
  } = useServicesStore();

  const selectAddons = getSelectAddons();
  const selectInventoryAddons = getAddonsForInventory();

  useAddonsMethods({ enabled: !selectAddons.length });
  useConsolidationAddonsMethods({
    enabled: !consolidationAddonsMethods.length,
  });

  const useInventoryAddons = panelData.useInventoryAddons;
  const addons = useInventoryAddons ? selectInventoryAddons : selectAddons;
  const returnScreen = panelData.returnScreen;
  const handleCancel = useCallback(() => {
    openRightPanel(returnScreen, panelData);
  }, [openRightPanel, panelData, returnScreen]);

  const id = panelData.id;
  const readOnlyProp = panelData.readOnly;
  const overrideAndEnablePackOptions = panelData.overrideAndEnablePackOptions;
  const readOnly = readOnlyProp && !overrideAndEnablePackOptions;
  const detailedItem = detailedShipment[id] || outgoingDetailedShipment[+id];
  const items = [detailedItem];

  const handleSelect = useCallback(
    (addonId: number) => () => {
      if (!id || !Array.isArray(values.items[id])) return;

      const hasAddon = values.items[id].includes(addonId);
      const value = hasAddon
        ? [...values.items[id].filter((item: number) => item !== addonId)]
        : [...values.items[id], addonId];

      setFieldValue(`items`, { ...values.items, [id]: value });
    },
    [id, values, setFieldValue],
  );

  const cost = getAddonsCost([{ addons: values.items[id] }], addons);

  const handleHelpClick = useCallback(
    () =>
      openRightPanel(RightPanelType.HELP_PANEL, {
        ...panelData,
        preventReLoading: true,
        readOnly: readOnlyProp,
        headerTitle: `${t("serviceRequests.packOptions.title")}: FAQ`,
      }),
    [panelData, openRightPanel],
  );

  return (
    <ContentWrapper>
      <Heading $isBordered>
        <button onClick={handleCancel}>
          <Icon type={IconType.Arrow} />
        </button>
        <Title>{t("serviceRequests.packOptions.title")}</Title>
      </Heading>
      <Content>
        <AddonsForm onSubmit={handleSubmit}>
          <Spinner isActive={isLoadingServices} />
          <div>
            <Paragraph>
              {t(`serviceRequests.packOptions.paragraph`)}
              <HelpButton onClick={handleHelpClick}>
                <HelpIcon type={IconType.Help} />
              </HelpButton>
            </Paragraph>
            <AdditionalTitle>
              {t(`serviceRequests.packOptions.heading`)}:
            </AdditionalTitle>
            <AddonsList
              addons={addons}
              items={items}
              values={values}
              readOnly={readOnly}
              handleSelect={handleSelect}
            />
            <PriceWrapper>
              <PriceBlock
                price={cost}
                title={t(`serviceRequests.packOptions.costHeading`)}
              />
            </PriceWrapper>
          </div>
          <ButtonsWrapper>
            <Button onClick={handleCancel}>{t("common.cancel")}</Button>
            {!readOnly && (
              <Button
                type={ButtonType.Submit}
                color={ButtonColor.Primary}
                variant={ButtonVariant.Filled}
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                {t(`serviceRequests.packOptions.submit`)}
              </Button>
            )}
          </ButtonsWrapper>
        </AddonsForm>
      </Content>
    </ContentWrapper>
  );
});

export default PackOptions;
