import React, { FC, useCallback } from "react";

import { Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/Button";

import {
  toastResponseError,
  toastResponseSuccess,
} from "@/utils/responseMessageHelper";
import { useDeleteCart } from "@/hooks/react-query/cart";
import { useProgressDelivery } from "@/hooks";

import { useAuthStore } from "@/store";
import { ButtonColor, ButtonSize, ButtonVariant } from "@/enums";

import { getLinkToStep } from "../../helpers/getLinkToStep";
import { Content, DeliveryBlock } from "../../styles";
import {
  CancelWrapper,
  DeleteButton,
  NotLeave,
  NotLeaveButton,
  Title,
} from "./CancelConfirmation.styles";

const CancelConfirmation: FC = React.memo(() => {
  const { getAuthSelectFfid } = useAuthStore();
  const { mutateAsync: deleteCart } = useDeleteCart();
  const userFfid = getAuthSelectFfid();
  const { t } = useTranslation("common");
  const [access] = useProgressDelivery(userFfid);
  const navigate = useNavigate();
  const handleSave = useCallback(
    () => navigate("/parcels/outgoing"),
    [navigate],
  );

  const handleNotLeave = () => navigate(getLinkToStep(access));

  const handleDelete = useCallback(() => {
    deleteCart()
      .then(() => {
        navigate("/parcels");
        toastResponseSuccess(t("common.deleteCartSuccess"));
      })
      .catch(toastResponseError);
  }, [deleteCart, t, navigate]);

  return (
    <Col lg={4} md={6} sm={12} offset={{ md: 3, lg: 4, sm: 12 }}>
      <Content>
        <DeliveryBlock>
          <CancelWrapper>
            <Title>{t("shipping.youAreLeaving")}</Title>
            <Button
              onClick={handleSave}
              size={ButtonSize.Large}
              color={ButtonColor.Primary}
              variant={ButtonVariant.Filled}
            >
              {t("common.saveAndLeave")}
            </Button>
            <DeleteButton
              onClick={handleDelete}
              size={ButtonSize.Large}
              color={ButtonColor.Red}
              variant={ButtonVariant.Bordered}
            >
              {t("common.deleteAndLeave")}
            </DeleteButton>
            <NotLeave>
              <NotLeaveButton
                onClick={handleNotLeave}
                size={ButtonSize.Large}
                color={ButtonColor.Primary}
                variant={ButtonVariant.Bordered}
              >
                {t("common.doNotLeave")}
              </NotLeaveButton>
            </NotLeave>
          </CancelWrapper>
        </DeliveryBlock>
      </Content>
    </Col>
  );
});

export default CancelConfirmation;
