const stateColor = (state: string): string => {
  switch (state) {
    case "paid":
      return "#E33122";
    case "pending":
      return "#57C6F7";
    case "claimed_for_packing":
      return "#65DD44";
    case "picked":
      return "#65DD44";
    case "claimed":
      return "#65DD44";

    default:
      return "#ffffff";
  }
};

export default stateColor;
