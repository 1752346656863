import { FC, memo, useCallback, useState } from "react";

import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";

import { AddonItem } from "@/components/AddonsList/AddonsList.styles";
import { RadioButton } from "@/components/RadioButton";

import { ConsolidationAddonsMethod } from "@/store/useServicesStore";
import { CartItem } from "@/types/api/cart";

import { getAddonsIconsImage } from "../../helpers";
import { ChooseAdditionalFormValues } from "../ChooseForms/ChooseAdditionalForm/helpers";
import {
  AddonDescription,
  RadioButtonsGroup,
} from "./ConsolidationAddonsList.styles";

interface ConsolidationAddonsListProps {
  addons?: ConsolidationAddonsMethod[];
  items: CartItem[];
  values: FormikProps<ChooseAdditionalFormValues>["values"];
  setFormValues: FormikProps<ChooseAdditionalFormValues>["setValues"];
  isConsolidationAddonsDefined: boolean;
  handleChangeConsolidationAddonsDefined: (bool: boolean) => void;
}

const ConsolidationAddonsList: FC<ConsolidationAddonsListProps> = ({
  addons = [],
  items,
  values,
  setFormValues,
  isConsolidationAddonsDefined,
  handleChangeConsolidationAddonsDefined,
}) => {
  const { t } = useTranslation("common");
  const [undefinedValues, setUndefinedValues] = useState(
    isConsolidationAddonsDefined ? [] : addons.map((addon) => addon.code),
  );

  const getChecked = (addon: ConsolidationAddonsMethod) => (value: string) => {
    if (undefinedValues.includes(value)) {
      return;
    }

    return !!items.find((cartItem) => {
      const itemValue = values.items[cartItem.id];
      const selectedAddon = itemValue.find(
        (id: string | number) => id === addon.code,
      );

      return selectedAddon === value;
    });
  };

  const handleChange = useCallback(
    (code: string, operation: string) => () => {
      const isOperationSelect = operation === "select";

      items.forEach((cartItem) => {
        const itemId = cartItem.id;
        const valuesItemId = values.items[itemId];

        const value =
          valuesItemId && isOperationSelect
            ? !valuesItemId.includes(code)
              ? [...valuesItemId, code]
              : valuesItemId
            : valuesItemId.filter(
                (addonId: string | number) => addonId !== code,
              );

        values.items[itemId] = value;
      });

      const undefinedIndex = undefinedValues.indexOf(code);
      if (undefinedIndex !== -1) {
        const updatedUndefinedValues = undefinedValues.filter(
          (_, index) => index !== undefinedIndex,
        );
        setUndefinedValues(updatedUndefinedValues);

        if (updatedUndefinedValues.length === 0) {
          handleChangeConsolidationAddonsDefined(true);
        }
      }

      setFormValues({ ...values });
    },
    [
      values,
      items,
      setFormValues,
      undefinedValues,
      setUndefinedValues,
      handleChangeConsolidationAddonsDefined,
    ],
  );

  return addons.map((addon) => {
    const isChecked = getChecked(addon);
    const icon = getAddonsIconsImage(addon.id, addons);
    const addonCode = addon.code;
    const checkedValue = isChecked(addonCode);

    return (
      <AddonItem $isSelected={!!checkedValue} key={addonCode}>
        {icon}
        <AddonDescription>{addon.description}</AddonDescription>
        <RadioButtonsGroup>
          <RadioButton
            label={t("auth.yes")}
            value={addonCode}
            checked={!!checkedValue}
            onClick={handleChange(addonCode, "select")}
          />
          <RadioButton
            label={t("auth.no")}
            value={undefined}
            checked={!checkedValue && checkedValue !== undefined}
            onClick={handleChange(addonCode, "deselect")}
          />
        </RadioButtonsGroup>
      </AddonItem>
    );
  });
};

export default memo(ConsolidationAddonsList);
