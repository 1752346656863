import { useEffect } from "react";

import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";

import CartAPI from "@/api/cart_api";

import { toastResponseError } from "@/utils/responseMessageHelper";

import { useCartStore, useStorageStore } from "@/store";
import { CartResponseDto, DescriptionsByHsCodes } from "@/types/api/cart";
import { CartQueryKey as QueryKey } from "@/types";

export interface ItemPicture {
  id: number;
  medium: string;
  large: string;
}

export const useCart = (
  options?: Omit<UseQueryOptions<CartResponseDto>, "queryKey" | "queryFn">,
) => {
  const { updateCart, updateIsLoadingCart } = useCartStore();
  const fetchCart = useQuery({
    queryKey: [QueryKey.Cart],
    queryFn: () => CartAPI.getCart(),
    ...options,
  });

  const { data, error, isLoading } = fetchCart;

  useEffect(() => {
    if (error) {
      updateIsLoadingCart(false);
      toastResponseError(error);
    }
  }, [error]);

  useEffect(() => {
    updateIsLoadingCart(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (data) {
      updateCart(data);
      updateIsLoadingCart(false);
    }
  }, [data]);

  return fetchCart;
};

export const useDeleteCart = () => {
  const queryClient = useQueryClient();
  const { updateCart, updateIsLoadingCart, isCartLoading } = useCartStore();
  const { updateIsAddParcelsToCart } = useStorageStore();
  const deleteAddress = useMutation({
    mutationFn: CartAPI.deleteCart,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CartDelete],
      });
    },
    onMutate: () => {
      updateIsLoadingCart(true);
    },
    onSuccess: () => {
      updateCart(null);
      updateIsLoadingCart(false);
      updateIsAddParcelsToCart(false);
    },
    onError: () => {
      updateIsLoadingCart(false);
    },
  });

  return deleteAddress;
};

export const useSetPartnerId = () => {
  const queryClient = useQueryClient();
  const { updateIsLoadingCart } = useCartStore();

  const setPartnerId = useMutation({
    mutationFn: CartAPI.setPartnerId,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CartSetPartnerId],
      });
    },
    onMutate: () => {
      updateIsLoadingCart(true);
    },
    onSuccess: () => {
      updateIsLoadingCart(false);
    },
    onError: () => {
      updateIsLoadingCart(false);
    },
  });

  return setPartnerId;
};

export const useCartSetAddress = () => {
  const queryClient = useQueryClient();
  const { updateIsLoadingCart, updateCart, cart } = useCartStore();

  const setAddress = useMutation({
    mutationFn: CartAPI.setAddress,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CartSetAddress],
      });
    },
    onMutate: () => {
      updateIsLoadingCart(true);
    },
    onSuccess: (destinationAddress) => {
      if (destinationAddress && destinationAddress.id && cart) {
        updateCart({
          ...cart,
          destination_address_id: destinationAddress.id,
          destination_address: destinationAddress,
        });
      }

      updateIsLoadingCart(false);
    },
    onError: () => {
      updateIsLoadingCart(false);
    },
  });

  return setAddress;
};

export const useCartSetDeliveryMethod = () => {
  const queryClient = useQueryClient();
  const { updateIsLoadingCart, updateCart, cart } = useCartStore();

  const setDeliveryMethod = useMutation({
    mutationFn: CartAPI.setDeliveryMethod,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CartSetDeliveryMethod],
      });
    },
    onMutate: () => {
      updateIsLoadingCart(true);
    },
    onSuccess: (deliveryMethods) => {
      if (deliveryMethods && cart) {
        updateCart({
          ...cart,
          ...deliveryMethods,
        });
      }

      updateIsLoadingCart(false);
    },
    onError: () => {
      updateIsLoadingCart(false);
    },
  });

  return setDeliveryMethod;
};

export const useCartSetPackingMethod = () => {
  const queryClient = useQueryClient();
  const { updateIsLoadingCart, updateCart, cart } = useCartStore();

  const setPackingMethod = useMutation({
    mutationFn: CartAPI.setPackingMethods,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CartSetPackingMethod],
      });
    },
    onMutate: () => {
      updateIsLoadingCart(true);
    },
    onSuccess: (packingMethods) => {
      if (packingMethods && cart) {
        updateCart({
          ...cart,
          ...packingMethods,
        });
      }

      updateIsLoadingCart(false);
    },
    onError: () => {
      updateIsLoadingCart(false);
    },
  });

  return setPackingMethod;
};

export const useCartSetAddonsMethod = () => {
  const queryClient = useQueryClient();
  const { updateIsLoadingCart, updateCart, cart } = useCartStore();

  const setAddonMethod = useMutation({
    mutationFn: CartAPI.setAddonsMethods,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CartSetAddonMethod],
      });
    },
    onMutate: () => {
      updateIsLoadingCart(true);
    },
    onSuccess: (addonMethods) => {
      if (addonMethods && cart) {
        updateCart({
          ...cart,
          items: [...cart.items, ...addonMethods.items],
        });
      }

      updateIsLoadingCart(false);
    },
    onError: () => {
      updateIsLoadingCart(false);
    },
  });

  return setAddonMethod;
};

export const useCartDeleteAddonsMethod = () => {
  const queryClient = useQueryClient();
  const { updateIsLoadingCart, updateCart, cart } = useCartStore();

  const deleteAddonsMethod = useMutation({
    mutationFn: CartAPI.deleteAddonsMethods,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CartDeleteAddonsMethod],
      });
    },
    onMutate: () => {
      updateIsLoadingCart(true);
    },
    onSuccess: (deleteAddonsMethodDto) => {
      if (deleteAddonsMethodDto && cart) {
        updateCart({
          ...cart,
          ...deleteAddonsMethodDto,
        });
      }

      updateIsLoadingCart(false);
    },
    onError: () => {
      updateIsLoadingCart(false);
    },
  });

  return deleteAddonsMethod;
};

export const useCartSetConsolidationAddonsMethod = () => {
  const queryClient = useQueryClient();
  const { updateIsLoadingCart, updateCart, cart } = useCartStore();

  const deleteAddonsMethod = useMutation({
    mutationFn: CartAPI.setConsolidationAddonsMethods,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CartSetConsolidationAddonsMethod],
      });
    },
    onMutate: () => {
      updateIsLoadingCart(true);
    },
    onSuccess: (consolidationAddonsMethodDto) => {
      if (consolidationAddonsMethodDto && cart) {
        updateCart({
          ...cart,
          addons_code: consolidationAddonsMethodDto.addons_codes,
        });
      }

      updateIsLoadingCart(false);
    },
    onError: () => {
      updateIsLoadingCart(false);
    },
  });

  return deleteAddonsMethod;
};

export const useConsolidateCart = () => {
  const queryClient = useQueryClient();
  const { updateIsLoadingCart } = useCartStore();

  const deleteAddonsMethod = useMutation({
    mutationFn: CartAPI.consolidateCart,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CartConsolidate],
      });
    },
    onMutate: () => {
      updateIsLoadingCart(true);
    },
    onSuccess: () => {
      updateIsLoadingCart(false);
    },
    onError: () => {
      updateIsLoadingCart(false);
    },
  });

  return deleteAddonsMethod;
};

export const useDescriptionsByHsCodes = (
  options?: Omit<
    UseQueryOptions<DescriptionsByHsCodes[]>,
    "queryKey" | "queryFn"
  >,
) => {
  const { updateHsCodes } = useCartStore();
  const fetchCart = useQuery({
    queryKey: [QueryKey.CartDescriptionsByHsCodes],
    queryFn: () => CartAPI.getDescriptions(),
    ...options,
  });

  const { data, isSuccess } = fetchCart;

  useEffect(() => {
    if (isSuccess && data) {
      updateHsCodes(data);
    }
  }, [data, isSuccess]);

  return fetchCart;
};

export const useCartImportCustomInformation = () => {
  const queryClient = useQueryClient();
  const { updateIsLoadingCart, updateCart } = useCartStore();

  const importCustomInformation = useMutation({
    mutationFn: CartAPI.importCustomInformation,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CartImportCustomInformation],
      });
    },
    onMutate: () => {
      updateIsLoadingCart(true);
    },
    onSuccess: (customInformationDto) => {
      if (customInformationDto) {
        updateCart(customInformationDto);
      }

      updateIsLoadingCart(false);
    },
    onError: () => {
      updateIsLoadingCart(false);
    },
  });

  return importCustomInformation;
};

export const useCartCreateCustomsDeclaration = () => {
  const queryClient = useQueryClient();
  const { updateCart, cart } = useCartStore();

  const createCustomsDeclaration = useMutation({
    mutationFn: CartAPI.createCustomsDeclaration,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CartImportCustomInformation],
      });
    },
    onSuccess: (customDeclarationDto) => {
      if (customDeclarationDto && cart) {
        updateCart({
          ...cart,
          customs_informations: [
            ...(cart.customs_informations ?? []),
            customDeclarationDto,
          ],
        });
      }
    },
  });

  return createCustomsDeclaration;
};

export const useCartUpdateCustomsDeclaration = () => {
  const queryClient = useQueryClient();
  const { updateCart, cart } = useCartStore();

  const updateCustomsDeclaration = useMutation({
    mutationFn: CartAPI.updateCustomsDeclaration,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CartUpdateCustomInformation],
      });
    },
    onSuccess: (customDeclarationDto, context) => {
      if (customDeclarationDto && cart && cart.customs_informations) {
        updateCart({
          ...cart,
          customs_informations: cart.customs_informations.map((information) =>
            information.id === context.id ? customDeclarationDto : information,
          ),
        });
      }
    },
  });

  return updateCustomsDeclaration;
};

export const useCartDeleteCustomsDeclaration = () => {
  const queryClient = useQueryClient();
  const { updateCart, cart } = useCartStore();

  const createCustomsDeclaration = useMutation({
    mutationFn: CartAPI.deleteCustomsDeclaration,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CartDeleteCustomInformation],
      });
    },
    onSuccess: (customDeclarationDto, variables) => {
      if (customDeclarationDto && cart) {
        updateCart({
          ...cart,
          customs_informations: [
            ...(cart.customs_informations ?? []).filter(
              (customInfo) => customInfo.id !== variables.id,
            ),
            customDeclarationDto,
          ],
        });
      }
    },
  });

  return createCustomsDeclaration;
};

export const useCartImportCustomsDeclarations = () => {
  const queryClient = useQueryClient();

  const { updateIsLoadingCart } = useCartStore();

  const importCustomInformation = useMutation({
    mutationFn: CartAPI.importCustomsDeclaration,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CartImportCustomDeclarations],
      });
    },
    onMutate: () => {
      updateIsLoadingCart(true);
    },
    onSuccess: () => {
      updateIsLoadingCart(false);
    },
    onError: () => {
      updateIsLoadingCart(false);
    },
  });

  return importCustomInformation;
};
