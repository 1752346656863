import { FC, memo } from "react";

import { useTranslation } from "react-i18next";

import PackageIcon from "@/components/Icon/icons/package.svg";

import { ParcelLogo } from "@/styles/parcels";

import { getFormattedDate } from "@/utils/formatDate";

import { getDayjsLocale } from "@/translations/dayjs-locale";
import { ExpectedShipmentResponseDto } from "@/types/api/expected";
import { ShopsResponseDto } from "@/types/api/shipments";

import {
  Comment,
  Description,
  ExpectedItemRowWrapper,
  LogoWrapper,
  MainStorageCol,
  StorageCol,
  StorageRow,
} from "./ExpectedList.styles";

interface ExpectedItemRowProps {
  parcel: ExpectedShipmentResponseDto;
  selectedParcel: number | null;
  disabled: boolean;
  onFocusExpectedItemInPanel: (parcel: ExpectedShipmentResponseDto) => void;
  shops: ShopsResponseDto[];
}

const ExpectedItemRow: FC<ExpectedItemRowProps> = ({
  parcel,
  selectedParcel,
  onFocusExpectedItemInPanel,
  disabled,
  shops,
}) => {
  const isSelectedShipment = selectedParcel === parcel.id;
  const trackingNumber = parcel.tracking_number;
  const orderNumber = parcel.order_number;
  const customerComment = parcel.customer_comment;
  const storeName = parcel.store_name;
  const createdAtDate = parcel.created_at;

  const shop = shops.find((x) => x.name === storeName);
  const logoUrl = shop && shop.thumb_image_url;

  const { i18n } = useTranslation("common");
  const locale = getDayjsLocale(i18n.language);

  const created = getFormattedDate(createdAtDate, locale);

  return (
    <ExpectedItemRowWrapper disabled={disabled}>
      <StorageRow
        align="center"
        selected={isSelectedShipment}
        onClick={() => onFocusExpectedItemInPanel(parcel)}
      >
        <MainStorageCol sm={12}>
          <StorageCol xs={6} md={4}>
            <LogoWrapper>
              <ParcelLogo image={logoUrl || PackageIcon} />
            </LogoWrapper>
            <Description>
              {trackingNumber}
              {customerComment && <Comment>{customerComment}</Comment>}
            </Description>
          </StorageCol>
          <StorageCol xs={3} md={2}>
            {orderNumber}
          </StorageCol>
          <StorageCol xs={3} md={6}>
            {created}
          </StorageCol>
        </MainStorageCol>
      </StorageRow>
    </ExpectedItemRowWrapper>
  );
};

export default memo(ExpectedItemRow);
