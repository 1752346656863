import { FC, memo, ReactElement, ReactNode, useCallback } from "react";

import { ScrollbarProps } from "react-custom-scrollbars-2";

import { CustomScroll, StyledScrollBars } from "./ScrollableContent.styles";

export interface ScrollableContentProps extends ScrollbarProps {
  children: ReactNode | ReactElement;
  isScrollDisabled?: boolean;
}

const ScrollableContent: FC<ScrollableContentProps> = ({
  children,
  ...otherProps
}) => {
  const renderThumb = useCallback(() => <CustomScroll />, []);

  return (
    <StyledScrollBars
      autoHide
      autoHideTimeout={800}
      renderThumbVertical={renderThumb}
      {...otherProps}
    >
      {children}
    </StyledScrollBars>
  );
};

export default memo(ScrollableContent);
