import { FC } from "react";

import { useTranslation } from "react-i18next";

import { LoadingWrapper, Spinner } from "./LoadingPage.styles";

const LoadingPage: FC = () => {
  const { t } = useTranslation("common");

  return (
    <LoadingWrapper>
      <Spinner isActive />
      {`${t("common.loading")}...`}
    </LoadingWrapper>
  );
};

export default LoadingPage;
