import React, { FC, useState } from "react";

import { useTranslation } from "react-i18next";

import { Content } from "@/containers/RightPanel/RightPanel.styles";

import { USD } from "@/styles/form";

import {
  calculateCustomsDataTotal,
  CustomsItem,
} from "@/utils/customsDataHelpers";
import formatter from "@/utils/formatter";
import {
  useCartCreateCustomsDeclaration,
  useCartDeleteCustomsDeclaration,
  useCartUpdateCustomsDeclaration,
} from "@/hooks/react-query/cart";

import { useCartStore, useServicesStore } from "@/store";

import {
  DeclarationTotal,
  StyledCustomsDeclarationV2,
  TabMain,
} from "../NotFinishedPanel.styles";
import { indexToTitleMapping } from "./helpers";

interface CustomsPanelProps {
  delivery: string;
  onActiveTabChange: (tab: string) => void;
}

const CustomsPanel: FC<CustomsPanelProps> = React.memo(
  ({ onActiveTabChange, delivery }) => {
    const { t } = useTranslation("common");

    const { cart } = useCartStore();
    const { allDeliveryMethods } = useServicesStore();

    const id = cart?.id;
    const declarations = cart?.customs_informations ?? [];

    const deliveryMethod = allDeliveryMethods.find(
      (method) => method.name === delivery,
    );
    const isShowHSCodeColumn =
      deliveryMethod && deliveryMethod.requires_hs_code;

    const { mutateAsync: createCustomsDeclaration } =
      useCartCreateCustomsDeclaration();
    const { mutateAsync: updateCustomsDeclaration } =
      useCartUpdateCustomsDeclaration();
    const { mutateAsync: deleteCustomsDeclaration } =
      useCartDeleteCustomsDeclaration();

    const getTotal = (nameProp: string) =>
      declarations.reduce(
        (sum: number, information) =>
          sum + +(information[nameProp as keyof typeof information] ?? 0),
        0,
      );

    const [headerData, setHeaderData] = useState({
      size: declarations.length || 0,
      total: formatter.currency(getTotal("value")),
    });

    const handleCustomsDataChange = (newValues: CustomsItem[]) => {
      const [newTotal, newLength] = calculateCustomsDataTotal(newValues);
      setHeaderData({
        size: newLength,
        total: newTotal,
      });
    };

    const handleCustomsDeclarationSubmit = () => {
      onActiveTabChange(t(indexToTitleMapping[2]));
    };

    return (
      <TabMain>
        <Content>
          <DeclarationTotal>
            {`${t("shipping.total")}: ${headerData.size} ${t(
              "shipping.totalRows",
            )} - $${headerData.total} `}
            <USD>USD</USD>
          </DeclarationTotal>
          <StyledCustomsDeclarationV2
            itemId={id}
            declarations={declarations}
            create={createCustomsDeclaration}
            update={updateCustomsDeclaration}
            remove={deleteCustomsDeclaration}
            onCustomsDataChange={handleCustomsDataChange}
            noPadding
            isShowHSCodeColumn={isShowHSCodeColumn}
            isHSCodeValidationRequired
            isWizardStep
            onSubmit={handleCustomsDeclarationSubmit}
            saveButtonText={t("common.continue")}
          />
        </Content>
      </TabMain>
    );
  },
);

export default CustomsPanel;
