import { useEffect, useRef } from 'react';

import { useAuthStore } from '@/store';

import { CloseIcon, MessengerIcon } from './IconsComponents';
import {
  IntercomIconClose,
  IntercomIconOpen,
  IntercomLauncher,
} from './IntercomMessenger.styles';

const IntercomMessenger = () => {
  const {
    userAuth: { created_at, email },
    getAuthSelectFullName,
    getAuthSelectFfid,
  } = useAuthStore();
  const name = getAuthSelectFullName();
  const user_id = getAuthSelectFfid();

  const IntercomMessengerProps = {
    email,
    created_at,
    name,
    user_id,
  };

  const intercomLauncherRef = useRef(null);
  const intercomConfig = {
    app_id: 'g8pf25uo',
    custom_launcher_selector: '.intercom-launcher',
    hide_default_launcher: true,
  };
  const loggedIn = (user) => user.email || user.user_id;

  useEffect(() => {
    window.intercomSettings = intercomConfig;

    if (!window.Intercom) {
      (function (w, d, id, s, x) {
        function i() {
          i.c(arguments);
        }
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        s = d.createElement('script');
        s.async = 1;
        s.src = `https://widget.intercom.io/widget/${id}`;
        d.head.appendChild(s);
      })(window, document, intercomConfig.app_id);
    }

    if (window.Intercom) {
      window.Intercom('onShow', () =>
        intercomLauncherRef.current.classList.add('intercom-open'),
      );
      window.Intercom('onHide', () =>
        intercomLauncherRef.current.classList.remove('intercom-open'),
      );
    }

    return () => {
      if (!window.Intercom) return false;

      if (loggedIn(IntercomMessengerProps)) {
        window.Intercom('shutdown');
      }

      delete window.Intercom;
      delete window.intercomSettings;
    };
  }, [intercomLauncherRef.current]);

  useEffect(() => {
    window.intercomSettings = intercomConfig;

    if (window.Intercom) {
      if (!loggedIn(IntercomMessengerProps)) {
        window.Intercom('boot', IntercomMessengerProps);
      } else {
        window.Intercom('boot', IntercomMessengerProps);
      }
    }
  }, [loggedIn, IntercomMessengerProps]);

  return (
    <IntercomLauncher
      className="intercom-launcher"
      href={`mailto:${intercomConfig.app_id}@incoming.intercom.io`}
      ref={intercomLauncherRef}
      data-testid={'intercom-messenger'}
    >
      <IntercomIconOpen>
        <MessengerIcon />
      </IntercomIconOpen>
      <IntercomIconClose>
        <CloseIcon />
      </IntercomIconClose>
    </IntercomLauncher>
  );
};

export default IntercomMessenger;
