import { create } from "zustand";

import { getTranslatedProp } from "@/translations/translationsHelper";
import {
  AddonsMethodsResponseDto,
  ConsolidationAddonsMethodsResponseDto,
  DeliveryShippingMethodsResponseDto,
  PackingMethodsResponseDto,
} from "@/types/api/cart";

import useAuthStore from "./useAuthStore";

export interface DashboardDto {
  delivered_count: number;
  new_count: number;
  next_end_time: string;
  next_start_time: string;
  pending_count: number;
  user_ffid: number;
  warehouse_address: string;
  warehouse_opened: boolean;
}

export type PackingMethod = PackingMethodsResponseDto & {
  logo_url: string;
  title: string;
};

export type AddonsMethod = AddonsMethodsResponseDto & {
  title: string;
};

export type ConsolidationAddonsMethod =
  ConsolidationAddonsMethodsResponseDto & {
    title: string;
    isOperationSelect?: boolean;
  };

interface UseServicesStore {
  isLoadingServices: boolean;
  deliveryMethods: DeliveryShippingMethodsResponseDto[];
  allDeliveryMethods: DeliveryShippingMethodsResponseDto[];
  packingMethods: PackingMethod[];
  addonsMethods: AddonsMethod[];
  consolidationAddonsMethods: ConsolidationAddonsMethod[];

  updateIsLoadingServices: (bool: boolean) => void;
  updateDeliveryMethods: (dto: DeliveryShippingMethodsResponseDto[]) => void;
  updateAllDeliveryMethods: (dto: DeliveryShippingMethodsResponseDto[]) => void;
  updatePackingMethods: (dto: PackingMethodsResponseDto[]) => void;
  updateAddonsMethods: (dto: AddonsMethodsResponseDto[]) => void;
  updateConsolidationAddonsMethods: (
    dto: ConsolidationAddonsMethodsResponseDto[],
  ) => void;

  getAllServices: () => (DeliveryShippingMethodsResponseDto | PackingMethod)[];
  getAddonsForInventory: () => AddonsMethod[];
  getSelectAddons: () => AddonsMethod[];

  resetServicesStore: () => void;
}

const currentLocale = useAuthStore.getState().userAuth.locale;

const initialState = {
  isLoadingServices: false,
  deliveryMethods: [],
  allDeliveryMethods: [],
  packingMethods: [],
  addonsMethods: [],
  consolidationAddonsMethods: [],
};

const useServicesStore = create<UseServicesStore>((set, get) => ({
  ...initialState,
  updateIsLoadingServices: (bool) =>
    set(() => ({
      isLoadingServices: bool,
    })),

  updateDeliveryMethods: (methods) =>
    set(() => ({
      deliveryMethods: methods,
    })),

  updateAllDeliveryMethods: (methods) =>
    set(() => ({
      allDeliveryMethods: methods,
    })),

  updatePackingMethods: (methods) =>
    set(() => {
      const normalizePackingMethods = methods
        .sort((a, b) => a.price_in_cents - b.price_in_cents)
        .map((method) => ({ ...method, logo_url: method.icon_url ?? "" }));

      const transformPackingMethods = normalizePackingMethods.map((method) => {
        const translatedTitle = getTranslatedProp(
          method,
          "title",
          currentLocale,
        );

        return { ...method, title: translatedTitle };
      });

      return {
        packingMethods: transformPackingMethods,
      };
    }),

  updateAddonsMethods: (methods) =>
    set(() => {
      const transformAddonsMethods = methods.map((method, index) => {
        const translatedTitle = getTranslatedProp(
          method,
          "description",
          currentLocale,
        );

        return { ...method, title: translatedTitle };
      });

      return {
        addonsMethods: transformAddonsMethods,
      };
    }),

  updateConsolidationAddonsMethods: (methods) =>
    set(() => {
      const transformConsolidationAddonsMethods = methods.map((method) => {
        const translatedTitle = getTranslatedProp(
          method,
          "description",
          currentLocale,
        );

        return { ...method, title: translatedTitle };
      });

      return {
        consolidationAddonsMethods: transformConsolidationAddonsMethods,
      };
    }),

  getAllServices: () => {
    const { allDeliveryMethods, packingMethods } = get();

    return [...allDeliveryMethods, ...packingMethods];
  },

  getAddonsForInventory: () => {
    const { addonsMethods } = get();

    return addonsMethods.filter(
      (item) => item.item_kind === "inventory" || item.item_kind === "all",
    );
  },

  getSelectAddons: () => {
    const { addonsMethods } = get();

    return addonsMethods.filter(
      (item) =>
        item.item_kind === "recd_as_package" || item.item_kind === "all",
    );
  },

  resetServicesStore: () =>
    set(() => ({
      ...initialState,
    })),
}));

export default useServicesStore;
