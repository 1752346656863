import styled from "styled-components";

import { Logo } from "@/components/Logo";

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterLogo = styled(Logo)`
  margin-right: 10px;
`;
