import { FC, LegacyRef, memo } from "react";

import {
  Description,
  OptionItem,
  SingleLogo,
  Value,
  Wrapper,
} from "./SelectComponents.styles";

type DataProps = {
  label?: string;
  description?: string;
  image?: string;
};

interface OptionProps {
  data?: DataProps;
  isFocused: boolean;
  isSelected: boolean;
  innerRef: LegacyRef<HTMLDivElement>;
  innerProps: object;
}

const Option: FC<OptionProps> = ({
  innerRef,
  innerProps,
  data = {},
  isFocused,
  isSelected,
}) => {
  const label = data?.label;
  const description = data?.description;
  const image = data?.image ?? "";

  return (
    <OptionItem
      ref={innerRef}
      {...innerProps}
      isFocused={isFocused}
      $isSelected={isSelected}
    >
      <Wrapper>
        <SingleLogo image={image} />
        <Value>
          {label}
          {description && <Description>{description}</Description>}
        </Value>
      </Wrapper>
    </OptionItem>
  );
};

export default memo(Option);
