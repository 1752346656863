import styled from "styled-components";

import { RasterIcon } from "@/components/Icon/Icon.styles";

import { ParcelRow } from "@/styles/parcels";

export const Price = styled.div`
  margin: 0;
  font-weight: 700;
`;

export const MPSIcon = styled(RasterIcon)`
  margin-left: 8px;
`;

export const Weight = styled.span`
  font-weight: 300;
`;

export const ParcelWrapper = styled(ParcelRow)`
  padding: 18px 10px;
`;
