import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import {
  getAddonDescription,
  getAddonPrice,
  getAddonsIconsImage,
} from "@/containers/Delivery/helpers";
import packageIcon from "@/components/Icon/icons/package.svg";

import { requestNames } from "@/store/useServicesRequestStore";
import {
  AddonsMethod,
  ConsolidationAddonsMethod,
} from "@/store/useServicesStore";
import { IconType } from "@/enums";
import { CartItem } from "@/types/api/cart";

import {
  Description,
  HeadText,
  HeadTextCompact,
  Item,
  ItemCompact,
  ItemHead,
  ItemHeadContent,
  ItemHeadPrice,
  ItemIcon,
  ItemLogo,
  ItemSection,
  ListItem,
  ListItemCompact,
  ServiceRequestIcon,
} from "./SideAddonItem.styles";

interface SideAddonItemProps {
  itemAddons: number[];
  addons: AddonsMethod[];
  cartItem: CartItem;
  consolidationAddons: ConsolidationAddonsMethod[];
  showAsCompact?: boolean;
}

const SideAddonItem: FC<SideAddonItemProps> = ({
  itemAddons,
  addons,
  consolidationAddons,
  cartItem,
  showAsCompact,
}) => {
  const { t } = useTranslation("common");

  const hasAddons = !!(itemAddons && itemAddons.length);
  const allAddons =
    addons.length && consolidationAddons.length
      ? [...addons, ...consolidationAddons]
      : [];
  const logoUrl = cartItem.emblem_thumb_url || packageIcon;
  const heading = cartItem.shipment_tracking_number;
  const description = cartItem.customer_comment;
  const serviceRequests = cartItem.service_requests ?? [];

  if (!hasAddons) {
    return null;
  }

  if (showAsCompact) {
    return (
      <ItemCompact>
        <ItemLogo image={logoUrl} size={20} />
        <HeadTextCompact>{heading}</HeadTextCompact>
        {serviceRequests.map((request: any) => {
          const requestType =
            requestNames[
              request.service_request_type.type.toLowerCase() as keyof typeof requestNames
            ];

          return (
            <ListItemCompact key={request.id}>
              <ServiceRequestIcon type={requestType as IconType} />
            </ListItemCompact>
          );
        })}
        {itemAddons.map((id) => (
          <ListItemCompact key={id}>
            <ItemIcon>{getAddonsIconsImage(id, allAddons)}</ItemIcon>
          </ListItemCompact>
        ))}
      </ItemCompact>
    );
  }

  let price = 0;
  itemAddons.forEach((id) => (price += parseFloat(getAddonPrice(id, addons))));
  serviceRequests.forEach(
    (request: any) => (price += parseFloat(request.service_request_type.cost)),
  );

  return (
    <Item>
      <ItemHead withDescription={!!description}>
        <ItemLogo image={logoUrl} size={20} />
        <ItemHeadContent>
          <HeadText>
            {heading}:{description && <Description>{description}</Description>}
          </HeadText>
          <ItemHeadPrice>
            {price !== 0 ? `$${price.toFixed(2)}` : "Free"}
          </ItemHeadPrice>
        </ItemHeadContent>
      </ItemHead>
      <ItemSection>
        {serviceRequests.map((request: any) => {
          const requestType =
            requestNames[
              request.service_request_type.type.toLowerCase() as keyof typeof requestNames
            ];

          return (
            <ListItem key={request.id}>
              <ServiceRequestIcon type={requestType as IconType} />
              <span>{t(`serviceRequests.${requestType}.title`)}</span>
            </ListItem>
          );
        })}

        {itemAddons.map((id) => (
          <ListItem key={id}>
            <ItemIcon>{getAddonsIconsImage(id, allAddons)}</ItemIcon>
            {getAddonDescription(id, allAddons)}
          </ListItem>
        ))}
      </ItemSection>
    </Item>
  );
};

export default React.memo(SideAddonItem);
