import React, { FC, useState } from "react";

import {
  QueryClient,
  QueryClientProvider as ReactQueryQueryClientProvider,
} from "@tanstack/react-query";

type QueryClientProviderProps = {
  children: React.ReactNode;
};

const QueryClientProvider: FC<QueryClientProviderProps> = ({ children }) => {
  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: { queries: { refetchOnWindowFocus: false } },
    }),
  );

  return (
    <ReactQueryQueryClientProvider client={queryClient}>
      {children}
    </ReactQueryQueryClientProvider>
  );
};

export default QueryClientProvider;
