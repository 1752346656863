import React, { FC, useCallback } from "react";

import { FormikProps } from "formik";
import { Col, Row } from "react-grid-system";
import { useTranslation } from "react-i18next";

import { PasswordValidation } from "@/containers/SignUp/components";
import { PasswordValidationWrapper } from "@/containers/SignUp/components/SignUpComponents.styles";
import {
  getCountriesOptions,
  getLocalesOptions,
} from "@/containers/SignUp/helpers";
import { Input } from "@/components/Input";
import { Select } from "@/components/Select";

import { SectionTitle, SectionWrapper } from "@/styles/account";

import { useAuthStore } from "@/store";
import { SettingsFormValues } from "@/types";

import {
  getHandlingOptions,
  getNotificationOptions,
  getUnitsOptions,
} from "../helpers";
import { SectionPasswords } from "./AccountComponents.styles";

interface SettigsProps {
  formOptions: FormikProps<SettingsFormValues>;
}

const Settings: FC<SettigsProps> = React.memo(({ formOptions }) => {
  const { t } = useTranslation("common");
  const {
    userAuth: { locale: currentLocale },
  } = useAuthStore();

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    setFieldValue,
    handleBlur,
  } = formOptions;

  const handleNotificationsChange = useCallback(
    (value: string) => setFieldValue("notification", value),
    [setFieldValue],
  );

  const handleCountryChange = useCallback(
    (value: string) => setFieldValue("country", value),
    [setFieldValue],
  );
  const handleLocaleChange = useCallback(
    (value: string) => setFieldValue("locale", value),
    [setFieldValue],
  );

  const handleUnitsChange = useCallback(
    (value: string) => setFieldValue("units", value),
    [setFieldValue],
  );

  const handleHandlingChange = useCallback(
    (value: string) => setFieldValue("handling", value),
    [setFieldValue],
  );

  return (
    <SectionWrapper>
      <SectionTitle>{t("account.settings")}</SectionTitle>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col sm={6} xs={12}>
            <Select
              name="country"
              label={t("auth.country")}
              options={getCountriesOptions(currentLocale)}
              value={values.country}
              onBlur={handleBlur}
              onChange={handleCountryChange}
            />
            <Select
              name="locale"
              label={t("auth.locale")}
              options={getLocalesOptions(t)}
              value={values.locale}
              onBlur={handleBlur}
              onChange={handleLocaleChange}
            />
            <Select
              name="notification"
              label={t("auth.emailNotifications")}
              options={getNotificationOptions(t)}
              value={values.notification}
              onBlur={handleBlur}
              onChange={handleNotificationsChange}
            />
            <Select
              name="units"
              label={t("auth.units")}
              options={getUnitsOptions(t)}
              value={values.units}
              onBlur={handleBlur}
              onChange={handleUnitsChange}
            />
            <Select
              name="handling"
              label={t("auth.handling")}
              options={getHandlingOptions(t)}
              value={values.handling}
              onBlur={handleBlur}
              onChange={handleHandlingChange}
            />
          </Col>
          <Col sm={6} xs={12}>
            <SectionPasswords>
              <Input
                type="password"
                label={t("auth.password")}
                name="oldPassword"
                placeholder={t("auth.oldPassword")}
                value={values.oldPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                tooltip={errors.oldPassword}
                invalid={errors.oldPassword && touched.oldPassword}
              />
              <Input
                type="password"
                name="newPassword"
                label={t("auth.newPassword")}
                placeholder={t("auth.newPassword")}
                value={values.newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                tooltip={errors.newPassword}
                invalid={errors.newPassword && touched.newPassword}
              />
              <Input
                type="password"
                name="newPasswordConfirm"
                label={t("auth.newPasswordConfirm")}
                placeholder={t("auth.newPasswordConfirm")}
                value={values.newPasswordConfirm}
                onChange={handleChange}
                onBlur={handleBlur}
                tooltip={errors.newPasswordConfirm}
                invalid={
                  errors.newPasswordConfirm && touched.newPasswordConfirm
                }
              />
            </SectionPasswords>
            <PasswordValidationWrapper>
              <PasswordValidation value={values.newPassword} />
            </PasswordValidationWrapper>
          </Col>
        </Row>
      </form>
    </SectionWrapper>
  );
});

export default Settings;
