import styled from "styled-components";

interface IndicatorProps {
  color: string;
}

export const Wrapper = styled.div`
  display: flex;
  height: 20px;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
`;

export const Indicator = styled.div<IndicatorProps>`
  width: 10px;
  height: 10px;
  min-width: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-left: 6px;
`;

export const Text = styled.div`
  margin-left: 6px;
`;
