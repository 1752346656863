import { FormikHelpers } from "formik";
import { TFunction } from "i18next";

import { toastResponseError } from "@/utils/responseMessageHelper";
import { ProgressDeliverySetAccessFunc } from "@/hooks";

import {
  AddonsMethod,
  ConsolidationAddonsMethod,
} from "@/store/useServicesStore";
import {
  CartCommonResponseDto,
  CartItem,
  SetAddonsMethodDto,
} from "@/types/api/cart";

export type ChooseAdditionalFormValues = {
  activeItem: {
    value: string;
    label: string;
    serviceRequests?: any;
  };
  items: {
    [key: string | number]: (number | string)[];
  };
};

export const chooseAdditionalFormHelper = (
  t: TFunction,
  cartItems: CartItem[],
  cartConsolidationItems: (number | string)[],
  consolidationAddons: ConsolidationAddonsMethod[],
  addons: AddonsMethod[],
  setAddonsMethods: (
    data: SetAddonsMethodDto,
  ) => Promise<CartCommonResponseDto>,
  setConsolidationAddonsMethods: (
    ids: number[],
  ) => Promise<CartCommonResponseDto>,
  deleteAddonsMethods: () => Promise<void>,
  setAccess: ProgressDeliverySetAccessFunc,
  navigateFunc: () => void,
) => {
  const getInitialValues = () => {
    const activeItem = { value: "all", label: t("shipping.allPackages") };
    const defaultValues: ChooseAdditionalFormValues = {
      activeItem,
      items: {},
    };

    cartItems.forEach((item) => {
      const res: number[] = [];

      cartConsolidationItems.forEach((id: string | number) => {
        consolidationAddons.forEach((el) => {
          if (el.code === id) {
            res.push(el.id);
          }
        });
      });

      defaultValues.items[item.id] = item.addons.concat(res);
    });

    return defaultValues;
  };

  const initialValues = getInitialValues();

  return {
    initialValues: initialValues,
    onSubmit: (
      { activeItem, ...items }: ChooseAdditionalFormValues,
      { setSubmitting }: FormikHelpers<ChooseAdditionalFormValues>,
    ) => {
      const allPackagesAddons = consolidationAddons
        .filter((addon) => Object.values(items.items)[0].includes(addon.code))
        .map((addon) => addon.id);

      const itemsAddons = Object.keys(items.items).reduce(
        (
          acc: { [key: string | number]: (number | string)[] },
          item: string,
        ) => {
          const filteredItems = items.items[item].filter(
            (id: string | number) =>
              addons.map((addon) => addon.id).includes(+id),
          );

          return { ...acc, [item]: filteredItems };
        },
        {},
      );

      Promise.all([
        setConsolidationAddonsMethods(
          allPackagesAddons.length ? allPackagesAddons : [],
        ),
        Object.keys(itemsAddons).length
          ? setAddonsMethods({ items: itemsAddons })
          : deleteAddonsMethods(),
      ])
        .then(() => {
          setAccess("customInformation");
          navigateFunc();
        })
        .catch(toastResponseError)
        .finally(() => setSubmitting(false));

      return;
    },
  };
};
