import { FC, memo, ReactNode } from "react";

import { ScrollbarProps } from "react-custom-scrollbars-2";

import { ScrollableWrapper } from "./ScrollableForm.styles";

interface ScrollableFormProps extends Omit<ScrollbarProps, "ref"> {
  children: ReactNode;
  className?: string;
}

const ScrollableForm: FC<ScrollableFormProps> = ({
  children,
  className = "",
  ...props
}) => (
  <ScrollableWrapper
    className={className}
    autoHeight
    autoHeightMax={285}
    autoHeightMin={50}
    {...props}
  >
    {children}
  </ScrollableWrapper>
);

export default memo(ScrollableForm);
