import { AxiosResponse } from "axios";
import { FormikHelpers } from "formik";
import * as yup from "yup";

import { toastResponseError } from "@/utils/responseMessageHelper";

import { DeliveryMethodsPanelData } from "@/store/useRightPanelStore";
import {
  SetDeliveryMethodDto,
  SetPackingMethodResponseDto,
} from "@/types/api/cart";
import { OutgoingResponseDto } from "@/types/api/outgoing";
import { OpenRightPanel } from "@/types/common/rightPanel";
import { DeliveryMethodsFormValues } from "@/types/OutgoingForms/deliveryMethods";

export const deliveryMethodsFormik = (
  panelData: DeliveryMethodsPanelData,
  updateDeliveryMethod: (
    deliveryMethod: SetDeliveryMethodDto,
  ) => Promise<AxiosResponse<OutgoingResponseDto>>,
  setCartDeliveryMethod: (
    preferred_carrier: string,
  ) => Promise<SetPackingMethodResponseDto>,
  openRightPanel: OpenRightPanel,
) => ({
  initialValues: {
    deliveryName: panelData.deliveryMethod ?? "",
  },

  validationSchema: () =>
    yup.object().shape({
      deliveryName: yup.string().required(),
    }),

  onSubmit: (
    values: DeliveryMethodsFormValues,
    { setSubmitting }: FormikHelpers<DeliveryMethodsFormValues>,
  ) => {
    const isCartVariant = panelData.isCartVariant;
    const consolidationId = panelData.detailedItemID;

    const updateMethod = isCartVariant
      ? setCartDeliveryMethod(values.deliveryName)
      : updateDeliveryMethod({
          id: consolidationId!,
          data: {
            preferred_carrier: values.deliveryName,
          },
        });

    updateMethod
      .then(() => {
        if (panelData.setAccess) {
          panelData.setAccess();
        }
        openRightPanel(panelData.returnPreviousPanel, panelData);
      })
      .catch(toastResponseError)
      .finally(() => setSubmitting(false));
  },
});
