import { FC, memo } from "react";

import { LinkProps as RouterLinkProps } from "react-router-dom";

import { LinkColor } from "@/enums";

import { LinkWrapper } from "./Link.styles";

interface LinkProps extends RouterLinkProps {
  color?: LinkColor;
}

const Link: FC<LinkProps> = ({ color = LinkColor.DarkGrey, ...otherProps }) => (
  <LinkWrapper color={color} {...otherProps} />
);

export default memo(Link);
