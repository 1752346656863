const dateWithZero = (str: number | string): string => ("0" + str).slice(-2);

interface Formatter {
  currency: (n: number) => string;
  price: (n: number) => string;
  weight: (n: number) => string;
  size: (n: number) => string;
  date_ddmmyyyy: (date: Date) => string;
  roundTo2Decimal: (number: number) => number;
}

const formatter: Formatter = {
  currency: (n: number): string => Number(n).toFixed(2),
  price: (n: number): string => Number(n).toFixed(2),
  weight: (n: number): string => Number(n).toFixed(2),
  size: (n: number): string => Number(n).toFixed(1),
  date_ddmmyyyy: (date: Date): string =>
    `${dateWithZero(date.getDate())}.${dateWithZero(date.getMonth() + 1)}.${date.getFullYear()}`,
  roundTo2Decimal: (number: number): number =>
    Math.round((number + Number.EPSILON) * 100) / 100,
};

export default formatter;
