import styled from "styled-components";

import { ScrollableContent } from "@/components/ScrollableContent";

export const ScrollableWrapper = styled(ScrollableContent)`
  ${(props) => props.theme.xs`
    &,
    & > div {
      min-height: auto !important;
      max-height: initial !important;
    }
  `}
`;
