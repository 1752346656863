import React, { FC, memo, ReactNode } from "react";

import { ScrollableWrapper } from "./RightPanelComponents.styles";

interface TabProps {
  children: ReactNode;
  title?: string | React.ElementType | ReactNode;
  disabled?: boolean;
}

const Tab: FC<TabProps> = ({ children, title, disabled }) => (
  <ScrollableWrapper>{children}</ScrollableWrapper>
);

export default memo(Tab);
