import { FC, memo } from "react";

import { useTranslation } from "react-i18next";

import {
  ServiceRequestIcon,
  ServiceRequestItem,
  ServiceRequestText,
} from "./ServiceRequestsList.style";

import formatter from "@/utils/formatter";

import { requestNames } from "@/store/useServicesRequestStore";
import { IconType } from "@/enums";

interface ServiceRequestsListProps {
  serviceRequests: any;
}

const ServiceRequestsList: FC<ServiceRequestsListProps> = ({
  serviceRequests,
}) => {
  const { t } = useTranslation("common");

  return serviceRequests.map((request: any) => {
    const cost = request.service_request_type.cost;
    const requestType =
      requestNames[
        request.service_request_type.type.toLowerCase() as keyof typeof requestNames
      ];

    return (
      <ServiceRequestItem key={request.id} $isSelected>
        <ServiceRequestIcon type={requestType as IconType} />
        <ServiceRequestText>
          {t(`serviceRequests.${requestType}.title`)}
          <span>${formatter.currency(cost)}</span>
        </ServiceRequestText>
      </ServiceRequestItem>
    );
  });
};

export default memo(ServiceRequestsList);
