import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button } from "@/components/Button";
import { Icon } from "@/components/Icon";
import { Tooltip } from "@/components/Tooltip";

import { ParcelCol } from "../parcels";

export const OutgoingCol = styled(ParcelCol)`
  ${(props) => props.theme.xs`
    &:nth-child(3) {
      order: 1;
    }

    &:first-child {
      order: -3;
      word-break: normal;
    }

    &:nth-child(2) {
      order: -2;
    }

    &:last-child {
      order: -1;
      text-align: end;
    }
  `}
`;

export const SectionHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const HelpIcon = styled(Icon)`
  width: 12px;
  height: 12px;
`;

export const HeaderTitle = styled.p`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  color: ${(props) => props.theme.black50};

  i {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }
`;

export const Section = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 15px;

  &:first-child {
    padding-top: 0;
  }

  ${HeaderTitle} {
    font-weight: 700;
  }
`;

export const InnerSection = styled(Section)`
  padding: 10px 0 5px 24px;

  ${SectionHeader},
  ${HeaderTitle} {
    font-weight: 700;
  }
`;

export const SectionContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 10px;

  &:first-child {
    padding-top: 0;
  }

  img {
    margin-left: 8px;
  }
`;

export const ButtonSection = styled.div`
  position: absolute;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  left: 0;
  right: 0;
  bottom: 100%;
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    )
    no-repeat;
`;

export const PayButton = styled(Button)`
  font-weight: bold;
  padding: 9px 30px;
`;

export const TotalPrice = styled.p`
  margin: 12px 0;
  color: ${(props) => props.theme.black50};

  span {
    color: ${(props) => props.theme.black};
    font-size: 16px;
    font-weight: 700;
    margin-left: 4px;
  }

  i {
    display: inline-block;
    width: 13px;
    height: 13px;
    margin-left: 4px;
  }
`;

export const TimerSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
  padding: 10px 20px;
  background-color: rgba(225, 226, 230, 0.25);

  i {
    width: 30px;
    height: 30px;
    margin-right: 12px;
  }
`;

export const TextWrapper = styled.div`
  color: ${(props) => props.theme.black50};
  font-size: 12px;

  p {
    margin: 6px 0;

    span {
      font-weight: 700;
    }
  }
`;

export const Warning = styled.div`
  background-color: rgba(255, 93, 95, 0.1);
  color: rgba(0, 0, 0, 0.5);
  padding: 12px;
  font-size: 12px;
  line-height: 18px;
`;

export const ExcessMarker = () => {
  const { t } = useTranslation("common");

  return (
    <Tooltip
      body={t("tooltips.excessMarker")}
      place={"bottom"}
      openOnClick={isMobile}
    >
      <ExcessMarkerWrapper>
        <ExcessMarkerIcon />
      </ExcessMarkerWrapper>
    </Tooltip>
  );
};

const ExcessMarkerWrapper = styled.div`
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExcessMarkerIcon = styled.div`
  ::after {
    content: "*";
  }
  margin-left: 2px;
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.red};
`;
