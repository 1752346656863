import React, { FC } from "react";

import { SideAddonItem } from "@/containers/Delivery/components/SideAddons/SideAddonItem";
import {
  getAddonDescription,
  getConsolidationAddonsIconsImage,
} from "@/containers/Delivery/helpers";

import {
  AddonsItems,
  AddonsWrapper,
  AllAddonsWrapper,
  AllConsolidationItemsWrapper,
  ItemIcon,
  ListItem,
} from "./AddonsSummary.style";

import {
  AddonsMethod,
  ConsolidationAddonsMethod,
} from "@/store/useServicesStore";
import { CartItem } from "@/types/api/cart";

export const AddonsSummaryShowSection = {
  ALL: "ALL",
  ONLY_CONSOLIDATION_OPTIONS: "ONLY_CONSOLIDATION_OPTIONS",
  ONLY_ADDONS: "ONLY_ADDONS",
};

interface AddonsSummaryProps {
  sectionVisibility: string;
  addons?: AddonsMethod[];
  items?: CartItem[];
  consolidationItems: (string | number)[];
  consolidationAddons: ConsolidationAddonsMethod[];
}

const AddonsSummary: FC<AddonsSummaryProps> = ({
  addons = [],
  items = [],
  consolidationAddons,
  consolidationItems,
  sectionVisibility = AddonsSummaryShowSection.ALL,
}) => {
  const isShowAddons =
    sectionVisibility === AddonsSummaryShowSection.ALL ||
    sectionVisibility === AddonsSummaryShowSection.ONLY_ADDONS;
  const isShowConsolidation =
    items &&
    (sectionVisibility === AddonsSummaryShowSection.ALL ||
      sectionVisibility ===
        AddonsSummaryShowSection.ONLY_CONSOLIDATION_OPTIONS);

  return (
    <AddonsWrapper>
      <AddonsItems>
        {isShowAddons && (
          <AllAddonsWrapper>
            {consolidationItems.map((id) => (
              <ListItem key={id}>
                <ItemIcon>
                  {getConsolidationAddonsIconsImage(id, consolidationAddons)}
                </ItemIcon>
                {getAddonDescription(id, consolidationAddons)}
              </ListItem>
            ))}
          </AllAddonsWrapper>
        )}
        {isShowConsolidation && (
          <AllConsolidationItemsWrapper>
            {items.map((item) => (
              <SideAddonItem
                key={item.id}
                addons={addons}
                consolidationAddons={consolidationAddons}
                itemAddons={item.addons}
                cartItem={item}
                showAsCompact
              />
            ))}
          </AllConsolidationItemsWrapper>
        )}
      </AddonsItems>
    </AddonsWrapper>
  );
};

export default React.memo(AddonsSummary);
