import React, { FC, useCallback, useEffect } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { Collapse } from "react-collapse";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
  AddonsSummary,
  AddonsSummaryShowSection,
} from "@/components/AddonsSummary";

import { useCartImportCustomsDeclarations } from "@/hooks/react-query/cart";

import { useCartStore, useServicesStore } from "@/store";
import { CartQueryKey } from "@/types";

import { SideItem, SideItemHead } from "../../styles";
import { ScrollableWrapper } from "../SideAddons/SideAddonsInfo/SideAddonsInfo.styles";
import {
  AddonsWrapper,
  ChangeLink,
  Logo,
  SideDescription,
  SideParagraph,
} from "./Summary.styles";

interface SummaryBlockProps {
  userFfid?: number;
  className?: string;
}

const SummaryBlock: FC<SummaryBlockProps> = React.memo(({ className = "" }) => {
  const { t } = useTranslation("common");
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const {
    deliveryMethods,
    packingMethods,
    consolidationAddonsMethods,
    getSelectAddons,
  } = useServicesStore();
  const { cart } = useCartStore();

  const addons = getSelectAddons();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [CartQueryKey.CartImportCustomInformation],
    });
  }, []);

  const cartPacking = cart?.optional_line_item_codes ?? [];
  const cartDelivery = cart?.preferred_carrier;
  const cartConsolidationItems = cart?.addons_codes ?? [];

  const hasPacking = !!packingMethods.length;
  const hasAddons = !!cartConsolidationItems.length;
  const hasConsolidationOptions = !!cart?.items.find(
    (item) => !!item.addons.length,
  );
  const isShowAddons = pathname === "/shipping/summary" && hasAddons;
  const isConsolidationOptions =
    pathname === "/shipping/summary" && hasConsolidationOptions;

  const rendererDelivery = useCallback(() => {
    const deliveryMethod = deliveryMethods.find(
      (method) => method.name === cartDelivery,
    );

    return deliveryMethod ? (
      <SideDescription>
        <Logo logoUrl={deliveryMethod.logo_url} />
        <SideParagraph>{deliveryMethod.name}</SideParagraph>
      </SideDescription>
    ) : null;
  }, [cartDelivery, deliveryMethods]);

  const rendererPacking = useCallback(() => {
    const packingMethod = packingMethods.find(
      (method) => method.code === cartPacking[0],
    );

    return packingMethod ? (
      <SideDescription>
        <Logo logoUrl={packingMethod.logo_url} />
        <SideParagraph>{packingMethod.title}</SideParagraph>
      </SideDescription>
    ) : null;
  }, [cartPacking, packingMethods]);

  return (
    <Collapse isOpened className={className}>
      {cartDelivery && (
        <SideItem>
          <SideItemHead>
            {t("shipping.delivery")}:
            <ChangeLink
              to={"/shipping/flow/delivery"}
              state={{ prevPath: pathname }}
            >
              {t("common.change")}
            </ChangeLink>
          </SideItemHead>
          {rendererDelivery()}
        </SideItem>
      )}
      {hasPacking && (
        <SideItem>
          <SideItemHead>
            {t("shipping.packing")}:
            <ChangeLink
              to={"/shipping/flow/delivery/packing"}
              state={{ prevPath: pathname }}
            >
              {t("common.change")}
            </ChangeLink>
          </SideItemHead>
          {rendererPacking()}
        </SideItem>
      )}
      {isShowAddons && (
        <AddonsWrapper>
          <SideItemHead>
            {t("shipping.optionsForAllPackages")}:
            <ChangeLink
              to={"/shipping/flow/delivery/additional"}
              state={{ prevPath: pathname }}
            >
              {t("common.change")}
            </ChangeLink>
          </SideItemHead>
          <ScrollableWrapper autoHeight autoHeightMax={250}>
            <AddonsSummary
              addons={addons}
              consolidationAddons={consolidationAddonsMethods}
              items={cart?.items}
              consolidationItems={cartConsolidationItems}
              sectionVisibility={AddonsSummaryShowSection.ONLY_ADDONS}
            />
          </ScrollableWrapper>
        </AddonsWrapper>
      )}
      {isConsolidationOptions && (
        <AddonsWrapper>
          <SideItemHead>
            {t("shipping.optionsForIndividualItems")}:
            <ChangeLink
              to={"/shipping/flow/delivery/additional"}
              state={{ prevPath: pathname }}
            >
              {t("common.change")}
            </ChangeLink>
          </SideItemHead>
          <ScrollableWrapper autoHeight autoHeightMax={250}>
            <AddonsSummary
              addons={addons}
              consolidationAddons={consolidationAddonsMethods}
              items={cart?.items}
              consolidationItems={cartConsolidationItems}
              sectionVisibility={
                AddonsSummaryShowSection.ONLY_CONSOLIDATION_OPTIONS
              }
            />
          </ScrollableWrapper>
        </AddonsWrapper>
      )}
    </Collapse>
  );
});

export default SummaryBlock;
