import styled from "styled-components";

export const RadioButtonDefault = styled.input`
  display: none;
`;

export const RadioButtonText = styled.div`
  line-height: 18px;
  margin-left: 8px;
`;

export const RadioButtonLabel = styled.label`
  display: flex;
  position: relative;
  color: ${(props) => props.theme.black50};

  ${RadioButtonDefault}:checked ~ div${RadioButtonText} {
    color: ${(props) => props.theme.black};
  }
`;

interface RadioButtonCustomProps {
  $disabled: boolean;
}

export const RadioButtonCustom = styled.div<RadioButtonCustomProps>`
  width: 18px;
  height: 18px;
  min-width: 18px;
  position: relative;
  cursor: ${({ $disabled }) => ($disabled ? "default" : "pointer")};

  &::before,
  &::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    background-color: ${({ theme, $disabled }) =>
      $disabled ? theme.black05 : theme.white};
  }

  &::before {
    width: 16px;
    height: 16px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border: ${({ theme }) => `1px solid ${theme.grey}`};
    box-shadow: ${({ theme }) => `inset 0 1px 3px 0 ${theme.lightgrey}`};
    transition: border 0.25s ease-in-out;
  }

  &::after {
    width: 10px;
    height: 10px;
    left: 50%;
    top: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.primary};
    border: ${({ theme }) => `1px solid ${theme.primary}`};
    transition: opacity 0.25s ease-in-out;
  }

  ${RadioButtonDefault}:checked + &::before {
    border: ${({ theme }) => `1px solid ${theme.primary}`};
  }

  ${RadioButtonDefault}:checked + &::after {
    opacity: 1;
  }
`;
