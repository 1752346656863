import { fadeIn } from "react-animations";
import styled, { css, keyframes } from "styled-components";

import { Content } from "@/containers/RightPanel/RightPanel.styles";
import { PreLoader } from "@/components/PreLoader";

export const fadeInAnimation = css`
  animation: 0.5s ${keyframes`${fadeIn}`} !important;
`;

export const Container = styled.div`
  width: 100%;
`;

export const ContentContainer = styled(Content)`
  ${fadeInAnimation}

  & > div:first-child {
    display: flex;
    flex-wrap: wrap;
  }

  & > div > button {
    margin: auto auto 0 auto;
  }
`;

export const Title = styled.span`
  margin-left: 10px;
`;

export const Spinner = styled(PreLoader)`
  position: absolute;
  margin-top: 80px;
`;

export const FormWrapper = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 22px;

    ${({ theme }) => theme.xs`
      margin-bottom: 10px;
    `}
  }
`;

export const ButtonsWrapper = styled.div`
  align-self: center;
  padding: 15px 0;

  button {
    margin: 5px;
  }
`;
