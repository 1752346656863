import React, { FC, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";
import { useHistoryStore, useRightPanelStore } from "store";

import { Icon } from "@/components/Icon";
import { PackagesList } from "@/components/PackagesList";

import {
  ExcessMarker,
  HeaderTitle,
  InnerSection,
  Section,
  SectionContent,
  SectionHeader,
} from "@/styles/outgoing";
import { theme } from "@/styles";

import { RightPanelType } from "@/store/useRightPanelStore";
import { IconType } from "@/enums";

interface ParcelInfoTabItemsListProps {
  isExcessItems?: boolean;
  items: any;
}

const ParcelInfoTabItemsList: FC<ParcelInfoTabItemsListProps> = React.memo(
  ({ items, isExcessItems = false }) => {
    const { t } = useTranslation("common");
    const { historyFullDetailedConsolidation: parcel } = useHistoryStore();
    const { panelData, openRightPanel } = useRightPanelStore();

    const itemsCount = (!!items && items.length) || 0;
    const localizedTitle = isExcessItems
      ? t("parcels.excessPackagesIncluded")
      : t("parcels.packagesIncluded");
    const trackNumberColor = isExcessItems ? theme.red : theme.black;
    const foundPackageId = panelData.foundPackageId ?? 0;
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (contentRef.current && foundPackageId)
        contentRef.current.scrollIntoView();
    }, [contentRef.current, foundPackageId]);

    const clickPackagesItem = (item: any) => {
      openRightPanel(RightPanelType.STORAGE_ITEM, {
        ...panelData,
        readOnly: true,
        id: item.shipment_id,
        originalItem: item,
        consolidationId: parcel?.id,
        consolidationSku: parcel?.sku,
        emblem_thumb_url: item.emblem_thumb_url,
        description: item.shipment_tracking_number,
        returnPreviousPanel: RightPanelType.PARCEL_DETAILED,
        rootReturnPanel: RightPanelType.WEEK_ITEM,
        shippingMethod: panelData?.shippingMethod,
        updateComment: () => {},
      });
    };

    return !itemsCount ? null : (
      <Section ref={contentRef}>
        <SectionHeader>
          <HeaderTitle>
            <Icon type={IconType.Package} />
            {`${localizedTitle} (${itemsCount}):`}
            {isExcessItems ? <ExcessMarker /> : null}
          </HeaderTitle>
        </SectionHeader>
        <InnerSection>
          <SectionContent>
            <PackagesList
              extended
              trackNumberColor={trackNumberColor}
              items={items}
              foundItemId={foundPackageId}
              handleClick={clickPackagesItem}
              serviceRequestPanelData={{
                detailedItemID: parcel?.id,
                consolidationSku: parcel?.sku,
                returnPreviousPanel: panelData.returnPreviousPanel,
                readOnly: true,
              }}
            />
          </SectionContent>
        </InnerSection>
      </Section>
    );
  },
);

export default ParcelInfoTabItemsList;
