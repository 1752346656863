import { FC, memo } from "react";

import { useTranslation } from "react-i18next";

import { SelectedYearItem } from "@/types/api/history";

import {
  TitleWeek,
  VertLine,
  WeekDate,
  Wrapper,
} from "./WeekComponents.styles";
import WeekItem from "./WeekItem";

interface WeekProps {
  numberWeek: string;
  units: string;
  items: (SelectedYearItem & { id: number })[];
  selectedYear: number;
}

const Week: FC<WeekProps> = ({ numberWeek, items, units, selectedYear }) => {
  const { t } = useTranslation("common");
  const dateRange = items[0].week_range;

  return (
    <Wrapper>
      <TitleWeek>
        {t("parcels.week", { numberWeek })}
        <VertLine>|</VertLine>
        <WeekDate>{dateRange}</WeekDate>
      </TitleWeek>
      <div>
        {items.map((item, index) => (
          <WeekItem
            units={units}
            key={item.week_range + index}
            item={item}
            selectedYear={selectedYear}
          />
        ))}
      </div>
    </Wrapper>
  );
};

export default memo(Week);
