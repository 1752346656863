import React, { FC, useCallback } from "react";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { DeliveryList } from "@/containers/Delivery/components/DeliveryList";
import {
  BackButton,
  ContentWrapper,
  Heading,
  LeftArrowIcon,
} from "@/containers/RightPanel/RightPanel.styles";
import { Button } from "@/components/Button";

import { useCartSetDeliveryMethod } from "@/hooks/react-query/cart";
import {
  useOutgoingDeliveryMethods,
  useOutgoingUpdateDeliveryMethod,
} from "@/hooks/react-query/outgoing";

import useOutgoingStore from "@/store/useOutgoingStore";
import { useRightPanelStore } from "@/store";
import { ButtonColor, ButtonType, ButtonVariant, IconType } from "@/enums";
import { DeliveryMethodsFormProps } from "@/types/OutgoingForms/deliveryMethods";

import {
  ButtonsWrapper,
  ContentContainer,
  Form,
  Spinner,
} from "./DeliveryMethodsPanel.styles";
import { deliveryMethodsFormik } from "./helpers";

const DeliveryMethodsPanel: FC<DeliveryMethodsFormProps> = React.memo(
  ({ panelData }) => {
    const { t } = useTranslation("common");
    const { openRightPanel } = useRightPanelStore();
    const {
      isLoadingDetailedItem: isLoading,
      deliveryMethods,
      detailedItem,
    } = useOutgoingStore();
    const { mutateAsync: setCartDeliveryMethod } = useCartSetDeliveryMethod();
    const { mutateAsync: updateDeliveryMethod } =
      useOutgoingUpdateDeliveryMethod();

    const {
      handleSubmit,
      values: { deliveryName },
      isValid,
      isSubmitting,
      setFieldValue,
    } = useFormik(
      deliveryMethodsFormik(
        panelData,
        updateDeliveryMethod,
        setCartDeliveryMethod,
        openRightPanel,
      ),
    );

    const id = panelData.detailedItemID;
    const isCartVariant = panelData.isCartVariant;
    const detailed = isCartVariant
      ? panelData.cart
      : id
        ? detailedItem[id]
        : {};

    const handleSelect = useCallback(
      (name: string) => setFieldValue("deliveryName", name),
      [setFieldValue],
    );

    const handleDeliverySubmit = useCallback(
      async (name: string) => {
        handleSubmit();
      },
      [setFieldValue, handleSubmit],
    );

    const handleBack = useCallback(
      () => openRightPanel(panelData.returnPreviousPanel, panelData),
      [openRightPanel, panelData],
    );

    useOutgoingDeliveryMethods(id, { enabled: !!id });

    return (
      <ContentWrapper>
        <Heading $isBordered>
          <BackButton color={ButtonColor.Black50} onClick={handleBack}>
            <LeftArrowIcon type={IconType.Arrow} />
            {detailed?.sku}:
          </BackButton>
          {t("parcels.shippingMethod")}
        </Heading>
        <ContentContainer>
          <Form onSubmit={handleSubmit}>
            <Spinner isActive={isLoading} />
            {!isLoading && (
              <DeliveryList
                deliveryMethods={deliveryMethods}
                selectedDelivery={deliveryName}
                onSelect={handleSelect}
                onSubmit={handleDeliverySubmit}
              />
            )}
            <ButtonsWrapper>
              <Button onClick={handleBack}>{t("common.cancel")}</Button>
              <Button
                isLoading={isSubmitting}
                disabled={!isValid || isSubmitting}
                type={ButtonType.Submit}
                color={ButtonColor.Primary}
                variant={ButtonVariant.Filled}
              >
                {t("common.saveChanges")}
              </Button>
            </ButtonsWrapper>
          </Form>
        </ContentContainer>
      </ContentWrapper>
    );
  },
);

export default DeliveryMethodsPanel;
