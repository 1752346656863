import React, { FC, useCallback, useEffect, useState } from "react";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { OutgoingConsolidationAddonsList } from "@/containers/Delivery/components";
import { PackingList } from "@/containers/Delivery/components/PackingList";
import { Tab, TabsView } from "@/containers/RightPanel/components";
import {
  BackButton,
  ButtonsWrapper,
  ContentWrapper,
  Heading,
  LeftArrowIcon,
} from "@/containers/RightPanel/RightPanel.styles";
import { Button } from "@/components/Button";

import {
  useCartSetConsolidationAddonsMethod,
  useCartSetPackingMethod,
} from "@/hooks/react-query/cart";
import {
  useOutgoingUpdateConsolidationAddons,
  useOutgoingUpdateConsolidationOptionalLineItems,
} from "@/hooks/react-query/outgoing";

import useOutgoingStore from "@/store/useOutgoingStore";
import { useCartStore, useRightPanelStore, useServicesStore } from "@/store";
import { ButtonColor, ButtonType, ButtonVariant, IconType } from "@/enums";
import { PackingGradeFormProps } from "@/types/OutgoingForms/packingGradePanel";

import { packingGradePanelFormik } from "./helpers";
import {
  Container,
  ContentContainer,
  tabsViewStyles,
} from "./PackingGradePanel.styles";

const PackingGradePanel: FC<PackingGradeFormProps> = React.memo(
  ({ panelData }) => {
    const { t } = useTranslation("common");
    const { consolidationAddonsMethods } = useServicesStore();
    const { openRightPanel } = useRightPanelStore();
    const { cart } = useCartStore();
    const { detailedItem } = useOutgoingStore();

    const { mutateAsync: setCartPackingMethods } = useCartSetPackingMethod();
    const { mutateAsync: setCartConsolidationAddonsMethods } =
      useCartSetConsolidationAddonsMethod();
    const { mutateAsync: updateConsolidationAddons } =
      useOutgoingUpdateConsolidationAddons();
    const { mutateAsync: updateConsolidationOptionalLineItems } =
      useOutgoingUpdateConsolidationOptionalLineItems();

    const {
      values: { packingType, packingMethods, selectedAddonCodes },
      setFieldValue,
      isSubmitting,
      handleSubmit,
    } = useFormik(
      packingGradePanelFormik(
        panelData,
        openRightPanel,
        consolidationAddonsMethods,
        cart,
        updateConsolidationAddons,
        updateConsolidationOptionalLineItems,
        setCartPackingMethods,
        setCartConsolidationAddonsMethods,
      ),
    );

    const [activeTab, setActiveTab] = useState(t("parcels.packingGrade"));
    const isCartVariant = panelData.isCartVariant;
    const id = panelData.detailedItemID;
    const detailed = isCartVariant
      ? panelData.cart
      : id
        ? detailedItem[id]
        : {};

    const handleSelect = useCallback(
      (type: string) => {
        setFieldValue("packingType", type);
        setActiveTab(t("parcels.packingOptions"));
      },
      [setFieldValue, setActiveTab, t],
    );

    const handlePackingSubmit = useCallback(
      async (type: string) => {
        handleSubmit();
      },
      [handleSubmit],
    );

    const handleBack = useCallback(
      () => openRightPanel(panelData.returnPreviousPanel, panelData),
      [openRightPanel, panelData],
    );

    return (
      <ContentWrapper>
        <Heading $isBordered>
          <BackButton color={ButtonColor.Black50} onClick={handleBack}>
            <LeftArrowIcon type={IconType.Arrow} />
            {detailed?.sku}:
          </BackButton>
          {t("parcels.packingGradeAndOptions")}
        </Heading>
        <TabsView
          openTab={activeTab}
          onTabChange={setActiveTab}
          tabsViewStyles={tabsViewStyles}
        >
          <Tab title={t("parcels.packingGrade")}>
            <ContentContainer>
              <Container>
                <PackingList
                  packingMethods={packingMethods}
                  packingType={packingType}
                  onSelect={handleSelect}
                  onSubmit={handlePackingSubmit}
                />
              </Container>
            </ContentContainer>
          </Tab>
          <Tab title={t("parcels.packingOptions")}>
            <ContentContainer>
              <Container>
                <OutgoingConsolidationAddonsList
                  addons={consolidationAddonsMethods}
                  selectedCodes={selectedAddonCodes}
                  setFormValues={(values) => {
                    setFieldValue("selectedAddonCodes", values);
                  }}
                />
              </Container>
            </ContentContainer>
          </Tab>
        </TabsView>
        <Container>
          <ButtonsWrapper>
            <Button onClick={handleBack}>{t("common.cancel")}</Button>
            <Button
              onClick={() => handleSubmit()}
              isLoading={isSubmitting}
              disabled={isSubmitting}
              type={ButtonType.Submit}
              color={ButtonColor.Primary}
              variant={ButtonVariant.Filled}
            >
              {t("common.saveChanges")}
            </Button>
          </ButtonsWrapper>
        </Container>
      </ContentWrapper>
    );
  },
);

export default PackingGradePanel;
