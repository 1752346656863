import { ReactNode } from "react";

import { fadeIn } from "react-animations";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import styled, { css, CSSProp, keyframes } from "styled-components";

import { ScrollableContent } from "@/components/ScrollableContent";

export const ScrollableWrapper = styled(ScrollableContent)`
  animation: 0.3s ${keyframes`${fadeIn}`};
`;

export const SwipeContainer = styled(SwipeableViews)`
  height: 100%;

  & > div {
    height: 100%;
    max-height: 100%;
  }
`;

export const highlightStyle = css`
  position: absolute;
  content: "";
  bottom: 0;
  display: block;
  transition: left 0.3s;
  width: 100%;
`;

interface TabsTitleWrapperProps {
  children: ReactNode[];
  index: number;
  tabsViewStyles?: CSSProp;
}

export const TabsTitleWrapper = styled.div<TabsTitleWrapperProps>`
  position: relative;
  display: flex;
  min-height: 25px;

  &::before {
    ${highlightStyle};
    height: 1px;
    background-color: ${(props) => props.theme.black05};
  }

  &::after {
    ${highlightStyle};
    height: 2px;
    width: ${(props) => `${100 / props.children.length}%`};
    left: ${({ children, index }) => `${(100 / children.length) * index}%`};
    background-color: ${(props) => props.theme.primary};
  }

  ${({ tabsViewStyles }) => css`
    ${tabsViewStyles}
  `}
`;

interface TabButtonProps {
  $isActive: boolean;
}

export const TabButton = styled.button<TabButtonProps>`
  width: 100%;
  padding: 0 0 15px 0;
  text-align: center;
  transition: color 0.3s;
  user-select: none;

  color: ${({ $isActive, theme }) =>
    $isActive ? `${theme.primaryLight} !important` : theme.darkGrey};

  &:hover {
    color: ${(props) => props.theme.primary05};
  }
`;
