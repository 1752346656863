import React, { ChangeEvent, FC, useCallback } from "react";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { Input } from "@/components/Input";

import { useDebouncedCallback } from "@/hooks";

import { InventoryItemCommentProps } from "@/types/OutgoingForms/InventoryItemComment";

import { InventoryItemCommentFormik } from "./helpers";
import { CommentWrapper } from "./InventoryItemCommentSkeleton";

const InventoryItemComment: FC<InventoryItemCommentProps> = ({
  shipment,
  updateComment,
}) => {
  const { t } = useTranslation("common");

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik(InventoryItemCommentFormik(t, shipment, updateComment));

  const [debouncedHandleSubmit] = useDebouncedCallback(
    () => handleSubmit(),
    500,
    [values],
  );

  const handleCommentChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleChange(event);
      debouncedHandleSubmit();
    },
    [debouncedHandleSubmit, handleChange],
  );

  return (
    <CommentWrapper>
      <Input
        name="comment"
        onChange={handleCommentChange}
        onBlur={handleBlur}
        tooltip={errors.comment as string}
        invalid={!!errors.comment && !!touched.comment}
        value={values.comment}
        placeholder={t("parcels.leaveComment")}
        isLoading={isSubmitting}
      />
    </CommentWrapper>
  );
};

export default React.memo(InventoryItemComment);
