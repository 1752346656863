import React, { FC } from "react";

import { Col } from "react-grid-system";

import { SideContent, SideWrapper } from "../styles/Delivery.styles";
import SideDeliveryInfo from "./SideDeliveryInfo";

const RightSideContent: FC = () => (
  <Col md={2} sm={12}>
    <SideContent>
      <SideWrapper>
        <SideDeliveryInfo />
      </SideWrapper>
    </SideContent>
  </Col>
);

export default React.memo(RightSideContent);
