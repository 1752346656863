import styled from "styled-components";

import { PreLoader } from "@/components/PreLoader";

import { HeaderTitle } from "@/styles/outgoing";

export const Spinner = styled(PreLoader)`
  position: absolute;
`;

export const Parcel = styled.div`
  border-bottom: 1px solid #f2f2f2;
  padding: 18px 0 10px;

  &:first-child {
    padding: 0;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const ParcelTitle = styled(HeaderTitle)`
  justify-content: space-between;

  span {
    color: ${(props) => props.theme.black};
    font-weight: 700;
    margin-left: 4px;
  }
`;

export const ParcelText = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0;
  padding-left: 5px;
`;

export const Footer = styled.div`
  ${({ theme }) =>
    theme.range(
      "padding",
      "15px",
      "22px",
      `${theme.breakpoints.md}px`,
      `${theme.breakpoints.lg}px`,
    )};
  position: relative;

  ${(props) => props.theme.xs`
    button {
      padding: 9px 18px;
    }
  `}
`;
