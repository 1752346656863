import { FormikHelpers } from "formik";
import { TFunction } from "i18next";
import * as yup from "yup";

import { toastResponseError } from "@/utils/responseMessageHelper";

import { DetailedShipment } from "@/store/useStorageStore";
import { ShipmentsUpdateCommentDto } from "@/types/api/shipments";
import { ShipmentCommentFormValues } from "@/types/OutgoingForms/ShipmentComment";

export const shipmentCommentFormik = (
  t: TFunction,
  shipment: DetailedShipment,
  updateComment: ({ id, data }: ShipmentsUpdateCommentDto) => Promise<void>,
) => ({
  validateOnChange: false,
  validateOnBlur: false,
  enableReinitialize: false,
  initialValues: {
    comment: shipment.customer_comment || "",
  },
  validationSchema: () =>
    yup.object().shape({
      comment: yup
        .string()
        .trim()
        .max(50, t("error.stringShorter", { length: 50 })),
    }),
  onSubmit: (
    values: ShipmentCommentFormValues,
    { setSubmitting }: FormikHelpers<ShipmentCommentFormValues>,
  ) => {
    const id = shipment.id;
    const data = {
      customer_comment: values.comment,
    };
    updateComment({ id, data })
      .catch(toastResponseError)
      .finally(() => setSubmitting(false));
  },
});
