export enum InputType {
  Text = "text",
  Number = "number",
  Email = "email",
  Password = "password",
  Tel = "tel",
  Date = "date",
}

export enum InputIconColor {
  Red = "red",
  LightGrey = "lightgrey",
  Green = "green",
  Primary = "primary",
}
