import color from "color";
import styled from "styled-components";

import { Content } from "@/containers/RightPanel/RightPanel.styles";
import { AddressItem } from "@/components/Addresses/components";
import { Button } from "@/components/Button";
import { Icon } from "@/components/Icon";

import { HeaderTitle } from "@/styles/outgoing";

export const Footer = styled.div`
  padding-top: 10px;
  padding-bottom: 15px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PanelAddressItem = styled(AddressItem)`
  width: 100%;
  padding: 8px 15px 0 25px;
  animation: none;
  border-bottom: none;

  p {
    white-space: pre;
  }
`;

interface ContentWrapperProps {
  isHideFooter: boolean;
}

export const ContentWrapper = styled(Content)<ContentWrapperProps>`
  ${({ isHideFooter }) =>
    !isHideFooter &&
    `
    height: calc(100% - 60px) !important;
  `}
`;

export const ImageSection = styled.div`
  min-height: 358px;
  width: 100%;
`;

export const CancelIcon = styled(Icon)`
  width: 12px;
  height: 12px;
  margin-right: 4px;
  transition: background-color 0.2s;
  background-color: ${({ theme }) => theme.red};
`;

export const CancelButton = styled(Button)`
  display: flex;
  align-items: center;
  margin-right: 15px;

  &:hover {
    ${({ theme }) => `
      ${CancelIcon} {
        background-color: ${color(theme.red).alpha(0.5)};
      }
    `}
  }
`;

export const SKUTitle = styled(HeaderTitle)`
  color: black;
`;
