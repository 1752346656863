import React, { FC, MouseEvent } from "react";

import { useRightPanelStore } from "@/store";
import { AddressDto } from "@/types/api/destinations";

import { TableCol, TableRow, WrapperRecipientItemRow } from "../../styles";

interface BooksRecipientsItemRowProps {
  item: AddressDto;
  disabled: boolean;
  onFocusRecipientItemInPanel: (e: MouseEvent, item: AddressDto) => void;
  selectedRecipientId: number | null;
}

const BooksRecipientsItemRow: FC<BooksRecipientsItemRowProps> = React.memo(
  ({ item, disabled, onFocusRecipientItemInPanel, selectedRecipientId }) => {
    const { isOpenRightPanel } = useRightPanelStore();
    const recipientName = `${item.first_name} ${item.last_name}`;
    const country = item.country;
    const zipCode = item.zip_code;
    const city = item.city;
    const phone = item.phone;
    const address1 = item.address_1;

    const isSelectedRecipient =
      isOpenRightPanel && selectedRecipientId === item.id;

    const recipientInfo = `${address1}, ${city}, ${zipCode}, ${country}, ${phone}`;

    return (
      <>
        <WrapperRecipientItemRow disabled={disabled}>
          <TableRow
            onClick={(e) => onFocusRecipientItemInPanel(e, item)}
            selected={!!isSelectedRecipient}
          >
            <TableCol xs={6} md={3}>
              {recipientName}
            </TableCol>
            <TableCol xs={6} md={9}>
              {recipientInfo}
            </TableCol>
          </TableRow>
        </WrapperRecipientItemRow>
      </>
    );
  },
);

export default BooksRecipientsItemRow;
