import { FC, memo } from "react";

import { Outlet } from "react-router-dom/dist";

import { PageContainer } from "./styles";

const Books: FC = () => (
  <PageContainer>
    <Outlet />
  </PageContainer>
);
export default memo(Books);
