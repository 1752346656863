import { useEffect } from "react";

import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import DashboardAPI from "@/api/dashboard_api";

import useDashboardStore, { DashboardDto } from "@/store/useDashboardStore";
import { FaqKBHelpersDto } from "@/types/api/dashboard";
import { DashboardQueryKey as QueryKey } from "@/types";

export function useDashboard(
  options?: Omit<UseQueryOptions<DashboardDto>, "queryKey" | "queryFn">,
) {
  const { updateDashboardData } = useDashboardStore();

  const fetchDashboard = useQuery({
    queryKey: [QueryKey.Dashboard],
    queryFn: DashboardAPI.getDashboard,
    ...options,
  });

  const { data: DashboardData, isSuccess } = fetchDashboard;

  useEffect(() => {
    if (isSuccess && DashboardData) {
      updateDashboardData(DashboardData);
    }
  }, [isSuccess, DashboardData]);

  return fetchDashboard;
}

export function useFAQ(
  currentLocale: string,
  options?: Omit<UseQueryOptions<FaqKBHelpersDto[]>, "queryKey" | "queryFn">,
) {
  const { updateFAQData } = useDashboardStore();

  const fetchFAQ = useQuery({
    queryKey: [QueryKey.FAQ],
    queryFn: () => DashboardAPI.getFAQ({ currentLocale }),
    ...options,
  });

  const { data: FAQData, isSuccess } = fetchFAQ;

  useEffect(() => {
    if (isSuccess && FAQData) {
      updateFAQData(FAQData);
    }
  }, [isSuccess, FAQData]);

  return fetchFAQ;
}

export function useKBHelpers(
  currentLocale: string,
  options?: Omit<UseQueryOptions<FaqKBHelpersDto[]>, "queryKey" | "queryFn">,
) {
  const { updateKbHelpersData } = useDashboardStore();

  const fetchKBHelpers = useQuery({
    queryKey: [QueryKey.KBHelpers],
    queryFn: () => DashboardAPI.getKnowledgeBaseHelpers({ currentLocale }),
    ...options,
  });

  const { data: KBHelpersData, isSuccess } = fetchKBHelpers;

  useEffect(() => {
    if (isSuccess && KBHelpersData) {
      updateKbHelpersData(KBHelpersData);
    }
  }, [isSuccess, KBHelpersData]);

  return fetchKBHelpers;
}
