import { ChangeEvent, FC, memo, useCallback } from "react";

import { isEqual } from "lodash-es";
import { useTranslation } from "react-i18next";

import { Icon } from "@/components/Icon";
import { Input } from "@/components/Input";

import { IconType } from "@/enums";

import { InputWrapper, SearchButton } from "./SearchInput.styles";

interface SearchInputControlledProps {
  value: string;
  placeholder: string;
  initialValue: string;
  onChange: (value: string) => void;
  name?: string;
}

const SearchInputControlled: FC<SearchInputControlledProps> = ({
  value,
  initialValue,
  placeholder,
  onChange,
  name = "",
}) => {
  const { t } = useTranslation("common");
  const isDirty = !isEqual(initialValue, value);
  const iconType = isDirty ? IconType.Close : IconType.Search;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const handleButtonClick = useCallback(() => {
    if (isDirty) {
      onChange(initialValue);
    }
  }, [isDirty, onChange, initialValue]);

  return (
    <InputWrapper>
      <Input
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        name={name}
      />
      <SearchButton
        title={t("common.search")}
        aria-label="search"
        onClick={handleButtonClick}
      >
        <Icon type={iconType} />
      </SearchButton>
    </InputWrapper>
  );
};

export default memo(SearchInputControlled);
