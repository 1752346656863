import React, { FC, MouseEvent, useCallback, useState } from "react";

import { RightPanelType } from "@/store/useRightPanelStore";
import { useRightPanelStore } from "@/store";
import { AddressDto } from "@/types/api/destinations";

import BooksRecipientsItemRow from "./BooksRecipientsItemRow";
import { BooksRecipientListWrapper } from "./BooksRecipientsList.styles";

interface BooksRecipientsListProps {
  recipientsItems: AddressDto[];
  disabled: boolean;
}

const BooksRecipientsList: FC<BooksRecipientsListProps> = React.memo(
  ({ recipientsItems, disabled }) => {
    const { openRightPanel } = useRightPanelStore();
    const [selectedRecipientId, setSelectedRecipientId] = useState<
      number | null
    >(null);

    const editAddress = useCallback(
      (event: MouseEvent, item: AddressDto) => {
        setSelectedRecipientId(item.id ?? null);
        event.stopPropagation();
        openRightPanel(RightPanelType.ADDRESS_FORM, {
          address: item,
          isEditForm: true,
          isRecipientEditForm: true,
          initialPanelData: item,
          addressId: item.id,
        });
      },
      [openRightPanel],
    );

    return (
      <BooksRecipientListWrapper>
        {recipientsItems.map((item) => (
          <BooksRecipientsItemRow
            item={item}
            key={item.id}
            disabled={disabled}
            onFocusRecipientItemInPanel={editAddress}
            selectedRecipientId={selectedRecipientId}
          />
        ))}
      </BooksRecipientListWrapper>
    );
  },
);

export default BooksRecipientsList;
