import { Collapse } from "react-collapse";
import styled from "styled-components";

export const CollapseWrapper = styled(Collapse)`
  padding: 0 30px;

  ${(props) => props.theme.xs`
    padding: 0 15px;
  `}
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
`;

export const ClickableDiv = styled.div`
  cursor: pointer;
  width: 100%;
`;
