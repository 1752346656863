import { FC, memo } from "react";

import { useTranslation } from "react-i18next";

import formatter from "@/utils/formatter";

import { Divider, Text } from "./PackageSizes.styles";

interface PackageSizesProps {
  width: number;
  height: number;
  depth: number;
  units: string;
}

const PackageSizes: FC<PackageSizesProps> = ({
  width,
  height,
  depth,
  units,
}) => {
  const { t } = useTranslation("common");

  return (
    <Text>
      {formatter.size(width)}
      <Divider />
      {formatter.size(height)}
      <Divider />
      {formatter.size(depth)} {t(`units.${units}.secondaryShort`)}
    </Text>
  );
};

export default memo(PackageSizes);
