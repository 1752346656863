import React, { FC, ReactNode } from "react";

import { SideContent, SideWrapper } from "../../styles/Delivery.styles";
import { SideCol } from "./LeftSideContent.styles";

interface LeftSideContentProps {
  children: ReactNode;
}

const LeftSideContent: FC<LeftSideContentProps> = ({ children }) => (
  <SideCol md={3} lg={4} sm={12}>
    <SideContent>
      <SideWrapper>{children}</SideWrapper>
    </SideContent>
  </SideCol>
);

export default React.memo(LeftSideContent);
