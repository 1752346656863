import React, { FC, memo } from "react";

import { DeliveryShippingMethodsResponseDto } from "@/types/api/cart";

import { ListItem, Wrapper } from "../../styles";
import { ServicesLogo } from "../ServicesLogo";
import {
  DeliveryListPrice,
  Description,
  Divider,
  Info,
  Type,
} from "./DeliveryList.styles";

interface DeliveryListProps {
  selectedDelivery: string;
  onSelect: (name: string) => void;
  onSubmit: (type: string) => Promise<void>;
  deliveryMethods: DeliveryShippingMethodsResponseDto[];
}

const DeliveryList: FC<DeliveryListProps> = ({
  deliveryMethods,
  selectedDelivery,
  onSelect,
  onSubmit,
}) => (
  <Wrapper>
    {deliveryMethods &&
      deliveryMethods.map((item) => {
        const name = item.name;

        return (
          <ListItem
            key={name}
            $isSelected={selectedDelivery === name}
            onClick={() => onSelect(name)}
            onDoubleClick={() => onSubmit(name)}
          >
            <ServicesLogo logoUrl={item.logo_url} />
            <Info>
              <Type>
                <b>{name}</b> <Divider>|</Divider> {item.time_frame}
              </Type>
              <Description>
                {item.descriptions.map((description: string, index: number) => (
                  <span key={index}> - {description}</span>
                ))}
              </Description>
            </Info>
            <DeliveryListPrice>
              {item.price}
              <span>{item.price_per}</span>
            </DeliveryListPrice>
          </ListItem>
        );
      })}
  </Wrapper>
);

export default memo(DeliveryList);
