import React, { FC, useCallback } from "react";

import { useTranslation } from "react-i18next";

import {
  Content,
  ContentWrapper,
  Heading,
} from "@/containers/RightPanel/RightPanel.styles";
import { ServiceLogo } from "@/components/ServiceLogo";

import { ButtonSection, PayButton } from "@/styles/outgoing";
import { Currency } from "@/styles/parcels";

import usePurchase from "@/hooks/react-query/purchase";

import useOutgoingStore from "@/store/useOutgoingStore";
import { useAuthStore, usePurchaseStore } from "@/store";
import { ButtonColor, ButtonVariant } from "@/enums";

import {
  Footer,
  Parcel,
  ParcelText,
  ParcelTitle,
  Spinner,
} from "./PayAllPanel.styles";

const PayAllPanel: FC = React.memo(() => {
  const { t } = useTranslation("common");
  const { isLoadingPurchase } = usePurchaseStore();
  const { paymentConfirmAll } = usePurchase();
  const {
    outgoingTypes: { waiting_for_payment: data },
  } = useOutgoingStore();
  const { mutateAsync: paymentConfirmAllMutate } = paymentConfirmAll;
  const items = data.items;
  const isLoading = data.isLoading;
  const totalPrice = data.totalPrice;
  const {
    userAuth: { display_weight_in: units },
  } = useAuthStore();

  const handlePayAll = useCallback(() => {
    paymentConfirmAllMutate();
  }, [paymentConfirmAllMutate]);

  const renderLoading = () => {
    return <Spinner isActive={isLoading} />;
  };

  const renderParcelsList = () => {
    return items.map((item, index: number) => {
      const deliveryName = item.preferred_carrier;
      const weight = item.total_weight && Number(item.total_weight).toFixed(2);

      return (
        <Parcel key={item.id}>
          <ParcelTitle>
            {`${index + 1} ${item.sku}`}
            <span>
              <Currency>$</Currency> {item?.estimate?.total ?? 0}
            </span>
          </ParcelTitle>
          <ParcelText>
            <ServiceLogo serviceKey={deliveryName} />
            {`${deliveryName}, ${weight} ${t(`units.${units}.primaryShort`)}`}
          </ParcelText>
        </Parcel>
      );
    });
  };

  return (
    <ContentWrapper>
      <Heading $isBordered>{t("common.payAll")}</Heading>
      <Content>{isLoading ? renderLoading() : renderParcelsList()}</Content>
      <Footer>
        <ButtonSection>
          <PayButton
            variant={ButtonVariant.Filled}
            disabled={isLoadingPurchase}
            isLoading={isLoadingPurchase}
            onClick={handlePayAll}
            color={ButtonColor.Secondary}
          >
            {t("common.payAll")} (${totalPrice})
          </PayButton>
        </ButtonSection>
      </Footer>
    </ContentWrapper>
  );
});

export default PayAllPanel;
