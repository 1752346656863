import React, { FC, useCallback, useState } from "react";

import { Hidden, Visible } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "@/components/Button";
import { Checkbox } from "@/components/Checkbox";
import { LoadingBar } from "@/components/LoadingBar";

import { StorageButton } from "@/styles/parcels";

import { useCart } from "@/hooks/react-query/cart";
import { useAddonsMethods } from "@/hooks/react-query/services";
import {
  addParcelsToCart,
  useShipment,
  useShops,
  useStorageUpdateComment,
  useStorageUpdateItemComment,
} from "@/hooks/react-query/storage";
import { useProgressDelivery } from "@/hooks";

import { RightPanelType } from "@/store/useRightPanelStore";
import {
  useAuthStore,
  useCartStore,
  useRightPanelStore,
  useServicesStore,
  useStorageStore,
} from "@/store";
import { ButtonColor, ButtonSize, ButtonVariant, IconType } from "@/enums";

import { LeftArrowIcon } from "../RightPanel/RightPanel.styles";
import CartInfo from "../Storage/components/CartInfo/CartInfo";
import SelectedInfo from "../Storage/components/SelectedInfo/SelectedInfo";
import { InventoryList, InventoryListPlaceholder } from "./components";
import {
  BackButtonInventory,
  Buttons,
  ButtonsWrapperInventory,
  SelectAllSwitchWrapper,
  StorageHeading,
  StyledGrid,
  StyledListHeader,
  StyledListHeaderTitle,
  WrapperInventory,
} from "./styles";

const Inventory: FC = () => {
  const { t } = useTranslation("common");
  const {
    userAuth: { display_weight_in: units },
    getAuthSelectFfid,
  } = useAuthStore();
  const { isCartLoading, cart } = useCartStore();
  const { shops } = useStorageStore();
  const { addonsMethods: addons } = useServicesStore();
  const { openRightPanel } = useRightPanelStore();
  const {
    updateSelectedStorageItemId,
    detailedShipment,
    isLoadingShipment: isLoading,
  } = useStorageStore();
  const { mutateAsync: updateComment } = useStorageUpdateComment();
  const { mutateAsync: updateItemComment } = useStorageUpdateItemComment();

  useCart({ enabled: !isCartLoading });
  useAddonsMethods({ enabled: !addons.length });

  const userFfid = getAuthSelectFfid();
  const [, setAccess] = useProgressDelivery(userFfid);
  const params = useParams();
  const [selectedItems, setSelectedItems] = useState<any>({});
  const id = parseInt(params.id ?? "");
  const navigate = useNavigate();

  const shipment = detailedShipment[id] ?? { items: [] };
  const items = shipment.items.filter(
    (x) =>
      x.status === "Active" ||
      x.status === "InContainer" ||
      x.status === "ContainerPacked",
  );

  const hasItems = !!items.length;
  const cartItems = cart?.items ?? [];
  const isCartEmpty = !cartItems.length;
  const getIsItemInCart = (itemId: number) => {
    return cartItems.some((item) => item.id === itemId);
  };

  const selectedParcels = Object.values(selectedItems).filter(
    (item: any) => item.isSelectedState && !getIsItemInCart(item.id),
  );
  const selectableItems = items.filter((item) => !getIsItemInCart(item.id));
  const selectedParcelsWeight = selectedParcels
    .reduce((sum: number, item: any) => {
      const itemId = items.find((x) => x.id === item.id);
      return itemId?.weight ? sum + itemId.weight : sum;
    }, 0)
    .toFixed(2);
  const totalSelectableItemsWeight = selectableItems
    .reduce((sum: number, item: any) => sum + item.weight, 0)
    .toFixed(2);
  const hasSelected = selectedParcels.length > 0;
  const isAllSelected = selectedParcels.length === selectableItems.length;

  useShops({ enabled: !shops.length });
  const { refetch: getShipment } = useShipment(id);

  const toggleAllItemsSelectState = (nextValue: any) => {
    const nextSelectedItems: any = {};
    selectableItems.forEach((item: any) => {
      nextSelectedItems[item.id] = selectedItems[item.id]
        ? {
            ...selectedItems[item.id],
            isSelectedState: nextValue,
          }
        : {
            id: item.id,
            isSelectedState: nextValue,
            ...(item.quantity > 1 && { selectedQuantity: 1 }),
          };
    });
    setSelectedItems(nextSelectedItems);
  };

  const handleSelectAll = () => {
    toggleAllItemsSelectState(true);
  };

  const handleDeselectAll = () => {
    toggleAllItemsSelectState(false);
  };

  const handleSend = useCallback(() => {
    if (!hasSelected && !isCartEmpty) {
      navigate("/shipping");
      return;
    }

    const retVal = {
      id: selectedParcels.map((item: any) => item.id),
      decant_ids: selectedParcels
        .filter((x: any) => x.selectedQuantity)
        .reduce(
          (
            accumulator: {
              [key: string]: number;
            },
            item: any,
          ) => {
            return { ...accumulator, [item.id]: item.selectedQuantity };
          },
          {},
        ),
      useCartItemsApi: true,
      shipmentId: id,
    };
    addParcelsToCart(retVal);
    setSelectedItems({});
    if (isCartEmpty) {
      setAccess("initialSteps");
    }
  }, [selectedParcels, hasSelected, isCartEmpty, setAccess]);

  const handleBack = useCallback(() => {
    openRightPanel(RightPanelType.STORAGE_ITEM, {
      getShipment,
      updateComment,
      updateItemComment,
      hasInventoryItems: true,
      originalItem: shipment,
      isCartVariant: true,
      preventAutoCloseOnNavigationChange: true,
      ...shipment,
    });
    updateSelectedStorageItemId(id);
    navigate("/parcels");
  }, [
    openRightPanel,
    getShipment,
    updateComment,
    updateItemComment,
    shipment,
    id,
  ]);

  const handleSelectItems = (selectedItems: any) => {
    setSelectedItems((prevState: any) => ({
      ...prevState,
      ...selectedItems,
    }));
  };

  return (
    <WrapperInventory>
      <StyledGrid fluid>
        <LoadingBar isLoading={isLoading} isCartLoading={isCartLoading} />
        <StorageHeading>
          {!isLoading && (
            <StyledListHeader>
              {selectableItems.length > 0 && (
                <Visible xs>
                  <SelectAllSwitchWrapper>
                    <Checkbox
                      checked={isAllSelected}
                      onChange={() =>
                        isAllSelected ? handleDeselectAll() : handleSelectAll()
                      }
                    />
                  </SelectAllSwitchWrapper>
                </Visible>
              )}
              <StyledListHeaderTitle>
                <BackButtonInventory
                  color={ButtonColor.Black50}
                  onClick={handleBack}
                >
                  <LeftArrowIcon type={IconType.Arrow} />
                  {shipment.description}
                </BackButtonInventory>
                {t("parcels.total", { amount: selectableItems.length })}
                {selectableItems.length > 0 && (
                  <span>
                    {` / ${totalSelectableItemsWeight} ${t(
                      `units.${units}.primaryShort`,
                    )}`}
                  </span>
                )}
              </StyledListHeaderTitle>
              {selectableItems.length > 0 && (
                <Hidden xs>
                  {isAllSelected ? (
                    <StorageButton onClick={handleDeselectAll}>
                      {t("common.deselect")}
                    </StorageButton>
                  ) : (
                    <StorageButton onClick={handleSelectAll}>
                      {t("common.selectAll")}
                    </StorageButton>
                  )}
                </Hidden>
              )}
            </StyledListHeader>
          )}
        </StorageHeading>
        {hasItems && (
          <InventoryList
            units={units}
            items={items}
            selectedItems={selectedItems}
            disabled={isCartLoading}
            getIsItemInCart={getIsItemInCart}
            handleSelectItems={handleSelectItems}
            shipment={shipment}
          />
        )}
        {!hasItems && !isLoading && <InventoryListPlaceholder />}
        {(hasSelected || !isCartEmpty) && (
          <ButtonsWrapperInventory>
            <Buttons>
              {hasSelected ? (
                <SelectedInfo
                  weight={selectedParcelsWeight}
                  selectedParcelsNumber={selectedParcels.length}
                />
              ) : (
                <CartInfo />
              )}
              <Button
                variant={ButtonVariant.Filled}
                color={
                  hasSelected ? ButtonColor.Primary : ButtonColor.Secondary
                }
                size={ButtonSize.Large}
                disabled={isCartLoading}
                onClick={handleSend}
              >
                {hasSelected ? t("common.addToCart") : t("common.sendTheCart")}
              </Button>
              {hasSelected && (
                <StorageButton
                  disabled={isCartLoading}
                  onClick={handleDeselectAll}
                >
                  {t("common.deselect")}
                </StorageButton>
              )}
            </Buttons>
          </ButtonsWrapperInventory>
        )}
      </StyledGrid>
    </WrapperInventory>
  );
};

export default React.memo(Inventory);
