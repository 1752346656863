import styled from "styled-components";

import { Button } from "@/components/Button";

export const Title = styled.h2`
  font-size: 18px;
  margin: 0 0 14px;
  user-select: none;
`;

export const CancelWrapper = styled.div`
  background-color: #fff;
  margin: auto;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  text-align: center;

  button {
    margin: 6px 20px;
    padding: 12px 22px;
    font-size: 16px;
  }
`;

export const DeleteButton = styled(Button)`
  && {
    margin-bottom: 20px;
  }
`;

export const NotLeave = styled.div`
  padding: 0 20px;
  border-top: 2px solid ${(props) => props.theme.lightgrey};
`;

export const NotLeaveButton = styled(Button)`
  width: 100%;

  && {
    margin: 20px 0 0;
  }
`;
