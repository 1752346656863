import React, { FC } from "react";

import {
  components,
  SingleValueProps as ReactSingleValueProps,
} from "react-select";

import { SingleWrapper, ValueLogo } from "./SelectComponents.styles";

interface SelectProps {
  inputValue: string;
  image?: string;
}

const SingleValue: FC<ReactSingleValueProps<SelectProps>> = React.memo(
  ({ selectProps, ...otherProps }) => {
    const { inputValue, value } = selectProps;

    const image = !inputValue && value && "image" in value ? value?.image : "";

    return (
      <SingleWrapper $hasImage={!!image}>
        <ValueLogo image={!!image ? image : ""} />
        <components.SingleValue selectProps={selectProps} {...otherProps} />
      </SingleWrapper>
    );
  },
);

export default SingleValue;
