import React, { FC, memo } from "react";

import { useNavigate } from "react-router-dom";

import { Overlay } from "./SignInComponents.styles";
import SignInForm from "./SignInForm";

const SignInModal: FC = () => {
  const navigate = useNavigate();

  return (
    <Overlay>
      <SignInForm signInModal redirect={() => navigate(0)} />
    </Overlay>
  );
};

export default memo(SignInModal);
