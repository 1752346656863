import React, { ChangeEvent, FC, useCallback } from "react";

import { Container, File, InputFile, Label } from "./FileUploader.style";

interface FileUploaderProps {
  handleFile: (file: File) => void;
  acceptFormats: string;
  title: string;
  disabled?: boolean;
  backgroundColor?: string;
}

const FileUploader: FC<FileUploaderProps> = ({
  title,
  handleFile,
  acceptFormats,
  disabled = false,
  backgroundColor = "white",
}) => {
  const didSelectFile = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let file = e.target.files ? e.target.files[0] : null;
      if (file) handleFile(file);
    },
    [handleFile],
  );

  return (
    <Container $backgroundColor={backgroundColor}>
      <File />
      <Label>{title}</Label>
      <InputFile
        type="file"
        accept={acceptFormats}
        onChange={didSelectFile}
        disabled={disabled}
        data-testId={"fileUploader"}
      />
    </Container>
  );
};

export default React.memo(FileUploader);
