import { fadeInLeft, fadeOutRight } from "react-animations";
import { Container } from "react-grid-system";
import styled from "styled-components";

import { PageWrapper } from "@/layouts/Layout.styles";
import { LeftArrowIcon } from "@/containers/RightPanel/RightPanel.styles";
import { Button } from "@/components/Button";

import { ListHeader, ListHeaderTitle } from "@/styles/parcels";

export const Wrapper = styled(PageWrapper)`
  position: absolute;
  width: 100%;
  animation: none;

  &.page-enter {
    animation: ${fadeInLeft} 0.22s ease;
  }

  &.page-exit {
    animation: ${fadeOutRight} 0.22s ease;
  }
`;

export const StyledGrid = styled(Container)`
  position: relative;
`;

export const StyledListHeader = styled(ListHeader)`
  align-items: center;
`;

export const StyledListHeaderTitle = styled(ListHeaderTitle)`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const StorageHeading = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const SelectAllSwitchWrapper = styled.div`
  margin-right: 8px;
  transform: translateY(4px);
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  height: 80px;
`;

export const Buttons = styled.div`
  padding: 10px 30px 20px 30px;
  background-color: #fff;
  position: fixed;
  display: flex;
  left: 80px;
  bottom: 0;
  width: 100%;

  & > div {
    display: inline-block;
    margin: 0 10px;
  }

  & > button {
    margin: 0 10px;
    height: 41px;
    min-width: 150px;
  }

  &::before {
    content: "";
    position: fixed;
    box-shadow: 0 1px 38px 22px rgba(255, 255, 255, 0.95);
    background-color: rgba(255, 255, 255, 0.95);
    height: 72px;
    width: 100%;
    bottom: 0;
    left: 110px;
    z-index: -1;
  }

  ${(props) => props.theme.sm`
    left: 0;

    &::before {
      left: 0;
    }
  `}

  ${(props) => props.theme.xs`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    button {
      margin: 5px;
    }
  `}
`;

export const BackButton = styled(Button)`
  font-size: 18px;
  display: inline-flex;
  padding: 0;
  align-items: center;
  margin-right: 20px;

  &:hover {
    ${LeftArrowIcon} {
      opacity: 0.5;
    }
  }
`;
