import styled, { keyframes } from "styled-components";

import { Icon } from "@/components/Icon";
import { PreLoader } from "@/components/PreLoader";

export const Spinner = styled(PreLoader)`
  position: absolute;
  width: 20px;
  height: 14px;
  transform-origin: 0 10px;
  transform: scale(0.5);
`;

export const StyledForm = styled.form``;

export const HelpButton = styled.button`
  display: inline-flex;
  position: absolute;
  margin-left: 5px;
`;

export const HelpIcon = styled(Icon)`
  background-color: ${(props) => props.theme.primary};
  height: 16px;
  width: 16px;
  min-width: 16px;
`;

interface ScrollHintProps {
  isVisible: boolean;
}

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

export const ScrollHint = styled.div<ScrollHintProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 12px;
  left: 35%;
  color: ${(props) => props.theme.primary};
  font-size: 12px;
  padding: 5px 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: fit-content;
  background-color: ${(props) => props.theme.white};

  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition:
    visibility 0s,
    opacity 0.6s ease-in-out;
  transition-delay: ${(props) => (props.isVisible ? "0s" : "0.6s")};

  animation: ${(props) => (props.isVisible ? fadeIn : fadeOut)} 0.6s ease-in-out
    forwards;
`;
