import dayjs from "dayjs";

export const getFormattedDate = (
  date: string | Date,
  locale: string,
  format: string = "DD MMMM, YYYY",
): string => {
  dayjs.locale(locale);
  return dayjs(date).format(format);
};
