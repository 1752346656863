import { useCallback, useEffect, useRef } from "react";

interface DebouncedOptions {
  maxWait?: number;
}

type CallbackFunction = (...args: any[]) => void;

const useDebouncedCallback = (
  callback: CallbackFunction,
  delay: number,
  deps: React.DependencyList,
  options: DebouncedOptions = {},
) => {
  const { maxWait } = options;
  const maxWaitHandler = useRef<NodeJS.Timeout | null>(null);
  const maxWaitArgs = useRef<any[]>([]);
  const functionTimeoutHandler = useRef<NodeJS.Timeout | null>(null);

  const debouncedFunction = useCallback(callback, [...deps, callback]);

  const cancelDebouncedCallback = useCallback(() => {
    if (functionTimeoutHandler.current)
      clearTimeout(functionTimeoutHandler.current);
    if (maxWaitHandler.current) clearTimeout(maxWaitHandler.current);
    maxWaitHandler.current = null;
    maxWaitArgs.current = [];
  }, []);

  useEffect(
    () => () => {
      cancelDebouncedCallback();
    },
    [cancelDebouncedCallback],
  );

  const debouncedCallback = (...args: any[]) => {
    maxWaitArgs.current = args;
    if (functionTimeoutHandler.current)
      clearTimeout(functionTimeoutHandler.current);
    functionTimeoutHandler.current = setTimeout(() => {
      debouncedFunction(...args);
      cancelDebouncedCallback();
    }, delay);

    if (maxWait && !maxWaitHandler.current) {
      maxWaitHandler.current = setTimeout(() => {
        debouncedFunction(...maxWaitArgs.current);
        cancelDebouncedCallback();
      }, maxWait);
    }
  };

  return [debouncedCallback, cancelDebouncedCallback] as const;
};

export default useDebouncedCallback;
