import styled from "styled-components";

export const Type = styled.p`
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 5px;
`;

export const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  line-height: 1.57;
`;

export const Price = styled.div`
  margin-left: 20px;
  color: ${(props) => props.theme.black50};
`;
