import React, { FC } from "react";

import { FormikProps } from "formik";
import { Col, Container } from "react-grid-system";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";

import { Input } from "@/components/Input";

import { CreditRow } from "./CreditCardForm.styles";

interface CreditCardFormValues {
  name: string;
  number: string;
  expiration: string;
  cvv: string;
}

interface CreditCardFormProps {
  values: FormikProps<CreditCardFormValues>["values"];
  handleChange: FormikProps<CreditCardFormValues>["handleChange"];
  errors: FormikProps<CreditCardFormValues>["errors"];
  touched: FormikProps<CreditCardFormValues>["touched"];
  handleBlur: FormikProps<CreditCardFormValues>["handleBlur"];
}

const CreditCardForm: FC<CreditCardFormProps> = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
}) => {
  const { t } = useTranslation("common");

  return (
    <Container fluid>
      <CreditRow>
        <Col xs={12}>
          <Input
            placeholder={t("purchase.creditCard.name")}
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            tooltip={errors.name}
            invalid={touched.name && !!errors.name}
          />
        </Col>
      </CreditRow>
      <CreditRow>
        <Col xs={12}>
          <Input
            placeholder={t("purchase.creditCard.number")}
            name="number"
            value={values.number}
            onChange={handleChange}
            onBlur={handleBlur}
            tooltip={errors.number}
            invalid={touched.number && !!errors.number}
          />
        </Col>
      </CreditRow>
      <CreditRow>
        <Col xs={6}>
          <InputMask
            placeholder={t("purchase.creditCard.expiration")}
            name="expiration"
            value={values.expiration}
            onChange={handleChange}
            onBlur={handleBlur}
            mask={"99/9999"}
            tooltip={errors.expiration}
            invalid={touched.expiration && !!errors.expiration}
          >
            {/* @ts-ignore */}
            {(inputProps) => <Input {...inputProps} />}
          </InputMask>
        </Col>
        <Col xs={6}>
          <Input
            placeholder={t("purchase.creditCard.cvv")}
            name="cvv"
            value={values.cvv}
            onChange={handleChange}
            onBlur={handleBlur}
            tooltip={errors.cvv}
            invalid={touched.cvv && !!errors.cvv}
          />
        </Col>
      </CreditRow>
    </Container>
  );
};

export default React.memo(CreditCardForm);
