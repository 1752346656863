import { create } from "zustand";

import {
  CartItem,
  CartResponseDto,
  DescriptionsByHsCodes,
} from "@/types/api/cart";
import { OutgoingType } from "@/types/api/outgoing";

export interface CartSelectItem {
  id: number;
  sku?: string;
  items: CartItem[];
  weight: string;
  delivery: string;
  state?: string;
  created_at?: string;
}

export interface CartSelectForOutgoing {
  page?: number;
  type?: OutgoingType;
  total?: number;
  totalPrice?: number;
  items: CartSelectItem[];
  isLoading?: boolean;
}

interface UseCartStore {
  cart: CartResponseDto | null;
  isCartLoading: boolean;
  hsCodes: DescriptionsByHsCodes[];
  resetCartStore: () => void;
  removeCartParcels: (id: string | number) => void;
  getCartSelectForOutgoing: () => CartSelectForOutgoing;
  updateCart: (cart: CartResponseDto | null) => void;
  updateHsCodes: (hsCodes: DescriptionsByHsCodes[]) => void;
  updateIsLoadingCart: (bool: boolean) => void;
}

const initialState = {
  cart: null,
  hsCodes: [],
  isCartLoading: false,
};

const useCartStore = create<UseCartStore>((set, get) => ({
  ...initialState,
  updateCart: (cart) =>
    set(() => ({
      cart,
    })),
  updateIsLoadingCart: (bool) =>
    set(() => ({
      isCartLoading: bool,
    })),
  updateHsCodes: (hsCodes) =>
    set(() => ({
      hsCodes,
    })),
  removeCartParcels: (id) =>
    set((state) => {
      if (!state.cart) {
        return state;
      }

      return {
        ...state,
        cart: {
          ...state.cart,
          items: state.cart.items.filter((item) => item.id !== id),
        },
      };
    }),
  getCartSelectForOutgoing: () => {
    const { cart } = get();
    const cartObj: { items: CartSelectItem[] } = { items: [] };
    if (!cart) return cartObj;

    const cartSelectSku = cart.sku;
    const cartSelectCartItems = cart.items ?? [];
    const cartSelectCartId = cart.id;
    const cartSelectCartDeliveryMethod = cart.preferred_carrier ?? "";
    const cartSelectState = cart.state;

    if (!cartSelectCartItems.length) return cartObj;

    const weight = cartSelectCartItems
      .reduce((sum, item) => sum + (item.received_item.weight ?? 0), 0)
      .toFixed(2);

    cartObj.items = [
      {
        id: cartSelectCartId,
        sku: cartSelectSku,
        items: cartSelectCartItems,
        weight,
        delivery: cartSelectCartDeliveryMethod,
        state: cartSelectState,
      },
    ];

    return cartObj;
  },

  resetCartStore: () =>
    set(() => ({
      ...initialState,
    })),
}));

export default useCartStore;
