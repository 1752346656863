import React, { FC } from "react";

import { components, DropdownIndicatorProps } from "react-select";

import { IconType } from "@/enums";

import { SelectArrow } from "./DropdownIndicator.styles";

const DropdownIndicator: FC<DropdownIndicatorProps> = (props) =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <SelectArrow type={IconType.Triangle} />
    </components.DropdownIndicator>
  );

export default React.memo(DropdownIndicator);
