import { useLocalStorage } from "@uidotdev/usehooks";

const initialSteps = {
  addons: false,
  customInformation: false,
  delivery: false,
  destination: true,
  packing: false,
  summary: false,
  initialSteps: false,
};

type FfidType = string;

type StepType = keyof typeof initialSteps;

type ProgressDeliverySteps = keyof typeof initialSteps;

export interface ProgressDeliveryType {
  [key: string]: typeof initialSteps;
}

export type ProgressDeliveryAccess = {
  [key: ProgressDeliverySteps | string]: boolean;
};

export type ProgressDeliverySetAccessFunc = (step: StepType) => void;

type ReturnProgressDelivery = [
  ProgressDeliveryAccess,
  ProgressDeliverySetAccessFunc,
];

const useProgressDelivery = (ffid: FfidType): ReturnProgressDelivery => {
  const [progressDelivery, setProgressDelivery] =
    useLocalStorage<ProgressDeliveryType>("progressDelivery", {
      [ffid]: initialSteps,
    });

  if (!ffid) {
    return [initialSteps, () => {}];
  }

  const changeProgressDelivery: ProgressDeliverySetAccessFunc = (
    step: StepType,
  ) => {
    const updatedProgressDelivery =
      step !== "initialSteps"
        ? {
            ...progressDelivery[ffid],
            [step]: true,
          }
        : initialSteps;

    setProgressDelivery({
      ...progressDelivery,
      [ffid]: updatedProgressDelivery,
    });
  };

  return [progressDelivery[ffid], changeProgressDelivery];
};

export default useProgressDelivery;
