import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;

  button {
    margin: 5px 0;
    padding: 9px 30px;
  }
`;

export const Heading = styled.h3`
  font-weight: normal;
  font-size: 18px;
  padding: 0 20px;

  span {
    color: ${(props) => props.theme.red};
  }
`;

export const Placeholder = styled.div`
  color: ${(props) => props.theme.black50};
  font-size: 14px;
  padding: 0 20px;
  margin-bottom: 25px;
`;
