import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import useAuthStore from "@/store/useAuthStore";

import { ProfileLogo } from "./ProfileLink.styles";

const ProfileLink: FC = React.memo(() => {
  const { t } = useTranslation("common");
  const {
    userAuth: { first_name, last_name },
  } = useAuthStore();
  const userCapital =
    first_name || last_name ? `${first_name[0] + last_name[0]}` : "-";

  return (
    <ProfileLogo title={t("pages.account")} to="/account">
      {userCapital}
    </ProfileLogo>
  );
});

export default ProfileLink;
