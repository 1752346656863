import styled from "styled-components";

import { Logo } from "@/components/Logo";

interface SingleWrapperProps {
  $hasImage: boolean;
}

export const SingleWrapper = styled.div<SingleWrapperProps>`
  ${({ $hasImage }) =>
    $hasImage &&
    `
      .react-select__single-value {
        left: 25px;
      }
    `}
`;

export const ValueLogo = styled(Logo)`
  margin-right: 5px;
`;

export const ScrollableWrapper = styled.div`
  overflow-y: scroll;
  max-height: 300px;
  &::after {
    bottom: -15px;
  }
`;

interface OptionItemProps {
  $isSelected: boolean;
  isFocused: boolean;
}

export const OptionItem = styled.div<OptionItemProps>`
  display: flex;
  padding: 8px 10px;
  min-height: 20px;
  width: 100%;
  color: ${({ $isSelected, theme }) =>
    $isSelected ? theme.black : theme.black50};
  background-color: ${({ $isSelected, theme }) =>
    $isSelected ? `${theme.primaryPale} !important` : theme.white};

  &:hover {
    background-color: ${(props) => props.theme.lightgrey03};
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const Value = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled.div`
  font-size: 12px;
  padding-top: 5px;
  color: ${({ theme }) => theme.black50};
`;

export const SingleLogo = styled(Logo)`
  margin-right: 10px;
`;
