import styled from "styled-components";

import { Button } from "@/components/Button";

import { PriceWrapper } from "@/styles/serviceRequest";

export const Price = styled(PriceWrapper)`
  border-bottom: none;
`;

export const SubmitButton = styled(Button)`
  margin-top: auto;
  align-self: center;
`;

export const TransferWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 8px;
  & > p {
    flex-shrink: 0;
  }
`;

export const TransferCodeWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
`;
