import styled from "styled-components";

export const Wrapper = styled.div`
  && {
    font-size: 12px;
    flex: 0 0 170px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${(props) => props.theme.black50};

    span:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`;
