import { fadeIn } from "react-animations";
import { Container as Grid, Row } from "react-grid-system";
import styled, { keyframes } from "styled-components";

export const DeliveryGrid = styled(Grid)`
  padding: 25px 16px;
  height: 100%;
  min-height: 100%;
  background-color: ${(props) => props.theme.lightgrey03};
  overflow-x: hidden;

  ${(props) => props.theme.xs`
    height: auto;
    padding: 30px 0 !important;
  `}
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 156px);
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.sm`
    max-height: fit-content;
  `}
`;

export const RowWrapper = styled(Row)`
  height: 100%;
  max-height: 500px;

  ${(props) => props.theme.sm`
    max-height: fit-content;
  `}
`;

export const SubmitWrapper = styled.div`
  position: relative;
  padding: 20px 20px;
  margin: auto auto 0 auto;
  width: 100%;
  text-align: center;

  button {
    z-index: 1;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  ${(props) => props.theme.xs`
    padding: 5px 20px 20px 20px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 400px;
    display: flex;
    justify-content: center;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      box-shadow: 0 0 20px 20px rgba(255,255,255,0.8);
      background-color: rgba(255,255,255,0.8);
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }
  `}
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  min-height: fit-content;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const SideContent = styled.div`
  height: calc(100% - 40px);
  margin-top: 40px;
  display: flex;

  ${(props) => props.theme.sm`
    margin: 0 0 30px 0;
    display: flex;
    justify-content: center;
  `}
`;

export const onEnterAnimation = keyframes`
  from {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

export const onExitAnimation = keyframes`
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    opacity: 0;
    visibility: hidden;
    transform: translate3d(-100%, 0, 0) scale(0.95);
  }
`;

export const DeliveryBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  position: absolute;
  user-select: none;
  max-width: 440px;

  form {
    width: 100%;
  }

  &.page-enter {
    animation: ${onEnterAnimation} 0.25s ease;

    ${(props) => props.theme.sm`
      animation: none
    `}
  }

  &.page-exit {
    animation: ${onExitAnimation} 0.25s ease;

    ${(props) => props.theme.sm`
      display: none;
      animation: none
    `}
  }

  ${(props) => props.theme.sm`
    position: static;
  `}
`;

export const Heading = styled.h2`
  font-size: 18px;
  margin-top: 0;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
  user-select: none;

  ${({ theme }) =>
    theme.range(
      "font-size",
      "16px",
      "18px",
      `${theme.breakpoints.lg}px`,
      `${theme.breakpoints.xl}px`,
    )};
`;

export const BlockContent = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid #e1e2e6;
`;

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 10px 20px 0;
`;

export const SideBlock = styled.div`
  width: 100%;
  max-height: 50vh;
  padding: 10px 20px 0;
`;

export const SideWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  user-select: none;

  ${(props) => props.theme.sm`
    width: calc(100% - 40px);
    height: min-content;
    max-width: 400px;
    margin: 20px;
    background-color: ${props.theme.white};
  `}
`;

interface SideItemProps {
  $hideBorder?: boolean;
}

export const SideItem = styled.div<SideItemProps>`
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  flex-direction: column;
  padding: 0 0 20px;
  animation: 0.8s ${keyframes`${fadeIn}`};

  ${({ theme, $hideBorder }) =>
    $hideBorder
      ? `
        border-top: 1px solid ${theme.lightgrey}
      `
      : ""};

  ${({ theme }) =>
    theme.range(
      "padding-bottom",
      "10px",
      "20px",
      `${theme.breakpoints.md}px`,
      `${theme.breakpoints.xl}px`,
    )};

  ${(props) => props.theme.sm`
    margin: 0 20px;

    &:first-of-type {
      border: none;
    }
  `}
`;

export const SideItemHead = styled.div`
  width: 100%;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.black50};
  font-size: 12px;
  padding-bottom: 10px;
  position: relative;
`;

export const SideItemHeadPrice = styled.div`
  position: absolute;
  z-index: 1;
  top: 19px;
  right: 10px;
  font-size: 12px;
  color: ${({ theme }) => theme.black50};
`;

export const SideListItem = styled.p`
  position: relative;
  margin: 0 0 8px 0;
  padding: 0 8px;
  animation: 0.8s ${keyframes`${fadeIn}`};

  &:last-child {
    margin-bottom: 0;
  }
`;

interface ListItemProps {
  $isSelected?: boolean;
  active?: boolean;
}

export const ListItem = styled.div<ListItemProps>`
  display: flex;
  align-items: center;
  padding: 20px;
  transition: background-color 0.2s;
  animation: 0.8s ${keyframes`${fadeIn}`};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.lightgrey};
  }

  ${({ $isSelected, active, theme }) =>
    ($isSelected && `background-color: ${theme.primaryPale}`) ||
    (active && "background-color: rgba(228, 234, 245, 0.25)")};

  ${(props) => props.theme.xs`
    padding: 10px 15px;
  `}
`;
