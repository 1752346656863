import SelectReact, { Props as SelectProps } from "react-select";
import Creatable from "react-select/creatable";
import styled, { css } from "styled-components";

type SelectStylesProps = {
  $invalid: boolean;
  $minHeight: number;
};

export const selectStyles = css<SelectStylesProps>`
  text-align: left;

  .react-select__control--is-disabled {
    background-color: rgba(0, 0, 0, 0.05);
    border-color: #c8c9cc;
  }

  .react-select__control {
    border-color: ${({ theme, $invalid }) =>
      $invalid ? theme.red : "#c8c9cc"};
    border-radius: 0;
    box-shadow: inset 0 1px 3px 0 #e1e2e6;
    padding-left: 8px;
    min-height: ${({ $minHeight }) => `${$minHeight}px`};
    font-size: 14px;
  }

  .react-select__value-container {
    padding: 3px 0;
    display: flex;

    & > div:first-child {
      display: flex;
    }
  }

  .react-select__control--is-focused {
    border-color: ${({ theme, $invalid }) =>
      $invalid ? theme.red : theme.primary};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__indicator {
    margin-top: 3px;
    display: flex;
    transition: transform 0.3s ease-in-out;
  }

  .react-select__control--is-focused .react-select__indicator {
    transform: rotate(-180deg);
  }

  .react-select__control .react-select__indicator i {
    background-color: ${({ theme, $invalid }) => $invalid && theme.red};
  }

  .react-select__control--is-focused .react-select__indicator i {
    background-color: ${({ theme, $invalid }) =>
      $invalid ? theme.red : theme.primary};
  }

  .react-select__menu {
    border-radius: 0;
    margin-top: 6px;
    font-size: 14px;
  }

  .react-select__menu-list {
    max-height: 100%;
  }

  .react-select__indicators {
    height: 32px;
  }

  .react-select__clear-indicator {
    padding: 5px;
  }

  .react-select__clear-indicator > svg {
    height: 24px;
    width: 24px;
    fill: ${({ theme }) => theme.red};
  }
`;

type OptionType = {
  value: string;
  label: string;
  image: string;
};

export const PlainSelect = styled(SelectReact)<
  SelectProps<OptionType, false> & SelectStylesProps
>`
  ${selectStyles}
`;
export const CreatableSelect = styled(Creatable)<
  SelectProps<OptionType, false> & SelectStylesProps
>`
  ${selectStyles}
`;
