import {
  AddonsMethodsResponseDto,
  ConsolidationAddonsMethodsResponseDto,
  PackingMethodsResponseDto,
} from "@/types/api/cart";

export const getTranslatedProp = <
  T extends
    | ConsolidationAddonsMethodsResponseDto
    | AddonsMethodsResponseDto
    | PackingMethodsResponseDto,
>(
  item: T,
  propName: string,
  currentLocale: string,
): string => {
  const locale = currentLocale.toLowerCase();

  switch (locale) {
    case "en":
      return item[`${propName}_en` as keyof T] as string;
    case "ru":
      return item[`${propName}_ru` as keyof T] as string;
    default:
      return item[propName as keyof T] as string;
  }
};
