import React, { FC, useEffect } from "react";

import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";

import { useBooksStore } from "@/store";
import { ButtonColor, ButtonVariant } from "@/enums";
import { BooksAddFundsFormValues } from "@/types";

import { PayButton } from "../../styles";

interface BooksAddFundsPayButtonProps {
  handleSubmit: FormikProps<BooksAddFundsFormValues>["handleSubmit"];
  isSubmitting: FormikProps<BooksAddFundsFormValues>["isSubmitting"];
  isValid: FormikProps<BooksAddFundsFormValues>["isValid"];
  setFieldValue: FormikProps<BooksAddFundsFormValues>["setFieldValue"];
}

const BooksAddFundsPayButton: FC<BooksAddFundsPayButtonProps> = React.memo(
  ({ handleSubmit, isSubmitting, isValid, setFieldValue }) => {
    const { t } = useTranslation("common");
    const { amountFunds } = useBooksStore();
    const payBtnTitle = `${t("common.processBankCard")} ($${amountFunds})`;

    useEffect(() => {
      if (amountFunds) setFieldValue("amount", amountFunds);
    }, [amountFunds]);

    return (
      <PayButton
        onClick={() => handleSubmit()}
        isLoading={isSubmitting}
        disabled={!isValid || isSubmitting}
        variant={ButtonVariant.Filled}
        color={ButtonColor.Secondary}
      >
        {payBtnTitle}
      </PayButton>
    );
  },
);

export default BooksAddFundsPayButton;
