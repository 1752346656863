import { FC, memo } from "react";

import styled from "styled-components";

import { Button } from "@/components/Button";

import { ButtonColor } from "@/enums";

interface HistoryPaginationProps {
  selectedPage: number;
  pageCount: number;
  onSelect: (page: number) => void;
}

const HistoryPagination: FC<HistoryPaginationProps> = ({
  selectedPage,
  onSelect,
  pageCount,
}) => {
  const items = () =>
    [...Array(pageCount)].map((_, i) => {
      const buttonHandler = () => onSelect(i);

      return (
        <Button
          color={i === selectedPage ? ButtonColor.Primary : ButtonColor.Black}
          onClick={buttonHandler}
          key={i}
        >
          {i + 1}
        </Button>
      );
    });

  return <Pagination>{items()}</Pagination>;
};

export default memo(HistoryPagination);

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;
