import { FC, memo } from "react";

import { useTranslation } from "react-i18next";

import stateColor from "./helpers";
import { Indicator, Text, Wrapper } from "./ParcelState.styles";

interface ParcelStateProps {
  state: string;
  isShowText?: boolean;
}

const ParcelState: FC<ParcelStateProps> = ({ state, isShowText = true }) => {
  const { t } = useTranslation("common");

  const text = isShowText ? <Text>{t(`parcels.state.${state}`)}</Text> : null;
  return (
    <Wrapper>
      <Indicator data-testId={"indicator"} color={stateColor(state)} />
      {text}
    </Wrapper>
  );
};

export default memo(ParcelState);
