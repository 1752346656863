import React, { FC, useEffect } from "react";

import { useFormik } from "formik";
import { Row } from "react-grid-system";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { Input } from "@/components/Input";

import {
  AuthLink,
  Fields,
  FieldWrapper,
  Form,
  LinksWrapper,
} from "@/styles/form";

import { useSignIn } from "@/hooks/react-query/userAuth";

import { useAuthStore } from "@/store";
import { ButtonColor, ButtonType, ButtonVariant } from "@/enums";
import { SignInFormProps } from "@/types/signInForm";

import { signInFormik } from "../helpers";

const SignInForm: FC<SignInFormProps> = React.memo(
  ({ signInModal = false, redirect }) => {
    const { t } = useTranslation("common");
    const {
      mutateAsync: handleSignIn,
      data: signInRes,
      isSuccess: isSuccessSignIn,
      isError: isErrorSignIn,
      isPending: isPendingSignIn,
    } = useSignIn();
    const { updateUserAuth, updateIsLoadingAuth, resetAuthStore } =
      useAuthStore();

    const {
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
    } = useFormik(signInFormik(t, handleSignIn, redirect));

    useEffect(() => {
      if (isSuccessSignIn && signInRes?.data) {
        updateUserAuth(signInRes.data, true);
      }
    }, [isSuccessSignIn, signInRes]);

    useEffect(() => {
      if (isErrorSignIn) {
        resetAuthStore();
      }
    }, [isErrorSignIn]);

    useEffect(() => {
      if (isPendingSignIn) {
        updateIsLoadingAuth(isPendingSignIn);
      }
    }, [isPendingSignIn]);

    return (
      <>
        <Row justify="center" align="center">
          <Form onSubmit={handleSubmit}>
            <Fields>
              <FieldWrapper>
                <Input
                  autoFocus
                  name="email"
                  placeholder={t("auth.email")}
                  label={t("auth.email")}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  tooltip={errors.email}
                  invalid={errors.email && touched.email}
                />
              </FieldWrapper>
              <FieldWrapper>
                <Input
                  type="password"
                  name="password"
                  placeholder={t("auth.password")}
                  label={t("auth.password")}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  tooltip={errors.password}
                  invalid={errors.password && touched.password}
                />
              </FieldWrapper>
            </Fields>
            <Button
              isLoading={isSubmitting}
              disabled={isSubmitting}
              color={ButtonColor.Primary}
              type={ButtonType.Submit}
              variant={ButtonVariant.Filled}
            >
              {t("auth.login")}
            </Button>
          </Form>
        </Row>
        {!signInModal && (
          <Row justify="center">
            <LinksWrapper>
              <AuthLink to="/signup">{t("auth.dontHaveAccount")}</AuthLink>
              <AuthLink
                to={{
                  pathname: "/password-reset",
                }}
                state={{ email: values.email }}
              >
                {t("auth.forgotPassword")}?
              </AuthLink>
            </LinksWrapper>
          </Row>
        )}
      </>
    );
  },
);

export default SignInForm;
