import React, { FC, memo, ReactNode } from "react";

import { TabButton } from "./RightPanelComponents.styles";

interface TabTitleProps {
  isActive: boolean;
  handleClick: () => void;
  children: ReactNode;
}

const TabTitle: FC<TabTitleProps> = ({ children, isActive, handleClick }) => (
  <TabButton type="button" $isActive={isActive} onClick={handleClick}>
    {children}
  </TabButton>
);

export default memo(TabTitle);
