import { create } from "zustand";

import { AddressDto } from "@/types/api/destinations";

interface UseDestinationsStore {
  addresses: AddressDto[];
  isRefetchAddress: boolean;
  updateAddresses: (addressesDto: AddressDto[]) => void;
  updateIsRefetchAddress: (bool: boolean) => void;
  resetDestinationsStore: () => void;
}

const initialState = {
  addresses: [],
  isRefetchAddress: false,
};

const useDestinationsStore = create<UseDestinationsStore>((set, get) => ({
  ...initialState,
  updateIsRefetchAddress: (bool) =>
    set(() => ({
      isRefetchAddress: bool,
    })),
  updateAddresses: (addressesDto) =>
    set(() => ({
      addresses: addressesDto,
    })),
  resetDestinationsStore: () =>
    set(() => ({
      ...initialState,
    })),
}));

export default useDestinationsStore;
