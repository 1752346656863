import { FormikHelpers } from "formik";
import { TFunction } from "i18next";
import * as yup from "yup";

import { toastResponseError } from "@/utils/responseMessageHelper";

import { AuthSignUpResponseDto } from "@/types/api/auth";
import { SignInFormValues } from "@/types/signInForm";
import {
  SignUpFormDto,
  SignUpFormOption,
  SignUpFormValues,
} from "@/types/signUpForm";

import dataEN from "../assets/en.json";
import dataRU from "../assets/ru.json";

const englishOnlyRegExp = /^[\x20-\x7E]*$/i;

export const getCountryByCode = (countries: SignUpFormOption[], code: string) =>
  countries.find((country) => country.value === code);

export const getCountriesOptions = (locale = "en") => {
  const lang = locale.toLowerCase();
  return Object.values(lang === "ru" ? dataRU : dataEN)
    .map((value) => ({
      label: value.name,
      value: value.iso,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const getLocalesOptions = (t: TFunction) =>
  [
    { label: t("locales.ru"), value: "RU" },
    { label: t("locales.en"), value: "en" },
  ].sort((a, b) => a.label.localeCompare(b.label));

export const signUpFormik = (
  t: TFunction,
  handleSignUp: (data: SignUpFormDto) => Promise<AuthSignUpResponseDto>,
  handleSignIn: (data: SignInFormValues) => Promise<AuthSignUpResponseDto>,
  redirect: () => void,
) => ({
  validateOnChange: true,
  validateOnBlur: true,
  initialValues: {
    firstName: "",
    lastName: "",
    email: "",
    signupToken: "",
    password: "",
    rePassword: "",
    terms: false,
    country: { value: "", label: "" },
    locale: getLocalesOptions(t)[0],
  },
  validationSchema: () =>
    yup.object().shape({
      firstName: yup
        .string()
        .min(2, t("error.stringLonger", { length: 2 }))
        .max(30, t("error.stringShorter", { length: 30 }))
        .matches(/^\S*$/, t("error.whiteSpaces"))
        .matches(englishOnlyRegExp, t("error.englishOnly"))
        .required(t("error.required", { field: t("auth.firstName") })),
      lastName: yup
        .string()
        .min(2, t("error.stringLonger", { length: 2 }))
        .max(30, t("error.stringShorter", { length: 30 }))
        .matches(/^\S*$/, t("error.whiteSpaces"))
        .matches(englishOnlyRegExp, t("error.englishOnly"))
        .required(t("error.required", { field: t("auth.lastName") })),
      country: yup
        .object()
        .required(t("error.required", { field: t("auth.country") })),
      email: yup
        .string()
        .email(t("error.invalid", { field: t("auth.email") }))
        .matches(englishOnlyRegExp, t("error.englishOnly"))
        .required(t("error.required", { field: t("auth.email") })),
      signupToken: yup
        .string()
        .min(2, t("error.stringLonger", { length: 2 }))
        .max(30, t("error.stringShorter", { length: 30 }))
        .matches(/^\S*$/, t("error.whiteSpaces"))
        .required(t("error.required", { field: t("auth.signupToken") })),
      password: yup
        .string()
        .min(6, t("error.stringLonger", { length: 6 }))
        .matches(/^\S*$/, t("error.whiteSpaces"))
        .matches(/(?=.*[a-zA-Z])/, t("error.hasAlphabetical"))
        .matches(/(?=.*[0-9])/, t("error.hasNumeric"))
        .matches(/[^a-zA-Z0-9\s]/, t("error.hasSymbol"))
        .required(t("error.required", { field: t("auth.password") })),
      rePassword: yup
        .string()
        .oneOf([yup.ref("password")], t("error.invalidRePass"))
        .required(t("error.required", { field: t("auth.rePassword") })),
      terms: yup.bool().oneOf([true], "Terms must be accepted"),
    }),
  onSubmit: (
    values: SignUpFormValues,
    { setSubmitting }: FormikHelpers<SignUpFormValues>,
  ) => {
    const {
      firstName,
      lastName,
      email,
      signupToken,
      password,
      rePassword,
      country,
      locale,
      terms,
    } = values;

    handleSignUp({
      email,
      signup_token: signupToken,
      password,
      password_confirmation: rePassword,
      country: country.value ?? "",
      locale: locale.value ?? "",
      accepted_terms_and_conditions: terms,
      first_name: firstName,
      last_name: lastName,
      user_type: "Forwarding",
    })
      .then(() => {
        //FIXME: delete after completing the task on the BE
        return handleSignIn({ email, password });
      })
      .then(() => {
        redirect();
      })
      .catch(toastResponseError)
      .finally(() => setSubmitting(false));
  },
});
