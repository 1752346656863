import { fadeInLeft, fadeOutRight } from "react-animations";
import styled from "styled-components";

import { PageWrapper } from "@/layouts/Layout.styles";

export const Wrapper = styled(PageWrapper)`
  position: absolute;
  width: 100%;
  animation: none;

  &.page-enter {
    animation: ${fadeInLeft} 0.22s ease;
  }

  &.page-exit {
    animation: ${fadeOutRight} 0.22s ease;
  }
`;

export const HistoryEmpty = styled.p`
  margin: 5px 10px;
  color: ${(props) => props.theme.black50};
`;
