import { ChangeEvent, FC, FocusEvent, useCallback, useState } from "react";

import { FormikProps } from "formik";

import { Input } from "@/components/Input";

import { FormValues } from "@/types";

import { detectCyrillic } from "../../helpers";
import { Box, CustomButton, InnerBox } from "./CustomSelectDescription.styles";

type InitialOptions = { value: string; value_ru: string };

interface CustomSelectDescriptionProps {
  name: string;
  value: string;
  placeholder?: string;
  initialOptions: InitialOptions[];
  handleChange: (selectedOption: string) => void;
  handleBlur: FormikProps<FormValues>["handleBlur"];
}

const CustomSelectDescription: FC<CustomSelectDescriptionProps> = ({
  value,
  handleChange,
  handleBlur,
  initialOptions,
  name,
  placeholder = "",
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isTextCyrillic, setIsCyrillic] = useState(false);
  const [options, setOptions] = useState<InitialOptions[]>([]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const description = event.target.value;
      const descriptionLength = description.length;
      setInputValue(description);

      const isTextCyrillic = detectCyrillic(description);
      setIsCyrillic(isTextCyrillic);

      if (descriptionLength >= 3) {
        const filteredOptions = initialOptions.filter((option) =>
          (isTextCyrillic ? option.value_ru : option.value)
            .toLowerCase()
            .startsWith(
              description.toLowerCase().substring(0, descriptionLength),
            ),
        );
        setOptions(filteredOptions);
        setIsOpenMenu(true);

        handleChange(description);
      } else {
        setIsOpenMenu(false);
        handleChange(description);
      }
    },
    [initialOptions],
  );

  const handleClickButton = (newValue: string) => {
    setInputValue(newValue);
    handleChange(newValue);
    setIsOpenMenu(false);
  };

  const handleInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    setTimeout(() => {
      setIsOpenMenu(false);
    }, 300);
    handleBlur(event);
  };

  return (
    <>
      <Input
        name={name}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        placeholder={placeholder}
      />
      {isOpenMenu && (
        <Box>
          <InnerBox>
            {options.map((el, index) => {
              const value = isTextCyrillic ? el.value_ru : el.value;

              return (
                <CustomButton
                  key={value + index}
                  onClick={() => {
                    handleClickButton(el.value);
                  }}
                >
                  {value}
                </CustomButton>
              );
            })}
          </InnerBox>
        </Box>
      )}
    </>
  );
};

export default CustomSelectDescription;
