import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";

import { Select } from "@/components/Select";

import { useStorageStore } from "@/store";
import { StorageSearchValues } from "@/types/StorageForms/Search";

import { SearchFilter } from "../";
import { TooltipContent } from "../Search.styles";
import { FilterLogo, TitleWrapper } from "./Filter.styles";

const FilterShop: FC<FormikProps<StorageSearchValues>> = React.memo(
  ({ initialValues, setFieldValue, values, handleSubmit, ...otherProps }) => {
    const { t } = useTranslation("common");
    const { shops } = useStorageStore();
    const value = values.vendor;
    const [innerValue, setInnerValue] = useState(value);
    const initialValue = initialValues.vendor;
    const isVendorChanged = !value;
    const shopTitle = isVendorChanged ? t("parcels.search.shop") : value.label;
    const image = value && value.image;
    const shopOptions = useMemo(
      () =>
        shops
          .map((shop) => {
            const name = shop.name;
            const image = shop.thumb_image_url;
            return { label: name, value: name, image };
          })
          .sort((a, b) => a.label.localeCompare(b.label)),
      [shops],
    );

    useEffect(() => {
      setInnerValue(value);
    }, [value]);

    const dropInnerValue = useCallback(() => setInnerValue(value), [value]);

    const applyInnerValue = useCallback(
      () => setFieldValue("vendor", innerValue),
      [innerValue, setFieldValue],
    );

    const handleInnerChange = useCallback((valueVendor: string) => {
      setInnerValue({ ...value, value: valueVendor });
      setFieldValue("vendor", valueVendor);
    }, []);

    const setDefault = useCallback(() => {
      setFieldValue("vendor", initialValue);
      dropInnerValue();
    }, [dropInnerValue, initialValue, setFieldValue]);

    return (
      <SearchFilter
        applyInnerValue={applyInnerValue}
        dropInnerValue={dropInnerValue}
        handleSubmit={handleSubmit}
        setDefault={setDefault}
        title={
          <TitleWrapper>
            <FilterLogo image={image ?? ""} size={18} />
            {shopTitle}
          </TitleWrapper>
        }
        innerTitle={t("parcels.search.selectShop")}
        isActive={!isVendorChanged}
        {...otherProps}
      >
        <TooltipContent>
          <Select
            autoFocus
            value={innerValue}
            onChange={handleInnerChange}
            options={shopOptions}
            placeholder={`${t("parcels.search.shop")}:`}
          />
        </TooltipContent>
      </SearchFilter>
    );
  },
);

export default FilterShop;
