import { AxiosResponse } from "axios";
import { FormikHelpers } from "formik";
import * as yup from "yup";

import { toastResponseError } from "@/utils/responseMessageHelper";

import { ChangingAddressPanelData } from "@/store/useRightPanelStore";
import { SetCartAddressResponseDto } from "@/types/api/cart";
import { AddressDto } from "@/types/api/destinations";
import { OutgoingUpdateAddressDto } from "@/types/api/outgoing";
import { OpenRightPanel } from "@/types/common/rightPanel";
import { ChangingAddressFormValues } from "@/types/OutgoingForms/changingAddressPanel";

export const changingAddressPanelFormik = (
  panelData: ChangingAddressPanelData,
  destinations: AddressDto[],
  updateAddress: (
    updateAddressDto: OutgoingUpdateAddressDto,
  ) => Promise<AxiosResponse<AddressDto>>,
  setCartAddress: (id: string | number) => Promise<SetCartAddressResponseDto>,
  openRightPanel: OpenRightPanel,
) => ({
  initialValues: {
    addressId: panelData.addressId ?? "",
  },
  validationSchema: () =>
    yup.object().shape({
      addressId: yup.string().required(),
    }),
  onSubmit: (
    values: ChangingAddressFormValues,
    { setSubmitting }: FormikHelpers<ChangingAddressFormValues>,
  ) => {
    const isCartVariant = panelData.isCartVariant;
    const selectedAddress = destinations.find(
      (destination) => destination.id && +destination.id === +values.addressId,
    );
    const updateMethod = isCartVariant
      ? setCartAddress(values.addressId)
      : updateAddress({
          id: panelData.detailedItemID!,
          destination_address: selectedAddress!,
        });

    updateMethod
      .then(() => {
        if (panelData.setAccess) {
          panelData.setAccess();
        }
        openRightPanel(panelData.returnPreviousPanel, panelData);
      })
      .catch(toastResponseError)
      .finally(() => setSubmitting(false));
  },
});
