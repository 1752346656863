import React, { FC, useRef } from "react";

import { Visible } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { MobileMenuButton as CloseButton } from "@/containers/HeaderContainer/HeaderContainer.styles";
import { Icon } from "@/components/Icon";
import { IntercomMessenger } from "@/components/IntercomMessenger";

import { useAuthStore } from "@/store";
import { IconType } from "@/enums";

import {
  DashboardIcon,
  ParcelIcon,
  PriceIcon,
  ToolsIcon,
} from "./IconsComponents";
import {
  CloseNav,
  LinkItem,
  LinksWrapper,
  LinkTitle,
  Nav,
  NavWrapper,
} from "./LeftMenu.styles";

interface LeftMenuProps {
  isOpen?: boolean;
  withMenu?: boolean;
  handleSetMenuOpen: (isOpen: boolean) => void;
}

const LeftMenu: FC<LeftMenuProps> = React.memo(
  ({ isOpen = false, withMenu = false, handleSetMenuOpen }) => {
    const location = useLocation();
    const navigationRef = useRef<HTMLElement>(null);
    const { t } = useTranslation("common");

    const {
      isImpersonate,
      userAuth: { extended_tools: allowExtendedTools },
    } = useAuthStore();

    const isActiveBooksPage = location.pathname.startsWith("/books");

    const closeMenu = () => handleSetMenuOpen(false);
    const handleClickOutside = (event: MouseEvent) => {
      if (
        navigationRef.current &&
        navigationRef.current.contains(event.target as Node)
      )
        return;
      closeMenu();
    };

    return (
      <NavWrapper
        $withMenu={withMenu}
        $isOpen={isOpen}
        onClick={handleClickOutside}
      >
        <Nav ref={navigationRef}>
          <LinkItem onClick={closeMenu} end to="/">
            Sk
          </LinkItem>
          <LinkItem
            to="/"
            title={t("pages.basicInformation")}
            onClick={closeMenu}
          >
            <DashboardIcon />
            <Visible sm xs>
              <LinkTitle>{t("pages.basicInformation")}</LinkTitle>
            </Visible>
          </LinkItem>
          <LinkItem
            to="/parcels"
            title={t("pages.parcels")}
            onClick={closeMenu}
          >
            <ParcelIcon />
            <Visible sm xs>
              <LinkTitle>{t("pages.parcels")}</LinkTitle>
            </Visible>
          </LinkItem>
          <Visible sm xs>
            <LinksWrapper>
              <LinkItem onClick={closeMenu} to="/parcels" end>
                <LinkTitle>{t("pages.storage")}</LinkTitle>
              </LinkItem>
              <LinkItem onClick={closeMenu} to="/parcels/expected">
                <LinkTitle>{t("pages.expected")}</LinkTitle>
              </LinkItem>
              <LinkItem onClick={closeMenu} to="/parcels/outgoing">
                <LinkTitle>{t("pages.outgoing")}</LinkTitle>
              </LinkItem>
              <LinkItem onClick={closeMenu} to="/parcels/history">
                <LinkTitle>{t("pages.history")}</LinkTitle>
              </LinkItem>
            </LinksWrapper>
          </Visible>
          <LinkItem
            to="/books/add-funds"
            title={t("pages.books")}
            onClick={closeMenu}
            className={isActiveBooksPage ? "active" : ""}
          >
            <PriceIcon />
            <Visible sm xs>
              <LinkTitle>{t("pages.books")}</LinkTitle>
            </Visible>
          </LinkItem>
          <Visible sm xs>
            <LinksWrapper>
              <LinkItem onClick={closeMenu} to="/books/add-funds">
                <LinkTitle>{t("books.addFunds")}</LinkTitle>
              </LinkItem>
              <LinkItem onClick={closeMenu} to="/books" end>
                <LinkTitle>{t("books.transactions")}</LinkTitle>
              </LinkItem>
              <LinkItem onClick={closeMenu} to="/books/recipients">
                <LinkTitle>{t("books.recipients")}</LinkTitle>
              </LinkItem>
            </LinksWrapper>
          </Visible>
          {allowExtendedTools && (
            <LinkItem to="/tools" title={t("pages.tools")} onClick={closeMenu}>
              <ToolsIcon />
              <Visible sm xs>
                <LinkTitle>{t("pages.tools")}</LinkTitle>
              </Visible>
            </LinkItem>
          )}

          {withMenu && !isImpersonate && <IntercomMessenger />}
        </Nav>
        <Visible sm xs>
          <CloseNav>
            <CloseButton aria-label="close-menu" onClick={closeMenu}>
              <Icon type={IconType.Close} />
            </CloseButton>
          </CloseNav>
        </Visible>
      </NavWrapper>
    );
  },
);

export default LeftMenu;
