import React, { ChangeEvent, FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { AddFundsBlock, AmountFundsInput } from "@/containers/Books/styles";
import { Button } from "@/components/Button";

import { breakpoints } from "@/styles";

import { toastResponseError } from "@/utils/responseMessageHelper";

import { RightPanelType } from "@/store/useRightPanelStore";
import { useBooksStore, useRightPanelStore } from "@/store";
import { ButtonColor } from "@/enums";

import { masterCardLogo, visaLogo } from "./../../images";

//constants for the range of funds value
const MIN_AMOUNT_ADD_FUNDS = 0.01;
const MAX_AMOUNT_ADD_FUNDS = 10000.0;

interface BooksAddFundsBlockProps {
  isPanelOpened: boolean;
  isBlockPanel?: boolean;
}

const BooksAddFundsBlock: FC<BooksAddFundsBlockProps> = React.memo(
  ({ isPanelOpened, isBlockPanel = false }) => {
    const { t } = useTranslation("common");
    const { amountFunds, updateAmountFunds } = useBooksStore();
    const { openRightPanel } = useRightPanelStore();
    const [amountValue, setAmountValue] = useState("");
    const [amountValueWithoutPrefix, setAmountValueWithoutPrefix] =
      useState(amountFunds);
    const [isErrorRangeFunds, setIsErrorRangeFunds] = useState(false);
    const isMobileBreakpoint = +window.innerWidth <= +breakpoints.xs;

    useEffect(() => {
      if (amountFunds) setAmountValue(`$ ${amountFunds}`);
    }, [amountFunds]);

    const handleChangeSumValue = (event: ChangeEvent<HTMLInputElement>) => {
      let inputValue = event.target.value.replace(/[^0-9.]/g, "");
      let numericValue = parseFloat(inputValue);
      setIsErrorRangeFunds(false);
      setAmountValue(`$ ${inputValue}`);
      setAmountValueWithoutPrefix(inputValue);

      //range of funds value
      if (
        numericValue > MAX_AMOUNT_ADD_FUNDS ||
        numericValue < MIN_AMOUNT_ADD_FUNDS
      ) {
        setIsErrorRangeFunds(true);
        toastResponseError(
          t("books.errorAmountRangeAddFunds", {
            min: MIN_AMOUNT_ADD_FUNDS,
            max: MAX_AMOUNT_ADD_FUNDS,
          }),
          "error-range-funds",
        );
      }
    };

    const showExpectedParcelForm = () => {
      openRightPanel(RightPanelType.BOOKS_ADD_FUNDS);
    };

    useEffect(() => {
      if (!isMobileBreakpoint) {
        showExpectedParcelForm();
      }
    }, [isMobileBreakpoint]);

    useEffect(() => {
      if (!isErrorRangeFunds) {
        updateAmountFunds(amountValueWithoutPrefix);
      }
    }, [amountValueWithoutPrefix]);

    useEffect(() => {
      if (!isErrorRangeFunds) return;
      toast.dismiss("error-range-funds");
    }, [isErrorRangeFunds]);

    return (
      <AddFundsBlock
        $isPanelOpened={isPanelOpened}
        $isBlockPanel={isBlockPanel}
      >
        <img src={visaLogo} alt="Visa" />
        <img src={masterCardLogo} alt="masterCard" />
        <AmountFundsInput
          value={amountValue}
          onChange={handleChangeSumValue}
          invalid={isErrorRangeFunds}
          placeholder={"$ 0.00"}
        />

        {!isPanelOpened && !isBlockPanel && (
          <Button color={ButtonColor.Primary} onClick={showExpectedParcelForm}>
            {t("books.addFundsBtnTitle")}
          </Button>
        )}
      </AddFundsBlock>
    );
  },
);

export default BooksAddFundsBlock;
