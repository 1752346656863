import React, { FC, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { ServiceLogo } from "@/components/ServiceLogo";

import { Currency, ParcelCol } from "@/styles/parcels";

import formatter from "@/utils/formatter";

import { RightPanelType } from "@/store/useRightPanelStore";
import { useRightPanelStore } from "@/store";
import { SelectedYearItem } from "@/types/api/history";

import { HistoryRow, Price } from "./WeekComponents.styles";

interface WeekItemProps {
  units: string;
  item: SelectedYearItem & { id: number };
  selectedYear: number;
}

const WeekItem: FC<WeekItemProps> = React.memo(
  ({ item, units, selectedYear }) => {
    const { t } = useTranslation("common");
    const { isOpenRightPanel, panelData, openRightPanel } =
      useRightPanelStore();
    const shippingMethod = item.shipping_method;
    const weekNumber = item.week_number;
    const isSelectedShipment =
      isOpenRightPanel &&
      panelData.shippingMethod === shippingMethod &&
      panelData.weekNumber === weekNumber;

    const clickWeekItem = useCallback(
      () =>
        openRightPanel(RightPanelType.WEEK_ITEM, {
          shippingMethod,
          weekNumber,
          selectedYear,
        }),
      [openRightPanel, item],
    );

    return (
      <HistoryRow selected={isSelectedShipment} onClick={clickWeekItem}>
        <ParcelCol xs={4} lg={3} offset={{ lg: 3 }}>
          <ServiceLogo serviceKey={shippingMethod} />
          {shippingMethod}
        </ParcelCol>
        <ParcelCol xs={3} xl={2}>
          {item.total_parcels}{" "}
          {item.total_parcels === 1
            ? t("parcels.parcel")
            : t("parcels.parcels")}
        </ParcelCol>
        <ParcelCol xs={3} sm={2} md={3} lg={2}>
          {`${formatter.weight(item.total_weight)} ${t(`units.${units}.primaryShort`)}`}
        </ParcelCol>
        <ParcelCol xs={2} lg={1}>
          <Price>
            <Currency>$</Currency> {formatter.currency(item.total_price)}
          </Price>
        </ParcelCol>
      </HistoryRow>
    );
  },
);

export default WeekItem;
