export const customDeclarationEditable = (state: string): boolean => {
  switch (state) {
    case "pending":
    case "packed":
      return true;
    default:
      return false;
  }
};

export const cancelAndPayAvaiable = (state: string): boolean => {
  switch (state) {
    case "pending":
      return true;
    default:
      return false;
  }
};
