import color from "color";
import { fadeIn } from "react-animations";
import styled, { DefaultTheme, keyframes } from "styled-components";

const rangeHeightParams = (theme: DefaultTheme) => [
  "70px",
  "90px",
  `${theme.breakpoints.sm}px`,
  `${theme.breakpoints.xl}px`,
];

export const HeaderWrapper = styled.header`
  ${({ theme }) => theme.range("height", ...rangeHeightParams(theme))}
  ${({ theme }) => theme.range("min-height", ...rangeHeightParams(theme))}
    
    ${(props) => props.theme.sm`
      min-height: 60px;
      height: 60px;
    `}
`;

export const Header = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.range("padding", "15px", "30px")};
  height: inherit;
  max-height: inherit;
  transition: height 0.2s ease-out;
  z-index: 1;
  background-color: ${({ theme }) => theme.white};
  border-bottom: 1px solid ${({ theme }) => theme.lightgrey};
`;

export const BasicHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: 0.8s ${keyframes`${fadeIn}`};

  ${({ theme, hidden }) =>
    hidden &&
    theme.sm`
        display: none;
    `}
`;

export const HeaderTitle = styled.span`
  flex: 1;
  margin: 0;
  padding: 0;
  font-size: 26px;
  font-weight: bold;

  ${(props) => props.theme.sm`
      width: 100%;
      text-align: center;
    `}
`;

export const MobileMenuButton = styled.button`
  padding: 5px;
  animation: 0.8s ${keyframes`${fadeIn}`};

  .icon {
    transition: background-color 0.2s;
  }

  &:hover .icon {
    background-color: ${({ theme }) => color(theme.grey).darken(0.2).hex()};
  }
`;
