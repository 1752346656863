import styled from "styled-components";

import { Icon } from "@/components/Icon";
import { Logo } from "@/components/Logo";
import { ScrollableContent } from "@/components/ScrollableContent";

export const ScrollableWrapper = styled(ScrollableContent)`
  .ReactCollapse--content {
    padding-right: 5px;
  }

  &::after {
    background-image: linear-gradient(
      to bottom,
      rgba(251, 251, 252, 0),
      #f6f6f7
    );

    ${({ theme }) => theme.sm`
      background-image: linear-gradient(
        to bottom,
        rgba(251, 251, 252, 0),
        ${theme.white}
      );
    `}
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-right: 20px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-right: 60px;
  }
`;

interface ItemCompactProps {
  isSelected: boolean;
}

export const ItemCompact = styled.div<ItemCompactProps>`
  align-items: center;
  display: flex;
  width: 100%;
  padding: 15px 10px;
  border-top: 1px solid #e1e2e6;
  cursor: pointer;
  &:first-child {
    border-top: none;
  }
  ${({ isSelected }) =>
    isSelected &&
    `
      background-color: rgba(62,130,247,0.1);
    `}
`;

export const ItemLogo = styled(Logo)`
  margin-right: 5px;
`;

export const ItemIcon = styled.span`
  display: flex;
  margin-right: 8px;
`;

export const HeadTextCompact = styled.div`
  color: ${({ theme }) => theme.black50};
  margin-right: 5px;
`;

export const ListItemCompact = styled.p`
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;

  i + span {
    padding-top: 1px;
  }
`;

export const ServiceRequestIcon = styled(Icon)`
  height: 16px;
  width: 16px;
  margin-right: 8px;
  background-color: ${({ theme }) => theme.secondary};

  ${({ theme }) => theme.sm`
    height: 12px;
    width: 12px;
  `}
`;
