import styled, { css } from "styled-components";

import { Logo } from "@/components/Logo";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.xs`
     margin-bottom: 20px
     flex-direction: column-reverse;
   `}
`;

export const ExactContainer = styled.div`
  ${({ theme }) => theme.xs`
   border-bottom: 1px solid ${theme.lightgrey}};
  `};
`;

export const DatesWrapper = styled.div`
  width: 100%;
  padding: 10px 20px 20px 20px;
  display: flex;
  flex-direction: row;
`;

export const ExactTitle = styled.div`
  padding: 15px 20px 0 20px;
  width: 100%;
  color: ${(props) => props.theme.black};
`;

interface DateButtonProps {
  $isSelected: boolean;
}

export const DateButton = styled.button<DateButtonProps>`
  width: 100%;
  height: 100%;
  padding: 15px 20px;
  text-align: left;
  color: ${(props) => props.theme.black};
  border-bottom: 1px solid ${(props) => props.theme.lightgrey};

  &:hover {
    background-color: ${(props) => props.theme.lightgrey03};
  }

  ${({ $isSelected, theme }) =>
    $isSelected && `background-color: ${theme.primaryPale} !important;`}
`;

export const DatesDivider = styled.div`
  min-width: 30px;
  position: relative;

  &::before {
    content: "";
    display: block;
    justify-content: center;
    position: absolute;
    top: calc(50% - 1px);
    left: 10px;
    right: 10px;
    background-color: ${({ theme }) => theme.grey};
    height: 1px;
  }
`;

export const DatesErrorWrapper = styled.div`
  padding: 0 20px 20px 20px;
  margin-top: -10px;
  color: ${({ theme }) => theme.red};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const FilterLogo = styled(Logo)`
  margin-right: 10px;
`;

export const InputsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const HorizontalDivider = styled.div`
  background: ${(props) => props.theme.lightgrey};
  display: inline-flex;
  height: 1px;
  margin: 0 10px;
  width: 40px;
`;

export const UnitsWrapper = styled.div`
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  color: rgba(0, 0, 0, 0.3);
`;

export const WeightSearchFilterOverrides = css`
  min-width: 160px;
`;
