import styled from "styled-components";

export const Field = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const Label = styled.span`
  font-weight: bold;
  flex-shrink: 0;
  margin-right: 8px;
`;

export const SkuLabel = styled(Label)`
  margin-right: 32px;
`;

export const ButtonsWrapper = styled.span`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`;

export const Wrapper = styled.div`
  width: 100%;

  ${(props) => props.theme.sm`
    display: none;
  `}
`;
