import React, { FC } from "react";

import { Visible } from "react-grid-system";

import { Icon } from "@/components/Icon";

import { IconType } from "@/enums";

import { BasicHeader } from "./components";
import {
  BasicHeaderWrapper,
  Header,
  HeaderWrapper,
  MobileMenuButton,
} from "./HeaderContainer.styles";

interface HeaderContainersProps {
  toggleMenu: () => void;
  isMenuOpen?: boolean;
  withMenu?: boolean;
}

const HeaderContainer: FC<HeaderContainersProps> = React.memo(
  ({ toggleMenu, withMenu, isMenuOpen }) => (
    <HeaderWrapper>
      <Header>
        {withMenu && (
          <Visible sm xs>
            <MobileMenuButton aria-label="open-menu" onClick={toggleMenu}>
              <Icon type={IconType.MenuBurger} />
            </MobileMenuButton>
          </Visible>
        )}
        <BasicHeaderWrapper hidden={isMenuOpen}>
          <BasicHeader />
        </BasicHeaderWrapper>
      </Header>
    </HeaderWrapper>
  ),
);

export default HeaderContainer;
