import styled from "styled-components";

import { Content } from "@/containers/RightPanel/RightPanel.styles";
import { PreLoader } from "@/components/PreLoader";

export const Spinner = styled(PreLoader)`
  position: absolute;
  top: 20px;
`;

export const ContentContainer = styled(Content)`
  && > div:first-child {
    padding: 0;
  }
`;

export const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;

  button {
    margin: 5px;
  }
`;

export const ButtonsWrapper = styled.div`
  margin-top: auto;
`;
