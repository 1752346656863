import React, { FC, useRef } from "react";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { Tab, TabsView } from "@/containers/RightPanel/components";
import {
  Content,
  ContentWrapper,
  Heading,
} from "@/containers/RightPanel/RightPanel.styles";
import { Button } from "@/components/Button";
import CustomsDeclarationV2 from "@/components/CustomDeclaration/CustomsDeclarationV2";

import {
  useCreateExpectedItem,
  useDeleteExpectedItem,
  useExpectedCreateCustomsDeclaration,
  useExpectedCustomsDeclarations,
  useExpectedDeleteCustomsDeclaration,
  useExpectedUpdateCustomsDeclaration,
  useUpdateExpectedItem,
} from "@/hooks/react-query/expected";

import { RightPanelType } from "@/store/useRightPanelStore";
import { getSelectedExpectedItemCustomsDeclarations } from "@/store/useStorageStore";
import { useRightPanelStore, useStorageStore } from "@/store";
import { ButtonColor, ButtonType, ButtonVariant } from "@/enums";
import { ExpectedItemsPanelProps } from "@/types/ExpectedForms/ExpectedItemsPanel";

import {
  ButtonsWrapper,
  Container,
  ContentContainer,
  FormWrapper,
  Spinner,
  Title,
} from "./ExpectedItemPanel.styles";
import ExpectedParcelForm from "./ExpectedParcelForm";
import { expectedItemsFormik } from "./helpers";

const ExpectedItemPanel: FC<ExpectedItemsPanelProps> = React.memo(
  ({ panelData }) => {
    const { t } = useTranslation("common");
    const { openRightPanel } = useRightPanelStore();
    const { expectedShops } = useStorageStore();
    const expectedItemDeclarations =
      getSelectedExpectedItemCustomsDeclarations();

    const { mutateAsync: createExpectedItem } = useCreateExpectedItem();
    const { mutateAsync: updateExpectedItem } = useUpdateExpectedItem();
    const { mutateAsync: deleteExpectedItem } = useDeleteExpectedItem();
    const { mutateAsync: createCustomsDeclarationForExpectedItem } =
      useExpectedCreateCustomsDeclaration();
    const { mutateAsync: updateCustomsDeclarationForExpectedItem } =
      useExpectedUpdateCustomsDeclaration();
    const { mutateAsync: deleteCustomsDeclarationForExpectedItem } =
      useExpectedDeleteCustomsDeclaration();

    const formProps = useFormik(
      expectedItemsFormik(
        t,
        panelData,
        expectedShops,
        createExpectedItem,
        updateExpectedItem,
      ),
    );

    const { handleSubmit, isSubmitting, values } = formProps;

    const isEditForm = panelData?.isEditForm;
    const formRef = useRef(null);

    const capitalized = (word: string) =>
      word.charAt(0).toUpperCase() + word.slice(1);

    const handleDelete = () => {
      deleteExpectedItem(+values.id).then(() => {
        openRightPanel(RightPanelType.EXPECTED_ITEM);
      });
    };

    useExpectedCustomsDeclarations(+values.id, {
      enabled: !!values.id && isEditForm,
    });

    return (
      <ContentWrapper>
        <Heading>
          <Title>{`${
            isEditForm ? t("common.edit") : capitalized(t("common.new"))
          } Expected parcel`}</Title>
        </Heading>
        <TabsView>
          <Tab title={t("parcels.details")}>
            <ContentContainer>
              <Container>
                <Spinner isActive={false} />
                <FormWrapper ref={formRef} onSubmit={handleSubmit}>
                  <Content>
                    <ExpectedParcelForm {...formProps} shops={expectedShops} />
                  </Content>
                  <ButtonsWrapper>
                    {isEditForm && (
                      <Button
                        color={ButtonColor.Red}
                        onClick={() => handleDelete()}
                      >
                        {t("common.remove")}
                      </Button>
                    )}
                    <Button
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                      type={ButtonType.Submit}
                      color={ButtonColor.Primary}
                      variant={ButtonVariant.Filled}
                    >
                      {t("common.saveChanges")}
                    </Button>
                  </ButtonsWrapper>
                </FormWrapper>
              </Container>
            </ContentContainer>
          </Tab>
          {isEditForm && (
            <Tab title={t("parcels.customsData")}>
              <Content>
                <CustomsDeclarationV2
                  itemId={+values.id}
                  declarations={expectedItemDeclarations}
                  create={createCustomsDeclarationForExpectedItem}
                  update={updateCustomsDeclarationForExpectedItem}
                  remove={deleteCustomsDeclarationForExpectedItem}
                  noPadding
                  isShipmentImportCustomsDeclaration
                  hideFileImport
                />
              </Content>
            </Tab>
          )}
        </TabsView>
      </ContentWrapper>
    );
  },
);

export default ExpectedItemPanel;
