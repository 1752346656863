import styled from "styled-components";

export const Text = styled.span`
  color: black !important;
`;

export const Divider = styled.span`
  &:before {
    content: "x";
  }
  margin: 0 5px;
  color: rgba(0, 0, 0, 0.5);

  ${(props) => props.theme.xs`
    margin: 0 2px;
  `}
`;
