import { FC, memo, MouseEvent } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";

import { Wrapper } from "@/styles/placeholder";

import { ButtonColor, ButtonSize, ButtonVariant } from "@/enums";

import { ButtonsWrapper, Message } from "../../styles";

interface BooksSearchPlaceholderProps {
  handleReset: (e: MouseEvent<HTMLButtonElement>) => void;
}

const BooksSearchPlaceholder: FC<BooksSearchPlaceholderProps> = ({
  handleReset,
}) => {
  const { t } = useTranslation("common");

  return (
    <Wrapper>
      <Message>{t("books.notFoundRecipients")}</Message>
      <ButtonsWrapper>
        <Button
          onClick={handleReset}
          color={ButtonColor.Primary}
          size={ButtonSize.Large}
          variant={ButtonVariant.Filled}
        >
          {t("parcels.search.tryAnotherSearch")}
        </Button>
        <Button
          onClick={handleReset}
          color={ButtonColor.Primary}
          size={ButtonSize.Large}
          variant={ButtonVariant.Bordered}
        >
          {t("parcels.search.dismiss")}
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  );
};

export default memo(BooksSearchPlaceholder);
