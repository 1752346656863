import { AuthAPI } from "api";
import { create } from "zustand";

export interface AuthStoreUserDto {
  email: string;
  ffid: number | null;
  state: string;
  last_name: string;
  first_name: string;
  country: string;
  display_weight_in: string;
  created_at: string;
  balance: number | null;
  allow_search_by_location: boolean;
  credit_card_allowed: boolean;
  id: number | null;
  locale: string;
  extended_tools: boolean;
  notifications: string;
  handling: number;
  invoiced: boolean;
}

interface UseAuthStore {
  isLoadingAuth: boolean;
  isImpersonate: boolean;
  isAuthenticated: boolean;
  userAuth: AuthStoreUserDto;
  updateIsLoadingAuth: (bool: boolean) => void;
  updateIsImpersonate: (bool: boolean) => void;
  updateIsAuthenticated: (bool: boolean) => void;
  updateUserAuth: (
    userDto: AuthStoreUserDto,
    isAuthenticated?: boolean,
  ) => void;
  getAuthSelectFullName: () => string;
  getAuthSelectFfid: () => string;
  resetAuthStore: () => void;
  fetchAuthState: () => Promise<void>;
}

const initialState = {
  isLoadingAuth: false,
  isImpersonate: false,
  isAuthenticated: false,
  userAuth: {
    email: "",
    ffid: null,
    state: "",
    last_name: "",
    first_name: "",
    country: "",
    display_weight_in: "",
    created_at: "",
    balance: null,
    credit_card_allowed: false,
    allow_search_by_location: false,
    id: null,
    locale: "",
    extended_tools: false,
    notifications: "one_of_the_day",
    handling: 0,
    invoiced: false,
  },
};

const useAuthStore = create<UseAuthStore>((set, get) => ({
  ...initialState,
  updateIsLoadingAuth: (bool) =>
    set(() => ({
      isLoadingAuth: bool,
    })),
  updateIsImpersonate: (bool) =>
    set(() => ({
      isImpersonate: bool,
    })),
  updateIsAuthenticated: (bool) =>
    set(() => ({
      isAuthenticated: bool,
    })),
  updateUserAuth: (userDto, isAuthenticated) => {
    set((state) => {
      state.updateIsLoadingAuth(false);
      if (isAuthenticated) {
        state.updateIsAuthenticated(isAuthenticated);
      }

      return {
        userAuth: userDto,
      };
    });
  },
  getAuthSelectFullName: () => {
    const { userAuth, isAuthenticated } = get();

    return isAuthenticated
      ? `${userAuth.first_name} ${userAuth.last_name}`
      : "";
  },
  getAuthSelectFfid: () => {
    const { userAuth, isAuthenticated } = get();

    return isAuthenticated ? `SB${userAuth.ffid}` : "";
  },
  resetAuthStore: () =>
    set(() => ({
      ...initialState,
    })),
  async fetchAuthState() {
    set({ isLoadingAuth: true });

    try {
      const res = await AuthAPI.getProfile();
      set({ userAuth: res });
    } catch (e) {
      console.error(e);
    } finally {
      set({ isLoadingAuth: false });
    }
  },
}));

export default useAuthStore;
