import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import { Currency } from "@/styles/parcels";

import formatter from "@/utils/formatter";

import { useAuthStore, useCartStore } from "@/store";

import { SideItem, SideItemHead } from "../../styles";
import {
  DashConnector,
  PriceItem,
  PriceWrapper,
  TotalCostWrapper,
  TotalPrice,
  Wrapper,
} from "./TotalPackingCost.styles";

const TotalPackingCost: FC = React.memo(() => {
  const {
    userAuth: { display_weight_in: units },
  } = useAuthStore();
  const { cart, getCartSelectForOutgoing } = useCartStore();

  const { t } = useTranslation("common");
  const estimate = cart?.estimate;
  const cartDelivery = cart?.preferred_carrier;
  const userCart = getCartSelectForOutgoing();

  const deliveryCost = parseFloat(estimate?.price ?? "0");
  const packingGradePrice = parseFloat(estimate?.optional_lines_cost ?? "0");
  const servicesPrice = parseFloat(estimate?.service_requests_cost ?? "0");
  const packingOptionsPrice =
    parseFloat(estimate?.items_addons_cost ?? "0") +
    parseFloat(estimate?.consolidation_addons_cost ?? "0");

  const totalPrice =
    deliveryCost + packingOptionsPrice + packingGradePrice + servicesPrice;

  const consolidationItem = userCart.items[0];
  const weight = consolidationItem?.weight || 0;

  return (
    <>
      <SideItem>
        <SideItemHead>{t("shipping.expectedСost")}:</SideItemHead>
        <Wrapper>
          <PriceItem>
            {`${cartDelivery} / ${weight} ${t(`units.${units}.primaryShort`)}`}
            <DashConnector />
            <PriceWrapper>
              <Currency>$</Currency>
              {deliveryCost}
            </PriceWrapper>
          </PriceItem>
          <PriceItem>
            {t("parcels.packingGrade")}
            <DashConnector />
            <PriceWrapper>
              <Currency>$</Currency>
              {packingGradePrice}
            </PriceWrapper>
          </PriceItem>
          <PriceItem>
            {t("shipping.services")}
            <DashConnector />
            <PriceWrapper>
              <Currency>$</Currency>
              {servicesPrice}
            </PriceWrapper>
          </PriceItem>
          <PriceItem>
            {t("parcels.packingOptions")}
            <DashConnector />
            <PriceWrapper>
              <Currency>$</Currency>
              {packingOptionsPrice}
            </PriceWrapper>
          </PriceItem>
        </Wrapper>
      </SideItem>
      <SideItem>
        <TotalCostWrapper>
          <TotalPrice>{`${t("shipping.totalExpectedСost")}:`}</TotalPrice>
          <strong>&nbsp;{`$${formatter.currency(totalPrice)}`}</strong>
        </TotalCostWrapper>
      </SideItem>
    </>
  );
});

export default TotalPackingCost;
