import styled from "styled-components";

import { Content } from "@/containers/RightPanel/RightPanel.styles";
import {
  AddAddressButton,
  AddressesList,
} from "@/components/Addresses/components";

export const ContentContainer = styled(Content)`
  && > div:first-child {
    padding: 0;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 65px;
`;

export const AddressesListWrapper = styled(AddressesList)`
  border: none;
  height: auto;

  & > div:last-child {
    border-bottom: 1px solid ${(props) => props.theme.lightgrey};
  }
`;

export const AddButton = styled(AddAddressButton)`
  && {
    margin: 10px auto 10px 10px;
    padding: 10px;
  }
`;

export const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  background-color: #fff;
`;
