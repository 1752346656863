import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import { getStorageSelectSelectedWeight } from "@/store/useStorageStore";
import { useAuthStore, useStorageStore } from "@/store";

import { Wrapper } from "./SelectedInfo.styles";

interface SelectedInfoProps {
  className?: string;
  weight?: string | number;
  selectedParcelsNumber?: number;
}

const SelectedInfo: FC<SelectedInfoProps> = React.memo(
  ({
    weight: weightProp,
    selectedParcelsNumber: selectedParcelsNumberProp,
    className = "",
  }) => {
    const { t } = useTranslation("common");
    const selectedWeight = getStorageSelectSelectedWeight();
    const {
      userAuth: { display_weight_in: units },
    } = useAuthStore();
    const { selectedStorage: selectedParcels } = useStorageStore();

    const weight = weightProp ? weightProp : selectedWeight;
    const number = selectedParcelsNumberProp
      ? selectedParcelsNumberProp
      : selectedParcels.length;

    return (
      <Wrapper className={className}>
        <span>
          {`${t("parcels.selectedAmount")}: `}
          <b>{number}</b>
        </span>
        <span>
          {`${t("parcels.weight")}: `}
          <b>{`${weight} ${t(`units.${units}.primaryShort`)}`}</b>
        </span>
      </Wrapper>
    );
  },
);

export default SelectedInfo;
