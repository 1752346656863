import { FC, memo } from "react";

import { useTranslation } from "react-i18next";

import { SectionWrapper } from "@/styles/account";

import { SectionText, SectionTitle } from "./AccountComponents.styles";

interface UserInfoProps {
  fullName: string;
  email: string;
}

const UserInfo: FC<UserInfoProps> = ({ fullName, email }) => {
  const { t } = useTranslation("common");

  return (
    <SectionWrapper>
      <SectionTitle>{t("account.hiUser", { fullName })}</SectionTitle>
      <SectionText>{email}</SectionText>
    </SectionWrapper>
  );
};

export default memo(UserInfo);
