import React, { FC } from "react";

import { useRightPanelStore } from "@/store";

import { BooksAddFundsBlock } from "./components/BooksAddFunds";
import { StyledGrid, Wrapper } from "./styles";

const BooksAddFunds: FC = React.memo(() => {
  const { isOpenRightPanel } = useRightPanelStore();

  return (
    <Wrapper>
      <StyledGrid fluid>
        <BooksAddFundsBlock isPanelOpened={isOpenRightPanel} />
      </StyledGrid>
    </Wrapper>
  );
});

export default BooksAddFunds;
