import { memo } from "react";

import { CustomsDeclarationV2Props } from "@/types";

import { CustomsDeclarationV2Form } from "./components";

const CustomsDeclarationV2 = <
  C extends Function,
  R extends Function,
  U extends Function,
>({
  itemId,
  create,
  remove,
  update,
  declarations: declarationsProp,
  onSubmit,
  readOnly,
  onCustomsDataChange,
  noPadding,
  autoFocus,
  isWizardStep,
  isShipmentImportCustomsDeclaration,
  hideFileImport,
  isShowHSCodeColumn,
  isHSCodeValidationRequired,
  ...rest
}: CustomsDeclarationV2Props<C, R, U>) => {
  const declarations = declarationsProp ?? [];

  return (
    <CustomsDeclarationV2Form
      declarations={declarations}
      itemId={itemId}
      create={create}
      update={update}
      remove={remove}
      onSubmit={onSubmit}
      readOnly={readOnly}
      onCustomsDataChange={onCustomsDataChange}
      noPadding={noPadding}
      autoFocus={autoFocus}
      isWizardStep={isWizardStep}
      isShipmentImportCustomsDeclaration={isShipmentImportCustomsDeclaration}
      hideFileImport={hideFileImport}
      isShowHSCodeColumn={isShowHSCodeColumn}
      isHSCodeValidationRequired={isHSCodeValidationRequired}
      {...rest}
    />
  );
};

export default memo(CustomsDeclarationV2);
