import React, { FC, MouseEvent, useCallback, useRef, useState } from "react";

import { useFormik } from "formik";
import Scrollbars, { positionValues } from "react-custom-scrollbars-2";
import { Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { AddonsList } from "@/components/AddonsList";
import { Button } from "@/components/Button";

import { breakpoints } from "@/styles";

import {
  useCartDeleteAddonsMethod,
  useCartSetAddonsMethod,
  useCartSetConsolidationAddonsMethod,
} from "@/hooks/react-query/cart";
import { ProgressDeliveryAccess, ProgressDeliverySetAccessFunc } from "@/hooks";

import useRightPanelStore, { RightPanelType } from "@/store/useRightPanelStore";
import { useCartStore, useServicesStore } from "@/store";
import {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  IconType,
} from "@/enums";

import {
  BlockContent,
  Container,
  Content,
  DeliveryBlock,
  Heading,
  RowWrapper,
  SubmitWrapper,
} from "../../../styles";
import { ConsolidationAddonsList } from "../../ConsolidationAddonsList";
import { LeftSideContent } from "../../LeftSideContent";
import { ScrollableForm } from "../../ScrollableForm";
import { ServiceRequestsList } from "../../ServiceRequestsList";
import { SideAddonsInfo } from "../../SideAddons/SideAddonsInfo";
import { ActiveItem } from "../../SideAddons/SideAddonsInfo/SideAddonInfoItem/SideAddonInfoItem";
import {
  HelpButton,
  HelpIcon,
  ScrollHint,
  Spinner,
} from "./ChooseAdditionalForm.styles";
import { chooseAdditionalFormHelper } from "./helpers";

export interface ChooseAdditionalFormProps {
  access: ProgressDeliveryAccess;
  setAccess: ProgressDeliverySetAccessFunc;
}

const ChooseAdditionalForm: FC<ChooseAdditionalFormProps> = React.memo(
  ({ access, setAccess }) => {
    const { t } = useTranslation("common");
    const navigate = useNavigate();
    const navigateFunc = () => navigate("/shipping/flow/customs-data");
    const isMobileBreakpoint = +window.innerWidth <= +breakpoints.xs;
    const [isScrolledAddonsList, setIsScrolledAddonsList] = useState(false);

    const { getSelectAddons, consolidationAddonsMethods } = useServicesStore();
    const { isCartLoading } = useCartStore();
    const { openRightPanel } = useRightPanelStore();
    const { cart } = useCartStore();
    const addons = getSelectAddons();

    const { mutateAsync: setAddonsMethods } = useCartSetAddonsMethod();
    const { mutateAsync: deleteAddonsMethods } = useCartDeleteAddonsMethod();
    const { mutateAsync: setConsolidationAddonsMethods } =
      useCartSetConsolidationAddonsMethod();

    const cartItems = cart?.items ?? [];
    const cartConsolidationItems = cart?.addons_codes ?? [];

    const { setFieldValue, values, setValues, handleSubmit, isSubmitting } =
      useFormik(
        chooseAdditionalFormHelper(
          t,
          cartItems,
          cartConsolidationItems,
          consolidationAddonsMethods,
          addons,
          setAddonsMethods,
          setConsolidationAddonsMethods,
          deleteAddonsMethods,
          setAccess,
          navigateFunc,
        ),
      );

    const formRef = useRef<HTMLFormElement>(null);
    const readyForCustomInformationStep = access?.customInformation;
    const [isConsolidationAddonsDefined, setIsConsolidationAddonsDefined] =
      useState(readyForCustomInformationStep);

    const handleChangeConsolidationAddonsDefined = (bool: boolean) =>
      setIsConsolidationAddonsDefined(bool);

    const handleActiveItemChange = useCallback(
      (value: ActiveItem) => {
        setFieldValue("activeItem", value);
        setIsScrolledAddonsList(false);
      },
      [setFieldValue],
    );

    const handleSelect = useCallback(
      (id: number) => () => {
        const activeItemId = values.activeItem.value;
        const hasAddon = values.items[activeItemId].includes(id);
        const value = hasAddon
          ? [
              ...values.items[activeItemId].filter(
                (item: string | number) => item !== id,
              ),
            ]
          : [...values.items[activeItemId], id];

        setFieldValue("items", { ...values.items, [activeItemId]: value });
      },
      [values, setFieldValue],
    );

    const isAllSelected = values.activeItem.value === "all";
    const headerTitle = isAllSelected
      ? `${t("shipping.selectAdditional")} ${cart?.sku}`
      : `${t("shipping.selectAdditionalShipment")} ${values.activeItem.label}`;

    const handleHelpClick = useCallback(
      (event: MouseEvent) => {
        event.preventDefault();
        openRightPanel(RightPanelType.HELP_PANEL, {
          preventReLoading: true,
          hideBackButton: true,
          showHelpersCode:
            values.activeItem.value === "all"
              ? "primary_options"
              : "single_options",
        });
      },
      [openRightPanel, values.activeItem.value],
    );

    const handleScroll = useCallback(
      (posValues: positionValues) => {
        if (!isScrolledAddonsList) {
          setIsScrolledAddonsList(posValues.top > 0);
        }
      },
      [setIsScrolledAddonsList, isScrolledAddonsList],
    );

    return (
      <Container>
        <RowWrapper>
          <LeftSideContent>
            <SideAddonsInfo
              addons={addons}
              values={values}
              cartItems={cart?.items ?? []}
              activeItem={values.activeItem}
              onActiveItemChange={handleActiveItemChange}
              activeSectionRef={formRef}
            />
          </LeftSideContent>

          <Col lg={4} md={6} sm={12}>
            <Content>
              <DeliveryBlock>
                <form ref={formRef}>
                  <Heading>
                    {headerTitle}
                    <HelpButton onClick={handleHelpClick}>
                      <HelpIcon type={IconType.Help} />
                    </HelpButton>
                  </Heading>
                  <BlockContent>
                    <Spinner isActive={isCartLoading} />
                    <ScrollableForm
                      onUpdate={(e) => handleScroll(e)}
                      universal={true}
                    >
                      {values.activeItem.value === "all" ? (
                        <ConsolidationAddonsList
                          values={values}
                          items={cart?.items ?? []}
                          addons={consolidationAddonsMethods}
                          setFormValues={setValues}
                          isConsolidationAddonsDefined={
                            !!isConsolidationAddonsDefined
                          }
                          handleChangeConsolidationAddonsDefined={
                            handleChangeConsolidationAddonsDefined
                          }
                        />
                      ) : (
                        <>
                          <AddonsList
                            addons={addons}
                            values={values}
                            items={cart?.items ?? []}
                            handleSelect={handleSelect}
                          />

                          <ScrollHint
                            isVisible={
                              addons.length > 4 &&
                              !isScrolledAddonsList &&
                              !isMobileBreakpoint
                            }
                          >
                            {`${t("shipping.showMore")} ↓`}
                          </ScrollHint>

                          <ServiceRequestsList
                            serviceRequests={values.activeItem.serviceRequests}
                          />
                        </>
                      )}
                    </ScrollableForm>
                    <SubmitWrapper>
                      <Button
                        isLoading={isSubmitting}
                        disabled={
                          values.activeItem.value === "all" &&
                          (isSubmitting ||
                            isCartLoading ||
                            !isConsolidationAddonsDefined)
                        }
                        size={ButtonSize.Large}
                        color={ButtonColor.Primary}
                        type={ButtonType.Submit}
                        variant={ButtonVariant.Filled}
                        onClick={(e) =>
                          values.activeItem.value === "all"
                            ? handleSubmit()
                            : handleActiveItemChange({ value: "all" })
                        }
                      >
                        {t("common.nextStep")}
                      </Button>
                    </SubmitWrapper>
                  </BlockContent>
                </form>
              </DeliveryBlock>
            </Content>
          </Col>
        </RowWrapper>
      </Container>
    );
  },
);

export default ChooseAdditionalForm;
