import styled from "styled-components";

export const IntercomLauncher = styled.a`
  display: flex;
  width: 40px;
  height: 40px;
  background-color: #3e82f7;
  border-radius: 50%;
  margin: auto 0 20px 15px;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.85;
  }
`;

export const IntercomIconOpen = styled.i`
  display: flex;
  justify-content: center;
  align-content: center;

  opacity: 1;
  transform: rotate(0deg) scale(1);
  transition:
    transform 0.16s linear,
    opacity 0.08s linear;
  position: absolute;

  svg {
    width: 16px;
    height: 18px;
    fill: ${({ theme }) => theme.white};
    path {
      fill: ${({ theme }) => theme.white};
    }
  }

  .intercom-open & {
    opacity: 0;
    transform: rotate(0deg);
  }
`;

export const IntercomIconClose = styled.i`
  display: flex;
  justify-content: center;
  align-content: center;
  opacity: 0;
  transform: rotate(-30deg);
  transition:
    transform 0.16s linear,
    opacity 0.08s linear;
  position: absolute;
  svg {
    width: 16px;
    height: 16x;
    fill: ${({ theme }) => theme.white};
    path {
      fill: ${({ theme }) => theme.white};
    }
  }

  .intercom-open & {
    opacity: 1;
    transform: rotate(0deg);
  }
`;
