import { memo } from "react";

import { useTranslation } from "react-i18next";

import { ScrollableContent } from "@/components/ScrollableContent";

import { ButtonColor } from "@/enums";

import {
  ShowMoreButton,
  TransactionHistoryWrapper,
  Transactions,
} from "../Account.styles";
import Transaction from "./Transaction";

// fake data FIXME:
const transactions = [
  {
    id: 1,
    number: "№ 109021",
    status: "pending",
    balance: "+ $ 25.30",
    date: "03 - 06 - 19, 14:21",
  },
  {
    id: 2,
    number: "№ 109021",
    status: "cancel",
    balance: "+ $ 25.30",
    date: "03 - 06 - 19, 14:21",
  },
  {
    id: 3,
    number: "№ 109021",
    status: "success",
    balance: "+ $ 25.30",
    date: "03 - 06 - 19, 14:21",
  },
];

const TransactionHistory = () => {
  const { t } = useTranslation("common");

  return (
    <TransactionHistoryWrapper>
      <ScrollableContent autoHeight autoHeightMax={420}>
        <Transactions>
          {transactions.map((transaction) => (
            <Transaction key={transaction.id} transaction={transaction} />
          ))}
        </Transactions>
      </ScrollableContent>
      <ShowMoreButton color={ButtonColor.Primary}>
        {t("common.show5More")}
      </ShowMoreButton>
    </TransactionHistoryWrapper>
  );
};

export default memo(TransactionHistory);
