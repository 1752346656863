import styled from "styled-components";

interface LogoComponentProps {
  $image: string;
  size: number;
}

export const LogoComponent = styled.div<LogoComponentProps>`
  height: ${(props) => `${props.size}px`};
  width: ${(props) => `${props.size}px`};
  min-width: ${(props) => `${props.size}px`};
  background: center no-repeat;
  background-size: cover;
  background-image: ${({ $image }) => `url(${$image})`};
  border-radius: 50%;

  &[disabled] {
    opacity: 30%;
  }
`;
