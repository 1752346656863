import React, { FC, memo, useCallback } from "react";

import { Row } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/Button";

import {
  ColWrapper,
  Content,
  Paragraph,
  PlaceholderIcon,
  PlaceholderTitle,
  Wrapper,
} from "@/styles/placeholder";

import { ButtonColor, ButtonVariant } from "@/enums";

import { placeholderIcon } from "../images";

const NoItemsPlaceholder: FC = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const clickChooseParcels = useCallback(
    () => navigate("/parcels"),
    [navigate],
  );

  return (
    <Wrapper>
      <Row>
        <ColWrapper md={6} xs={12}>
          <Content className="separator">
            <PlaceholderIcon
              src={placeholderIcon}
              alt={t("parcels.placeholderIcon")}
            />
            <Paragraph>{t("parcels.youDontAnyOutgoingParcels")}</Paragraph>
          </Content>
        </ColWrapper>
        <ColWrapper md={6} xs={12}>
          <Content>
            <PlaceholderTitle>
              {t("parcels.startSelectingForSending")}
            </PlaceholderTitle>
            <Button
              color={ButtonColor.Primary}
              variant={ButtonVariant.Filled}
              onClick={clickChooseParcels}
            >
              {t("common.chooseParcels")}
            </Button>
          </Content>
        </ColWrapper>
      </Row>
    </Wrapper>
  );
};

export default memo(NoItemsPlaceholder);
