import ReactPopover from "react-popover";
import styled from "styled-components";

export const TooltipWrapper = styled(ReactPopover)`
  min-width: max-content;
  z-index: 3;

  .Popover-tipShape {
    fill: white;
  }
`;

export const TooltipBody = styled.div`
  filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.15));
  font-size: 12px;
  padding: 12px;
  line-height: 18px;
  text-align: left;
  color: rgba(0, 0, 0, 0.75);
  background-color: #fff;
  max-height: 90vh;
  max-width: 90vw;
`;
