import React, { FC } from "react";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { redirect } from "react-router-dom";

import {
  ContentWrapper,
  Heading,
} from "@/containers/RightPanel/RightPanel.styles";

import { useRechargeBalance } from "@/hooks/react-query/books";

import { useBooksStore } from "@/store";

import addFundsFormik from "../../helpers/addFundsFormik";
import { ContentContainer, FormWrapper } from "../../styles";
import BooksAddFundsForm from "./BooksAddFundsForm";
import BooksAddFundsPayButton from "./BooksAddFundsPayButton";

const BooksAddFundsPanel: FC = React.memo(() => {
  const { t } = useTranslation("common");
  const redirectFunc = () => redirect("/parcels");
  const { mutateAsync: createRechargeBalance } = useRechargeBalance();
  const { updateAmountFunds } = useBooksStore();

  const {
    handleBlur,
    handleSubmit,
    handleChange,
    errors,
    values,
    touched,
    isSubmitting,
    isValid,
    setFieldValue,
  } = useFormik(
    addFundsFormik(t, createRechargeBalance, redirectFunc, updateAmountFunds),
  );

  return (
    <ContentWrapper>
      <Heading>{t("books.addFundsPanel.chargeBankCard")}</Heading>
      <ContentContainer>
        <FormWrapper>
          <BooksAddFundsForm
            handleBlur={handleBlur}
            handleChange={handleChange}
            values={values}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
          />
        </FormWrapper>
        <BooksAddFundsPayButton
          setFieldValue={setFieldValue}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          isValid={isValid}
        />
      </ContentContainer>
    </ContentWrapper>
  );
});

export default BooksAddFundsPanel;
