import { FC, memo } from "react";

import { PaginationButton } from "./PaginationButton.styles";

interface PackagesPaginationProps {
  count: number;
  selectedIndex: number;
  onSelect: (index: number) => void;
}

const PackagesPagination: FC<PackagesPaginationProps> = ({
  count,
  selectedIndex,
  onSelect,
}) => {
  const items = () =>
    [...Array(count)].map((_, i) => {
      const defaultColor = "#AAABAC";
      const selectedColor = "#E0703A";
      const buttonHandler = () => onSelect(i);

      return (
        <PaginationButton
          key={i}
          background={selectedIndex === i ? selectedColor : defaultColor}
          onClick={buttonHandler}
          data-testId={"pagination-button"}
        >
          {i + 1}
        </PaginationButton>
      );
    });

  return <div>{items()}</div>;
};

export default memo(PackagesPagination);
