import React, { FC } from "react";

import { DefaultTheme } from "styled-components";

import { useFullScreenImageStore } from "@/store";

import {
  ImageContainer,
  Wrapper,
  ZoomableImage,
} from "./FullscreenImage.styles";

interface FullscreenImageProps {
  leftOffsetByTheme?: (theme: DefaultTheme) => string[];
}

const FullscreenImage: FC<FullscreenImageProps> = React.memo(
  ({ leftOffsetByTheme = () => ["60px", "80px"] }) => {
    const {
      isVisibleFullScreenImage,
      relativePosition,
      zoomLevel,
      imageSource,
    } = useFullScreenImageStore();

    if (!isVisibleFullScreenImage) return null;

    return (
      <Wrapper $leftOffsetByTheme={leftOffsetByTheme}>
        <ImageContainer>
          <ZoomableImage
            src={imageSource ?? ""}
            position={relativePosition}
            $zoomLevel={zoomLevel}
          />
        </ImageContainer>
      </Wrapper>
    );
  },
);

export default FullscreenImage;
