import { FC, memo } from "react";

import { PreLoaderColor } from "@/enums";

import { Loader, Wrapper } from "./PreLoader.styles";

interface PreLoaderProps {
  isActive?: boolean;
  color?: PreLoaderColor;
  className?: string;
}

const PreLoader: FC<PreLoaderProps> = ({
  isActive = false,
  color = PreLoaderColor.PrimaryLight,
  className = "",
}) => (
  <Wrapper
    color={color}
    $isActive={isActive}
    className={className}
    data-testId="preLoaderWrapper"
  >
    <Loader>
      {[...new Array(8)].map((i, index) => (
        <div key={index} />
      ))}
    </Loader>
  </Wrapper>
);

export default memo(PreLoader);
