import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Purchase_API, PurchaseAll_API } from "@/api/purchase_api";

import { toastResponseError } from "@/utils/responseMessageHelper";

import useOutgoingStore from "@/store/useOutgoingStore";
import { usePurchaseStore, useRightPanelStore } from "@/store";
import { OutgoingType } from "@/types/api/outgoing";
import {
  PaymentConfirmAllErrorType,
  PurchaseConsolidationCreditCardCompleteDto,
} from "@/types/api/purchase_all";
import { PurchaseQueryKey } from "@/types";

import { openExternalURL } from "./helpers";
import { useOutgoing } from "./outgoing";

const usePurchase = () => {
  const queryClient = useQueryClient();

  const { closeRightPanel } = useRightPanelStore();
  const { movePackageFromWaitingPaymentToPaid } = useOutgoingStore();
  const {
    resetPurchaseStore: clearPurchase,
    updatePurchaseId,
    updateIsLoadingPurchase,
  } = usePurchaseStore();
  const {
    outgoingTypes: {
      packing_in_progress: inProgressData,
      waiting_for_payment: paymentData,
    },
  } = useOutgoingStore();

  const handleError = (error: string) => {
    clearPurchase();
    toastResponseError(error);
  };

  const confirmationSuccess = async (id: number) => {
    closeRightPanel();
    movePackageFromWaitingPaymentToPaid(id);
    clearPurchase();
  };

  const checkout = useMutation({
    mutationFn: Purchase_API.confirmation,
    onMutate: ({ id }) => {
      updatePurchaseId(id);
    },
    onSuccess: (data, variables) => {
      confirmationSuccess(+variables.id);
    },
    onError: (e) => handleError(e.message),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PurchaseQueryKey.PurchaseCheckout],
      });
    },
  });

  const checkoutPayPalFunc = useMutation({
    mutationFn: Purchase_API.checkout,
    onMutate: ({ id }) => {
      updatePurchaseId(id);
    },
    onSuccess: ({ data }, variables) => {
      confirmationSuccess(variables.id);
      const redirect = data.redirect_url;
      openExternalURL(redirect);
      clearPurchase();
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PurchaseQueryKey.PurchaseCheckoutPayPal],
      });
    },
    onError: (e) => handleError(e.message),
  }).mutateAsync;

  const checkoutCreditCardFunc = useMutation({
    mutationFn: Purchase_API.checkoutCreditCard,
    onMutate: (id) => {
      updatePurchaseId(id);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PurchaseQueryKey.PurchaseCheckoutCreditCard],
      });
    },
    onError: (e) => handleError(e.message),
  }).mutateAsync;

  const paymentCompleteViaPayPal = useMutation({
    mutationFn: Purchase_API.complete,
    onMutate: ({ id }) => {
      updatePurchaseId(id);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PurchaseQueryKey.PurchasePaymentCompleteViaPayPal],
      });
    },
    onError: (e) => handleError(e.message),
    onSuccess: (data, variables) => {
      Purchase_API.confirmation({ id: variables.id })
        .then(() => confirmationSuccess(+variables.id))
        .catch((e) => {
          useOutgoing({
            type: OutgoingType.WaitingForPayment,
            params: {
              page: 1,
              per_page: paymentData.items.length + 1,
            },
          });
          useOutgoing({
            type: OutgoingType.Paid,
            params: {
              page: 1,
              per_page: 0,
            },
          });
        });
    },
  });

  const confirmationAllSuccess = () => {
    useOutgoing({
      type: OutgoingType.WaitingForPayment,
      params: {
        page: 1,
        per_page: paymentData.items.length + 1,
      },
    });

    useOutgoing({
      type: OutgoingType.Paid,
      params: {
        page: 1,
        per_page: 0,
      },
    });

    closeRightPanel();
    useOutgoing({
      type: OutgoingType.PackingInProgress,
      params: {
        page: 1,
        per_page: inProgressData.items.length,
      },
    });

    clearPurchase();
  };

  const paymentConfirmAll = useMutation({
    mutationFn: PurchaseAll_API.confirmation,
    onMutate: () => {
      updateIsLoadingPurchase(true);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PurchaseQueryKey.PurchasePaymentConfirmAll],
      });
    },
    onError: (error: PaymentConfirmAllErrorType) => {
      if (!error.data || !error.data.pay_url || !error.data.sign_id) {
        toastResponseError(error);
      }

      const sign_id = error?.data?.sign_id;
      checkoutPayPalAll(sign_id ?? "");
    },
  });

  const checkoutPayPalAllFunc = useMutation({
    mutationFn: PurchaseAll_API.checkout_paypal,
    onSuccess: ({ data }) => {
      const redirect = data.redirect_url;
      openExternalURL(redirect);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PurchaseQueryKey.PurchaseCheckoutPayPalAll],
      });
    },
    onError: (e) => handleError(e.message),
  }).mutateAsync;

  const paymentCompleteAllViaPaypal = useMutation({
    mutationFn: PurchaseAll_API.complete_paypal,
    onMutate: () => {
      updateIsLoadingPurchase(true);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PurchaseQueryKey.PurchasePaymentCompleteAllViaPayPal],
      });
    },
    onError: (e) => handleError(e.message),
    onSuccess: () => {
      PurchaseAll_API.confirmation().then(() => confirmationAllSuccess());
    },
  });

  const checkoutPayPal = (id: number) => {
    const origin = window.location.origin;

    const checkoutPaypalDto = {
      id,
      data: {
        success_url: `${origin}/parcels/outgoing/${id}/success`,
        cancel_url: `${origin}/parcels/outgoing/${id}/cancel`,
      },
    };

    checkoutPayPalFunc(checkoutPaypalDto);
  };

  const checkoutCreditCard = (creditCartDto: {
    id: number;
    data: PurchaseConsolidationCreditCardCompleteDto;
  }) => {
    checkoutCreditCardFunc(creditCartDto.id).then(() => {
      Purchase_API.completeCreditCard(
        creditCartDto.id,
        creditCartDto.data,
      ).then(() => {
        checkout.mutateAsync({ id: creditCartDto.id });
      });
    });
  };

  const checkoutPayPalAll = (id: string) => {
    const origin = window.location.origin;

    const checkoutPaypalAllDto = {
      sign_id: id,
      success_url: `${origin}/parcels/outgoing/all/success?sign_id=${id}`,
      cancel_url: `${origin}/parcels/outgoing/all/cancel?sign_id=${id}`,
    };

    checkoutPayPalAllFunc(checkoutPaypalAllDto);
  };

  return {
    checkout,
    checkoutPayPal,
    checkoutCreditCard,
    paymentCompleteViaPayPal,
    paymentConfirmAll,
    paymentCompleteAllViaPaypal,
  };
};

export default usePurchase;
