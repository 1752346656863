import { useEffect } from "react";

import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import ExpectedAPI from "@/api/expected_api";
import StorageAPI from "@/api/storage_api";

import { toastResponseError } from "@/utils/responseMessageHelper";

import { useStorageStore } from "@/store";
import { CustomsDeclaration as CustomsDeclarationResponseDto } from "@/types/api/common";
import {
  ExpectedShipmentResponseDto,
  ExpectedShipmentsDto,
} from "@/types/api/expected";
import { ShopsResponseDto } from "@/types/api/shipments";
import { ExpectedQueryKey as QueryKey } from "@/types";

export const useExpectedItems = (
  paramsDto: ExpectedShipmentsDto | null,
  options?: Omit<
    UseQueryOptions<AxiosResponse<ExpectedShipmentResponseDto[]>>,
    "queryKey" | "queryFn"
  >,
) => {
  const params = paramsDto ? paramsDto : { page: 1 };
  const {
    updateIsLoadingStorage,
    updateExpectedItemsSearch,
    updateExpectedTotalAmount,
  } = useStorageStore();

  const fetchExpectedItems = useQuery({
    queryKey: [QueryKey.ExpectedItems, params],
    queryFn: () => ExpectedAPI.getExpectedItems(params),
    ...options,
  });

  const { isLoading, isSuccess, data, error } = fetchExpectedItems;

  useEffect(() => {
    updateIsLoadingStorage(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (error) {
      toastResponseError(error);
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess && data) {
      const { data: responseDto, headers } = data;
      updateIsLoadingStorage(false);
      updateExpectedItemsSearch(responseDto);
      updateExpectedTotalAmount(headers["total"]);
    }
  }, [isSuccess, data]);

  return fetchExpectedItems;
};

export const useCreateExpectedItem = () => {
  const queryClient = useQueryClient();
  const { updateExpectedItemsSearch, expectedItemsSearch } = useStorageStore();

  const createExpectedItem = useMutation({
    mutationFn: ExpectedAPI.createExpectedItem,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.ExpectedCreateExpectedItem],
      });
    },
    onSuccess: (createItemDto) => {
      updateExpectedItemsSearch([...expectedItemsSearch, createItemDto.data]);
    },
  });

  return createExpectedItem;
};

export const useUpdateExpectedItem = () => {
  const queryClient = useQueryClient();
  const { updateExpectedItemsSearch, expectedItemsSearch } = useStorageStore();

  const updateExpectedItem = useMutation({
    mutationFn: ExpectedAPI.updateExpectedItem,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.ExpectedUpdateExpectedItem],
      });
    },
    onSuccess: (updateItemDto, variables) => {
      updateExpectedItemsSearch(
        expectedItemsSearch.map((item) =>
          item.id === variables.id ? updateItemDto.data : item,
        ),
      );
    },
  });

  return updateExpectedItem;
};

export const useDeleteExpectedItem = () => {
  const queryClient = useQueryClient();
  const { updateExpectedItemsSearch, expectedItemsSearch } = useStorageStore();

  const deleteExpectedItem = useMutation({
    mutationFn: ExpectedAPI.deleteExpectedItem,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.ExpectedDeleteExpectedItem],
      });
    },
    onSuccess: (_, id) => {
      updateExpectedItemsSearch(
        expectedItemsSearch.filter((item) => item.id !== id),
      );
    },
  });

  return deleteExpectedItem;
};

export const useExpectedCustomsDeclarations = (
  id: number,
  options?: Omit<
    UseQueryOptions<CustomsDeclarationResponseDto[]>,
    "queryKey" | "queryFn"
  >,
) => {
  const {
    updateIsLoadingCustomsDeclarations,
    expectedItemsSearch,
    updateExpectedItemsSearch,
  } = useStorageStore();

  const fetchExpectedCustomsDeclarations = useQuery({
    queryKey: [QueryKey.ExpectedCustomsDeclarations],
    queryFn: () => ExpectedAPI.getCustomsDeclarationsForExpectedItem(id),
    ...options,
  });

  const { data, isSuccess, isLoading } = fetchExpectedCustomsDeclarations;

  useEffect(() => {
    if (isSuccess && data) {
      updateExpectedItemsSearch(
        expectedItemsSearch.map((item) =>
          item.id === id ? { ...item, customs_declarations: data } : item,
        ),
      );
    }
  }, [isSuccess, data]);

  useEffect(() => {
    updateIsLoadingCustomsDeclarations(isLoading);
  }, [isLoading]);

  return fetchExpectedCustomsDeclarations;
};

export const useExpectedCreateCustomsDeclaration = () => {
  const queryClient = useQueryClient();
  const { expectedItemsSearch, updateExpectedItemsSearch } = useStorageStore();

  const createCustomsDeclaration = useMutation({
    mutationFn: ExpectedAPI.createCustomsDeclarationForExpectedItem,
    onSettled: (_) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.ExpectedCreateCustomInformation],
      });
    },
    onSuccess: (customDeclarationDto, { itemId }) => {
      updateExpectedItemsSearch(
        expectedItemsSearch.map((item) => {
          const customsDeclarations = item.customs_declarations ?? [];

          return item.id === itemId
            ? {
                ...item,
                customs_declarations: [
                  ...customsDeclarations,
                  customDeclarationDto.data,
                ],
              }
            : item;
        }),
      );
    },
  });

  return createCustomsDeclaration;
};

export const useExpectedUpdateCustomsDeclaration = () => {
  const queryClient = useQueryClient();
  const { expectedItemsSearch, updateExpectedItemsSearch } = useStorageStore();

  const updateCustomsDeclaration = useMutation({
    mutationFn: ExpectedAPI.createCustomsDeclarationForExpectedItem,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.ExpectedUpdateCustomInformation],
      });
    },
    onSuccess: (customDeclarationDto, { itemId }) => {
      updateExpectedItemsSearch(
        expectedItemsSearch.map((item) => {
          const customsDeclarations = item.customs_declarations ?? [];

          return item.id === itemId
            ? {
                ...item,
                customs_declarations: customsDeclarations.map((declaration) =>
                  declaration.id === itemId
                    ? customDeclarationDto.data
                    : declaration,
                ),
              }
            : item;
        }),
      );
    },
  });

  return updateCustomsDeclaration;
};

export const useExpectedDeleteCustomsDeclaration = () => {
  const queryClient = useQueryClient();

  const deleteCustomsDeclaration = useMutation({
    mutationFn: ExpectedAPI.deleteCustomsDeclarationForExpectedItem,
    onSettled: (_) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.ExpectedDeleteCustomInformation],
      });
    },
  });

  return deleteCustomsDeclaration;
};

export const useExpectedShops = (
  options?: Omit<UseQueryOptions<ShopsResponseDto[]>, "queryKey" | "queryFn">,
) => {
  const { updateExpectedShops } = useStorageStore();

  const fetchShops = useQuery({
    queryKey: [QueryKey.ExpectedShops],
    queryFn: () => StorageAPI.getExpectedShops(),
    ...options,
  });

  const { data, isSuccess } = fetchShops;

  useEffect(() => {
    if (isSuccess && data) {
      updateExpectedShops(data);
    }
  }, [isSuccess, data]);

  return fetchShops;
};
