import styled from "styled-components";

import { Icon } from "@/components/Icon";

import { FieldWrapper } from "@/styles/form";

export const PasswordValidationWrapper = styled.div`
  align-self: flex-start;
  display: flex;
  width: 100%;
`;

export const LeftPasswordSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const FieldWrapperTop = styled(FieldWrapper)`
  align-items: flex-start;
`;

export const ValidationItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  color: #333;
  font-size: 13px;
  margin-bottom: 1px;
`;

export const GoodIconWrapper = styled.div`
  height: 16px;
  width: 16px;
  padding: 2px;
  flex-shrink: 0;

  & > i {
    height: 13px;
    width: 13px;
    transition: background-color 0.3s;
  }
`;

export const WrongIcon = styled(Icon)`
  height: 16px;
  width: 16px;
  flex-shrink: 0;
  transition: background-color 0.3s;
`;

export const Wrapper = styled.div`
  text-align: left;
  margin-top: 10px;
`;
