import { Scrollbars } from "react-custom-scrollbars-2";
import styled from "styled-components";

export const CustomScroll = styled.div`
  z-index: 1;
  cursor: pointer;
  border-radius: 4px;
  background-color: #e1e2e6;
`;

export const StyledScrollBars = styled(Scrollbars)``;
