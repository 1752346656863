import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
} from "react";

import { AxiosResponse } from "axios";
import { ButtonColor, ButtonSize, ButtonType, ButtonVariant } from "enums";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { Input } from "@/components/Input";

import { ItemsPutAwayDto } from "@/types/api/items";

import { toolsPutAwayForm } from "../helpers";
import {
  ButtonsWrapper,
  Field,
  Label,
  SkuLabel,
} from "./ToolsComponents.styles";

export interface ToolsPutAwayFormProps {
  handlePutAway: (data: ItemsPutAwayDto) => Promise<AxiosResponse>;
}

const ToolsPutAwayForm: FC<ToolsPutAwayFormProps> = React.memo(
  ({ handlePutAway }) => {
    const { t } = useTranslation("common");
    const {
      isSubmitting = true,
      setFieldValue,
      values,
      touched,
      errors,
      handleSubmit,
    } = useFormik(toolsPutAwayForm(t, handlePutAway));

    const skuInputRef = useRef<HTMLInputElement>(null);
    const locationInputRef = useRef<HTMLInputElement>(null);

    const handleEnterPress = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
          event.preventDefault();
          event.stopPropagation();

          if (skuInputRef.current && skuInputRef.current.value) {
            locationInputRef.current && locationInputRef.current.focus();
          }
        }
      },
      [],
    );

    useEffect(() => {
      if (!values.sku && skuInputRef.current) {
        skuInputRef.current.focus();
      }
    }, [values.sku]);

    return (
      <form onSubmit={handleSubmit}>
        <Field>
          <SkuLabel>Scan SKU</SkuLabel>
          <Input
            autoFocus
            name="sku"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setFieldValue("sku", event.target.value)
            }
            tooltip={errors.sku}
            invalid={errors.sku && touched.sku}
            value={values.sku}
            disabled={isSubmitting}
            onKeyDown={handleEnterPress}
            inputRef={skuInputRef}
            data-testid={"sku"}
          />
        </Field>
        <Field>
          <Label>Scan location</Label>
          <Input
            name="location"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setFieldValue("location", event.target.value)
            }
            tooltip={errors.location}
            invalid={errors.location && touched.location}
            value={values.location}
            disabled={isSubmitting}
            inputRef={locationInputRef}
            data-testid={"location"}
          />
        </Field>
        <ButtonsWrapper>
          <Button
            isLoading={isSubmitting}
            disabled={isSubmitting}
            size={ButtonSize.Large}
            type={ButtonType.Submit}
            color={ButtonColor.Primary}
            variant={ButtonVariant.Filled}
            onClick={() => handleSubmit()}
          >
            {t("common.save")}
          </Button>
        </ButtonsWrapper>
      </form>
    );
  },
);

export default ToolsPutAwayForm;
