import { WeeksItems } from "@/store/useHistoryStore";
import { requestNames } from "@/store/useServicesRequestStore";
import {
  HistoryFullDetailedItemResponseDto,
  SelectedYearItem,
  ServiceRequest,
  ServiceRequestCount,
} from "@/types/api/history";

export const setWeeks = (items: SelectedYearItem[]) => {
  const weeks: WeeksItems = {};
  let id = 1;

  for (const item of items) {
    const weekNumber = item.week_number;
    const updatedItem = { ...item, id: id++ };

    if (!weeks[weekNumber]) {
      weeks[weekNumber] = [];
    }

    weeks[weekNumber].push(updatedItem);
  }

  return weeks;
};

export const getServiceRequestCount = (
  serviceRequests: ServiceRequest[],
): ServiceRequestCount => {
  const serviceRequestCount: ServiceRequestCount = serviceRequests.reduce(
    (acc, request) => {
      const rawRequestType = request.service_request_type.type.toLowerCase();
      const state = request.processed_state;
      const requestType =
        requestNames[rawRequestType as keyof typeof requestNames];

      if (requestType) {
        acc[requestType] = state;
      }

      return acc;
    },
    {} as ServiceRequestCount,
  );

  return serviceRequestCount;
};

export const normalizeDetailed = (
  parcel: HistoryFullDetailedItemResponseDto,
) => {
  const updatedItems = parcel.items.map((item) => ({
    ...item,
    serviceRequestCount: getServiceRequestCount(item.service_requests),
  }));

  const updatedAddons = parcel.consolidation_addons.map((addon) => addon.id);

  return {
    ...parcel,
    items: updatedItems,
    consolidation_addons: updatedAddons,
  };
};

export const openExternalURL = (url: string) => {
  // For mobile platform it is not posible to call window.open from async call,
  // browser allows to open new window only by clicking UI component
  // more to read: https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
  const isMobile = /Mobi/.test(navigator.userAgent);
  if (isMobile) {
    window.location.assign(url);
  } else {
    window.open(url, "_blank", "noopener");
  }
};
