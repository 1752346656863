import { create } from "zustand";

import { ServiceRequestsResponseDto } from "@/types/api/service_requests";
import { TransferRequestsType } from "@/types/StorageForms/ServiceRequest";

interface UseServicesRequestStore {
  types: ServiceRequestsResponseDto[];
  transferRequests: TransferRequestsType;
  updateServiceRequestTypes: (
    serviceRequestTypes: ServiceRequestsResponseDto[],
  ) => void;
  updateTransferRequests: (transferRequests: TransferRequestsType) => void;
}

export const requestNames = {
  "additional photos": "additionalPhotos",
  "power-on test": "powerCheck",
  custom: "serviceByInstruction",
  requestinventoryshipment: "requestInventoryShipment",
};

const useServicesRequestStore = create<UseServicesRequestStore>((set) => ({
  types: [],
  transferRequests: {},
  updateServiceRequestTypes: (serviceRequestTypes) =>
    set(() => ({
      types: serviceRequestTypes.map((req) => ({
        ...req,
        type: req.type.toLowerCase(),
      })),
    })),
  updateTransferRequests: (transferRequests) =>
    set(() => ({
      transferRequests,
    })),
}));

export default useServicesRequestStore;
