import styled from "styled-components";

export const Box = styled.div`
  position: absolute;
  background-color: white;
  margin-top: 6px;
  z-index: 99999;
  box-shadow: 0 4px 16px 0 #1616160d;
  top: 100%;
  width: 370px;
  max-height: 300px;
  display: flex;
  justify-content: center;
  overflow-y: auto;
`;

export const InnerBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 99999;
`;

export const CustomButton = styled.button`
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.primaryLight};
  display: flex;
  padding: 20px;
  flex: 1;
  max-height: 40px;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  transition:
    box-shadow 0.2s,
    background-color 0.2s,
    color 0.2s,
    border 0.2s;
  cursor: pointer;
  border: none;

  &:hover {
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.primaryLight};
  }
  &:focus {
    outline: none;
  }
`;
