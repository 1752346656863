import styled from "styled-components";

import { Button } from "@/components/Button";

interface ParcelProps {
  $isSelected?: boolean;
}

export const ParcelWrapper = styled.div<ParcelProps>`
  padding-bottom: 13px;
  border-bottom: 1px solid #f2f2f2;
  padding: 22px;

  &:not(:first-child) {
    padding-top: 15px;
    margin-top: 0;
  }

  &:last-child {
    ${({ theme }) =>
      theme.range(
        "margin-bottom",
        "-15px",
        "-22px",
        `${theme.breakpoints.md}px`,
        `${theme.breakpoints.lg}px`,
      )};
  }

  ${({ theme }) =>
    theme.range(
      "margin-left",
      "-15px",
      "-22px",
      `${theme.breakpoints.md}px`,
      `${theme.breakpoints.lg}px`,
    )};

  ${({ theme }) =>
    theme.range(
      "margin-top",
      "-15px",
      "-22px",
      `${theme.breakpoints.md}px`,
      `${theme.breakpoints.lg}px`,
    )};

  ${({ theme }) =>
    theme.range(
      "margin-right",
      "-15px",
      "-22px",
      `${theme.breakpoints.md}px`,
      `${theme.breakpoints.lg}px`,
    )};

  ${({ $isSelected, theme }) =>
    $isSelected &&
    `
      background-color: ${theme.primary10};
      border: none;
    `}
`;

export const ParcelHeader = styled.div`
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ParcelTitle = styled.p`
  margin: 8px 0;
`;

export const ParcelInfo = styled.div`
  color: ${(props) => props.theme.black50};
`;

export const ParcelText = styled.p`
  margin: 4px 0 7px;
`;

export const ParcelButton = styled(Button)<ParcelProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 15px 0 0;

  ${({ $isSelected }) =>
    $isSelected &&
    `
      background-color: #d0f3ae;
      padding: 5px 10px;
    `}
`;

export const FoundPackage = styled.div`
  margin-right: auto;
  color: ${(props) => props.theme.black};
  display: flex;
  align-items: center;

  div,
  i {
    margin-right: 6px;
  }
`;

export const CustomsDeclarationContent = styled.div`
  padding: 0 15px;
`;

export const DeclarationTotal = styled.div`
  padding: 5px;
  line-height: 30px;
  font-size: 16px;
  font-weight: bold;
`;
