import { FC, memo } from "react";

import { ServiceRequestPanelData } from "@/store/useRightPanelStore";

import { Requests } from "./ServiceTooltip.styles";
import ServiceTooltipItem from "./ServiceTooltipItem";

interface ServiceTooltipsProps {
  zIndex?: number;
  fullWidth?: boolean;
  requests: any;
  additionalPanelData?: ServiceRequestPanelData;
  parcel: any;
}

const ServiceTooltips: FC<ServiceTooltipsProps> = ({
  requests,
  parcel,
  zIndex = 0,
  additionalPanelData,
  fullWidth = true,
}) => {
  const requestKeys = Object.keys(parcel.serviceRequestCount);
  const parcelId = parcel.id;

  return (
    <Requests $fullWidth={fullWidth}>
      {requestKeys.map((name) => (
        <ServiceTooltipItem
          key={name}
          parcelId={parcelId}
          name={name}
          status={requests[name]}
          zIndex={zIndex}
          additionalPanelData={additionalPanelData}
        />
      ))}
    </Requests>
  );
};

export default memo(ServiceTooltips);
