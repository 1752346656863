import { FC, memo } from "react";

import placeholderIcon from "@/components/Icon/icons/not-selected-delivery.svg";

import { ServicesLogo as Logo } from "./ServicesLogo.styles";

interface ServicesLogoProps {
  size?: number;
  logoUrl?: string;
}

const ServicesLogo: FC<ServicesLogoProps> = ({ logoUrl, size = 45 }) => {
  const image = logoUrl || placeholderIcon;

  return <Logo image={image} size={size} />;
};

export default memo(ServicesLogo);
