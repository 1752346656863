import styled from "styled-components";

import { PreLoader } from "@/components/PreLoader";

export const Title = styled.span`
  margin-left: 10px;
`;

export const Spinner = styled(PreLoader)`
  position: absolute;
  margin-top: 80px;
`;

export const Answer = styled.div`
  padding: 6px 12px;
  line-height: 1.7;
  color: ${(props) => props.theme.black50};

  p {
    margin: 0;
  }
`;
