import React, { FC, useCallback, useEffect } from "react";

import { useFormik } from "formik";
import { Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import {
  AddressesList,
  AddressSearch,
} from "@/components/Addresses/components";
import { Button } from "@/components/Button";

import { toastResponseError } from "@/utils/responseMessageHelper";
import { useCartSetAddress } from "@/hooks/react-query/cart";
import { useDeliveryMethods } from "@/hooks/react-query/services";
import { ProgressDeliverySetAccessFunc } from "@/hooks";

import { useCartStore, useDestinationsStore, useServicesStore } from "@/store";
import { ButtonColor, ButtonSize, ButtonType, ButtonVariant } from "@/enums";

import {
  BlockContent,
  Content,
  DeliveryBlock,
  Heading,
  SubmitWrapper,
} from "../../styles";
import { ScrollableForm } from "../ScrollableForm";
import { AddButton, Form } from "./ChooseForm.styles";

interface ChooseAddressFormProps {
  setAccess: ProgressDeliverySetAccessFunc;
}

const ChooseAddressForm: FC<ChooseAddressFormProps> = React.memo(
  ({ setAccess }) => {
    const { t } = useTranslation("common");
    const navigate = useNavigate();
    const { cart } = useCartStore();

    const { addresses: destinations } = useDestinationsStore();

    const { mutateAsync: setAddress } = useCartSetAddress();
    const { refetch: getDeliveryMethods } = useDeliveryMethods({
      enabled: false,
    });

    const {
      handleSubmit,
      setFieldValue,
      isSubmitting,
      values: { addressId },
    } = useFormik({
      initialValues: {
        addressId: cart?.destination_address_id,
      },
      validationSchema: () =>
        yup.object().shape({
          addressId: yup.number().required(),
        }),
      onSubmit: (values) => {
        const selectedAddressCountry = destinations.find(
          (destination) => destination.id === values.addressId,
        )?.country;
        const shouldSubmit =
          values.addressId !== cart?.destination_address_id ||
          selectedAddressCountry !== cart?.destination_address?.country;

        if (shouldSubmit && values.addressId) {
          setAddress(values.addressId)
            .then(() => {
              getDeliveryMethods().catch(toastResponseError);
              setAccess("delivery");
              navigate("/shipping/flow/delivery");
            })
            .catch(toastResponseError);
          return;
        }

        setAccess("delivery");
        navigate("/shipping/flow/delivery");
      },
    });

    const handleAddressSelect = useCallback(
      (id: number | string) => setFieldValue("addressId", id),
      [setFieldValue],
    );

    const handleAddressSubmit = useCallback(
      async (id: number) => {
        await setFieldValue("addressId", id);
        handleSubmit();
      },
      [setFieldValue, handleSubmit],
    );

    return (
      <Col lg={4} md={6} sm={12} offset={{ md: 3, lg: 4, sm: 12 }}>
        <Content>
          <DeliveryBlock>
            <Heading>{t("shipping.selectAddress")}:</Heading>
            <BlockContent>
              <AddressSearch />
              <Form onSubmit={handleSubmit}>
                <ScrollableForm>
                  <AddressesList
                    editable
                    selectedAddress={addressId}
                    onSelect={handleAddressSelect}
                    onSubmit={handleAddressSubmit}
                  />
                </ScrollableForm>
                <AddButton />
                <SubmitWrapper>
                  <Button
                    disabled={!addressId || isSubmitting}
                    isLoading={isSubmitting}
                    type={ButtonType.Submit}
                    size={ButtonSize.Large}
                    color={ButtonColor.Primary}
                    variant={ButtonVariant.Filled}
                  >
                    {t("common.nextStep")}
                  </Button>
                </SubmitWrapper>
              </Form>
            </BlockContent>
          </DeliveryBlock>
        </Content>
      </Col>
    );
  },
);

export default ChooseAddressForm;
