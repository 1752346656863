import React, { FC, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import { Icon } from "@/components/Icon";
import { ImageViewer2 } from "@/components/ImageViewer2";
import { PackagesList } from "@/components/PackagesList";

import {
  HeaderTitle,
  InnerSection,
  Section,
  SectionContent,
  SectionHeader,
} from "@/styles/outgoing";

import { RightPanelType } from "@/store/useRightPanelStore";
import { useHistoryStore, useRightPanelStore } from "@/store";
import { IconType } from "@/enums";
import { CartItem } from "@/types/api/cart";
import { Item } from "@/types/api/history";

import { ImageSection } from "../ParcelInfoTab.styles";

const ParcelInfoTabStandardPackageSection: FC = React.memo(() => {
  const { t } = useTranslation("common");
  const { historyFullDetailedConsolidation: parcel } = useHistoryStore();
  const { panelData, openRightPanel } = useRightPanelStore();
  const foundPackageId = panelData.foundPackageId ?? 0;
  const pictures = parcel?.pictures ?? [];
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current && foundPackageId)
      contentRef.current.scrollIntoView();
  }, [contentRef.current, foundPackageId]);

  const clickPackagesItem = (item: Item | CartItem) => {
    openRightPanel(RightPanelType.STORAGE_ITEM, {
      ...panelData,
      readOnly: true,
      id: item.shipment_id,
      originalItem: item,
      consolidationId: parcel?.id,
      consolidationSku: parcel?.sku,
      emblem_thumb_url: item.emblem_thumb_url,
      description: item.shipment_tracking_number,
      returnPreviousPanel: RightPanelType.PARCEL_DETAILED,
      rootReturnPanel: RightPanelType.WEEK_ITEM,
      shippingMethod: panelData.shippingMethod,
      updateComment: () => {},
      getShipmentType: "HistoryDetailedShipment",
    });
  };

  return (
    <Section>
      <HeaderTitle>
        <Icon type={IconType.Package} /> {t("parcels.parcelInformation")}:
      </HeaderTitle>
      {!!pictures.length && (
        <InnerSection>
          <SectionContent>
            <ImageSection>
              <ImageViewer2 images={pictures} />
            </ImageSection>
          </SectionContent>
        </InnerSection>
      )}
      <InnerSection ref={contentRef}>
        <SectionHeader>
          <HeaderTitle>
            {`${t("parcels.packagesIncluded")} (${!!parcel?.items.length || 0}):`}
          </HeaderTitle>
        </SectionHeader>
        <PackagesList
          extended
          items={parcel?.items ?? []}
          foundItemId={foundPackageId}
          handleClick={clickPackagesItem}
        />
      </InnerSection>
    </Section>
  );
});

export default ParcelInfoTabStandardPackageSection;
