import styled from "styled-components";

import { navWidth } from "../LeftMenu/LeftMenu.styles";

interface BlueLoadingIndicatorProps {
  $isLoading: boolean;
  $percentage: number;
}

export const BlueLoadingIndicator = styled.div<BlueLoadingIndicatorProps>`
  display: none;
  position: fixed;
  ${({ theme }) => theme.range("left", ...navWidth(theme))};
  top: 0;
  bottom: 0;
  z-index: 2;
  min-height: 100vh;
  margin-left: -1px;

  ${(props) => props.theme.sm`
    left: 2px;
  `}

  & > div {
    display: none;
    position: fixed;
    ${({ theme }) => theme.range("left", ...navWidth(theme))};
    top: 0;
    bottom: 0;
    transition: height 0.3s ease-in-out;
    margin-left: -1px;

    ${(props) => props.theme.sm`
      left: 2px;
    `}

    ${({ $isLoading, $percentage }) =>
      $isLoading &&
      `
      display: flex;
      border-left: 1px solid #c8c9cc;
      height: ${100 - $percentage}vh;
    `},
  }

  ${({ theme, $isLoading }) =>
    $isLoading &&
    `
    display: flex;
    border-left: 1px solid ${theme.primary};
  `},
`;
