import React, { FC, memo, useCallback } from "react";

import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";

import { ChooseAdditionalFormValues } from "@/containers/Delivery/components/ChooseForms/ChooseAdditionalForm/helpers";
import { getAddonsIconsImage } from "@/containers/Delivery/helpers";

import type { AddonsMethod } from "@/store/useServicesStore";
import { DetailedShipment } from "@/store/useStorageStore";
import { CartItem } from "@/types/api/cart";
import type { PackOptionsFormValues } from "@/types/StorageForms/PackOptions";

import { Checkbox } from "../Checkbox";
import { AddonItem, AddonsListPrice } from "./AddonsList.styles";

interface AddonsListProps {
  handleSelect: (id: number) => () => void;
  readOnly?: boolean;
  values: FormikProps<
    PackOptionsFormValues | ChooseAdditionalFormValues
  >["values"];
  addons?: AddonsMethod[];
  items: DetailedShipment[] | CartItem[];
}

const AddonsList: FC<AddonsListProps> = ({
  addons = [],
  items,
  values,
  readOnly = false,
  handleSelect,
}) => {
  const { t } = useTranslation("common");

  const getChecked = (addon: AddonsMethod) => {
    const parcelId = values.activeItem.value;

    if (parcelId === "all")
      return !items.find((cartItem) => {
        const itemValue = values.items[cartItem.id];

        return itemValue && !itemValue.includes(addon.id);
      });

    return values.items[parcelId] && values.items[parcelId].includes(addon.id);
  };

  const handleCheckBoxClick = useCallback(
    (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => e.preventDefault(),
    [],
  );

  return addons.map((addon) => {
    const isChecked = getChecked(addon);
    const icon = getAddonsIconsImage(addon.id, addons, 28);
    const isPrice = +addon.price;
    const priceTitle = isPrice ? `$${addon.price}` : t("common.free");
    const desc = addon.description;

    return (
      <AddonItem
        onClick={handleSelect(addon.id)}
        $isSelected={isChecked}
        $readOnly={readOnly}
        key={addon.id}
      >
        <Checkbox
          icon={icon}
          checked={isChecked}
          onClick={handleCheckBoxClick}
          label={desc}
        />
        <AddonsListPrice $isHighlighted={!!isPrice && isChecked}>
          {priceTitle}
        </AddonsListPrice>
      </AddonItem>
    );
  });
};

export default memo(AddonsList);
