import { FC, memo, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";

import { Icon } from "@/components/Icon";
import { PackagesPagination } from "@/components/PackagesPagination";

import { HeaderTitle, InnerSection, Section } from "@/styles/outgoing";

import { IconType } from "@/enums";
import { Package } from "@/types/api/history";

import ParcelInfoTabPackageInfo from "./ParcelInfoTabPackageInfo";

interface ParcelInfoTabPaginatedPackagesSectionProps {
  packages: Package[];
  hasFewPackages: boolean;
}

const ParcelInfoTabPaginatedPackagesSection: FC<
  ParcelInfoTabPaginatedPackagesSectionProps
> = ({ packages, hasFewPackages }) => {
  const { t } = useTranslation("common");
  const [selectedPackageIndex, setSelectedPackageIndex] = useState(0);

  const handleSetSelectPackageIndex = useCallback(
    (index: number) => {
      setSelectedPackageIndex(index);
    },
    [setSelectedPackageIndex],
  );

  return (
    <>
      <Section>
        <HeaderTitle>
          <Icon type={IconType.Package} />
          {hasFewPackages
            ? t("parcels.parcelsInformation")
            : t("parcels.parcelInformation")}
          :
        </HeaderTitle>
        {hasFewPackages && (
          <InnerSection>
            <PackagesPagination
              count={packages.length}
              selectedIndex={selectedPackageIndex}
              onSelect={handleSetSelectPackageIndex}
            />
          </InnerSection>
        )}
      </Section>
      <ParcelInfoTabPackageInfo
        pkg={packages[selectedPackageIndex]}
        showMPSInfo={hasFewPackages}
      />
    </>
  );
};

export default memo(ParcelInfoTabPaginatedPackagesSection);
