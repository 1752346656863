import { FormikHelpers } from "formik";
import * as yup from "yup";

import { toastResponseError } from "@/utils/responseMessageHelper";

import { OutgoingItem } from "@/types/api/outgoing";
import { ShipmentsUpdateCommentDto } from "@/types/api/shipments";
import { InventoryItemCommentFormValues } from "@/types/OutgoingForms/InventoryItemComment";

export const InventoryItemCommentFormik = (
  t: Function,
  shipment: OutgoingItem,
  updateComment: ({ id, data }: ShipmentsUpdateCommentDto) => Promise<void>,
) => ({
  validateOnChange: false,
  validateOnBlur: false,
  enableReinitialize: false,
  initialValues: {
    comment: shipment.customer_comment ?? "",
  },
  validationSchema: () =>
    yup.object().shape({
      comment: yup
        .string()
        .trim()
        .max(50, t("error.stringShorter", { length: 50 })),
    }),
  onSubmit: (
    values: InventoryItemCommentFormValues,
    { setSubmitting }: FormikHelpers<InventoryItemCommentFormValues>,
  ) => {
    const id = shipment.id;
    const data = {
      customer_comment: values.comment,
    };

    updateComment({ id, data })
      .catch(toastResponseError)
      .finally(() => setSubmitting(false));
  },
});
