import { FC, memo } from "react";

import { LogoComponent } from "./Logo.styles";

interface LogoProps {
  image: string;
  size?: number;
  className?: string;
}

const Logo: FC<LogoProps> = ({ image, size = 20, className = "" }) =>
  image ? (
    <LogoComponent $image={image} size={size} className={className} />
  ) : null;

export default memo(Logo);
