import styled from "styled-components";

export const HeaderDate = styled.span`
  margin-left: 5px;
  color: gray;
  font-size: 14px;
`;

export const CenterBlock = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;

  button {
    margin: 5px 0;
    padding: 9px 30px;
  }
`;

export const TextTotalPrice = styled.h1`
  color: ${(props) => props.theme.primary};
  font-size: 60px;
`;

export const Text = styled.h3`
  font-weight: normal;
  font-size: 18px;
  padding: 0 20px;
  margin-bottom: 0;

  span {
    color: ${(props) => props.theme.primary};
    padding: 0 6px;
  }
`;

export const Placeholder = styled.div`
  color: ${(props) => props.theme.black50};
  font-size: 14px;
  padding: 0 20px;
  margin-bottom: 25px;
`;
