import { Row } from "react-grid-system";
import styled from "styled-components";

import { Content } from "@/containers/RightPanel/RightPanel.styles";
import { Input } from "@/components/Input";

import { PayButton as Button } from "@/styles/outgoing";

interface AddFundsBlockProps {
  $isPanelOpened: boolean;
  $isBlockPanel?: boolean;
}

export const AddFundsBlock = styled.div<AddFundsBlockProps>`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  button {
    text-wrap: nowrap;
  }

  img {
    width: 60px;
    height: 40px;
  }

  ${({ $isBlockPanel }) =>
    $isBlockPanel &&
    `
    flex-wrap: nowrap;

    input {
      max-width: 170px;
    }`}

  ${({ theme, $isPanelOpened }) =>
    $isPanelOpened &&
    theme.range(
      "padding-right",
      "370px",
      "410px",
      `${theme.breakpoints.lg}px`,
      `${theme.breakpoints.xl}px`,
    )}
`;

export const AmountFundsInput = styled(Input)`
  max-width: 200px;
`;

export const PayButton = styled(Button)`
  margin-top: auto;
  align-self: center;
`;

export const ContentContainer = styled(Content)`
  & > div:first-child {
    display: flex;
    flex-direction: column;
  }
`;

export const FormWrapper = styled.div`
  padding-top: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    padding-top: 10px;
  }
`;

export const AddFundsFormRow = styled(Row)`
  margin-bottom: 15px;
`;

export const BooksAddFundsFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
