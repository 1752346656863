import { useEffect } from "react";

import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { HistoryAPI } from "@/api";

import { useHistoryStore } from "@/store";
import { CustomsDeclaration as CustomsDeclarationResponseDto } from "@/types/api/common";
import {
  HistoryDetailedParamsDto,
  HistoryDetailedResponseDto,
  HistoryFullDetailedItemResponseDto,
  HistoryResponseDto,
} from "@/types/api/history";
import { ShipmentResponseDto } from "@/types/api/shipments";
import { HistoryQueryKey as QueryKey } from "@/types";

import { normalizeDetailed, setWeeks } from "./helpers";

export const useHistory = (
  year: number,
  options?: Omit<UseQueryOptions<HistoryResponseDto>, "queryKey" | "queryFn">,
) => {
  const { updateHistoryItems } = useHistoryStore();

  const fetchHistoryQuery = useQuery({
    queryKey: [QueryKey.History, year],
    queryFn: () => HistoryAPI.getHistory(year),
    ...options,
  });

  const { data, isSuccess } = fetchHistoryQuery;

  useEffect(() => {
    if (isSuccess && data) {
      const oldArchived = data.archived;
      const sortedWeeksSelectedYear = setWeeks(data.selected_year);
      const archived = oldArchived.length
        ? [...oldArchived, [new Date().getFullYear(), 0]].sort(
            (a, b) => b[0] - a[0],
          )
        : [];

      updateHistoryItems({ archived, selected_year: sortedWeeksSelectedYear });
    }
  }, [isSuccess, data]);

  return fetchHistoryQuery;
};

export const useHistoryDetailed = (
  params: HistoryDetailedParamsDto,
  options?: Omit<
    UseQueryOptions<AxiosResponse<HistoryDetailedResponseDto[]>>,
    "queryKey" | "queryFn"
  >,
) => {
  const { updateHistoryDetailedItems, updateHistoryPageCount } =
    useHistoryStore();

  const fetchHistoryDetailedQuery = useQuery({
    queryKey: [QueryKey.HistoryDetailed],
    queryFn: () => HistoryAPI.getHistoryDetailed(params),
    ...options,
  });

  const { data, isSuccess } = fetchHistoryDetailedQuery;

  useEffect(() => {
    if (isSuccess && data) {
      const headersRaw = Object.fromEntries(Object.entries(data.headers));

      const pageCount = Math.ceil(
        +headersRaw["total"] / +headersRaw["per-page"],
      );

      updateHistoryDetailedItems(data.data);
      updateHistoryPageCount(pageCount);
    }
  }, [isSuccess, data]);

  return fetchHistoryDetailedQuery;
};

export const useHistoryFullDetailed = (
  id: number,
  options?: Omit<
    UseQueryOptions<HistoryFullDetailedItemResponseDto>,
    "queryKey" | "queryFn"
  >,
) => {
  const {
    updateHistoryDetailedItems,
    updateHistoryFullDetailedConsolidation,
    updateIsLoadingHistoryDetailsItem,
    historyDetailedItems,
  } = useHistoryStore();

  const fetchHistoryDetailedQuery = useQuery({
    queryKey: [QueryKey.HistoryFullDetailed],
    queryFn: () => HistoryAPI.getHistoryFullDetailedItem(id),
    ...options,
  });

  const { data, isSuccess, isFetching } = fetchHistoryDetailedQuery;

  useEffect(() => {
    updateIsLoadingHistoryDetailsItem(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (isSuccess && data) {
      const updatedDetailedItem = historyDetailedItems.map((item) => {
        if (data.id === item.consolidation.id) {
          return {
            ...item,
            consolidation: {
              ...item.consolidation,
              items: data.items,
            },
          };
        }
        return item;
      });

      updateHistoryDetailedItems(updatedDetailedItem);
      updateHistoryFullDetailedConsolidation(normalizeDetailed(data));
    }
  }, [isSuccess, data]);

  return fetchHistoryDetailedQuery;
};

export const useHistoryCustomsDeclarations = (
  id: number,
  options?: Omit<
    UseQueryOptions<CustomsDeclarationResponseDto[]>,
    "queryKey" | "queryFn"
  >,
) => {
  const {
    updateHistoryFullDetailedConsolidation,
    historyFullDetailedConsolidation,
    tempDetailedItemId,
  } = useHistoryStore();

  const fetchHistoryDetailedQuery = useQuery({
    queryKey: [QueryKey.HistoryCustomsDeclarations],
    queryFn: () => HistoryAPI.getCustomsDeclarations(id),
    ...options,
  });

  const { data, isSuccess } = fetchHistoryDetailedQuery;

  useEffect(() => {
    if (isSuccess && data) {
      const itemId = tempDetailedItemId;
      if (historyFullDetailedConsolidation) {
        const updHistoryFullDetailedConsolidation =
          historyFullDetailedConsolidation.items.map((item) => {
            return item.id === itemId
              ? { ...item, customs_declarations: data }
              : item;
          });

        updateHistoryFullDetailedConsolidation({
          ...historyFullDetailedConsolidation,
          items: updHistoryFullDetailedConsolidation,
        });
      }
    }
  }, [isSuccess, data]);

  return fetchHistoryDetailedQuery;
};

export const useHistoryDetailedShipment = (
  id: number,
  options?: Omit<UseQueryOptions<ShipmentResponseDto>, "queryKey" | "queryFn">,
) => {
  const {
    updateHistoryDetailedShipment,
    updateIsLoadingHistoryDetailedShipment,
  } = useHistoryStore();

  const fetchHistoryDetailedQuery = useQuery({
    queryKey: [QueryKey.HistoryDetailedShipment],
    queryFn: () => HistoryAPI.getShipment(id),
    ...options,
  });

  const { data, isSuccess, isLoading } = fetchHistoryDetailedQuery;

  useEffect(() => {
    if (isSuccess && data) {
      updateHistoryDetailedShipment(data);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    updateIsLoadingHistoryDetailedShipment(isLoading);
  }, [isLoading]);

  return fetchHistoryDetailedQuery;
};
