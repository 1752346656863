import React, { FC, useCallback } from "react";

import { useTranslation } from "react-i18next";

import {
  Content,
  ContentWrapper,
  Heading,
} from "@/containers/RightPanel/RightPanel.styles";

import { PayButton as Button } from "@/styles/outgoing";

import formatter from "@/utils/formatter";

import useOutgoingStore from "@/store/useOutgoingStore";
import {
  ConfirmPaymentDetailsPanelData,
  RightPanelType,
} from "@/store/useRightPanelStore";
import { useRightPanelStore } from "@/store";
import { ButtonColor, ButtonVariant } from "@/enums";

import {
  CenterBlock,
  HeaderDate,
  Placeholder,
  Text,
  TextTotalPrice,
  Wrapper,
} from "./PaymentDetailsConfirmationPanel.styles";

interface PaymentDetailsConfirmationPanelProps {
  panelData: ConfirmPaymentDetailsPanelData;
}

const PaymentDetailsConfirmationPanel: FC<PaymentDetailsConfirmationPanelProps> =
  React.memo(({ panelData }) => {
    const { t } = useTranslation("common");
    const { openRightPanel } = useRightPanelStore();
    const { detailedItem } = useOutgoingStore();
    const id = panelData.detailedItemID;
    const detailed = id ? detailedItem[id] : {};
    const declarations = detailed?.customs_declarations ?? [];

    const getTotal = (nameProp: string) =>
      declarations.reduce(
        (sum: number, information) =>
          sum + +(information[nameProp as keyof typeof information] ?? 0),
        0,
      );
    const totalPrice = formatter.currency(getTotal("value"));

    const sku = detailed?.sku;
    const createdAt = new Date(detailed?.created_at ?? "");
    const createdAtStr = formatter.date_ddmmyyyy(createdAt);

    const address = detailed?.address_1;
    const fullName = [detailed?.first_name, detailed?.last_name].join(" ");

    const handleBack = useCallback(() => {
      openRightPanel(panelData.returnPreviousPanel, panelData);
    }, [openRightPanel, panelData]);

    const handleConfirm = useCallback(() => {
      openRightPanel(RightPanelType.REVIEW_AND_PAY, panelData);
    }, [openRightPanel, panelData]);

    return (
      <ContentWrapper>
        <Heading>
          {sku}
          <HeaderDate>{`${t("common.from")} ${createdAtStr}`}</HeaderDate>
        </Heading>
        <Content>
          <CenterBlock>
            <Wrapper>
              <Text>{t("paymentInfoConfirmation.confirmationTitle")}</Text>
              <TextTotalPrice>
                <span>{`$${totalPrice}`}</span>
              </TextTotalPrice>
              <Placeholder>
                {t("paymentInfoConfirmation.confirmationHint")}
              </Placeholder>
              <Button
                color={ButtonColor.Red}
                variant={ButtonVariant.Bordered}
                onClick={handleBack}
              >
                {t("paymentInfoConfirmation.cancel")}
              </Button>
              <Button
                color={ButtonColor.Primary}
                variant={ButtonVariant.Bordered}
                onClick={handleConfirm}
              >
                {t("paymentInfoConfirmation.confirm")}
              </Button>
            </Wrapper>
          </CenterBlock>
        </Content>
      </ContentWrapper>
    );
  });

export default PaymentDetailsConfirmationPanel;
