import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";

import { SearchInput } from "@/components/SearchInput";
import { Select } from "@/components/Select";

import { IconType } from "@/enums";
import { ExpectedSearchValues } from "@/types/ExpectedForms/Search";

import {
  Content,
  ContentWrapper,
  FilterCounter,
  FilterIcon,
  FilterLogo,
  FiltersContainer,
  FilterSegmentButton,
  FiltersToggleButton,
  MenuIcon,
} from "./Search.styles";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { useStorageStore } from "@/store";

interface CompactSearchProps {
  handleSetResetCompactFunction: (func: () => void) => void;
  formOptions: FormikProps<ExpectedSearchValues>;
}

const CompactSearch: FC<CompactSearchProps> = React.memo(
  ({ handleSetResetCompactFunction, formOptions }) => {
    const { t } = useTranslation("common");

    const {
      values,
      initialValues,
      handleSubmit,
      handleChange,
      setFieldValue,
      handleReset,
    } = formOptions;
    const filterTypes = {
      shop: "shop",
    };

    const [isFilter, setIsFilter] = useState(false);
    const [openedFilter, setOpenedFilter] = useState<string | null>(null);
    const [shopValue, setShopValue] = useState<any>("");
    const { shops } = useStorageStore();

    const shopOptions = useMemo(
      () =>
        shops
          .map((shop) => {
            const name = shop.name;
            const image = shop.thumb_image_url;
            return { label: name, value: name, image };
          })
          .sort((a, b) => a.label.localeCompare(b.label)),
      [shops],
    );

    const filterProps = useMemo(
      () => ({
        values,
        initialValues,
        handleSubmit,
        handleChange,
        setFieldValue,
      }),
      [values, initialValues, handleSubmit, handleChange, setFieldValue],
    );

    const toggleFilterMenu = useCallback(() => {
      setIsFilter(!isFilter);
    }, [isFilter, setIsFilter]);

    const handleSelectedFilter = useCallback(
      (filterName: string | null) => {
        if (openedFilter === filterName) {
          setOpenedFilter(null);
        } else {
          setOpenedFilter(filterName);
        }
      },
      [openedFilter, setOpenedFilter],
    );

    const handleShopValueChange = useCallback(
      (value: string) => {
        setShopValue(value);
        if (value) {
          handleSelectedFilter(null);
        }

        setFieldValue("vendor", value);
        handleSubmit();
      },
      [setShopValue, handleSelectedFilter, setFieldValue, handleSubmit],
    );

    const resetInternalValues = useCallback(() => {
      setShopValue("");
    }, [setShopValue]);

    const handleResetClick = useCallback(() => {
      handleSelectedFilter(null);
      setIsFilter(false);
      resetInternalValues();
      handleReset();
      handleSubmit();
    }, [handleReset, handleSubmit]);

    useEffect(() => {
      handleSetResetCompactFunction(() => handleResetClick);
    }, [handleResetClick]);

    const searchContent = (
      <SearchInput<ExpectedSearchValues>
        name="trackingNumber"
        value={values.trackingNumber}
        initialValue={initialValues.trackingNumber}
        placeholder={t("parcels.search.filterByTransactionNumber")}
        {...filterProps}
      />
    );

    const shopFilterButton = (showTitleOnly: boolean) => {
      const shopSelected = showTitleOnly === false && !!values.vendor;
      const shopTitle = shopSelected ? null : t("parcels.search.shop");
      const image = shopSelected && shopValue.image;
      return (
        <FilterSegmentButton
          selected={!!shopSelected}
          onClick={() => handleSelectedFilter(filterTypes.shop)}
        >
          {image ? <FilterLogo image={image} size={18} /> : null}
          {shopTitle}
        </FilterSegmentButton>
      );
    };

    const activeFiltersCount = () => {
      const activeFiltersCount = [
        values.comment,
        values.order,
        values.vendor,
      ].filter((x) => x).length;

      return activeFiltersCount === 0 ? null : activeFiltersCount;
    };

    const allFiltersContent = (
      <FiltersContainer>{shopFilterButton(false)}</FiltersContainer>
    );

    const selectedShopFilterContent = (
      <FiltersContainer>
        {shopFilterButton(true)}
        <Select
          autoFocus
          isSearchable
          defaultMenuIsOpen={true}
          value={shopValue}
          onChange={handleShopValueChange}
          minHeight={34}
          options={shopOptions}
          placeholder={`${t("parcels.search.shop")}:`}
          isClearable
          onClick={(e: ChangeEvent) => e.preventDefault()}
        />
      </FiltersContainer>
    );

    const filtersContent = () => {
      if (openedFilter === null) {
        return allFiltersContent;
      } else {
        switch (openedFilter) {
          case filterTypes.shop:
            return selectedShopFilterContent;
          default:
            return allFiltersContent;
        }
      }
    };

    return (
      <ContentWrapper>
        <Content>{isFilter ? filtersContent() : searchContent}</Content>
        <FiltersToggleButton onClick={toggleFilterMenu}>
          <FilterIcon type={IconType.Filter} />
          <MenuIcon type={IconType.Menu} />
          {activeFiltersCount() && (
            <FilterCounter>{activeFiltersCount()}</FilterCounter>
          )}
        </FiltersToggleButton>
      </ContentWrapper>
    );
  },
);

export default CompactSearch;
