import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";

export const AllAddonsWrapper = styled.div`
  padding-bottom: 10px;
`;

export const AllConsolidationItemsWrapper = styled.div`
  padding-top: 10px;
`;

export const AddonsItems = styled.div`
  & > div {
    padding: 10px 5px;
  }
`;

export const ListItem = styled.div`
  position: relative;
  padding-left: 5px;
  margin: 0;
  margin-bottom: 5px;
  animation: 0.8s ${keyframes`${fadeIn}`};
`;

export const ItemIcon = styled.span`
  display: inline-block;
  margin-right: 8px;
  position: relative;
  top: 2px;
`;

export const AddonsWrapper = styled.div`
  margin-top: -12px;
`;
