import { create } from "zustand";

type RelativePosition = {
  x: number;
  y: number;
};

interface UseFullScreenImageStore {
  isVisibleFullScreenImage: boolean;
  imageSource: string | null;
  relativePosition: RelativePosition;
  zoomLevel: number;
  updateFullScreenImagePosition: (relativePosition: RelativePosition) => void;
  updateFullScreenImageSource: (imageSource: string | null) => void;
  showFullScreenImage: (zoom: number) => void;
  hideFullScreenImage: () => void;
}

export const initialState = {
  isVisibleFullScreenImage: false,
  imageSource: null,
  relativePosition: { x: 0, y: 0 },
  zoomLevel: 2,
};

const useFullScreenImageStore = create<UseFullScreenImageStore>((set) => ({
  ...initialState,
  updateFullScreenImagePosition: (relativePosition) =>
    set(() => ({
      relativePosition,
    })),
  updateFullScreenImageSource: (source) =>
    set(() => ({
      imageSource: source,
    })),
  hideFullScreenImage: () =>
    set(() => ({
      isVisibleFullScreenImage: false,
    })),
  showFullScreenImage: (zoom) =>
    set(() => ({
      isVisibleFullScreenImage: true,
      zoomLevel: zoom,
    })),
}));

export default useFullScreenImageStore;
