import React, { FC, useMemo, useState } from "react";

import { FormikProps } from "formik";
import { Hidden, Visible } from "react-grid-system";
import { useTranslation } from "react-i18next";

import { SearchInput } from "@/components/SearchInput";

import { useRightPanelStore } from "@/store";
import { ButtonType, IconType } from "@/enums";
import { StorageSearchValues } from "@/types/StorageForms/Search";

import { FilterDate, FilterLocation, FilterShop, FilterWeight } from "./Filter";
import {
  ButtonsWrapper,
  CloseButton,
  CloseIcon,
  FilterIcon,
  FiltersWrapper,
  MenuIcon,
  SearchFilterButton,
  SearchWrapper,
} from "./Search.styles";

interface SearchProps {
  formOptions: FormikProps<StorageSearchValues>;
  isAllowSearchByLocation: boolean;
}

const Search: FC<SearchProps> = React.memo(
  ({ formOptions, isAllowSearchByLocation }) => {
    const { t } = useTranslation("common");
    const {
      values,
      initialValues,
      handleSubmit,
      handleChange,
      setFieldValue,
      setValues,
      dirty,
    } = formOptions;
    const { isOpenRightPanel } = useRightPanelStore();

    const [isOpen, setOpened] = useState(false);

    const filterProps = useMemo(
      () => ({
        values,
        initialValues,
        handleSubmit,
        handleChange,
        setFieldValue,
      }),
      [values, initialValues, handleSubmit, handleChange, setFieldValue],
    );

    const toggleFilterMenu = async () => {
      if (isOpen && dirty) {
        setValues({ ...initialValues, trackingNumber: values.trackingNumber });
        await Promise.resolve();
        handleSubmit();
      }
      setOpened(!isOpen);
    };

    return (
      <>
        <SearchWrapper $isOpen={isOpen} $isPanelOpened={isOpenRightPanel}>
          <SearchInput<StorageSearchValues>
            name="trackingNumber"
            value={values.trackingNumber}
            initialValue={initialValues.trackingNumber}
            placeholder={t("parcels.search.filterByTransactionNumber")}
            {...filterProps}
          />
          <ButtonsWrapper $isOpen={isOpen}>
            {isOpen && (
              <FiltersWrapper>
                <FilterWeight {...formOptions} />
                <FilterDate {...formOptions} />
                <FilterShop {...formOptions} />
                {isAllowSearchByLocation && <FilterLocation {...formOptions} />}
              </FiltersWrapper>
            )}
            {isOpen ? (
              <CloseButton
                title={t("common.close")}
                type={ButtonType.Button}
                onClick={toggleFilterMenu}
              >
                <CloseIcon type={IconType.Close} />
              </CloseButton>
            ) : (
              <SearchFilterButton onClick={toggleFilterMenu}>
                <Hidden sm>{t("parcels.search.tools")}</Hidden>
                <Visible sm>
                  <FilterIcon type={IconType.Filter} />
                </Visible>
                <MenuIcon type={IconType.Menu} />
              </SearchFilterButton>
            )}
          </ButtonsWrapper>
        </SearchWrapper>
      </>
    );
  },
);

export default Search;
