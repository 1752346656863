import { useRef, useState } from "react";

import { AxiosResponse } from "axios";

import { toastResponseError } from "@/utils/responseMessageHelper";

import { AddressDto } from "@/types/api/destinations";

type DeleteFunctionType = (id: number) => Promise<AxiosResponse<AddressDto>>;

type SetDeletedCallbackType = (items: (string | number)[]) => void;

const useDeletedItems = (
  deleteFunction: DeleteFunctionType,
  setDeletedCallback?: SetDeletedCallbackType,
) => {
  const [deletedItems, setDeletedItems] = useState<(string | number)[]>([]);
  const [timerDeleting, setTimerDeleting] = useState<NodeJS.Timeout | null>(
    null,
  );
  const deletionRef = useRef<(string | number)[]>(deletedItems);

  const onDelete = (items: (string | number)[], id: number) => {
    if (deletedItems.includes(id || "")) {
      clearTimeout(timerDeleting!);
      setTimerDeleting(null);
    } else {
      const timer = setTimeout(() => {
        deleteFunction(id)
          .then(({ data }) => {
            if (typeof setDeletedCallback === "function") {
              setDeletedCallback(items.filter((item) => item !== data.id));
            }
          })
          .catch(toastResponseError)
          .finally(() => clearTimeout(timerDeleting!));
      }, 3000);

      setTimerDeleting(timer);
    }

    setDeletedItems(items);
    if (typeof setDeletedCallback === "function") setDeletedCallback(items);
  };

  const removeItem = (id: number) => {
    if (!deletedItems.includes(id)) {
      const updatedDeletedItems = [...deletedItems, id];
      deletionRef.current = updatedDeletedItems;
      onDelete(updatedDeletedItems, id);
    }
  };

  const undoItem = (id: number) => {
    const updatedDeletedItems = deletedItems.filter((item) => item !== id);
    deletionRef.current = updatedDeletedItems;
    onDelete(updatedDeletedItems, id);
  };

  return [deletedItems, removeItem, undoItem] as const;
};

export default useDeletedItems;
