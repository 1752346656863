import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

import { LinkColor } from "@/enums";

interface LinkWrapperProps {
  color: LinkColor;
}

export const LinkWrapper = styled(RouterLink)<LinkWrapperProps>`
  color: ${({ theme, color }) => theme[color]};
  transition: color 0.2s;

  &:active,
  &:hover,
  &:visited {
    color: ${({ theme, color }) => theme[`${color}05`]};
  }
`;
