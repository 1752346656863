import React, {
  FC,
  memo,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";

import { HeaderWrapper, ParcelsWrapper } from "@/styles/parcels";

import { useCart } from "@/hooks/react-query/cart";
import { useOutgoing } from "@/hooks/react-query/outgoing";

import { CartSelectForOutgoing, CartSelectItem } from "@/store/useCartStore";
import { OutgoingTypeDto } from "@/store/useOutgoingStore";
import { OutgoingResponseDto, OutgoingType } from "@/types/api/outgoing";

import {
  ButtonWrapper,
  ClickableDiv,
  CollapseWrapper,
} from "./OutgoingSection.styles";

interface OutgoingSectionProps {
  itemRenderer: (
    item: OutgoingResponseDto | CartSelectItem,
  ) => ReactElement | ReactNode;
  isCollapseOpen?: boolean;
  children: ReactNode;
  data: OutgoingTypeDto | CartSelectForOutgoing | null;
  countItemsPerSection?: number;
  isFetchCartData?: boolean;
}

const OutgoingSection: FC<OutgoingSectionProps> = ({
  data,
  isCollapseOpen = false,
  children,
  itemRenderer,
  countItemsPerSection = 10,
  isFetchCartData = false,
}) => {
  const { t } = useTranslation("common");

  const [isOpened, setCollapseOpened] = useState(isCollapseOpen);
  const items = data?.items ?? [];
  const type = data?.type ?? OutgoingType.WaitingForInformation;
  const currentPage = data?.page ?? 1;
  const total = data?.total ?? items.length;
  const isLoading = data?.isLoading ?? false;

  const loadData = (page: number) => ({
    type,
    params: {
      page: page,
      per_page: countItemsPerSection,
    },
  });

  const [loadDto, setLoadDto] = useState(loadData(1));
  const { refetch: fetchCart } = useCart({ enabled: false });
  const { refetch: fetchOutgoing } = useOutgoing(loadDto, { enabled: false });
  const fetchData = isFetchCartData ? fetchCart : fetchOutgoing;

  const sortedItems = useMemo(
    () =>
      items.sort(
        (a, b) => +new Date(b.created_at ?? "") - +new Date(a.created_at ?? ""),
      ),
    [items],
  );

  useEffect(() => {
    fetchData();
  }, []);

  const handleLoadMore = useCallback(() => {
    setLoadDto(loadData(currentPage + 1));
    fetchData();
  }, [fetchData, loadData, currentPage]);
  const toggleCollapse = () => setCollapseOpened(!isOpened);

  const headerChildren = () => {
    return <>{children}</>;
  };

  if (!items.length) return null;

  return (
    <section>
      <HeaderWrapper>
        <ClickableDiv onClick={toggleCollapse}>{headerChildren()}</ClickableDiv>
      </HeaderWrapper>
      <CollapseWrapper isOpened={!!isOpened}>
        <ParcelsWrapper>
          {sortedItems.map((item) => itemRenderer(item))}
        </ParcelsWrapper>
        <ButtonWrapper>
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            hidden={sortedItems.length === +total}
            onClick={handleLoadMore}
          >
            {t("parcels.loadMore")}
          </Button>
        </ButtonWrapper>
      </CollapseWrapper>
    </section>
  );
};

export default memo(OutgoingSection);
