import color from "color";
import { NavLink } from "react-router-dom";
import styled, { DefaultTheme } from "styled-components";

export const navWidth = (theme: DefaultTheme) => [
  "60px",
  "80px",
  `${theme.breakpoints.xs}px`,
  `${theme.breakpoints.xl}px`,
];

export const CloseNav = styled.div`
  position: absolute;
  right: 20px;
  top: 15px;
`;

interface NavWrapperProps {
  $withMenu: boolean;
  $isOpen: boolean;
  onClick: (e: MouseEvent) => void;
}

export const NavWrapper = styled.div<NavWrapperProps>`
  height: 100%;
  ${({ theme }) => theme.range("width", ...navWidth(theme))};
  ${({ theme }) => theme.range("min-width", ...navWidth(theme))};
  position: relative;
  overflow: hidden;
  font-weight: bold;
  color: ${({ theme }) => theme.black};
  transition: all 0.2s ease-out;

  ${({ $withMenu }) =>
    !$withMenu &&
    `transform: translateX(-80px);
        width: 0 !important;
        min-width: 0 !important;
      `};

  ${({ theme, $isOpen }) =>
    theme.sm`
          position: fixed;
          transform: translateX(0);
          z-index: 2;
          width: 100%;
          
          ${
            !$isOpen &&
            `transform: translateX(-80px);
            width: 0;
            min-width: 0;
      
            ${CloseNav} {
              display: none;
            }
          `
          }
        `}
`;

export const iconSize = (prop: string, theme: DefaultTheme) =>
  theme.range(prop, "30px", "35px");

export const LinkTitle = styled.span`
  font-size: 22px;
  width: 100%;
  text-decoration: none;
  font-weight: normal;
  transition: background-color 0.3s ease;
  color: ${({ theme }) => theme.greyInactive};
`;

export const LinkItem = styled(NavLink)`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  ${({ theme }) => theme.range("max-height", "30px", "40px")};
  ${({ theme }) => theme.range("max-width", "30px", "40px")};
  ${({ theme }) => theme.range("margin", "20px", "30px")};
  font-size: 26px;
  color: ${({ theme }) => theme.black};
  padding: 20px 15px;

  svg {
    fill: ${({ theme }) => color(theme.grey).hex()};
    path {
      fill: ${({ theme }) => color(theme.grey).hex()};
    }
    width: 100%;
    ${({ theme }) => iconSize("width", theme)}
    ${({ theme }) => iconSize("height", theme)}
  ${({ theme }) => iconSize("min-width", theme)}
  ${({ theme }) => theme.sm`
    margin-right: 20px;
  `}
  }

  &:hover {
    svg {
      fill: ${({ theme }) => color(theme.grey).darken(0.2).hex()};
      path {
        fill: ${({ theme }) => color(theme.grey).darken(0.2).hex()};
      }
    }

    ${LinkTitle} {
      color: ${({ theme }) => color(theme.grey).darken(0.2).hex()};
    }
  }

  &.active ${LinkTitle} {
    color: ${({ theme }) => theme.primary};
    border-bottom: 1px solid ${({ theme }) => theme.primary};
    padding-bottom: 2px;
  }

  &.active svg {
    fill: ${({ theme }) => theme.primary};

    path {
      fill: ${({ theme }) => theme.primary};
    }
  }

  ${({ theme }) => theme.sm`
  margin: 5px 0;
  max-width: fit-content;
  justify-content: flex-start;
`}
`;

export const LinksWrapper = styled.div`
  padding-left: 65px;

  ${LinkTitle} {
    font-size: 20px;
  }
`;

export const Nav = styled.nav`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  height: -webkit-fill-available;
  border-right: ${({ theme }) => `1px solid ${theme.grey}`};
  background-color: #ffffff;
  z-index: 1;

  ${({ theme }) => theme.range("width", ...navWidth(theme))};
  ${({ theme }) => theme.sm`
      padding 15px 10px;
      position: absolute;
      align-items: flex-start;
      max-width: 300px;
      width: 100%;
      max-width: 300px;
      width: calc(100% - 80px);
      background-color: ${color(theme.grey).lighten(0.2).hex()};
  
      & > ${LinkItem}:first-child {
        margin-bottom: 30px;
      }
    `}
`;
