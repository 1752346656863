import { ChangeEvent, FC, memo, useCallback, useEffect, useState } from "react";

import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";

import { Input } from "@/components/Input";

import { ExpectedSearchValues } from "@/types/ExpectedForms/Search";

import { SearchFilter } from "../";
import { TooltipContent } from "../Search.styles";
import { TitleWrapper } from "./Filter.styles";

const FilterOrder: FC<FormikProps<ExpectedSearchValues>> = ({
  initialValues,
  setFieldValue,
  values,
  handleSubmit,
  ...otherProps
}) => {
  const { t } = useTranslation("common");
  const value = values.order;

  const [innerValue, setInnerValue] = useState(value);

  const initialValue = initialValues.order;
  const isOrderChanged = !value;
  const locationTitle = isOrderChanged ? t("parcels.search.order") : value;

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const dropInnerValue = useCallback(() => setInnerValue(value), [value]);

  const applyInnerValue = useCallback(
    () => setFieldValue("order", innerValue),
    [innerValue, setFieldValue],
  );

  const handleInnerChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInnerValue(value);
    },
    [],
  );

  const setDefault = useCallback(() => {
    setFieldValue("order", initialValue);
    dropInnerValue();
  }, [dropInnerValue, initialValue, setFieldValue]);

  return (
    <SearchFilter
      applyInnerValue={applyInnerValue}
      dropInnerValue={dropInnerValue}
      handleSubmit={handleSubmit}
      setDefault={setDefault}
      title={<TitleWrapper>{locationTitle}</TitleWrapper>}
      innerTitle={t("parcels.search.selectOrder")}
      isActive={!isOrderChanged}
      {...otherProps}
    >
      <TooltipContent>
        <Input
          value={innerValue}
          onChange={handleInnerChange}
          placeholder={`${t("parcels.search.order")}:`}
        />
      </TooltipContent>
    </SearchFilter>
  );
};

export default memo(FilterOrder);
