import {
  FormEvent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { FileUploader } from "@/components/FileUploader";
import { Input } from "@/components/Input";

import { ButtonsWrapper } from "@/styles/form";
import { colors } from "@/styles";

import { toastResponseError } from "@/utils/responseMessageHelper";
import {
  useCart,
  useCartImportCustomsDeclarations,
  useDescriptionsByHsCodes,
} from "@/hooks/react-query/cart";
import { useOutgoingCustomsDeclarations } from "@/hooks/react-query/outgoing";
import {
  useShipment,
  useStorageImportCustomsDeclarations,
} from "@/hooks/react-query/storage";

import { useCartStore } from "@/store";
import { ButtonColor, ButtonType, ButtonVariant } from "@/enums";
import {
  CustomsDeclarationV2FormProps,
  ErrorsFormValues,
  type FormValues,
} from "@/types";

import {
  Cell,
  LastRow,
  Row,
  SaveButton,
  StyledForm,
  StyledTable,
} from "../CustomDeclarationV2.styles";
import { customDeclarationForm, getShowError } from "../helpers";
import { CustomSelectDescription } from "./CustomSelectDescription";
import DeleteRowButton from "./DeleteRowButton";

const defaultNewItem = {
  description: "",
  hs_code: "",
  quantity: "",
  value: "",
};

interface HsCodeOptions {
  value: string;
  value_ru: string;
}

const CustomsDeclarationV2Form = <
  C extends Function,
  R extends Function,
  U extends Function,
>({
  declarations = [],
  create,
  remove,
  update,
  onSubmit,
  onCustomsDataChange,
  isShipmentImportCustomsDeclaration,
  itemId,
  isWizardStep = false,
  readOnly = false,
  noPadding = false,
  autoFocus = false,
  hideFileImport = false,
  isShowHSCodeColumn,
  isHSCodeValidationRequired,
  className,
  saveButtonText,
}: CustomsDeclarationV2FormProps<C, R, U>) => {
  const { t } = useTranslation("common");
  const { cart, isCartLoading, hsCodes } = useCartStore();
  const { refetch: getCart } = useCart();
  const formRef = useRef<HTMLFormElement>(null);
  const [newItem, setNewItem] = useState(defaultNewItem);
  const [inputForFocus, setInputForFocus] = useState<number | null>(null);
  const [hsCodesOptions, setHsCodesOptions] = useState<HsCodeOptions[]>([]);
  const consolidation_id = cart && isWizardStep ? cart.id : itemId;

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    setValues,
    errors,
    isSubmitting,
    dirty,
  } = useFormik<FormValues>({
    initialValues: customDeclarationForm.mapPropsToValues({
      declarations,
    }),
    validationSchema: customDeclarationForm.validationSchema({
      isShowHSCodeColumn,
      isHSCodeValidationRequired,
    }),
    onSubmit: (values, formikHelpers) => {
      customDeclarationForm.handleSubmit(values, {
        props: {
          onSubmit,
          declarations,
          create,
          remove,
          update,
          itemId,
          isShowHSCodeColumn,
        },
        setSubmitting: formikHelpers.setSubmitting,
      });
    },
    validateOnChange: customDeclarationForm.validateOnChange,
    validateOnBlur: customDeclarationForm.validateOnBlur,
    enableReinitialize: customDeclarationForm.enableReinitialize,
  });

  const itemsLength = values.length;
  const { mutateAsync: importCustomsDeclarationCart } =
    useCartImportCustomsDeclarations();
  const { mutateAsync: importCustomsDeclarationShipment } =
    useStorageImportCustomsDeclarations();

  const { refetch: getOutgoingCustomsDeclarations } =
    useOutgoingCustomsDeclarations(consolidation_id!, {
      enabled: false,
    });

  const { refetch: getShipment } = useShipment(consolidation_id!, {
    enabled: false,
  });

  useDescriptionsByHsCodes({ enabled: !hsCodes.length });

  useEffect(() => {
    if (
      formRef.current &&
      inputForFocus &&
      formRef.current.elements[inputForFocus]
    ) {
      const inputElement = formRef.current.elements[
        inputForFocus
      ] as HTMLInputElement;
      inputElement.focus();
    }
  }, [formRef, inputForFocus]);

  useEffect(() => {
    onCustomsDataChange && onCustomsDataChange(values);
  }, [values]);

  useEffect(() => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      const hasDuplicateRowError = errorKeys.some((key) => {
        const error = errors[key as keyof typeof errors];
        return typeof error === "string" && error === "Duplicate row found";
      });

      if (hasDuplicateRowError) {
        toastResponseError(t("error.duplicateRowCustomData"), "errorDuplicate");
      }
    }
  }, [errors]);

  useEffect(() => {
    if (!hsCodes.length) return;

    const options = hsCodes.map((description) => {
      const descriptionEn = description.description;
      const descriptionRu = description.description_ru;

      return {
        value: descriptionEn,
        value_ru: descriptionRu,
      };
    });

    setHsCodesOptions(options);
  }, [hsCodes]);

  const handleNewItemChange = (
    newValue: FormEvent<HTMLInputElement>,
    fieldName: string,
  ) => {
    const inputTarget = newValue.target as HTMLInputElement;
    const nextItem = {
      ...newItem,
      [fieldName]: inputTarget.value,
    };

    const addNewRow =
      nextItem.description !== "" &&
      nextItem.quantity !== "" &&
      nextItem.value !== "";

    setNewItem(addNewRow ? defaultNewItem : nextItem);
    if (addNewRow) {
      // Focus from correct input will be lost because we are adding new item to form.values
      const focusOffset =
        fieldName === "description" ? 0 : fieldName === "quantity" ? 2 : 3; // This is 0 for description, 2 for quantity and 3 for value
      setInputForFocus(itemsLength * 4 + focusOffset);
      setFieldValue(`[${values.length}]`, nextItem);
    }
  };

  const handleDeleteRow = (index: number) => {
    setValues(values.filter((_, i: number) => i !== index));
  };

  const handleDescriptionChange = useCallback(
    (index: number, selectedOption: string) => {
      if (!selectedOption) return;

      setFieldValue(`[${index}].description`, selectedOption);

      const hsCode = hsCodes.find(
        (option) => option.description === selectedOption,
      );

      if (isShowHSCodeColumn && hsCode) {
        const code = hsCode.code;

        setFieldValue(`[${index}].hs_code`, code);

        //focus quantity input from formRef
        setTimeout(() => {
          if (formRef.current) {
            const quantityInput = formRef.current.querySelector(
              `input[name='[${index}].quantity']`,
            ) as HTMLInputElement;
            if (quantityInput) {
              quantityInput.focus();
            }
          }
        }, 0);
      }
    },
    [setValues, values, hsCodes, isShowHSCodeColumn],
  );

  const handleFileUpload = useCallback(
    (file: File) => {
      if (!consolidation_id) return;

      const importCustomsDeclaration = isShipmentImportCustomsDeclaration
        ? importCustomsDeclarationShipment
        : importCustomsDeclarationCart;

      importCustomsDeclaration({ id: consolidation_id, file })
        .then(() => {
          if (isWizardStep) {
            getCart();
          } else if (isShipmentImportCustomsDeclaration) {
            getShipment();
          } else {
            getOutgoingCustomsDeclarations();
          }
        })
        .catch(toastResponseError);
    },
    [
      consolidation_id,
      isWizardStep,
      getCart,
      isShipmentImportCustomsDeclaration,
      importCustomsDeclarationCart,
      importCustomsDeclarationShipment,
    ],
  );

  return (
    <StyledForm
      onSubmit={handleSubmit}
      ref={formRef}
      $noPadding={noPadding}
      className={className}
    >
      <StyledTable
        $readOnly={readOnly}
        $isWizardStep={!!isWizardStep}
        disabled={isCartLoading}
        $isShowHSCodeColumn={!!isShowHSCodeColumn}
      >
        <thead>
          <tr>
            <th>Description</th>
            {isShowHSCodeColumn && <th>HS code</th>}
            <th>{isShowHSCodeColumn ? "Q-ty" : "Quantity"}</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {values.map((row, index: number) => (
            <Row key={`${index}${row.tempId ?? row.id}`}>
              <Cell
                $invalid={getShowError(
                  values,
                  errors as ErrorsFormValues,
                  index,
                  "description",
                )}
                size="large"
              >
                <CustomSelectDescription
                  name={`[${index}].description`}
                  value={row.description ?? ""}
                  handleChange={(selectedOption: string) =>
                    handleDescriptionChange(index, selectedOption)
                  }
                  handleBlur={handleBlur}
                  initialOptions={hsCodesOptions}
                />

                <DeleteRowButton onClick={() => handleDeleteRow(index)} />
              </Cell>
              {isShowHSCodeColumn && (
                <Cell
                  $invalid={getShowError(
                    values,
                    errors as ErrorsFormValues,
                    index,
                    "hs_code",
                    1,
                  )}
                >
                  <Input
                    name={`[${index}].hs_code`}
                    value={row.hs_code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Cell>
              )}
              <Cell
                $invalid={getShowError(
                  values,
                  errors as ErrorsFormValues,
                  index,
                  "quantity",
                  1,
                )}
              >
                <Input
                  name={`[${index}].quantity`}
                  value={row.quantity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Cell>
              <Cell
                $invalid={getShowError(
                  values,
                  errors as ErrorsFormValues,
                  index,
                  "value",
                  0.01,
                )}
              >
                <Input
                  name={`[${index}].value`}
                  value={row.value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Cell>
            </Row>
          ))}
          {!readOnly && (
            <LastRow>
              <Cell>
                <Input
                  autoFocus={autoFocus}
                  name={`[${itemsLength}].description`}
                  value={newItem.description}
                  onChange={(newValue) =>
                    handleNewItemChange(newValue, "description")
                  }
                />
              </Cell>
              <Cell>
                <Input
                  name={`[${itemsLength}].quantity`}
                  value={newItem.quantity}
                  onChange={(newValue) =>
                    handleNewItemChange(newValue, "quantity")
                  }
                />
              </Cell>
              <Cell>
                <Input
                  name={`[${itemsLength}].value`}
                  value={newItem.value}
                  onChange={(newValue) =>
                    handleNewItemChange(newValue, "value")
                  }
                />
              </Cell>
            </LastRow>
          )}
        </tbody>
      </StyledTable>
      {!readOnly && (
        <ButtonsWrapper>
          {!hideFileImport && (
            <FileUploader
              title={t("shipping.uploadDataFromFile")}
              handleFile={handleFileUpload}
              acceptFormats=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              backgroundColor={colors.lightgrey03}
            />
          )}
          <SaveButton
            isLoading={isSubmitting}
            disabled={isSubmitting || (!isWizardStep && !dirty)}
            color={ButtonColor.Primary}
            type={ButtonType.Submit}
            variant={ButtonVariant.Filled}
            $alignCenter={hideFileImport}
          >
            {saveButtonText ? saveButtonText : t("common.save")}
          </SaveButton>
        </ButtonsWrapper>
      )}
    </StyledForm>
  );
};

export default memo(CustomsDeclarationV2Form);
