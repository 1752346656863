import { FC, useEffect, useRef, useState } from "react";

import { BlueLoadingIndicator } from "./LoadingBar.styles";

interface LoadingBarProps {
  isLoading: boolean;
  isCartLoading?: boolean;
}

const LoadingBar: FC<LoadingBarProps> = ({
  isLoading,
  isCartLoading = false,
}) => {
  const isLoaderActive = useRef(false);
  const loadingPercentageHandler = useRef<ReturnType<typeof setTimeout>>();
  const [loadPercentage, setLoadPercentage] = useState(0);

  useEffect(() => {
    if (!isLoaderActive.current && (isLoading || isCartLoading)) {
      isLoaderActive.current = true;
      clearTimeout(loadingPercentageHandler.current);
      setLoadPercentage(30);
    }
    if (isLoaderActive.current && !isLoading && !isCartLoading) {
      isLoaderActive.current = false;
      clearTimeout(loadingPercentageHandler.current);
      setLoadPercentage(100);
    }
  }, [isLoading, isCartLoading]);

  useEffect(() => {
    if (isLoaderActive.current) {
      loadingPercentageHandler.current = setTimeout(() => {
        const nextValue =
          loadPercentage +
          (loadPercentage > 80 ? 2 : loadPercentage > 60 ? 3 : 5);
        setLoadPercentage(nextValue <= 99 ? nextValue : 99);
      }, 150);
    } else if (loadPercentage === 100) {
      loadingPercentageHandler.current = setTimeout(() => {
        setLoadPercentage(0);
      }, 350);
    }
  }, [loadPercentage, isLoaderActive.current]);

  return (
    <BlueLoadingIndicator
      $isLoading={loadPercentage > 0}
      $percentage={loadPercentage}
      data-testid={"progressbar"}
    >
      <div />
    </BlueLoadingIndicator>
  );
};

export default LoadingBar;
