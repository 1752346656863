import React, { FC, MouseEvent, useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";

import { useRequestTypes } from "@/hooks/react-query/servicesRequests";
import {
  transformDetailedShipment,
  useShipment,
  useStorageUpdateComment,
} from "@/hooks/react-query/storage";

import {
  RightPanelType,
  ServiceRequestPanelData,
} from "@/store/useRightPanelStore";
import { useRightPanelStore } from "@/store";
import { ButtonColor, ButtonVariant, IconType } from "@/enums";
import { ShipmentResponseDto } from "@/types/api/shipments";

import {
  ProcessedContainer,
  ProcessedIcon,
  ServiceTooltip,
  Status,
  StorageIcon,
} from "./ServiceTooltip.styles";

interface ServiceTooltipItemProps {
  zIndex?: number;
  name: string;
  status: string;
  parcelId: number;
  additionalPanelData?: ServiceRequestPanelData;
}

const ServiceTooltipItem: FC<ServiceTooltipItemProps> = React.memo(
  ({ name, status, parcelId, zIndex = 0, additionalPanelData }) => {
    const { t } = useTranslation("common");
    const isProcessed = status === "processed";
    const { data: shipmentData, isSuccess: isSuccessShipment } =
      useShipment(parcelId);

    const { openRightPanel } = useRightPanelStore();

    const { refetch: getRequestTypes } = useRequestTypes({
      enabled: false,
    });
    const { mutateAsync: updateComment } = useStorageUpdateComment();

    const openPanel = useCallback(
      (shipment: ShipmentResponseDto) => {
        openRightPanel(RightPanelType.SERVICE_REQUEST, {
          id: shipment.id,
          description: shipment.description,
          item: shipment.items[0],
          requests: shipment.service_requests,
          emblem_thumb_url: shipment.emblem_thumb_url,
          requestType: name,
          preventReLoading: true,
          updateComment: updateComment,
          ...additionalPanelData,
        });
      },
      [name, openRightPanel],
    );

    const openServicePanel = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        getRequestTypes();
        if (isSuccessShipment) {
          const normalizedData = transformDetailedShipment(shipmentData);

          openPanel(normalizedData);
        }
      },
      [openPanel, parcelId, getRequestTypes],
    );

    const body = useMemo(
      () =>
        isProcessed ? (
          <ProcessedContainer>
            <ProcessedIcon type={name as IconType} />
            <Status> {t(`serviceRequests.${name}.processed`)}</Status>
            <Button
              onClick={openServicePanel}
              color={ButtonColor.Primary}
              variant={ButtonVariant.Filled}
            >
              {t("serviceRequests.check")}
            </Button>
          </ProcessedContainer>
        ) : (
          t(`serviceRequests.${name}.${status}`)
        ),
      [isProcessed, name, openServicePanel, status, t],
    );

    return (
      <ServiceTooltip key={name} place="bottom" body={body} $zIndex={zIndex}>
        <StorageIcon $isProcessed={isProcessed} type={name as IconType} />
      </ServiceTooltip>
    );
  },
);

export default ServiceTooltipItem;
