import React, { FC, memo } from "react";

import { Address, AddressText, FullName } from "./WarehouseAddress.styles";

interface WarehouseAddressProps {
  warehouseAddress: string;
  className?: string;
  userFfid: number | string;
  fullName: string;
}

const WarehouseAddress: FC<WarehouseAddressProps> = ({
  warehouseAddress = "",
  className,
  userFfid,
  fullName,
}) => {
  const positionEndTitle = warehouseAddress.indexOf("</h4>") + 5;
  const positionHtmlNewRow = warehouseAddress.indexOf("<br>") - 1;
  const formatedWarehouseAddress =
    warehouseAddress.slice(positionEndTitle, positionHtmlNewRow) +
    `, SB${userFfid}` +
    warehouseAddress.slice(positionHtmlNewRow);

  return (
    <>
      <Address className={className}>
        <FullName>{fullName}</FullName>
        <AddressText
          dangerouslySetInnerHTML={{ __html: formatedWarehouseAddress }}
        />
      </Address>
    </>
  );
};

export default memo(WarehouseAddress);
