import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import { ImageSection } from "@/containers/Inventory/styles";
import { ImageViewer2 } from "@/components/ImageViewer2";

import { InnerSection, SectionContent, SectionHeader } from "@/styles/outgoing";

import formatter from "@/utils/formatter";

import { useAuthStore, useHistoryStore, useRightPanelStore } from "@/store";
import { Package } from "@/types/api/history";

import { SKUTitle, StyledPackageSizes } from "../ParcelInfoTab.styles";
import ParcelInfoTabItemsList from "./ParcelInfoTabItemsList";

interface ParcelInfoTabPackageInfoProps {
  pkg: Package;
  showMPSInfo: boolean;
}

const ParcelInfoTabPackageInfo: FC<ParcelInfoTabPackageInfoProps> = React.memo(
  ({ pkg, showMPSInfo }) => {
    const { t } = useTranslation("common");
    const {
      userAuth: { display_weight_in: units },
    } = useAuthStore();
    const { historyFullDetailedConsolidation: parcel } = useHistoryStore();
    const { panelData } = useRightPanelStore();
    const pictures = pkg.pictures ?? [];
    const allItems = parcel?.items ?? [];
    const items = allItems.filter(
      (item) => (item.sku ?? parcel?.sku) === pkg.sku,
    );
    const sku = pkg.sku;

    const weight = parcel?.weight ?? 0;
    const height = parcel?.height ?? 0;
    const width = parcel?.width ?? 0;
    const depth = parcel?.depth ?? 0;

    const excessItemsOriginal = panelData.excessItems;
    const excessItems =
      !!excessItemsOriginal &&
      excessItemsOriginal.map((item: any) => item.item);

    const itemsUnitsTitle =
      items.length === 1 ? t("parcels.item") : t("parcels.items");
    const itemsTitle = `${items.length} ${itemsUnitsTitle}`;
    const weightTitle = `${formatter.weight(weight)} ${t(
      `units.${units}.primaryShort`,
    )}`;
    const itemsPerWeightTitle = `${itemsTitle} / ${weightTitle}`;

    const infoSection = (
      <>
        <SectionHeader>
          <SKUTitle>{sku}</SKUTitle>
        </SectionHeader>
        <SectionContent>{itemsPerWeightTitle}</SectionContent>
        <SectionContent>
          <StyledPackageSizes
            width={width}
            height={height}
            depth={depth}
            units={units}
          />
        </SectionContent>
      </>
    );

    return (
      <>
        {(showMPSInfo || !!pictures.length) && (
          <InnerSection>
            {showMPSInfo ? infoSection : null}
            <SectionContent>
              {!!pictures.length && (
                <ImageSection>
                  <ImageViewer2 images={pictures} />
                </ImageSection>
              )}
            </SectionContent>
          </InnerSection>
        )}
        <ParcelInfoTabItemsList isExcessItems items={excessItems} />
        <ParcelInfoTabItemsList items={items} />
      </>
    );
  },
);

export default ParcelInfoTabPackageInfo;
