import React, { FC, useState } from "react";

import { useTranslation } from "react-i18next";

import { Tab, TabsView } from "@/containers/RightPanel/components";
import {
  ContentWrapper,
  Heading,
} from "@/containers/RightPanel/RightPanel.styles";
import { Tooltip } from "@/components/Tooltip";

import {
  useAddonsMethods,
  useAllDeliveryMethods,
  useConsolidationAddonsMethods,
  usePackingMethods,
} from "@/hooks/react-query/services";

import { NotFinishedPanelPanelData } from "@/store/useRightPanelStore";
import { useCartStore, useServicesStore } from "@/store";

import { CustomsPanel, DetailsPanel, SummaryPanel } from "./components";
import { indexToTitleMapping } from "./components/helpers";
import { CustomSenderErrorMsg, Title } from "./NotFinishedPanel.styles";

interface NotFinishedPanelProps {
  panelData: NotFinishedPanelPanelData;
}

const NotFinishedPanel: FC<NotFinishedPanelProps> = React.memo(
  ({ panelData }) => {
    const { t } = useTranslation("common");
    const { cart } = useCartStore();

    const [activeTab, setActiveTab] = useState<string>(
      t(indexToTitleMapping[0]),
    );

    const sku = cart?.sku;
    const destinationAddress = cart?.destination_address;
    const destAddressId = cart?.destination_address_id;
    const showDestAddressSection =
      (destinationAddress && destinationAddress.id) || destAddressId;
    const delivery = cart?.preferred_carrier ?? "";
    const packingCode = cart?.optional_line_item_codes?.[0];

    const disableContinueToCustomsData =
      !showDestAddressSection || !delivery || !packingCode;

    const { packingMethods, getSelectAddons, consolidationAddonsMethods } =
      useServicesStore();

    const addonsMethods = getSelectAddons();

    useAllDeliveryMethods();
    usePackingMethods({ enabled: !packingMethods.length });
    useAddonsMethods({ enabled: !addonsMethods.length });
    useConsolidationAddonsMethods({
      enabled: !consolidationAddonsMethods.length,
    });

    const handleSetActiveTab = (tab: string) => {
      setActiveTab(tab);
    };

    const tabItems = [];
    if (
      activeTab === t(indexToTitleMapping[0]) ||
      activeTab === t(indexToTitleMapping[1])
    ) {
      tabItems.push(
        <Tab title={t("parcels.details")}>
          <DetailsPanel
            panelData={panelData}
            onActiveTabChange={handleSetActiveTab}
          />
        </Tab>,
      );
    }
    tabItems.push(
      <Tab
        title={
          disableContinueToCustomsData ? (
            <Tooltip
              body={
                <CustomSenderErrorMsg>
                  {t("parcels.notFinishedShowRequiredFieldsError")}
                </CustomSenderErrorMsg>
              }
            >
              {t("parcels.customsData")}
            </Tooltip>
          ) : (
            t("parcels.customsData")
          )
        }
        disabled={disableContinueToCustomsData}
      >
        <CustomsPanel
          onActiveTabChange={handleSetActiveTab}
          delivery={delivery}
        />
      </Tab>,
    );
    if (activeTab === t(indexToTitleMapping[2])) {
      tabItems.push(
        <Tab title={t("shipping.summary")}>
          <SummaryPanel onActiveTabChange={handleSetActiveTab} />
        </Tab>,
      );
    }

    return (
      <ContentWrapper>
        <Heading>
          <Title>{sku}</Title>
        </Heading>
        <TabsView openTab={activeTab} onTabChange={setActiveTab}>
          {tabItems}
        </TabsView>
      </ContentWrapper>
    );
  },
);

export default NotFinishedPanel;
