import { fadeIn } from "react-animations";
import styled, { DefaultTheme, keyframes } from "styled-components";

import { Position } from "./ImageViewer2.styles";

interface WrapperProps {
  $leftOffsetByTheme: (theme: DefaultTheme) => string[];
}

export const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  top: 0px;
  ${({ theme, $leftOffsetByTheme }) =>
    theme.range("left", ...$leftOffsetByTheme(theme))};
  bottom: 0px;
  background: rgba(255, 255, 255, 0.75);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) =>
    theme.range(
      "right",
      "380px",
      "420px",
      `${theme.breakpoints.lg}px`,
      `${theme.breakpoints.xl}px`,
    )};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

const maxHeight = 90;
// 1.334 is aspect ratio of ImageViewer2 hover handler
const maxWidth = maxHeight / 1.334;
export const ImageContainer = styled.div`
  width: ${maxWidth}vh;
  height: ${maxHeight}vh;
  overflow: hidden;
`;

interface ZoomableImageProps {
  position: Position;
  $zoomLevel: number;
}

export const ZoomableImage = styled.img.attrs<ZoomableImageProps>((props) => {
  const { $zoomLevel, position } = props;
  const xVal = `${position.x * -100}%`;
  const yVal = `${position.y * -100}%`;

  const w = $zoomLevel * 100;
  const h = $zoomLevel * 100;

  return {
    style: {
      width: `${w}%`,
      height: `${h}%`,
      transform: `translate(${xVal}, ${yVal}`,
    },
  };
})`
  position: relative;
  object-fit: cover;
  animation: 0.3s ${keyframes`${fadeIn}`};
`;
