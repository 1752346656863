import { AxiosResponse } from "axios";
import {
  CheckoutDto,
  CheckoutPaypalDto,
  CheckoutResponseDto,
  CommonPurchaseResponseDto,
  CompleteAllPaypalDto,
  ConfirmationResponseDto,
  PurchaseAllConformationsResponseDto,
  PurchaseConsolidationCreditCardCompleteDto,
  PurchaseConsolidationPaypalsCompleteDto,
} from "types/api/purchase_all";

import axiosInstance from "./axios_instance";

export const Purchase_API = {
  prepaid: (id: number): Promise<AxiosResponse<unknown>> =>
    axiosInstance.post(`/consolidations/${id}/prepaid`),
  checkout: (
    checkoutDto: CheckoutDto,
  ): Promise<AxiosResponse<CheckoutResponseDto>> => {
    const { id, data } = checkoutDto;

    return axiosInstance.post(
      `/purchase/consolidations/${id}/paypals/checkout`,
      data,
    );
  },
  confirmation: (confirmationDto: {
    id: number | string;
  }): Promise<AxiosResponse<ConfirmationResponseDto>> => {
    const { id } = confirmationDto;

    return axiosInstance.post(`/purchase/consolidations/${id}/confirmations`);
  },
  complete: (
    completeDto: PurchaseConsolidationPaypalsCompleteDto,
  ): Promise<AxiosResponse<CommonPurchaseResponseDto>> => {
    const { id, data } = completeDto;

    return axiosInstance.post(
      `/purchase/consolidations/${id}/paypals/complete`,
      data,
    );
  },
  checkoutCreditCard: (id: number) =>
    axiosInstance.get(`purchase/consolidations/${id}/credit_cards/checkout`),
  completeCreditCard: (
    id: number,
    data: PurchaseConsolidationCreditCardCompleteDto,
  ): Promise<AxiosResponse<CommonPurchaseResponseDto>> =>
    axiosInstance.post(
      `purchase/consolidations/${id}/credit_cards/complete`,
      data,
    ),
};

export const PurchaseAll_API = {
  confirmation: (): Promise<
    AxiosResponse<PurchaseAllConformationsResponseDto>
  > => axiosInstance.post(`purchase_all/confirmations`),
  checkout_paypal: (
    data: CheckoutPaypalDto,
  ): Promise<AxiosResponse<CheckoutResponseDto>> =>
    axiosInstance.post(`/purchase_all/paypals/checkout`, data),
  complete_paypal: (
    data: CompleteAllPaypalDto,
  ): Promise<AxiosResponse<CheckoutResponseDto>> =>
    axiosInstance.post(`/purchase_all/paypals/complete`, data),
};
