import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";

interface AddonItemProps {
  $readOnly?: boolean;
  $isSelected?: boolean;
  onClick?: () => void;
}

export const AddonItem = styled.div<AddonItemProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  transition: background-color 0.2s;
  animation: 0.8s ${keyframes`${fadeIn}`};
  border-bottom: 1px solid ${(props) => props.theme.lightgrey};
  min-height: 69px;

  label {
    width: 100%;
  }

  ${({ $isSelected, theme }) =>
    $isSelected && `background-color: ${theme.primaryPale};`}

  ${({ $readOnly }) => $readOnly && `pointer-events: none;`}
`;

interface AddonsListPriceProps {
  $isHighlighted: boolean;
}

export const AddonsListPrice = styled.span<AddonsListPriceProps>`
  color: ${({ $isHighlighted, theme }) =>
    $isHighlighted ? theme.primary : theme.black50};
`;
