import styled from "styled-components";

import { SideBlock } from "../../styles";
import { ScrollableWrapper } from "../SideAddons/SideAddonsInfo/SideAddonsInfo.styles";
import SummaryBlock from "./SummaryBlock";

export const Summary = styled(SummaryBlock)`
  ${ScrollableWrapper} {
    &::after {
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        #ffffff
      );
    }
  }

  ${SideBlock} {
    width: 100%;
    padding: 10px 20px 0;

    ${(props) => props.theme.xs`
      padding: 0;
    `}
  }

  .ReactCollapse--content > ${SideBlock}:first-of-type {
    border: none;
  }
`;
