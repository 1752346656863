import React, { FC, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { Tab, TabsView } from "@/containers/RightPanel/components";
import {
  BackButton,
  Heading,
  LeftArrowIcon,
  ContentWrapper as Wrapper,
} from "@/containers/RightPanel/RightPanel.styles";
import CustomsDeclarationV2 from "@/components/CustomDeclaration/CustomsDeclarationV2";

import { USD } from "@/styles/form";

import formatter from "@/utils/formatter";
import { toastResponseError } from "@/utils/responseMessageHelper";
import {
  useHistoryCustomsDeclarations,
  useHistoryFullDetailed,
} from "@/hooks/react-query/history";

import { ParcelDetailedPanelData } from "@/store/useRightPanelStore";
import { useHistoryStore, useRightPanelStore } from "@/store";
import { ButtonColor, IconType } from "@/enums";

import {
  CustomsDeclarationContent,
  DeclarationTotal,
} from "./ParcelComponents.styles";
import { ParcelInfoTab } from "./ParcelInfoTab";

interface ParcelDetailedPaneProps {
  isOpen: boolean;
  panelData: ParcelDetailedPanelData;
}

const ParcelDetailedPanel: FC<ParcelDetailedPaneProps> = React.memo(
  ({ isOpen, panelData }) => {
    const { t } = useTranslation("common");
    const { openRightPanel } = useRightPanelStore();
    const id = panelData.id;

    const { updateHistoryTempDetailedItemId } = useHistoryStore();
    const {
      refetch: getHistoryFullDetailed,
      isSuccess: isSuccessHistoryFullDetailed,
      error: errorHistoryFullDetailed,
    } = useHistoryFullDetailed(id, {
      enabled: false,
    });
    const { data: customDeclarations, error: errorHistoryCustomDeclarations } =
      useHistoryCustomsDeclarations(id!, {
        enabled: !!id,
      });

    const declarations = customDeclarations ?? [];

    const getTotal = (nameProp: string) =>
      declarations.reduce(
        (sum: number, information) =>
          sum + +(information[nameProp as keyof typeof information] ?? 0),
        0,
      );
    const totalPrice = formatter.currency(getTotal("value"));
    const handleBack = () => {
      openRightPanel(panelData.returnPreviousPanel, panelData);
    };

    useEffect(() => {
      if (isOpen && id) {
        getHistoryFullDetailed();
      }
    }, [isOpen, getHistoryFullDetailed, id]);

    useEffect(() => {
      if (isSuccessHistoryFullDetailed) {
        updateHistoryTempDetailedItemId(id);
      }
    }, [isSuccessHistoryFullDetailed]);

    useEffect(() => {
      if (errorHistoryFullDetailed) {
        toastResponseError(errorHistoryFullDetailed);
      }
    }, [errorHistoryFullDetailed]);

    useEffect(() => {
      if (errorHistoryCustomDeclarations) {
        toastResponseError(errorHistoryCustomDeclarations);
      }
    }, [errorHistoryCustomDeclarations]);

    return (
      <Wrapper>
        <Heading>
          <BackButton color={ButtonColor.Black50} onClick={handleBack}>
            <LeftArrowIcon type={IconType.Arrow} />
          </BackButton>
          {panelData.sku}
        </Heading>
        <TabsView>
          <Tab title={t("parcels.details")}>
            <ParcelInfoTab />
          </Tab>
          <Tab title={t("parcels.customsData")}>
            <CustomsDeclarationContent>
              <DeclarationTotal>
                {`${t("shipping.total")}: ${declarations.length} ${t(
                  "shipping.totalRows",
                )} - $${totalPrice} `}
                <USD />
              </DeclarationTotal>
              {isOpen && (
                <CustomsDeclarationV2
                  itemId={id}
                  readOnly
                  declarations={declarations}
                  noPadding
                />
              )}
            </CustomsDeclarationContent>
          </Tab>
        </TabsView>
      </Wrapper>
    );
  },
);

export default ParcelDetailedPanel;
