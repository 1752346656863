import React, { FC, MouseEvent, useCallback } from "react";

import { useFormik } from "formik";
import { Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { Button } from "@/components/Button";

import { toastResponseError } from "@/utils/responseMessageHelper";
import { useCartSetDeliveryMethod } from "@/hooks/react-query/cart";
import { ProgressDeliverySetAccessFunc } from "@/hooks";

import { RightPanelType } from "@/store/useRightPanelStore";
import { useCartStore, useRightPanelStore, useServicesStore } from "@/store";
import {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  IconType,
} from "@/enums";

import {
  BlockContent,
  Content,
  DeliveryBlock,
  Heading,
  SubmitWrapper,
} from "../../styles";
import { DeliveryList } from "../DeliveryList";
import { ScrollableForm } from "../ScrollableForm";
import { HelpButton, HelpIcon, Spinner } from "./ChooseForm.styles";

interface ChooseDeliveryFormProps {
  setAccess: ProgressDeliverySetAccessFunc;
}

const ChooseDeliveryForm: FC<ChooseDeliveryFormProps> = React.memo(
  ({ setAccess }) => {
    const { t } = useTranslation("common");
    const navigate = useNavigate();

    const { isLoadingServices, deliveryMethods } = useServicesStore();
    const { openRightPanel } = useRightPanelStore();
    const { cart } = useCartStore();

    const { mutateAsync: setDeliveryMethod } = useCartSetDeliveryMethod();

    const cartDelivery = cart?.preferred_carrier ?? "";

    const {
      handleSubmit,
      isSubmitting,
      setFieldValue,
      values: { deliveryName },
    } = useFormik({
      initialValues: {
        deliveryName: cartDelivery,
      },
      validationSchema: () =>
        yup.object().shape({
          deliveryName: yup.string().required(),
        }),
      onSubmit: (values) => {
        if (cartDelivery !== values.deliveryName) {
          setDeliveryMethod(values.deliveryName)
            .then(() => {
              setAccess("packing");
              navigate("/shipping/flow/delivery/packing");
            })
            .catch(toastResponseError);
          return;
        }

        setAccess("packing");
        navigate("/shipping/flow/delivery/packing");
      },
    });

    const handleSelect = useCallback(
      (name: string) => {
        setFieldValue("deliveryName", name);
      },
      [setFieldValue],
    );
    const handleDeliverySubmit = useCallback(
      async (name: string) => {
        await setFieldValue("deliveryName", name);
        handleSubmit();
      },
      [setFieldValue, handleSubmit],
    );

    const handleHelpClick = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        openRightPanel(RightPanelType.HELP_PANEL, {
          preventReLoading: true,
          hideBackButton: true,
          showHelpersCode: "shipping_method",
        });
      },
      [openRightPanel],
    );

    return (
      <Col lg={4} md={6} sm={12} offset={{ md: 3, lg: 4, sm: 12 }}>
        <Content>
          <DeliveryBlock>
            <form onSubmit={handleSubmit}>
              <Heading>
                {t("shipping.selectDelivery")}:
                <HelpButton onClick={handleHelpClick}>
                  <HelpIcon type={IconType.Help} />
                </HelpButton>
              </Heading>
              <BlockContent>
                <ScrollableForm>
                  <Spinner isActive={isLoadingServices} />
                  {!isLoadingServices && (
                    <DeliveryList
                      deliveryMethods={deliveryMethods}
                      selectedDelivery={deliveryName}
                      onSelect={handleSelect}
                      onSubmit={handleDeliverySubmit}
                    />
                  )}
                </ScrollableForm>
                <SubmitWrapper>
                  <Button
                    disabled={!deliveryName || isSubmitting}
                    isLoading={isSubmitting}
                    type={ButtonType.Submit}
                    size={ButtonSize.Large}
                    color={ButtonColor.Primary}
                    variant={ButtonVariant.Filled}
                  >
                    {t("common.nextStep")}
                  </Button>
                </SubmitWrapper>
              </BlockContent>
            </form>
          </DeliveryBlock>
        </Content>
      </Col>
    );
  },
);

export default ChooseDeliveryForm;
