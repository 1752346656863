import styled from "styled-components";

export const Wrapper = styled.div`
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IconProps {
  icon: string;
}

export const Icon = styled.div<IconProps>`
  width: 12px;
  height: 12px;
  background-image: url(${(props) => props.icon});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
