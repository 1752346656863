import styled from "styled-components";

export const AddonDescription = styled.div`
  margin-left: 8px;
`;

export const RadioButtonsGroup = styled.div`
  display: flex;
  margin: 0 15px 0 auto;

  label:not(:last-child) {
    margin-right: 18px;
  }
`;
