import styled from "styled-components";

import { ParcelCol } from "@/styles/parcels";

export const OutgoingCol = styled(ParcelCol)`
  &:nth-child(1) {
    word-break: normal;
  }

  ${(props) => props.theme.xs`
    &:nth-child(4) {
      justify-content: flex-end;
      text-align: end;
    }
  `}
`;

export const WeightAndPrice = styled.div`
  font-weight: 700;
  color: black;
`;
