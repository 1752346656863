import { AxiosResponse } from "axios";
import { CustomsDeclaration as CustomsDeclarationResponseDto } from "types/api/common";
import {
  CreateCustomsDeclarationsForExpectedItemDto,
  CreateExpectedShipmentDto,
  ExpectedShipmentResponseDto,
  ExpectedShipmentsDto,
  UpdateCustomsDeclarationForExpectedItemDto,
  UpdateExpectedShipmentDto,
} from "types/api/expected";

import axiosInstance from "./axios_instance";

const ExpectedAPI = {
  getExpectedItems: async ({
    page,
    filter,
  }: ExpectedShipmentsDto): Promise<
    AxiosResponse<ExpectedShipmentResponseDto[]>
  > => {
    const res = await axiosInstance.get("/expected_shipments", {
      params: { page, ...filter },
    });

    return res;
  },
  createExpectedItem: ({
    expected_shipment,
  }: CreateExpectedShipmentDto): Promise<
    AxiosResponse<ExpectedShipmentResponseDto>
  > => axiosInstance.post(`/expected_shipments`, expected_shipment),
  updateExpectedItem: ({
    data,
    id,
  }: UpdateExpectedShipmentDto): Promise<
    AxiosResponse<ExpectedShipmentResponseDto>
  > => axiosInstance.patch(`/expected_shipments/${id}`, data),
  deleteExpectedItem: (id: number): Promise<AxiosResponse<unknown>> =>
    axiosInstance.delete(`/expected_shipments/${id}`),
  getCustomsDeclarationsForExpectedItem: async (
    id: number,
  ): Promise<CustomsDeclarationResponseDto[]> => {
    const res = await axiosInstance.get(
      `/expected_shipments/${id}/customs_declarations`,
    );

    return res.data;
  },
  createCustomsDeclarationForExpectedItem: ({
    itemId,
    data,
  }: CreateCustomsDeclarationsForExpectedItemDto): Promise<
    AxiosResponse<CustomsDeclarationResponseDto>
  > =>
    axiosInstance.post(
      `/expected_shipments/${itemId}/customs_declarations`,
      data,
    ),
  updateCustomsDeclarationForExpectedItem: ({
    itemId,
    id,
    data,
  }: UpdateCustomsDeclarationForExpectedItemDto): Promise<
    AxiosResponse<CustomsDeclarationResponseDto>
  > =>
    axiosInstance.patch(
      `/expected_shipments/${itemId}/customs_declarations/${id}`,
      data,
    ),
  deleteCustomsDeclarationForExpectedItem: ({
    id,
    itemId,
  }: {
    id: number;
    itemId: number;
  }): Promise<AxiosResponse<CustomsDeclarationResponseDto[]>> =>
    axiosInstance.delete(
      `/expected_shipments/${itemId}/customs_declarations/${id}`,
    ),
};

export default ExpectedAPI;
