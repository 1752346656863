import React, { FC, MouseEvent, useCallback, useRef } from "react";

import dayjs from "dayjs";
import truncate from "lodash-es/truncate";
import { isMobile } from "react-device-detect";
import { Hidden, Visible } from "react-grid-system";
import { useTranslation } from "react-i18next";

import { getAddonsIconsImage } from "@/containers/Delivery/helpers";
import { Checkbox } from "@/components/Checkbox";
import { CopyToClipboard } from "@/components/CopyToClipboard";
import { IconPng } from "@/components/Icon";
import PackageIcon from "@/components/Icon/icons/package.svg";
import { PackageTypeIcon } from "@/components/PackageTypeIcon";
import { Tooltip } from "@/components/Tooltip";

import { ParcelLogo } from "@/styles/parcels";

import { getFormattedDate } from "@/utils/formatDate";

import { AddonsMethod } from "@/store/useServicesStore";
import { StorageItem } from "@/store/useStorageStore";
import { useRightPanelStore } from "@/store";
import { IconType } from "@/enums";
import { getDayjsLocale } from "@/translations/dayjs-locale";

import { isUnprocessedRequest } from "../ServiceRequests/helpers";
import { ServiceTooltips } from "../ServiceTooltip";
import {
  CheckboxAreaButton,
  Comment,
  Description,
  EmptyConsolidationSpacing,
  MainStorageCol,
  MobileCheckboxAreaButton,
  MobileCheckboxAreaButton2,
  NoCheckboxActionArea,
  ParcelDate,
  RedirectLink,
  RightArrowIcon,
  StorageCol,
  StorageItemRowWrapper,
  StorageRow,
  TransferIcon,
  WarningText,
} from "./StorageList.styles";

interface StorageItemRowProps {
  parcel: StorageItem;
  selectedParcel?: number | null;
  onFocusStorageItemInPanel: (item: StorageItem) => void;
  isChecked: boolean;
  disabled: boolean;
  units: string;
  addons: AddonsMethod[];
  handleSelect: (id: string | number | number[]) => void;
  selectedRangeParcels: (id: number) => number[];
}

const StorageItemRow: FC<StorageItemRowProps> = React.memo(
  ({
    units,
    parcel,
    isChecked,
    handleSelect,
    selectedRangeParcels,
    selectedParcel,
    onFocusStorageItemInPanel,
    addons,
    disabled,
  }) => {
    const { isOpenRightPanel } = useRightPanelStore();
    const itemRef = useRef<HTMLDivElement>(null);
    const { i18n, t } = useTranslation("common");
    const logoUrl = parcel.emblem_thumb_url;
    const receivedAt = parcel.received_at ?? new Date();
    const locale = getDayjsLocale(i18n.language);
    const currentYear = dayjs().year();
    const received = getFormattedDate(receivedAt, locale).split(", ");
    const parcelDate =
      currentYear === parseFloat(received[1])
        ? received[0]
        : received.join(", ");
    const requests = Object.values(parcel.serviceRequestCount);
    const isClientTransferring = parcel.state === "client_transferring";
    const isProhibited = parcel.prohibited || false;
    const isDisabled =
      !!isUnprocessedRequest(requests) ||
      isProhibited === true ||
      isClientTransferring;
    const tooltipTrigger = isDisabled ? (isMobile ? "click" : "hover") : "none";
    const tooltipBody =
      isDisabled &&
      (isProhibited
        ? t("parcels.shipmentProhibited")
        : t("parcels.shipmentNotSend"));
    const isSelectedShipment =
      (isMobile || isOpenRightPanel) && selectedParcel === parcel.id;
    const comment = parcel.customer_comment;
    const weight = parcel.weight && Number(parcel.weight).toFixed(2);
    const consolidationId = parcel.consolidation_id ?? 0;
    const package_type = parcel.package_type;
    const parcelAddons = parcel.addons;

    const handleItemSelect = useCallback(
      () => handleSelect(parcel.id),
      [handleSelect, parcel],
    );
    const handleCheckBoxClick = useCallback(
      (event: MouseEvent<HTMLLabelElement | HTMLButtonElement>) => {
        event.stopPropagation();
        if (isDisabled || !!consolidationId) return;
        if (event.nativeEvent.shiftKey) {
          const selectedParcels = selectedRangeParcels(parcel.id);
          handleSelect(selectedParcels);
        } else {
          handleItemSelect();
        }
      },
      [selectedRangeParcels, parcel, handleSelect, handleItemSelect],
    );

    const handleParcelLogoClick = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        if (isDisabled || !!consolidationId) return;

        event.stopPropagation();
        handleItemSelect();
      },
      [handleItemSelect],
    );

    const compactDescription = (description: string) => {
      if (description && description.length > 20) {
        let chars = description.split("");
        chars.splice(8, 8, "...");
        return chars.join("");
      } else {
        return description;
      }
    };

    const prohibitedIcon = (isProhibited: boolean) => {
      return isProhibited ? (
        <Tooltip
          body={tooltipBody}
          place="right"
          openOnClick={!!tooltipTrigger}
          className="tooltip-content-checkbox"
        >
          <WarningText>!</WarningText>
        </Tooltip>
      ) : null;
    };

    const renderRedirectLink = () => {
      return (
        <RedirectLink
          to={`${parcel.state === "shipped" ? "history" : "outgoing"}`}
          state={{
            consolidationId,
            shippingMethod: parcel.carrier,
            weekNumber: parcel.week_number,
            shipmentId: parcel.id,
            receivedAt: parcel.shipped_on,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <span>{t("common.sentIn")}</span>
          <span>{`PK${consolidationId}`}</span>{" "}
          <RightArrowIcon type={IconType.RightArrow} />
        </RedirectLink>
      );
    };

    const hasConsolidation = !!consolidationId;

    return (
      <StorageItemRowWrapper
        ref={itemRef}
        disabled={disabled && isChecked}
        id={parcel.id.toString()}
      >
        <StorageRow
          align={"center"}
          checked={isChecked}
          selected={isSelectedShipment}
          styled={{ consolidationId }}
          onClick={() => onFocusStorageItemInPanel(parcel)}
        >
          {/* Mobile version of rows */}
          <StorageCol xs={2}>
            <Visible xs>
              {isChecked ? (
                <MobileCheckboxAreaButton2
                  onClick={(e: MouseEvent<HTMLButtonElement>) =>
                    handleCheckBoxClick(e)
                  }
                >
                  <Checkbox
                    checked={isChecked}
                    onChange={handleItemSelect}
                    onClick={(e: MouseEvent<HTMLLabelElement>) =>
                      handleCheckBoxClick(e)
                    }
                    disabled={isDisabled}
                  />
                </MobileCheckboxAreaButton2>
              ) : (
                <MobileCheckboxAreaButton onClick={handleParcelLogoClick}>
                  <ParcelLogo
                    image={logoUrl || PackageIcon}
                    onClick={handleParcelLogoClick}
                    disabled={isDisabled}
                  />
                  {isClientTransferring && (
                    <TransferIcon type={"transfer"} width="14" height="14" />
                  )}
                </MobileCheckboxAreaButton>
              )}
            </Visible>
          </StorageCol>
          <StorageCol xs={10}>
            <Visible xs>
              <StorageCol xs={hasConsolidation ? 5 : 8}>
                <Description disabled={!!isDisabled}>
                  {prohibitedIcon(isProhibited)}
                  <CopyToClipboard text={parcel.description}>
                    {compactDescription(parcel.description)}
                  </CopyToClipboard>
                  {getAddonsIconsImage(parcelAddons, addons)}
                  {!!requests.length && (
                    <ServiceTooltips
                      parcel={parcel}
                      requests={parcel.serviceRequestCount}
                      fullWidth={false}
                    />
                  )}
                  {comment && (
                    <Comment>
                      {truncate(comment, {
                        length: 30,
                        omission: "...",
                      })}
                    </Comment>
                  )}
                </Description>
              </StorageCol>
              <StorageCol xs={4}>
                <PackageTypeIcon type={package_type} />
                {`${weight} ${t(`units.${units}.primaryShort`)}`}
              </StorageCol>
              {hasConsolidation && (
                <StorageCol xs={3}>{renderRedirectLink()}</StorageCol>
              )}
            </Visible>
          </StorageCol>

          {/* Desktop version of rows */}
          <MainStorageCol sm={12}>
            <Hidden xs>
              <StorageCol xs={12} sm={consolidationId ? 5 : 6} md={5}>
                {!consolidationId && isProhibited && (
                  <NoCheckboxActionArea>
                    {prohibitedIcon(isProhibited)}
                    <ParcelLogo image={logoUrl || PackageIcon} />
                  </NoCheckboxActionArea>
                )}
                {!consolidationId && !isProhibited && (
                  <CheckboxAreaButton onClick={handleCheckBoxClick}>
                    {isClientTransferring ? (
                      <IconPng type={"transfer"} />
                    ) : (
                      <Checkbox
                        checked={isChecked}
                        onChange={handleItemSelect}
                        disabled={isDisabled}
                      />
                    )}

                    <ParcelLogo image={logoUrl || PackageIcon} />
                  </CheckboxAreaButton>
                )}

                {!!consolidationId && (
                  <NoCheckboxActionArea>
                    <EmptyConsolidationSpacing />
                    <ParcelLogo image={logoUrl || PackageIcon} />
                  </NoCheckboxActionArea>
                )}
                <Description>
                  <CopyToClipboard text={parcel.description}>
                    {parcel.description}
                  </CopyToClipboard>
                  {getAddonsIconsImage(parcelAddons, addons)}
                  {!!requests.length && (
                    <ServiceTooltips
                      parcel={parcel}
                      requests={requests}
                      fullWidth={false}
                    />
                  )}
                  {comment && <Comment>{comment}</Comment>}
                </Description>
              </StorageCol>
              <StorageCol xs={4} sm={2}>
                <PackageTypeIcon type={package_type} />
                {`${weight} ${t(`units.${units}.primaryShort`)}`}
              </StorageCol>
              <StorageCol xs={consolidationId ? 4 : 6} sm={3} md={2}>
                <ParcelDate>{parcelDate}</ParcelDate>
              </StorageCol>
              {!!consolidationId && (
                <StorageCol xs={4} sm={2} md={3}>
                  {renderRedirectLink()}
                </StorageCol>
              )}
            </Hidden>
          </MainStorageCol>
        </StorageRow>
      </StorageItemRowWrapper>
    );
  },
);

export default StorageItemRow;
