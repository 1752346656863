import { useEffect } from "react";

import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import { DestinationsAPI } from "@/api";

import { toastResponseError } from "@/utils/responseMessageHelper";

import { useDestinationsStore } from "@/store";
import { DestinationAddressDto } from "@/types/api/destination_addresses";
import { AddressDto } from "@/types/api/destinations";
import { DestinationQueryKey as QueryKey } from "@/types";

export const useAddresses = (
  params?: DestinationAddressDto,
  options?: Omit<UseQueryOptions<AddressDto[]>, "queryKey" | "queryFn">,
) => {
  const { updateAddresses, isRefetchAddress, updateIsRefetchAddress } =
    useDestinationsStore();
  const fetchAddresses = useQuery({
    queryKey: [QueryKey.DestinationAddresses],
    queryFn: () => DestinationsAPI.getAddresses(params),
    ...options,
  });

  const { data, error, refetch } = fetchAddresses;

  useEffect(() => {
    toastResponseError(error);
  }, [error]);

  useEffect(() => {
    refetch();
  }, [params]);

  useEffect(() => {
    if (isRefetchAddress) {
      refetch();
      updateIsRefetchAddress(false);
    }
  }, [isRefetchAddress]);

  useEffect(() => {
    if (data) {
      updateAddresses(data);
    }
  }, [data]);

  return fetchAddresses;
};

interface ApiResponse {
  error: string;
}

export const useCreateAddresses = () => {
  const queryClient = useQueryClient();
  const { updateIsRefetchAddress } = useDestinationsStore();
  const createAddress = useMutation({
    mutationFn: DestinationsAPI.createAddress,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.DestinationCreateAddress],
      });
    },
    onError: (error: AxiosError<ApiResponse>) => {
      const response = error.response;
      if (response && response.data?.error) {
        const errorMessage = response.data?.error || "Something went wrong";
        toastResponseError(errorMessage);
      } else {
        toastResponseError("An unexpected error occurred");
      }
    },
    onSuccess: () => {
      updateIsRefetchAddress(true);
    },
  });

  return createAddress;
};

export const useUpdateAddresses = () => {
  const queryClient = useQueryClient();
  const { updateIsRefetchAddress } = useDestinationsStore();
  const updateAddress = useMutation({
    mutationFn: DestinationsAPI.updateAddress,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.DestinationUpdateAddress],
      });
    },
    onSuccess: () => {
      updateIsRefetchAddress(true);
    },
    onError: (error: AxiosError<ApiResponse>) => {
      const response = error.response;
      if (response && response.data?.error) {
        const errorMessage = response.data?.error || "Something went wrong";
        toastResponseError(errorMessage);
      } else {
        toastResponseError("An unexpected error occurred");
      }
    },
  });

  return updateAddress;
};

export const useDeleteAddress = () => {
  const queryClient = useQueryClient();
  const { updateIsRefetchAddress } = useDestinationsStore();
  const deleteAddress = useMutation({
    mutationFn: DestinationsAPI.deleteAddress,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.DestinationDeleteAddress],
      });
    },
    onSuccess: () => {
      updateIsRefetchAddress(true);
    },
    onError: (error: AxiosError<ApiResponse>) => {
      const response = error.response;
      if (response && response.data?.error) {
        const errorMessage = response.data?.error || "Something went wrong";
        toastResponseError(errorMessage);
      } else {
        toastResponseError("An unexpected error occurred");
      }
    },
  });

  return deleteAddress;
};
