import { create } from "zustand";

import {
  ArchivedItem,
  HistoryDetailedResponseDto,
  HistoryFullDetailedItemResponseDto,
  SelectedYearItem,
} from "@/types/api/history";
import { ShipmentResponseDto } from "@/types/api/shipments";

export interface WeeksItems {
  [key: string]: (SelectedYearItem & { id: number })[];
}

type HistoryItems = {
  selected_year: WeeksItems;
  archived: ArchivedItem[];
};

type HistoryWithoutConsolidationAddonsAndPictures = Omit<
  HistoryFullDetailedItemResponseDto,
  "pictures" | "consolidation_addons"
>;

export interface HistoryFullDetailed
  extends HistoryWithoutConsolidationAddonsAndPictures {
  pictures: { large: string; medium: string }[];
  consolidation_addons: number[];
  passport_full_name?: string;
  passport_issued_by?: string;
  passport_issued_date?: string;
  passport_number?: string;
  passport_serial?: string;
}

interface UseHistoryStore {
  selectedYear: number | null;
  historyPageCount: number;
  historyItems: HistoryItems;
  historyDetailedItems: HistoryDetailedResponseDto[];
  historyFullDetailedConsolidation: HistoryFullDetailed | null;
  historyDetailedShipment: { [key: number]: ShipmentResponseDto } | null;
  isLoadingHistoryDetailedShipment: boolean;
  isLoadingHistoryDetailsItem: boolean;
  tempDetailedItemId: number | null;
  updateHistoryItems: (items: HistoryItems) => void;
  updateHistoryPageCount: (pageCount: number) => void;
  updateHistoryDetailedItems: (items: any) => void;
  updateSelectedYear: (year: number) => void;
  updateHistoryTempDetailedItemId: (id: number) => void;
  updateHistoryDetailedShipment: (shipment: ShipmentResponseDto) => void;
  updateHistoryFullDetailedConsolidation: (items: HistoryFullDetailed) => void;
  updateIsLoadingHistoryDetailedShipment: (bool: boolean) => void;
  updateIsLoadingHistoryDetailsItem: (bool: boolean) => void;
  resetHistoryStore: () => void;
}

const initialState = {
  selectedYear: null,
  tempDetailedItemId: null,
  isLoadingHistoryDetailedShipment: false,
  isLoadingHistoryDetailsItem: false,
  historyPageCount: 0,
  historyItems: {
    selected_year: {},
    archived: [],
  },
  historyDetailedItems: [],
  historyFullDetailedConsolidation: null,
  historyDetailedShipment: null,
};

const useHistoryStore = create<UseHistoryStore>((set, get) => ({
  ...initialState,
  updateHistoryItems: (items) =>
    set(() => ({
      historyItems: items,
    })),
  updateHistoryDetailedItems: (items) =>
    set(() => ({
      historyDetailedItems: items,
    })),
  updateHistoryFullDetailedConsolidation: (items) => {
    set(() => ({
      historyFullDetailedConsolidation: items,
    }));
  },
  updateHistoryTempDetailedItemId: (id) =>
    set(() => ({
      tempDetailedItemId: id,
    })),
  updateIsLoadingHistoryDetailsItem: (bool) => {
    set(() => ({
      isLoadingHistoryDetailsItem: bool,
    }));
  },
  updateHistoryDetailedShipment: (shipment) => {
    const historyDetailedShipment = get();
    const shipmentDetailed = historyDetailedShipment ?? {};

    set(() => ({
      historyDetailedShipment: {
        ...shipmentDetailed,
        [shipment.id]: shipment,
      },
    }));
  },
  updateSelectedYear: (year) =>
    set(() => ({
      selectedYear: year,
    })),
  updateIsLoadingHistoryDetailedShipment: (bool) =>
    set(() => ({
      isLoadingHistoryDetailedShipment: bool,
    })),
  updateHistoryPageCount: (pageCount) =>
    set(() => ({
      historyPageCount: pageCount,
    })),
  resetHistoryStore: () =>
    set(() => ({
      ...initialState,
    })),
}));

export default useHistoryStore;
