import React, { FC } from "react";

import { Waypoint } from "react-waypoint";

import { RightPanelType } from "@/store/useRightPanelStore";
import { useRightPanelStore, useStorageStore } from "@/store";
import { ExpectedShipmentResponseDto } from "@/types/api/expected";
import { ShopsResponseDto } from "@/types/api/shipments";

import ExpectedItemRow from "./ExpectedItemRow";
import { Spinner, Wrapper } from "./ExpectedList.styles";

interface ExpectedListProps {
  parcels: ExpectedShipmentResponseDto[];
  shops: ShopsResponseDto[];
  onPagination: () => void;
  disabled: boolean;
  totalAmount: number;
}

const ExpectedList: FC<ExpectedListProps> = React.memo(
  ({ parcels, onPagination, disabled, totalAmount, shops }) => {
    const hasMore = totalAmount > parcels.length;

    const { openRightPanel } = useRightPanelStore();
    const { updateSelectedExpectedItemId, selectedExpectedItemId } =
      useStorageStore();

    const handleFocusExpectedItemInPanel = (
      parcel: ExpectedShipmentResponseDto,
    ) => {
      const id = parcel.id;

      if (selectedExpectedItemId === id) {
        updateSelectedExpectedItemId(null);
        openRightPanel(RightPanelType.EXPECTED_ITEM);
      } else {
        updateSelectedExpectedItemId(id);
        openRightPanel(RightPanelType.EXPECTED_ITEM, {
          selectedExpectedItem: parcel,
          isEditForm: true,
          ...parcel,
        });
      }
    };

    return (
      <Wrapper>
        {parcels.map((parcel) => (
          <ExpectedItemRow
            onFocusExpectedItemInPanel={handleFocusExpectedItemInPanel}
            parcel={parcel}
            selectedParcel={selectedExpectedItemId}
            key={parcel.id}
            disabled={disabled}
            shops={shops}
          />
        ))}
        {hasMore && (
          <>
            <Waypoint onEnter={onPagination} />
            <Spinner key={0} isActive />
          </>
        )}
      </Wrapper>
    );
  },
);

export default ExpectedList;
