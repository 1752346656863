import styled from "styled-components";

export const Type = styled.p`
  font-size: 14px;
  color: ${(props) => props.theme.black};
  margin-top: 0;
  margin-bottom: 5px;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Divider = styled.span`
  color: ${(props) => props.theme.lightgrey};
  margin: 0 5px;
`;

export const Info = styled.div`
  white-space: pre-wrap;
  font-size: 12px;
  width: 100%;
  line-height: 1.6;
`;

export const DeliveryListPrice = styled.div`
  display: flex;
  white-space: nowrap;
  flex-direction: column;
  text-align: right;
  width: 75px;
  margin-left: 15px;
  line-height: 1.6;

  span {
    color: ${(props) => props.theme.black50};
  }
`;
