import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import { Icon } from "@/components/Icon";
import { Logo } from "@/components/Logo";

import { RightPanelType } from "@/store/useRightPanelStore";
import { useRightPanelStore } from "@/store";
import { ButtonColor, IconType } from "@/enums";
import { HistoryDetailedResponseDto } from "@/types/api/history";

import {
  FoundPackage,
  ParcelButton,
  ParcelHeader,
  ParcelInfo,
  ParcelText,
  ParcelTitle,
  ParcelWrapper,
} from "./ParcelComponents.styles";

interface ParcelProps {
  parcel: HistoryDetailedResponseDto;
  units: string;
  foundPackageId: number | null;
}

const Parcel: FC<ParcelProps> = React.memo(
  ({ parcel, units, foundPackageId }) => {
    const { t } = useTranslation("common");
    const { panelType, panelData, openRightPanel } = useRightPanelStore();
    const item = parcel.consolidation;
    const number = item.sku;
    const items = item.items ?? [];
    const fullName = `${item.first_name} ${item.last_name}`;
    const address = `${item.city}, ${item.postal_code}, ${item.country}, ${item.street}${item.house ? `, ${item.house}` : ""}`;
    const foundPackage =
      items.find((item) => item.shipment_id === foundPackageId) ?? null;

    const openParcelDetailed = () =>
      openRightPanel(RightPanelType.PARCEL_DETAILED, {
        ...panelData,
        returnPreviousPanel: panelType as RightPanelType,
        number,
        items,
        id: parcel.consolidation.id,
        sku: parcel.consolidation.sku,
      });

    return (
      <ParcelWrapper>
        <ParcelHeader>
          <ParcelTitle>
            <span>{number}</span>
            {` - ${parcel.weight.toFixed(0)} ${t(`units.${units}.primaryShort`)}`}
            {` - $${parcel.total_price}`}
          </ParcelTitle>
          <div>{item.order.tracking_number ?? t("parcels.numberNotFound")}</div>
        </ParcelHeader>
        <ParcelInfo>
          <ParcelText>{fullName}</ParcelText>
          <ParcelText>{address}</ParcelText>
        </ParcelInfo>
        <ParcelButton
          $isSelected={!!foundPackage && !!Object.keys(foundPackage).length}
          onClick={openParcelDetailed}
          color={ButtonColor.Primary}
        >
          {foundPackage && (
            <FoundPackage>
              {foundPackage?.emblem_thumb_url ? (
                <Logo image={foundPackage.emblem_thumb_url} />
              ) : (
                <Icon type={IconType.Package} />
              )}
              {foundPackage?.shipment_tracking_number}
            </FoundPackage>
          )}
          {`${t("parcels.moreDetails")} >>`}
        </ParcelButton>
      </ParcelWrapper>
    );
  },
);

export default Parcel;
