import { ExpectedShipmentsDto } from "@/types/api/expected";
import { ExpectedSearchValues } from "@/types/ExpectedForms/Search";

export const initialValues = {
  trackingNumber: "",
  vendor: null,
  comment: "",
  order: "",
};

export const searchFormik = (
  onGetExpectedSubmit: (data: ExpectedShipmentsDto | null) => void,
) => ({
  initialValues,
  onSubmit: (values: ExpectedSearchValues) => {
    const { trackingNumber, vendor, order, comment } = values;

    onGetExpectedSubmit({
      page: 1,
      filter: {
        "q[tracking_number_eq]": trackingNumber || null,
        "q[store_name_eq]": vendor && vendor.value,
        "q[customer_comment_eq]": comment || null,
        "q[order_number_eq]": order || null,
      },
    });
  },

  displayName: "ExpectedSearch",
});
