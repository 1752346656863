import { FC } from "react";

import { useTranslation } from "react-i18next";

import { PlaceholderIcon, Wrapper } from "@/styles/placeholder";

import { placeholderIcon } from "../images";
import { Message } from "../styles";

export const InventoryListPlaceholder: FC = () => {
  const { t } = useTranslation("common");

  return (
    <Wrapper>
      <PlaceholderIcon
        src={placeholderIcon}
        alt={t("parcels.placeholderIcon")}
      />
      <Message>{t("parcels.search.inventoryItemsFound")}</Message>
    </Wrapper>
  );
};
