import { BooksAddFundsPanel } from "@/containers/Books/components/BooksAddFunds";
import { ExpectedItemPanel } from "@/containers/Expected/components/ExpectedItemPanel";
import { HelpPanel } from "@/containers/Help/components/HelpPanel";
import { ParcelDetailedPanel } from "@/containers/History/components/Parcel";
import { WeekItemPanel } from "@/containers/History/components/Week";
import { ChangingAddressPanel } from "@/containers/Outgoing/components/ChangingAddressPanel";
import { ConsolidationPanel } from "@/containers/Outgoing/components/ConsolidationPanel";
import { DeliveryMethodsPanel } from "@/containers/Outgoing/components/DeliveryMethodsPanel";
import { NotFinishedPanel } from "@/containers/Outgoing/components/NotFinishedPanel";
import { PackingGradePanel } from "@/containers/Outgoing/components/PackingGradePanel";
import { PayAllPanel } from "@/containers/Outgoing/components/PayAllPanel";
import { PaymentDetailsConfirmationPanel } from "@/containers/Outgoing/components/PaymentDetailsConfirmationPanel";
import { ProhibitedGoodsPanel } from "@/containers/Outgoing/components/ProhibitedGoodsPanel";
import { ReviewAndPayPanel } from "@/containers/Outgoing/components/ReviewAndPayPanel";
import { PackOptions } from "@/containers/Storage/components/PackOptions";
import { ServiceRequest } from "@/containers/Storage/components/ServiceRequests";
import { StorageItemPanel } from "@/containers/Storage/components/StorageItemPanel";
import { AddressForm } from "@/components/Addresses/components";

export const PANEL_COMPONENTS = {
  PAY_ALL: PayAllPanel,
  WEEK_ITEM: WeekItemPanel,
  ADDRESS_FORM: AddressForm,
  BOOKS_ADD_FUNDS: BooksAddFundsPanel,
  HELP_PANEL: HelpPanel,
  PACK_OPTIONS: PackOptions,
  REVIEW_AND_PAY: ReviewAndPayPanel,
  CHANGING_ADDRESS: ChangingAddressPanel,
  PROHIBITED_FOUND: ProhibitedGoodsPanel,
  DELIVERY_METHODS: DeliveryMethodsPanel,
  PARCEL_DETAILED: ParcelDetailedPanel,
  PACKING_GRADE: PackingGradePanel,
  NOT_FINISHED: NotFinishedPanel,
  WAITING_PAYMENT: ConsolidationPanel,
  IN_PROGRESS: ConsolidationPanel,
  CONFIRM_PAYMENT_DETAILS: PaymentDetailsConfirmationPanel,
  SERVICE_REQUEST: ServiceRequest,
  EXPECTED_ITEM: ExpectedItemPanel,
  STORAGE_ITEM: StorageItemPanel,
};

export const hasIgnoredClass = (
  element: EventTarget,
  ignoredClass: string,
): boolean => {
  if (!(element instanceof HTMLElement)) return false;

  const classList = element.classList;

  return Array.from(classList).some((c) => c.includes(ignoredClass));
};

export const isInIgnoredElement = (
  element: EventTarget | null,
  ignoredClass: string,
): boolean => {
  let currentElement = element as HTMLElement | null;

  while (currentElement) {
    if (hasIgnoredClass(currentElement, ignoredClass)) {
      return true;
    }
    currentElement = currentElement.parentElement;
  }

  return false;
};
