import React, { FC } from "react";

import NoPackingIcon from "@/icons/not-selected-delivery.svg";

import { useServicesStore } from "@/store";
import {
  DeliveryShippingMethodsResponseDto,
  PackingMethodsResponseDto,
} from "@/types/api/cart";

import { Logotype } from "./ServiceLogo.styles";

interface ServiceLogoProps {
  serviceKey: string;
}

const ServiceLogo: FC<ServiceLogoProps> = React.memo(
  ({ serviceKey, ...otherProps }) => {
    const { getAllServices } = useServicesStore();
    const services = getAllServices();

    const service = services.find(
      (service) =>
        (service as DeliveryShippingMethodsResponseDto).name === serviceKey ||
        (service as PackingMethodsResponseDto).code === serviceKey,
    );

    const logoProps =
      service && service.logo_url
        ? { image: service.logo_url, size: 30 }
        : { image: NoPackingIcon, size: 20 };

    return <Logotype {...logoProps} {...otherProps} />;
  },
);

export default ServiceLogo;
