import React, { FC, memo, useCallback, useState } from "react";

import { Collapse } from "react-collapse";
import { useTranslation } from "react-i18next";

import { useHistory } from "@/hooks/react-query/history";

import { useHistoryStore } from "@/store";
import { IconType } from "@/enums";
import { ArchivedItem } from "@/types/api/history";

import {
  CollapseIcon,
  HeaderTitle,
  HeaderWrapper,
  SelectYearButton,
  Wrapper,
  YearButton,
  YearsWrapper,
} from "./HistoryHeader.styles";

interface HistoryHeaderProps {
  years: ArchivedItem[];
}

const HistoryHeader: FC<HistoryHeaderProps> = ({ years }) => {
  const { t } = useTranslation("common");
  const { updateSelectedYear, selectedYear } = useHistoryStore();
  useHistory(selectedYear!, {
    enabled: !!selectedYear,
  });
  const [isOpened, setOpened] = useState(false);
  const selectYear = useCallback(
    (year: number) => updateSelectedYear(year),
    [updateSelectedYear],
  );
  const toggleCollapse = () => setOpened(!isOpened);
  const hasYears = !!(years && years.length);

  return (
    <Wrapper>
      <HeaderWrapper>
        <HeaderTitle>
          {t("parcels.historyForSelectedYear", { selectedYear })}
        </HeaderTitle>
        {hasYears && (
          <SelectYearButton onClick={toggleCollapse}>
            {t("common.selectYear")}
            <CollapseIcon type={IconType.Arrow} $isOpened={isOpened} />
          </SelectYearButton>
        )}
      </HeaderWrapper>
      {hasYears && (
        <Collapse isOpened={isOpened}>
          <YearsWrapper>
            {years.map((year) => (
              <YearButton
                key={year[0]}
                $isActive={selectedYear === year[0]}
                onClick={() => selectYear(year[0])}
              >
                {year[0]}
              </YearButton>
            ))}
          </YearsWrapper>
        </Collapse>
      )}
    </Wrapper>
  );
};

export default memo(HistoryHeader);
