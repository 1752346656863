import React, { FC, useCallback, useEffect, useState } from "react";

import {
  Content,
  ContentWrapper,
  Heading,
} from "@/containers/RightPanel/RightPanel.styles";
import { ServiceLogo } from "@/components/ServiceLogo";

import { Spinner } from "@/styles/common";

import { toastResponseError } from "@/utils/responseMessageHelper";
import { useHistoryDetailed } from "@/hooks/react-query/history";

import { WeekItemPanelData } from "@/store/useRightPanelStore";
import { useAuthStore, useHistoryStore } from "@/store";
import { HistoryDetailedParamsDto } from "@/types/api/history";

import HistoryPagination from "../HistoryPagination";
import { Parcel } from "../Parcel";
import { VertLine, WeekDate } from "./WeekComponents.styles";

interface WeekItemPanelProps {
  isOpen: boolean;
  panelData: WeekItemPanelData;
}

const WeekItemPanel: FC<WeekItemPanelProps> = React.memo(
  ({ isOpen, panelData }) => {
    const [page, setPage] = useState(0);
    const { historyPageCount } = useHistoryStore();
    const [historyDetailedParams, setHistoryDetailedParams] =
      useState<HistoryDetailedParamsDto | null>(null);

    const {
      error: errorHistoryDetailed,
      isLoading: isDetailedLoading,
      refetch: getHistoryDetailed,
    } = useHistoryDetailed(historyDetailedParams!, {
      enabled: !!historyDetailedParams,
    });
    const isPaginationVisible = historyPageCount > 1;
    const {
      userAuth: { display_weight_in: units },
    } = useAuthStore();

    const { historyDetailedItems: parcels } = useHistoryStore();

    const shippingMethod = panelData.shippingMethod;
    const foundPackageId = panelData.foundPackageId ?? null;

    useEffect(() => {
      if (historyDetailedParams) {
        getHistoryDetailed();
      }
    }, [historyDetailedParams]);

    useEffect(() => {
      if (isOpen && !panelData.returnPreviousPanel) {
        setHistoryDetailedParams({
          week_number: panelData.weekNumber,
          shipping_method: shippingMethod,
          year: panelData.selectedYear,
          page: page + 1,
        });
      }
    }, [isOpen, panelData, page]);

    useEffect(() => {
      if (errorHistoryDetailed) {
        toastResponseError(errorHistoryDetailed);
      }
    }, [errorHistoryDetailed]);

    const handleChangePage = useCallback(
      (page: number) => setPage(page),
      [setPage],
    );

    return (
      <ContentWrapper>
        <Spinner isActive={isDetailedLoading} />
        {parcels && !isDetailedLoading && (
          <>
            <Heading $isBordered>
              <ServiceLogo serviceKey={shippingMethod} />
              {shippingMethod}
              <VertLine>|</VertLine>
              <WeekDate>{parcels[0]?.week_range ?? ""}</WeekDate>
            </Heading>
            <Content>
              {parcels.map((parcel) => {
                return (
                  <Parcel
                    units={units}
                    parcel={parcel}
                    key={parcel.id}
                    foundPackageId={foundPackageId}
                  />
                );
              })}
              {isPaginationVisible && (
                <HistoryPagination
                  selectedPage={page}
                  onSelect={handleChangePage}
                  pageCount={historyPageCount}
                />
              )}
            </Content>
          </>
        )}
      </ContentWrapper>
    );
  },
);

export default WeekItemPanel;
