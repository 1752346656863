import React, { FC } from "react";

import { Row } from "react-grid-system";
import { useTranslation } from "react-i18next";

import { WarehouseAddress } from "@/components/WarehouseAddress";

import {
  ColWrapper,
  Content,
  Paragraph,
  PlaceholderIcon,
  PlaceholderTitle,
  Wrapper,
} from "@/styles/placeholder";

import { useDashboard } from "@/hooks/react-query/dashboard";

import { useAuthStore, useDashboardStore } from "@/store";

import { storagePlaceholder as placeholderIcon } from "./images";
import { AddressWrapper, Spinner } from "./NoItemsPlaceholder.styles";

const NoItemsPlaceholder: FC = ({}) => {
  const { t } = useTranslation("common");
  const { getAuthSelectFullName } = useAuthStore();
  const { dashboardData } = useDashboardStore();

  const userFullName = getAuthSelectFullName();

  const { isLoading: isLoadingDashboard } = useDashboard();

  return (
    <Wrapper>
      <Row>
        <ColWrapper md={6} xs={12}>
          <Content className="separator">
            <PlaceholderIcon
              src={placeholderIcon}
              alt={t("parcels.placeholderIcon")}
            />
            <Paragraph>{t("parcels.youDontAnyExpectedParcels")}</Paragraph>
          </Content>
        </ColWrapper>
        <ColWrapper md={6} xs={12}>
          <Content>
            <PlaceholderTitle>
              {t("parcels.youCouldStartOrder")}
            </PlaceholderTitle>
            <Spinner isActive={isLoadingDashboard} />
            {dashboardData && (
              <AddressWrapper>
                <WarehouseAddress
                  userFfid={dashboardData.user_ffid}
                  fullName={userFullName}
                  warehouseAddress={dashboardData.warehouse_address}
                />
              </AddressWrapper>
            )}
          </Content>
        </ColWrapper>
      </Row>
    </Wrapper>
  );
};

export default React.memo(NoItemsPlaceholder);
