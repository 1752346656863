import { Container } from "react-grid-system";
import { NavLink, NavLinkProps } from "react-router-dom";
import styled from "styled-components";

import { Icon } from "@/components/Icon";

export const HeaderGrid = styled(Container)`
  width: 100%;
`;

export const BackIcon = styled(Icon)`
  background-color: ${({ theme }) => theme.primary};
`;

export const StepperWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StepperProgress = styled.div`
  width: 100%;
  max-width: 740px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ConnectedLineProps {
  filled: boolean;
}

export const ConnectedLine = styled.div<ConnectedLineProps>`
  max-width: 90px;
  width: 100%;
  height: 100%;
  position: relative;

  &::after,
  &::before {
    content: "";
    position: absolute;
    display: flex;
    top: 50%;
    height: 2px;
    border-radius: 12px;
  }

  &::before {
    left: 10px;
    right: 10px;
    background-color: ${({ theme }) => theme.lightgrey};
  }

  &::after {
    left: 10px;
    transition: width 0.6s;
    background-color: ${({ theme }) => theme.secondary05};
    width: ${({ filled }) => (filled ? "calc(100% - 20px)" : "0")};
  }
`;

interface LinkItemProps {
  disabled?: boolean;
  passed?: boolean;
}

export const LinkItem = styled(
  ({ disabled, passed, ...linkProps }: NavLinkProps & LinkItemProps) => (
    <NavLink {...linkProps} />
  ),
)`
  display: flex;
  max-width: 160px;
  width: 100%;
  min-width: 120px;
  padding: 5px 12px;
  border-radius: 20px;
  position: relative;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.2s;
  color: ${({ theme, passed }) => (passed ? theme.black : theme.black50)};

  &.active {
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.secondary};
  }

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  `}

  ${(props) => props.theme.sm`
    max-width: 30px;
    min-width: 30px;
    heigth: 30px;
    padding: 6px 0;
    border-radius: 50%;
  `}
`;

export const ButtonMD = styled.button`
  display: block;

  ${(props) => props.theme.sm`
    display: none
  `}
`;

export const ButtonXS = styled.button`
  display: none;

  ${(props) => props.theme.sm`
    display: block
  `}
`;
