import { AxiosResponse } from "axios";
import { FormikHelpers } from "formik";
import * as yup from "yup";

import { toastResponseError } from "@/utils/responseMessageHelper";

import { PackingGradePanelData } from "@/store/useRightPanelStore";
import { ConsolidationAddonsMethod } from "@/store/useServicesStore";
import {
  CartCommonResponseDto,
  CartResponseDto,
  SetPackingMethodResponseDto,
} from "@/types/api/cart";
import {
  UpdateConsolidationAddonsDto,
  UpdateConsolidationAddonsResponseDto,
  UpdateConsolidationOptionalLineItemsDto,
} from "@/types/api/outgoing";
import { OpenRightPanel } from "@/types/common/rightPanel";
import { PackingGradeFormValues } from "@/types/OutgoingForms/packingGradePanel";

export const packingGradePanelFormik = (
  panelData: any | PackingGradePanelData,
  openRightPanel: OpenRightPanel,
  consolidationAddons: ConsolidationAddonsMethod[],
  cart: CartResponseDto | null,
  updateConsolidationAddons: (
    addonDto: UpdateConsolidationAddonsDto,
  ) => Promise<AxiosResponse<UpdateConsolidationAddonsResponseDto>>,
  updateConsolidationOptionalLineItems: (
    dto: UpdateConsolidationOptionalLineItemsDto,
  ) => Promise<AxiosResponse<string[]>>,
  setCartPackingMethods: (
    method: string[],
  ) => Promise<SetPackingMethodResponseDto>,
  setCartConsolidationAddonsMethods: (
    ids: number[],
  ) => Promise<CartCommonResponseDto>,
) => ({
  initialValues: {
    packingType: panelData.packingCode,
    packingMethods: panelData.packingMethods,
    selectedAddonCodes: ((selectedConsolidationAddons) => {
      const packingCode = cart?.optional_line_item_codes?.[0];
      return consolidationAddons.reduce(
        (
          selectedValues: {
            [key: string]: boolean;
          },
          addon: ConsolidationAddonsMethod,
        ) => {
          const code = addon.code;
          const currentSelected = selectedConsolidationAddons?.includes(code);
          return packingCode
            ? {
                ...selectedValues,
                [code]: currentSelected,
              }
            : selectedValues;
        },
        {},
      );
    })(panelData.selectedConsolidationAddons),
  },

  validationSchema: () =>
    yup.object().shape({
      packingType: yup.string().required(),
      selectedAddonCodes: yup
        .object()
        .test(
          "areSelectedAddonCodesValid",
          (value: { [key: string]: ConsolidationAddonsMethod }, params) => {
            if (!consolidationAddons && !value) {
              return false;
            }
            for (
              let i = 0;
              i < params.parent.consolidationAddons?.length;
              i++
            ) {
              const addonItem = params.parent.consolidationAddons.i;
              if (value && value[addonItem.code] === undefined) {
                return false;
              }
            }

            return true;
          },
        ),
    }),

  onSubmit: (
    values: PackingGradeFormValues,
    { setSubmitting }: FormikHelpers<PackingGradeFormValues>,
  ) => {
    const id = panelData.detailedItemID!;
    const isCartVariant = panelData.isCartVariant;
    const { selectedAddonCodes, packingType } = values;

    const selectedAddonsIds = consolidationAddons.reduce(
      (list: number[], addon) => {
        const code = addon.code;
        if (selectedAddonCodes[code]) {
          list.push(addon.id);
        }
        return list;
      },
      [],
    );

    const requests = isCartVariant
      ? Promise.all([
          setCartPackingMethods([packingType]),
          setCartConsolidationAddonsMethods(selectedAddonsIds),
        ])
      : Promise.all([
          updateConsolidationAddons({
            id,
            data: { addons_ids: selectedAddonsIds },
          }),
          updateConsolidationOptionalLineItems({
            id,
            data: {
              optional_line_item_codes: [packingType],
            },
          }),
        ]);

    requests
      .then(() => {
        if (panelData.setAccess) {
          panelData.setAccess(selectedAddonsIds);
        }
        openRightPanel(panelData.returnPreviousPanel, panelData);
      })
      .catch(toastResponseError)
      .finally(() => setSubmitting(false));
  },
});
