import { memo } from "react";

import { useTranslation } from "react-i18next";

import { ParcelsLink, Wrapper } from "../Parcels.styles";

const ParcelHeader = () => {
  const { t } = useTranslation("common");
  return (
    <Wrapper>
      <ParcelsLink title={t("pages.storage")} to="/parcels" end>
        {t("pages.storage")}
      </ParcelsLink>
      <ParcelsLink title={t("pages.expected")} to="/parcels/expected">
        {t("pages.expected")}
      </ParcelsLink>
      <ParcelsLink title={t("pages.outgoing")} to="/parcels/outgoing">
        {t("pages.outgoing")}
      </ParcelsLink>
      <ParcelsLink title={t("pages.history")} to="/parcels/history">
        {t("pages.history")}
      </ParcelsLink>
    </Wrapper>
  );
};

export default memo(ParcelHeader);
