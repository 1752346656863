import { FormikHelpers } from "formik";
import { TFunction } from "i18next";

import {
  toastResponseError,
  toastResponseSuccess,
} from "@/utils/responseMessageHelper";

import { DetailedShipment } from "@/store/useOutgoingStore";
import { PackOptionsPanelData } from "@/store/useRightPanelStore";
import { DetailedShipments } from "@/store/useStorageStore";
import { ShipmentResponseDto, UpdateAddonsDto } from "@/types/api/shipments";
import { OpenRightPanel } from "@/types/common/rightPanel";
import { PackOptionsFormValues } from "@/types/StorageForms/PackOptions";

export const packOptionsFormik = (
  t: TFunction,
  openRightPanel: OpenRightPanel,
  detailedShipment: DetailedShipments,
  outgoingDetailedShipment: DetailedShipment,
  panelData: PackOptionsPanelData,
  updateAddons: ({ id, data }: UpdateAddonsDto) => Promise<ShipmentResponseDto>,
) => {
  const getInitialValues = () => {
    const id = panelData.id;

    const shipment = detailedShipment[id] || outgoingDetailedShipment[id];

    const shipmentAddons = shipment.addons;

    return {
      activeItem: { value: id },
      items: {
        [id]: shipmentAddons,
      },
    };
  };

  const initialValues = getInitialValues();

  return {
    enableReinitialize: true,
    initialValues,
    onSubmit: (
      values: PackOptionsFormValues,
      { setSubmitting }: FormikHelpers<PackOptionsFormValues>,
    ) => {
      const id = panelData.id;
      const returnScreen = panelData.returnScreen;

      if (!id) return;

      updateAddons({
        id,
        data: {
          addon_ids: values.items[id],
        },
      })
        .then(() => {
          toastResponseSuccess(
            t("parcels.packOptionsSaved", {
              number: panelData.description,
            }),
          );
          openRightPanel(returnScreen, panelData);
        })
        .catch(toastResponseError)
        .finally(() => setSubmitting(false));
    },
  };
};
