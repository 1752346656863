import React, { FC, MouseEvent, useCallback } from "react";

import { useFormik } from "formik";
import { Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { Button } from "@/components/Button";

import { toastResponseError } from "@/utils/responseMessageHelper";
import { useCartSetPackingMethod } from "@/hooks/react-query/cart";
import { ProgressDeliverySetAccessFunc } from "@/hooks";

import { RightPanelType } from "@/store/useRightPanelStore";
import { useCartStore, useRightPanelStore, useServicesStore } from "@/store";
import {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  IconType,
} from "@/enums";

import {
  BlockContent,
  Content,
  DeliveryBlock,
  Heading,
  SubmitWrapper,
} from "../../styles";
import { PackingList } from "../PackingList";
import { ScrollableForm } from "../ScrollableForm";
import { HelpButton, HelpIcon, Spinner } from "./ChooseForm.styles";

interface ChoosePackingFormProps {
  setAccess: ProgressDeliverySetAccessFunc;
}

const ChoosePackingForm: FC<ChoosePackingFormProps> = React.memo(
  ({ setAccess }) => {
    const { t } = useTranslation("common");
    const navigate = useNavigate();

    const { isLoadingServices, packingMethods } = useServicesStore();
    const { openRightPanel } = useRightPanelStore();
    const { cart } = useCartStore();

    const { mutateAsync: setPackingMethods } = useCartSetPackingMethod();

    const cartPacking = cart?.optional_line_item_codes?.[0];

    const {
      isSubmitting,
      setFieldValue,
      values: { packingType },
      handleSubmit,
    } = useFormik({
      initialValues: {
        packingType: cartPacking,
      },
      validationSchema: () =>
        yup.object().shape({
          packingType: yup.string().required(),
        }),
      onSubmit: (values) => {
        if (cartPacking !== values.packingType) {
          setPackingMethods([values.packingType])
            .then(() => {
              setAccess("addons");
              navigate("/shipping/flow/delivery/additional");
            })
            .catch(toastResponseError);
          return;
        }

        setAccess("addons");
        navigate("/shipping/flow/delivery/additional");
      },
    });

    const handleSelect = useCallback(
      (type: string) => {
        setFieldValue("packingType", type);
      },
      [setFieldValue],
    );

    const handlePackingSubmit = useCallback(
      async (type: string) => {
        await setFieldValue("packingType", type);
        handleSubmit();
      },
      [setFieldValue, handleSubmit],
    );

    const handleHelpClick = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        openRightPanel(RightPanelType.HELP_PANEL, {
          preventReLoading: true,
          hideBackButton: true,
          showHelpersCode: "packing_grade",
        });
      },
      [openRightPanel],
    );

    return (
      <Col lg={4} md={6} sm={12} offset={{ md: 3, lg: 4, sm: 12 }}>
        <Content>
          <DeliveryBlock>
            <form onSubmit={handleSubmit}>
              <Heading>
                {t("shipping.selectPacking")}:
                <HelpButton onClick={handleHelpClick}>
                  <HelpIcon type={IconType.Help} />
                </HelpButton>
              </Heading>
              <Spinner isActive={isLoadingServices} />
              <BlockContent>
                <ScrollableForm>
                  <PackingList
                    packingMethods={packingMethods}
                    packingType={packingType}
                    onSelect={handleSelect}
                    onSubmit={handlePackingSubmit}
                  />
                </ScrollableForm>
                <SubmitWrapper>
                  <Button
                    disabled={!packingType || isSubmitting}
                    isLoading={isSubmitting}
                    type={ButtonType.Submit}
                    size={ButtonSize.Large}
                    color={ButtonColor.Primary}
                    variant={ButtonVariant.Filled}
                  >
                    {t("common.nextStep")}
                  </Button>
                </SubmitWrapper>
              </BlockContent>
            </form>
          </DeliveryBlock>
        </Content>
      </Col>
    );
  },
);

export default ChoosePackingForm;
