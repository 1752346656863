import React, { FC, useState } from "react";

import { useStorageUpdateItemComment } from "@/hooks/react-query/storage";

import { RightPanelType } from "@/store/useRightPanelStore";
import { DetailedShipment } from "@/store/useStorageStore";
import { useRightPanelStore } from "@/store";
import { InventoryItemResponseDto } from "@/types/api/items";
import { OutgoingItem } from "@/types/api/outgoing";

import { InventoryListWrapper } from "../styles";
import { InventoryItemRow } from "./InventoryItemRow";

interface InventoryListProps {
  units: string;
  items: InventoryItemResponseDto[] | OutgoingItem[];
  shipment?: DetailedShipment;
  selectedItems: any;
  disabled: boolean;
  getIsItemInCart: (itemId: number) => boolean;
  handleSelectItems: (items: any) => void;
  isInventoryItemsSearchVariant?: boolean;
}

const InventoryList: FC<InventoryListProps> = React.memo(
  ({
    items,
    selectedItems,
    disabled,
    getIsItemInCart,
    handleSelectItems,
    shipment,
    isInventoryItemsSearchVariant,
    units,
  }) => {
    const { openRightPanel } = useRightPanelStore();
    const [selectedInventoryItem, setSelectedInventoryItem] = useState<
      number | null
    >(null);
    // Don't display Q items in inventory
    const filteredItems = items.filter((item) => !item.sku.startsWith("Q"));

    const { mutateAsync: updateItemComment } = useStorageUpdateItemComment();

    const handleFocusInventoryItemInPanel = (
      item: InventoryItemResponseDto | OutgoingItem,
    ) => {
      setSelectedInventoryItem(item.id);
      openRightPanel(RightPanelType.STORAGE_ITEM, {
        id:
          "shipment_id" in item && isInventoryItemsSearchVariant
            ? item.shipment_id
            : shipment!.id,
        updateItemComment,
        originalItem: item,
        isItemInCart: getIsItemInCart(item.id),
        isCartVariant: true,
        preventImageZoom: false,
      });
    };

    return (
      <InventoryListWrapper>
        {filteredItems.map((inventoryItem) => (
          <InventoryItemRow
            units={units}
            key={inventoryItem.id}
            inventoryItem={inventoryItem}
            selectedInventoryItem={selectedInventoryItem}
            isItemInCart={getIsItemInCart(inventoryItem.id)}
            isSelected={
              selectedItems[inventoryItem.id] &&
              selectedItems[inventoryItem.id].isSelectedState
            }
            onChange={({
              isSelected,
              selectedQuantity,
            }: {
              isSelected: boolean;
              selectedQuantity: number;
            }) =>
              handleSelectItems({
                [inventoryItem.id]: {
                  id: inventoryItem.id,
                  isSelectedState: isSelected,
                  selectedQuantity,
                },
              })
            }
            disabledParent={disabled}
            onFocusInventoryItemInPanel={handleFocusInventoryItemInPanel}
          />
        ))}
      </InventoryListWrapper>
    );
  },
);

export default InventoryList;
