import styled from "styled-components";

import { Content } from "@/containers/RightPanel/RightPanel.styles";

import { PayButton as Button } from "@/styles/outgoing";

export const Section = styled.div`
  margin: 20px 0;
`;

export const SectionTitle = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  margin: 0;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  margin-top: 15px;
  ${({ theme }) => `border: 1px solid ${theme.primary}`}
`;

interface SwitchButtonProps {
  sectionsCount: number;
  selected?: boolean;
  disabled?: boolean;
}

export const SwitchButton = styled.div<SwitchButtonProps>`
  cursor: pointer;
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.primary};
  line-height: 28px;
  padding: 3px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  ${({ sectionsCount }) =>
    `
    flex-basis: ${100 / sectionsCount}%;
  `}

  &:nth-child(n+2) {
    ${({ theme }) => `border-left: 1px solid ${theme.primary}`}
  }

  .tooltip-content-checkbox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ theme, selected }) =>
    selected &&
    `
    color: ${theme.white};
    background-color: ${theme.primary};
  `}

  ${({ disabled }) =>
    disabled &&
    `
    color: rgba(0, 0, 0, 0.2);
    background-color: transparent;
    
    cursor: default;

    img {
      opacity: 0.3;
    }
  `}
`;

export const PaymentMethodIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 6px;
`;

export const PayButton = styled(Button)`
  margin-top: auto;
  align-self: center;
`;

export const ScrollableContent = styled(Content)`
  & > div:first-child {
    display: flex;
    flex-direction: column;
  }
`;

export const CreditCardFormWrapper = styled.div`
  padding-top: 30px;
`;
