import { FC, memo, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { AddonItem } from "@/components/AddonsList/AddonsList.styles";
import { RadioButton } from "@/components/RadioButton";

import { ConsolidationAddonsMethod } from "@/store/useServicesStore";

import { getAddonsIconsImage } from "../helpers";
import {
  AddonDescription,
  RadioButtonsGroup,
} from "./ConsolidationAddonsList/ConsolidationAddonsList.styles";

type SelectedCodes = {
  [key: string]: boolean;
};

interface OutgoingConsolidationAddonsListProps {
  addons?: ConsolidationAddonsMethod[];
  selectedCodes: SelectedCodes;
  setFormValues: (values: SelectedCodes) => void;
}

const OutgoingConsolidationAddonsList: FC<
  OutgoingConsolidationAddonsListProps
> = ({ addons = [], selectedCodes, setFormValues }) => {
  const { t } = useTranslation("common");

  const handleChange = useCallback(
    (code: string, operation: string) => () => {
      const isOperationSelect = operation === "select";
      setFormValues({
        ...selectedCodes,
        [code]: isOperationSelect,
      });
    },
    [setFormValues],
  );

  return addons.map((addon) => {
    const code = addon.code;
    const icon = getAddonsIconsImage(addon.id, addons);
    const addonId = addon.id;

    const checked = selectedCodes[code];

    return (
      <AddonItem $isSelected={!!checked} key={addonId}>
        {icon}
        <AddonDescription>{addon.description}</AddonDescription>
        <RadioButtonsGroup>
          <RadioButton
            label={t("auth.yes")}
            value={addonId}
            checked={!!checked}
            onClick={handleChange(code, "select")}
          />
          <RadioButton
            label={t("auth.no")}
            value={undefined}
            checked={!checked && checked !== undefined}
            onClick={handleChange(code, "deselect")}
          />
        </RadioButtonsGroup>
      </AddonItem>
    );
  });
};

export default memo(OutgoingConsolidationAddonsList);
