import { useState } from "react";

interface FocusHandlers {
  isFocused: boolean;
  onFocus: () => void;
  onBlur: () => void;
}

const useFocus = (): FocusHandlers => {
  const [isFocused, setFocused] = useState(false);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  return {
    isFocused,
    onFocus,
    onBlur,
  };
};

export default useFocus;
