import * as yup from "yup";

import { toastResponseError } from "@/utils/responseMessageHelper";

import {
  ErrorsFormValues,
  FormValue,
  FormValues,
  HandleSubmitHelpers,
} from "@/types";

export function getShowError(
  values: FormValues,
  errors: ErrorsFormValues,
  index: number,
  fieldName: keyof FormValue,
  minNumericValue?: number,
): boolean {
  const fieldValue = values[index][fieldName];
  const fieldErrors = errors?.[index]?.[fieldName];

  if (
    fieldErrors &&
    (fieldValue === "" ||
      fieldValue === null ||
      fieldValue === undefined ||
      typeof fieldValue === "string" ||
      errors[index] === "Duplicate row found")
  ) {
    return true;
  }

  if (minNumericValue !== undefined && typeof fieldValue === "string") {
    const parsedNumber = parseFloat(fieldValue);
    return (
      (fieldErrors
        ? isNaN(parsedNumber) || parsedNumber < minNumericValue
        : false) || errors[index] === "Duplicate row found"
    );
  }

  return !!fieldErrors;
}

const isFullyDuplicateRow = (
  items: FormValues = [],
  currentIndex: number,
  fields: string[],
) => {
  const currentItem = items[currentIndex];

  const duplicateIndices = items.reduce((acc: number[], item, index) => {
    const isDuplicate = fields.every(
      (field) =>
        item[field as keyof typeof item] ==
        currentItem[field as keyof typeof currentItem],
    );
    if (isDuplicate) {
      acc.push(index);
    }
    return acc;
  }, []);

  return duplicateIndices[0] === currentIndex;
};

const valueTestDuplicated = (
  value: FormValue,
  params: yup.TestContext<FormValues>,
  isShowHSCodeColumn: boolean,
) => {
  if (value.description) {
    const fields = isShowHSCodeColumn
      ? ["description", "hs_code", "quantity", "value"]
      : ["description", "quantity", "value"];
    const { path } = params;
    const items = params.options.context;

    const currentIndex = parseInt(path.match(/\d+/)?.[0] ?? "0", 10);

    return isFullyDuplicateRow(items, currentIndex, fields);
  }
  return true;
};

const descriptionSchema = yup.string().required();
const quantitySchema = yup
  .number()
  .integer()
  .min(1, "Value must be greater than or equal to 1")
  .required();
const valueSchema = yup
  .number()
  .transform((_, value) => {
    if (typeof value === "string") {
      return parseFloat(value.replace(/,/, "."));
    }
    return value;
  })
  .min(0.01, "Value must be greater than or equal to 0.01")
  .required();

export const customDeclarationForm = {
  validateOnChange: false,
  validateOnBlur: false,
  enableReinitialize: true,
  mapPropsToValues: ({
    declarations,
  }: {
    declarations: FormValues;
  }): FormValues => {
    let retVal = [];
    retVal.push(...declarations);
    for (let i = declarations.length; i < 50; i++) {
      retVal.push({
        tempId: i - declarations.length,
        description: "",
        hs_code: "",
        quantity: "",
        value: "",
      });
    }
    return retVal as FormValues;
  },
  validationSchema: ({
    isShowHSCodeColumn,
    isHSCodeValidationRequired,
  }: {
    isShowHSCodeColumn?: boolean;
    isHSCodeValidationRequired?: boolean;
  }) =>
    yup.array().of(
      yup
        .object()
        .shape({
          description: yup.string().test("isDescValid", (value, params) => {
            if (!params.parent.quantity && !params.parent.value && !value)
              return true;
            return descriptionSchema.isValidSync(value);
          }),
          ...(isShowHSCodeColumn
            ? {
                hs_code: yup
                  .string()
                  .test("isHsCodeValid", "Invalid HS code", function (value) {
                    const { hs_code, value: valueField } = this.parent;
                    if (!hs_code && !valueField && !value) return true;
                    if (isHSCodeValidationRequired)
                      return yup.string().required().isValidSync(value);

                    return yup.string().isValidSync(value);
                  }),
              }
            : {}),
          quantity: yup.number().test("isQuantityValid", (value, params) => {
            if (!params.parent.description && !params.parent.value && !value)
              return true;
            return quantitySchema.isValidSync(value);
          }),
          value: yup.mixed().test("isValueValid", (value, params) => {
            if (!params.parent.description && !params.parent.quantity && !value)
              return true;
            return valueSchema.isValidSync(value);
          }),
        })
        .test(
          "isDuplicateRow",
          "Duplicate row found",
          function (value, params) {
            return valueTestDuplicated(
              value as FormValue,
              params as yup.TestContext<FormValues>,
              isShowHSCodeColumn ?? false,
            );
          },
        ),
    ),
  handleSubmit: async <
    C extends Function,
    R extends Function,
    U extends Function,
  >(
    values: FormValues,
    {
      props: {
        onSubmit,
        declarations,
        create,
        update,
        remove,
        itemId,
        isShowHSCodeColumn,
      },
      setSubmitting,
    }: HandleSubmitHelpers<C, R, U>,
  ) => {
    setSubmitting(true);
    const toUpdateList = [];
    const toCreateList = [];
    const toDeleteList = [];
    for (const item of values) {
      const convertedItem = {
        ...item,
        description: item.description,
        quantity: item.quantity ? +item.quantity : 0,
        value:
          typeof item.value === "string"
            ? parseFloat(item.value.replace(/,/, "."))
            : item.value,
      };
      if (isShowHSCodeColumn) {
        convertedItem.hs_code = item.hs_code;
      }

      if (item.id) {
        const prevItem = declarations.find((x) => x.id === item.id);
        if (
          item.description !== prevItem?.description ||
          item.quantity !== prevItem?.quantity ||
          item.value !== prevItem?.value ||
          item.hs_code !== prevItem?.hs_code
        ) {
          toUpdateList.push(convertedItem);
        }
      } else if (item.description && item.quantity && item.value) {
        toCreateList.push(convertedItem);
      }
    }
    for (const item of declarations) {
      const existingItem = values.find((x) => x.id === item.id);
      if (!existingItem) {
        toDeleteList.push(item);
      }
    }

    try {
      for (const item of toUpdateList) {
        if (update) {
          await update({ itemId, id: item.id, data: item });
        }
      }

      for (const item of toCreateList) {
        if (create) {
          await create({
            itemId,
            data: { ...item, weight: 1, country_of_origin: "US" },
          });
        }
      }

      for (const item of toDeleteList) {
        if (remove) {
          await remove({ itemId, id: item.id });
        }
      }

      if (onSubmit) {
        onSubmit();
      }
    } catch (error: unknown) {
      toastResponseError(error);
    }

    setSubmitting(false);
  },
  displayName: "CustomsDeclaration",
};

export const detectCyrillic = (input: string): boolean =>
  /[\u0400-\u04FF]/.test(input);
