import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";

export const LayoutWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100vh;
  height: -webkit-fill-available;
`;

export const ContentWrapper = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  position: relative;
  height: 100%;
`;

export const PageWrapper = styled.div`
  background-color: #fff;
  padding: 15px;
  height: 100%;
  animation: 0.8s ${keyframes`${fadeIn}`};

  ${(props) => props.theme.sm`
    min-height: 100%;
    height: fit-content;
  `}

  ${(props) => props.theme.xs`
    padding: 15px 0;
  `}
`;
