import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { AccountHeader } from "@/containers/Account/components";
import { BooksHeader } from "@/containers/Books/components/BooksHeader";
import { DeliveryHeading } from "@/containers/Delivery/components/DeliveryHeading";
import { ParcelHeader } from "@/containers/Parcels/components";
import { ToolsHeader } from "@/containers/Tools/components";
import { ProfileLink } from "@/components/ProfileLink";

import { useProgressDelivery } from "@/hooks";

import { useAuthStore } from "@/store";

import { HeaderTitle } from "../HeaderContainer.styles";

type ComponentKeys = "tools" | "account" | "books" | "parcels";

const componentsMap: Record<ComponentKeys, () => JSX.Element> = {
  tools: () => <ToolsHeader />,
  account: () => <AccountHeader />,
  books: () => <BooksHeader />,
  parcels: () => <ParcelHeader />,
};

const BasicHeader: FC = React.memo(() => {
  const location = useLocation();
  const { getAuthSelectFfid } = useAuthStore();
  const userFfid = getAuthSelectFfid();
  const [access] = useProgressDelivery(userFfid);
  const { t } = useTranslation("common");
  const path = location.pathname.split("/")[1];
  const title = t(`pages.${path || "basicInformation"}`);
  const HeaderComponent = componentsMap[path as ComponentKeys]?.() ?? null;

  if (path === "shipping") return <DeliveryHeading access={access} />;

  return (
    <Wrapper>
      <HeaderTitle>{title}</HeaderTitle>
      {HeaderComponent}
      <ProfileLink />
    </Wrapper>
  );
});

export const Wrapper = styled.span`
  display: flex;
  align-items: center;
  width: calc(100% - 80px);

  ${(props) => props.theme.sm`
    width: 100%;
  `}
`;

export default BasicHeader;
