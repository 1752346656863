import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { Icon } from "@/components/Icon";

import { IconType, InputIconColor } from "@/enums";

import {
  GoodIconWrapper,
  ValidationItemWrapper,
  Wrapper,
  WrongIcon,
} from "./SignUpComponents.styles";

const lengthValidationRule = yup.string().min(6);
const alphabeticalValidationRule = yup.string().matches(/(?=.*[a-zA-Z])/);
const numericValidationRule = yup.string().matches(/(?=.*[0-9])/);
const symbolValidationRule = yup.string().matches(/[^a-zA-Z0-9\s]/);
const noWhitespaceValidationRule = yup.string().matches(/^\S*$/);

const validateForRule = (rule: yup.StringSchema, value: string) => {
  const passwordIsEntered = !!value;
  let isValid;
  try {
    rule.validateSync(value);
    isValid = true;
  } catch (err) {
    isValid = false;
  }

  return isValid && passwordIsEntered;
};

interface PasswordValidationProps {
  value?: string;
}

const PasswordValidation: FC<PasswordValidationProps> = ({ value = "" }) => {
  const { t } = useTranslation("common");

  const isLengthValid = validateForRule(lengthValidationRule, value);
  const isAlphabeticalValidationRule = validateForRule(
    alphabeticalValidationRule,
    value,
  );
  const isNumericValidationRule = validateForRule(numericValidationRule, value);
  const isSymbolValidationRule = validateForRule(symbolValidationRule, value);
  const isNoWhitespaceValidationRule = validateForRule(
    noWhitespaceValidationRule,
    value,
  );

  return (
    <Wrapper>
      <ValidationItem
        isValid={isLengthValid}
        text={t("errorPasswordSignup.length", { length: 6 })}
      />
      <ValidationItem
        isValid={isAlphabeticalValidationRule}
        text={t("errorPasswordSignup.alphabetical")}
      />
      <ValidationItem
        isValid={isNumericValidationRule}
        text={t("errorPasswordSignup.numeric")}
      />
      <ValidationItem
        isValid={isSymbolValidationRule}
        text={t("errorPasswordSignup.symbol")}
      />
      <ValidationItem
        isValid={isNoWhitespaceValidationRule}
        text={t("errorPasswordSignup.whiteSpaces")}
      />
    </Wrapper>
  );
};

export default PasswordValidation;

interface ValidationItemProps {
  isValid?: boolean;
  text?: string;
}

const ValidationItem: FC<ValidationItemProps> = ({ isValid, text = "" }) => {
  return (
    <ValidationItemWrapper>
      {isValid ? (
        <GoodIconWrapper>
          <Icon type={IconType.Good} color={InputIconColor.Green} />
        </GoodIconWrapper>
      ) : (
        <WrongIcon type={IconType.Wrong} color={InputIconColor.LightGrey} />
      )}
      {text}
    </ValidationItemWrapper>
  );
};
