import { AddonsMethod } from "@/store/useServicesStore";

export const getAddonsCost = (
  items: { addons: (number | string)[] }[],
  addons: AddonsMethod[] = [],
): number =>
  items?.reduce(
    (price: number, item: { addons: (number | string)[] }, index) => {
      const itemAddons = items[index]?.addons || [];
      itemAddons.forEach((id: string | number) => {
        const addon = addons.find((addon) => addon.id === id);
        if (addon) {
          price += parseFloat(addon.price);
        }
      });
      return price;
    },
    0,
  );
