import { fadeIn } from "react-animations";
import styled, { css, keyframes } from "styled-components";

import { Content } from "@/containers/RightPanel/RightPanel.styles";
import { PreLoader } from "@/components/PreLoader";

import { ParcelLogo } from "@/styles/parcels";

export const SpinnerSection = styled.section`
  width: 100%;
  margin-bottom: 15px;
  min-height: 390px;
  display: flex;
  align-items: center;
`;

export const Spinner = styled(PreLoader)``;

export const fadeInAnimation = css`
  animation: 0.5s ${keyframes`${fadeIn}`} !important;
`;

export const ContentContainer = styled(Content)`
  ${fadeInAnimation}

  & > div:first-child {
    display: flex;
    flex-wrap: wrap;
  }

  & > div > button {
    margin: auto auto 0 auto;
  }
`;

export const Container = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

interface ShipmentLogoProps {
  $readOnly: boolean;
}

export const ShipmentLogo = styled(ParcelLogo)<ShipmentLogoProps>`
  ${({ $readOnly }) =>
    $readOnly &&
    `
    height: 16px;
    width: 16px;
    min-width: 16px;
    margin: 0 6px 0 2px;
  `}
`;

interface TitleProps {
  $readOnly?: boolean;
}

export const Title = styled.div<TitleProps>`
  width: 100%;

  ${({ $readOnly }) =>
    $readOnly &&
    `
    font-size: 16px;
    word-break: break-word;
  `}
`;

export const ImageSection = styled.section`
  width: 100%;
  margin-bottom: 15px;
  min-height: 390px;

  & > div {
    margin-bottom: 0 !important;
    ${fadeInAnimation}

    & > label {
      ${({ theme }) =>
        theme.range(
          "margin-left",
          "0px",
          "10px",
          `${theme.breakpoints.md}px`,
          `${theme.breakpoints.lg}px`,
        )};
    }
  }
`;

export const ParcelInfoContainer = styled.div`
  display: flex;
  height: 39px;
  line-height: 39px;
  flex-direction: row;
  justify-content: space-between;
`;

export const ParcelInfoKey = styled.span`
  color: #9a9a9a;
`;

export const ParcelInfoValue = styled.span`
  color: black;
  font-weight: bold;
`;
