import styled from "styled-components";

export const DeclarationTotal = styled.div`
  padding: 5px;
  line-height: 30px;
  font-size: 16px;
  font-weight: bold;
`;

export const HeaderDate = styled.span`
  margin-left: 5px;
  color: gray;
  font-size: 14px;
`;

export const CustomsDeclarationContent = styled.div`
  padding: 0 15px;
  height: calc(100% - 80px) !important;
`;
