import React, { FC, useCallback, useState } from "react";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { Tab, TabsView } from "@/containers/RightPanel/components";
import {
  BackButton,
  Content,
  ContentWrapper,
  Heading,
  LeftArrowIcon,
} from "@/containers/RightPanel/RightPanel.styles";
import { CreditCardForm } from "@/components/CreditCardForm";
import CustomsDeclarationV2 from "@/components/CustomDeclaration/CustomsDeclarationV2";
import creditCardIcon from "@/components/Icon/icons/credit-card.svg";
import payPalIcon from "@/components/Icon/icons/paypal.svg";

import {
  useOutgoingCreateCustomsDeclaration,
  useOutgoingDeleteCustomsDeclaration,
  useOutgoingUpdateCustomsDeclaration,
} from "@/hooks/react-query/outgoing";
import usePurchase from "@/hooks/react-query/purchase";

import useOutgoingStore from "@/store/useOutgoingStore";
import {
  ReviewAndPayPanelData,
  RightPanelType,
} from "@/store/useRightPanelStore";
import { useAuthStore, usePurchaseStore, useRightPanelStore } from "@/store";
import { ButtonColor, ButtonVariant, IconType } from "@/enums";

import { PriceList } from "../PriceList";
import {
  CreditCardFormWrapper,
  PayButton,
  PaymentMethodIcon,
  ScrollableContent,
  Section,
  SectionTitle,
  SwitchButton,
  SwitchWrapper,
} from "./ReviewAndPayPanel.styles";

interface ReviewAndPayPanelProps {
  panelData: ReviewAndPayPanelData;
}

const ReviewAndPayPanel: FC<ReviewAndPayPanelProps> = React.memo(
  ({ panelData }) => {
    const { t } = useTranslation("common");
    const {
      userAuth: { credit_card_allowed: allowedToUseCreditCard },
    } = useAuthStore();
    const { purchaseId } = usePurchaseStore();
    const { checkout, checkoutPayPal, checkoutCreditCard } = usePurchase();
    const { mutateAsync: checkoutMutate } = checkout;
    const { openRightPanel } = useRightPanelStore();
    const { detailedItem } = useOutgoingStore();
    const {
      userAuth: { invoiced },
    } = useAuthStore();
    const { mutateAsync: createCustomsDeclaration } =
      useOutgoingCreateCustomsDeclaration();
    const { mutateAsync: deleteCustomsDeclaration } =
      useOutgoingDeleteCustomsDeclaration();
    const { mutateAsync: updateCustomsDeclaration } =
      useOutgoingUpdateCustomsDeclaration();

    const id = panelData?.detailedItemID;
    const editCustomsData = panelData.isEditCustomData ?? false;
    const historyConsolidation = panelData.consolidation;
    const detailed = !!historyConsolidation
      ? historyConsolidation
      : id
        ? detailedItem[id]
        : {};
    const estimate = detailed.estimate;
    const declarations =
      "customs_declarations" in detailed && detailed.customs_declarations
        ? detailed.customs_declarations
        : [];
    const total = estimate?.total ?? 0;
    const userTotal = estimate?.user_total ?? 0;
    const shouldPayOnlyFromBalance = userTotal <= 0;
    const toPayTotal = shouldPayOnlyFromBalance ? total : userTotal;
    const state = detailed.state;
    const isPaid = state === "paid" || state === "shipped";

    const paymentMethods = ["paypal"];
    if (allowedToUseCreditCard) {
      paymentMethods.push("credit_card");
    }
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
      paymentMethods[0],
    );
    const continuesToSelectedMethod = (method: string) => {
      const paymentMethodTitle =
        method === paymentMethods[0] ? "PayPal" : t("common.creditCard");
      return t("common.continueToPaymentMethod", {
        paymentMethod: paymentMethodTitle,
      });
    };

    const creditCardFormik = useFormik({
      initialValues: {
        name: "",
        number: "",
        expiration: "",
        cvv: "",
      },
      validationSchema: yup.object({
        name: yup.string().required(t("error.requiredField")),
        number: yup.string().required(t("error.requiredField")),
        expiration: yup
          .string()
          .matches(
            /^\d{2}\/\d{4}$/,
            t("error.creditCard.wrongExpirationFormat"),
          )
          .required(t("error.requiredField")),
        cvv: yup
          .string()
          .matches(
            /^\d{3,4}$/,
            t("error.numberLonger", {
              field: t("purchase.creditCard.cvv"),
              length: "3-4",
            }),
          )
          .required(t("error.requiredField")),
      }),
      onSubmit: (values) => {
        const { name, number, expiration, cvv } = values;
        const [month, year] = expiration.split("/");

        const request = {
          credit_card: {
            card_name: name,
            card_number: number,
            exp_month: month,
            exp_year: year,
            card_code: cvv,
          },
        };

        checkoutCreditCard({ id, data: request });
      },
    });

    const handleBack = useCallback(() => {
      openRightPanel(
        panelData.returnPreviousPanel as RightPanelType,
        panelData,
      );
    }, [openRightPanel, panelData]);

    const handlePayFromBalance = useCallback(() => {
      checkoutMutate({ id });
    }, [checkoutMutate, id]);

    const handlePayViaPayPal = useCallback(() => {
      checkoutPayPal(id);
    }, [checkoutPayPal, id]);

    const switchPaymentMethod = useCallback(
      (method: string) => {
        setSelectedPaymentMethod(method);
      },
      [setSelectedPaymentMethod],
    );

    const handleSubmitButton = (event: React.MouseEvent<HTMLButtonElement>) => {
      creditCardFormik.handleSubmit();
    };

    const paymentForm = (method: string) => {
      switch (method) {
        case paymentMethods[0]:
        default:
          return null;
        case paymentMethods[1]:
          return (
            <CreditCardFormWrapper>
              <CreditCardForm
                handleBlur={creditCardFormik.handleBlur}
                handleChange={creditCardFormik.handleChange}
                values={creditCardFormik.values}
                errors={creditCardFormik.errors}
                touched={creditCardFormik.touched}
              />
            </CreditCardFormWrapper>
          );
      }
    };

    const payButton = () => {
      if (shouldPayOnlyFromBalance || invoiced) {
        return (
          <PayButton
            onClick={handlePayFromBalance}
            isLoading={purchaseId === id}
            variant={ButtonVariant.Filled}
          >
            {`${t("common.continueToBalance")} (${total})`}
          </PayButton>
        );
      } else {
        let title = `${continuesToSelectedMethod(
          selectedPaymentMethod,
        )} (${toPayTotal})`;

        if (selectedPaymentMethod === paymentMethods[0]) {
          return (
            <PayButton
              onClick={handlePayViaPayPal}
              isLoading={purchaseId === id}
              variant={ButtonVariant.Filled}
            >
              {title}
            </PayButton>
          );
        } else {
          return (
            <PayButton
              onClick={handleSubmitButton}
              isLoading={purchaseId === id}
              disabled={!!purchaseId || !creditCardFormik.isValid}
              variant={ButtonVariant.Filled}
            >
              {title}
            </PayButton>
          );
        }
      }
    };

    const creditCardSwitchButton = () => {
      return (
        <SwitchButton
          selected={selectedPaymentMethod === paymentMethods[1]}
          onClick={() => switchPaymentMethod(paymentMethods[1])}
          sectionsCount={paymentMethods.length}
        >
          <PaymentMethodIcon src={creditCardIcon} alt="Credit Card" />{" "}
          {t("common.creditCard")}
        </SwitchButton>
      );
    };

    const paymentMethodsSection = (
      <>
        <Section>
          <SectionTitle>{t("parcels.paymentMethod")}:</SectionTitle>
          <SwitchWrapper>
            <SwitchButton
              selected={selectedPaymentMethod === paymentMethods[0]}
              onClick={() => switchPaymentMethod(paymentMethods[0])}
              sectionsCount={paymentMethods.length}
            >
              <PaymentMethodIcon src={payPalIcon} alt="PayPal" />
              {`PayPal`}
            </SwitchButton>
            {allowedToUseCreditCard === true ? creditCardSwitchButton() : null}
          </SwitchWrapper>
          {paymentForm(selectedPaymentMethod)}
        </Section>
        {payButton()}
      </>
    );

    const paymentOnlyFromBalance = (
      <>
        <Section>
          <SectionTitle>{t("parcels.paymentByBalance")}</SectionTitle>
        </Section>
        {payButton()}
      </>
    );

    return (
      <ContentWrapper>
        <Heading>
          <BackButton color={ButtonColor.Black50} onClick={handleBack}>
            <LeftArrowIcon type={IconType.Arrow} />
            {detailed.sku}:
          </BackButton>
          {isPaid ? t("parcels.paymentPaid") : t("common.reviewAndPay")}
        </Heading>
        <TabsView>
          <Tab title={t("parcels.details")}>
            <ScrollableContent>
              <PriceList parcel={detailed} />
              {!isPaid &&
                (shouldPayOnlyFromBalance
                  ? paymentOnlyFromBalance
                  : paymentMethodsSection)}
            </ScrollableContent>
          </Tab>
          <Tab title={t("parcels.customsData")}>
            <Content>
              <CustomsDeclarationV2
                itemId={id}
                readOnly={!editCustomsData}
                declarations={declarations}
                create={createCustomsDeclaration}
                update={updateCustomsDeclaration}
                remove={deleteCustomsDeclaration}
                noPadding
              />
            </Content>
          </Tab>
        </TabsView>
      </ContentWrapper>
    );
  },
);

export default ReviewAndPayPanel;
