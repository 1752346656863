import styled from "styled-components";

export const SectionWrapper = styled.div`
  margin-bottom: 30px;
`;

export const SectionTitle = styled.h2`
  width: 100%;
  font-size: 18px;
  margin-bottom: 8px;
`;

export const PasswordValidationWrapper = styled.div`
  align-self: flex-start;
  display: flex;
  margin-top: 10px;
  width: 100%;
`;
