import styled from "styled-components";

import { Content } from "@/containers/RightPanel/RightPanel.styles";
import { AddressItem } from "@/components/Addresses/components";
import { PackageSizes } from "@/components/PackageSizes";
import { PreLoader } from "@/components/PreLoader";

import { HeaderTitle, SectionContent } from "@/styles/outgoing";

export const ContentWrapper = styled(Content)`
  height: calc(100% - 60px) !important;
`;

export const ImageSection = styled.div`
  min-height: 358px;
  width: 100%;
`;

export const Spinner = styled(PreLoader)`
  transform: scale(0.5);
  position: absolute;
  top: 8px;
`;

export const PanelAddressItem = styled(AddressItem)`
  width: 100%;
  padding: 8px 15px 0 25px;
  animation: none;
  border-bottom: none;

  p {
    white-space: pre;
  }
`;

export const DeliveryContent = styled(SectionContent)`
  font-weight: 700;
`;

export const PackageDimensions = styled.div`
  margin-top: 6px;

  span {
    margin: 0 4px;
  }
`;

export const TrackingNumber = styled.div`
  width: 100%;
  font-weight: 700;
  margin-bottom: 6px;
  color: ${(props) => props.theme.primary};
`;

export const PaidInfoSectionWrapper = styled.div`
  text-align: center;
  width: 100%;
`;

export const Footer = styled.div`
  padding-top: 10px;
  padding-bottom: 15px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SKUTitle = styled(HeaderTitle)`
  color: black;
`;

export const StyledPackageSizes = styled(PackageSizes)`
  margin: 0 !important;
`;
