import React, {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import { SearchInputControlled } from "@/components/SearchInput";

import { useClickAwayListener } from "@/hooks";

import { AddonsMethod } from "@/store/useServicesStore";
import { CartItem } from "@/types/api/cart";

import { ChooseAdditionalFormValues } from "../../ChooseForms/ChooseAdditionalForm/helpers";
import { SideAddonInfoItem } from "./SideAddonInfoItem";
import { ActiveItem } from "./SideAddonInfoItem/SideAddonInfoItem";
import { MainWrapper, ScrollableWrapper } from "./SideAddonsInfo.styles";

interface SideAddonsInfoProps {
  addons: AddonsMethod[];
  cartItems: CartItem[];
  onActiveItemChange: (data: ActiveItem) => void;
  values: ChooseAdditionalFormValues;
  activeItem: ActiveItem;
  activeSectionRef: RefObject<HTMLFormElement>;
}

const SideAddonsInfo: FC<SideAddonsInfoProps> = ({
  values,
  addons,
  cartItems,
  activeItem,
  onActiveItemChange,
  activeSectionRef,
}) => {
  const { t } = useTranslation("common");
  const [searchTerm, setSearchTerm] = useState("");
  const wrapperRef = useRef(null);

  const handleClickAway = () => {
    handleClearSelection();
  };

  useClickAwayListener([wrapperRef, activeSectionRef], handleClickAway);

  const filteredItems =
    searchTerm && searchTerm !== ""
      ? cartItems.filter((item) =>
          item.shipment_tracking_number
            .toLowerCase()
            .includes(searchTerm.toLowerCase()),
        )
      : cartItems;

  const handleClearSelection = useCallback(() => {
    onActiveItemChange({ value: "all" });
  }, [onActiveItemChange]);

  const listenKeyboard = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape" || event.keyCode === 27)
        handleClearSelection();
    },
    [handleClearSelection],
  );

  const handleSearchTerm = useCallback(
    (value: string) => {
      setSearchTerm(value);
    },
    [setSearchTerm],
  );

  useEffect(() => {
    window.addEventListener("keydown", listenKeyboard, true);

    return () => {
      window.removeEventListener("keydown", listenKeyboard, true);
    };
  }, [listenKeyboard]);

  return (
    <MainWrapper ref={wrapperRef}>
      <SearchInputControlled
        name="trackingNumber"
        initialValue={""}
        value={searchTerm}
        placeholder={t("parcels.search.filterByTrackingNumber")}
        onChange={handleSearchTerm}
      />
      <ScrollableWrapper autoHeight autoHeightMax={isMobile ? 220 : 520}>
        {filteredItems.map((cartItem) => (
          <SideAddonInfoItem
            key={cartItem.id}
            addons={addons}
            itemAddons={values.items[cartItem.id]}
            cartItem={cartItem}
            activeItem={activeItem}
            onActiveItemChange={onActiveItemChange}
          />
        ))}
      </ScrollableWrapper>
    </MainWrapper>
  );
};

export default React.memo(SideAddonsInfo);
