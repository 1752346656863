import { useEffect } from "react";

import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import ServicesAPI from "@/api/services_api";

import { useServicesStore } from "@/store";
import {
  AddonsMethodsResponseDto,
  ConsolidationAddonsMethodsResponseDto,
  DeliveryShippingMethodsResponseDto,
  PackingMethodsResponseDto,
} from "@/types/api/cart";
import { ServicesQueryKey as QueryKey } from "@/types";

export function useDeliveryMethods(
  options?: Omit<
    UseQueryOptions<DeliveryShippingMethodsResponseDto[]>,
    "queryKey" | "queryFn"
  >,
) {
  const { updateDeliveryMethods, updateIsLoadingServices } = useServicesStore();

  const fetchDeliveryMethods = useQuery({
    queryKey: [QueryKey.ServicesDeliveryMethods],
    queryFn: ServicesAPI.getDeliveryMethods,
    ...options,
  });

  const {
    data: DeliveryMethods,
    isLoading: isLoadingDeliveryMethods,
    isError: isErrorDeliveryMethods,
    isSuccess: isSuccessDeliveryMethods,
  } = fetchDeliveryMethods;

  useEffect(() => {
    if (isSuccessDeliveryMethods && DeliveryMethods)
      updateDeliveryMethods(DeliveryMethods);
  }, [isSuccessDeliveryMethods, DeliveryMethods]);

  useEffect(() => {
    if (isErrorDeliveryMethods) updateIsLoadingServices(false);
  }, [isErrorDeliveryMethods]);

  useEffect(() => {
    updateIsLoadingServices(isLoadingDeliveryMethods);
  }, [isLoadingDeliveryMethods]);

  return fetchDeliveryMethods;
}

export function useAllDeliveryMethods(
  options?: Omit<
    UseQueryOptions<DeliveryShippingMethodsResponseDto[]>,
    "queryKey" | "queryFn"
  >,
) {
  const { updateAllDeliveryMethods, updateIsLoadingServices } =
    useServicesStore();
  const fetchAllDeliveryMethods = useQuery({
    queryKey: [QueryKey.ServicesAllDeliveryMethods],
    queryFn: ServicesAPI.getAllDeliveryMethods,
    ...options,
  });

  const {
    data: AllDeliveryMethodsDto,
    isLoading: isLoadingAllDeliveryMethods,
    isSuccess: isSuccessAllDeliveryMethods,
    isError: isErrorAllDeliveryMethods,
  } = fetchAllDeliveryMethods;

  useEffect(() => {
    if (isSuccessAllDeliveryMethods && AllDeliveryMethodsDto)
      updateAllDeliveryMethods(AllDeliveryMethodsDto);
  }, [isSuccessAllDeliveryMethods, AllDeliveryMethodsDto]);

  useEffect(() => {
    updateIsLoadingServices(isLoadingAllDeliveryMethods);
  }, [isLoadingAllDeliveryMethods]);

  useEffect(() => {
    if (isErrorAllDeliveryMethods) updateIsLoadingServices(false);
  }, [isErrorAllDeliveryMethods]);

  return fetchAllDeliveryMethods;
}

export function usePackingMethods(
  options?: Omit<
    UseQueryOptions<PackingMethodsResponseDto[]>,
    "queryKey" | "queryFn"
  >,
) {
  const { updatePackingMethods, updateIsLoadingServices } = useServicesStore();
  const fetchPackingMethods = useQuery({
    queryKey: [QueryKey.ServicesPackingMethods],
    queryFn: ServicesAPI.getPackingMethods,
    ...options,
  });

  const {
    data: PackingMethodsDto,
    isLoading: isLoadingPackingMethods,
    isError: isErrorPackingMethods,
    isSuccess: isSuccessPackingMethods,
  } = fetchPackingMethods;

  useEffect(() => {
    if (isSuccessPackingMethods && PackingMethodsDto)
      updatePackingMethods(PackingMethodsDto);
  }, [isSuccessPackingMethods, PackingMethodsDto]);

  useEffect(() => {
    updateIsLoadingServices(isLoadingPackingMethods);
  }, [isLoadingPackingMethods]);

  useEffect(() => {
    if (isErrorPackingMethods) updateIsLoadingServices(false);
  }, [isErrorPackingMethods]);

  return fetchPackingMethods;
}

export function useConsolidationAddonsMethods(
  options?: Omit<
    UseQueryOptions<ConsolidationAddonsMethodsResponseDto[]>,
    "queryKey" | "queryFn"
  >,
) {
  const { updateConsolidationAddonsMethods, updateIsLoadingServices } =
    useServicesStore();
  const fetchConsolidationAddonsMethods = useQuery({
    queryKey: [QueryKey.ServicesConsolidationAddonsMethods],
    queryFn: ServicesAPI.getConsolidationAddonsMethods,
    ...options,
  });

  const {
    data: ConsolidationAddonsMethodsDto,
    isLoading: isLoadingConsolidationAddonsMethods,
    isError: isErrorConsolidationAddonsMethods,
    isSuccess: isSuccessConsolidationAddonsMethods,
  } = fetchConsolidationAddonsMethods;

  useEffect(() => {
    if (isSuccessConsolidationAddonsMethods && ConsolidationAddonsMethodsDto)
      updateConsolidationAddonsMethods(ConsolidationAddonsMethodsDto);
  }, [isSuccessConsolidationAddonsMethods, ConsolidationAddonsMethodsDto]);

  useEffect(() => {
    if (isErrorConsolidationAddonsMethods) updateIsLoadingServices(false);
  }, [isErrorConsolidationAddonsMethods]);

  useEffect(() => {
    updateIsLoadingServices(isLoadingConsolidationAddonsMethods);
  }, [isLoadingConsolidationAddonsMethods]);

  return fetchConsolidationAddonsMethods;
}

export function useAddonsMethods(
  options?: Omit<
    UseQueryOptions<AddonsMethodsResponseDto[]>,
    "queryKey" | "queryFn"
  >,
) {
  const { updateAddonsMethods, updateIsLoadingServices } = useServicesStore();
  const fetchAddonsMethods = useQuery({
    queryKey: [QueryKey.ServicesAddonsMethods],
    queryFn: ServicesAPI.getAddonsMethods,
    ...options,
  });

  const {
    data: AddonsMethodsDto,
    isLoading: isLoadingAddonsMethods,
    isError: isErrorAddonsMethods,
    isSuccess: isSuccessAddonsMethods,
  } = fetchAddonsMethods;

  useEffect(() => {
    if (isSuccessAddonsMethods && AddonsMethodsDto)
      updateAddonsMethods(AddonsMethodsDto);
  }, [isSuccessAddonsMethods, AddonsMethodsDto]);

  useEffect(() => {
    if (isErrorAddonsMethods) updateIsLoadingServices(false);
  }, [isErrorAddonsMethods]);

  useEffect(() => {
    updateIsLoadingServices(isLoadingAddonsMethods);
  }, [isLoadingAddonsMethods]);

  return fetchAddonsMethods;
}
