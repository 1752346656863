import styled from "styled-components";

export const Address = styled.div`
  padding-bottom: 10px;
`;

export const FullName = styled.h4`
  margin: 6px 0;
`;

export const AddressText = styled.div`
  line-height: 1.5;
  word-break: break-word;

  p {
    margin: 0;
  }
`;
