import styled from "styled-components";

import { Logo } from "@/components/Logo";

export const Logotype = styled(Logo)`
  margin-right: 10px;
  border-radius: initial;
  ${(props) => props.theme.xs`
    margin-right: 5px;
  `}
`;
