import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const PageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div`
  width: 100%;

  ${(props) => props.theme.sm`
    display: none;
  `}
`;

export const ParcelsLink = styled(NavLink)`
  position: relative;
  margin-left: 40px;
  letter-spacing: 0.5px;
  text-decoration: none;
  transition: color 0.2s ease-in;
  color: ${(props) => props.theme.black50};

  &:hover {
    color: rgba(0, 0, 0, 0.25);
  }

  &:nth-last-child(2) {
    margin-right: auto;
  }

  &::before {
    content: "";
    position: absolute;
    transform: scale(0);
    transition: transform cubic-bezier(0, 0, 0.7, 2.5) 0.3s;
    background-color: ${(props) => props.theme.red};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: #fff;
    right: -15px;
    top: -10px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
  }

  &::after {
    content: "";
    height: 2px;
    background-color: ${(props) => props.theme.primary};
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 2px;
    transform: translateY(5px);
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  &.active {
    color: ${(props) => props.theme.primary};

    &::before {
      display: none;
    }

    &::after {
      transform: translateX(0px);
      opacity: 1;
    }
  }
`;
