import styled from "styled-components";

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-right: 10px;

  input {
    max-height: 34px;
    height: 34px;
    width: 100%;
    padding: 8px 30px 8px 10px;
  }

  ${(props) => props.theme.sm`
    margin: 0;
  `}
`;
