import React, { FC, MouseEvent, useCallback, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { getAddonsIconsImage } from "@/containers/Delivery/helpers";
import { Content } from "@/containers/RightPanel/RightPanel.styles";
import { Button } from "@/components/Button";
import { Icon, IconPng } from "@/components/Icon";
import { PackagesList } from "@/components/PackagesList";
import { ServiceLogo } from "@/components/ServiceLogo";
import { Tooltip } from "@/components/Tooltip";

import {
  ExcessMarker,
  HeaderTitle,
  InnerSection,
  Section,
  SectionContent,
  SectionHeader,
} from "@/styles/outgoing";
import { ChangeButton } from "@/styles/parcels";

import {
  toastResponseError,
  toastResponseSuccess,
} from "@/utils/responseMessageHelper";
import {
  useCartImportCustomInformation,
  useDeleteCart,
} from "@/hooks/react-query/cart";
import {
  useShipment,
  useStorageUpdateComment,
  useStorageUpdateItemComment,
} from "@/hooks/react-query/storage";
import { useProgressDelivery } from "@/hooks";

import useRightPanelStore, {
  NotFinishedPanelPanelData,
  RightPanelType,
} from "@/store/useRightPanelStore";
import {
  useAuthStore,
  useCartStore,
  useServicesStore,
  useStorageStore,
} from "@/store";
import { ButtonColor, ButtonVariant, IconType } from "@/enums";
import { CartItem } from "@/types/api/cart";
import { Item } from "@/types/api/history";

import {
  CustomSenderErrorMsg,
  Footer,
  PanelAddressItem,
  TabMain,
} from "../NotFinishedPanel.styles";
import { indexToTitleMapping } from "./helpers";

interface DetailsPanelProps {
  panelData: NotFinishedPanelPanelData;
  onActiveTabChange: (tab: string) => void;
}

const DetailsPanel: FC<DetailsPanelProps> = React.memo(
  ({ panelData, onActiveTabChange }) => {
    const { t } = useTranslation("common");
    const { getAuthSelectFfid } = useAuthStore();
    const { closeRightPanel, openRightPanel } = useRightPanelStore();
    const { packingMethods, consolidationAddonsMethods } = useServicesStore();
    const { mutateAsync: deleteCart } = useDeleteCart();
    const { mutateAsync: updateComment } = useStorageUpdateComment();
    const { mutateAsync: updateItemComment } = useStorageUpdateItemComment();
    const { mutateAsync: importCustomInformation } =
      useCartImportCustomInformation();
    const userFfid = getAuthSelectFfid();
    const [_, setAccess] = useProgressDelivery(userFfid);
    const { cart } = useCartStore();

    const sku = cart?.sku;
    const items = cart?.items;
    const destinationAddress = cart?.destination_address;
    const destAddressId = cart?.destination_address_id;
    const showDestAddressSection =
      (destinationAddress && destinationAddress.id) || destAddressId;
    const delivery = cart?.preferred_carrier;
    const packingCode = cart?.optional_line_item_codes?.[0];

    const disableContinueToCustomsData =
      !showDestAddressSection || !delivery || !packingCode;

    const isExcessItems = false;
    const itemsCount = (!!items && items.length) || 0;
    const localizedTitle = isExcessItems
      ? t("parcels.excessPackagesIncluded")
      : t("parcels.shipments");

    const packing = packingMethods.find(
      (packingMethod) => packingMethod.code === packingCode,
    );
    const packingTitle = packing?.title ?? "";

    const handleGetShipment = useCallback(
      (id: number) => {
        useShipment(id);
      },
      [useShipment],
    );

    const clickChangingAddress = useCallback(() => {
      openRightPanel(RightPanelType.CHANGING_ADDRESS, {
        ...panelData,
        returnPreviousPanel: RightPanelType.NOT_FINISHED,
        addressId: destAddressId,
        isCartVariant: true,
        cart,
        setAccess: () => setAccess("delivery"),
      });
    }, [cart, openRightPanel, panelData, setAccess]);

    const editDeliveryMethod = useCallback(
      () =>
        openRightPanel(RightPanelType.DELIVERY_METHODS, {
          ...panelData,
          detailedItemID: cart?.id,
          returnPreviousPanel: RightPanelType.NOT_FINISHED,
          deliveryCountry: destinationAddress?.country,
          deliveryMethod: delivery,
          isCartVariant: true,
          cart,
          setAccess: () => setAccess("packing"),
        }),
      [
        openRightPanel,
        panelData,
        cart,
        destinationAddress,
        delivery,
        setAccess,
      ],
    );

    const editPackingGrade = useCallback(
      () =>
        openRightPanel(RightPanelType.PACKING_GRADE, {
          ...panelData,
          returnPreviousPanel: RightPanelType.NOT_FINISHED,
          consolidationSku: sku,
          deliveryMethod: delivery,
          packingCode,
          packingMethods,
          selectedConsolidationAddons: cart?.addons_codes,
          isCartVariant: true,
          cart,
          setAccess: (selectedAddonsIds: string[]) => {
            if (selectedAddonsIds && selectedAddonsIds.length) {
              setAccess("customInformation");
            } else {
              setAccess("addons");
            }
          },
        }),
      [
        openRightPanel,
        panelData,
        sku,
        delivery,
        packingCode,
        packingMethods,
        cart,
        setAccess,
      ],
    );

    const clickPackagesItem = (item: CartItem | Item) => {
      openRightPanel(RightPanelType.STORAGE_ITEM, {
        readOnly: true,
        overrideAndEnablePackOptions: true,
        originalItem: item,
        id: item.shipment_id,
        detailedItemID: cart?.id,
        consolidationSku: sku,
        emblem_thumb_url: item.emblem_thumb_url,
        description: item.shipment_tracking_number,
        returnPreviousPanel: RightPanelType.NOT_FINISHED,
        excessItems: panelData.excessItems,
        rootReturnPanel: RightPanelType.NOT_FINISHED,
        getShipment: handleGetShipment,
        updateComment,
        updateItemComment,
        parcel: cart,
        isCartVariant: true,
        isItemInCart: true,
      });
    };

    const handleCancel = useCallback(
      (event: MouseEvent) => {
        event.stopPropagation();
        if (
          window.confirm(t("popup.notFinished.deletionConfirmation")) === true
        ) {
          deleteCart()
            .then(() => {
              closeRightPanel();
              toastResponseSuccess(t("common.deleteCartSuccess"));
            })
            .catch(toastResponseError);
        }
      },
      [deleteCart, closeRightPanel, panelData, t],
    );

    const detailsHandleContinue = () => {
      onActiveTabChange(t(indexToTitleMapping[1]));
      importCustomInformation();
    };

    return (
      <TabMain>
        <Content>
          <Section>
            <SectionHeader>
              <HeaderTitle>
                <Icon type={IconType.Address} /> {t("parcels.shippingAddress")}:
              </HeaderTitle>
              <ChangeButton
                color={ButtonColor.Primary}
                onClick={clickChangingAddress}
              >
                {t("common.change")}
              </ChangeButton>
            </SectionHeader>
            {showDestAddressSection && destinationAddress && (
              <PanelAddressItem address={destinationAddress} isHideButtons />
            )}
          </Section>
          <Section>
            <SectionHeader>
              <HeaderTitle>
                <IconPng type="turbine" /> {t("parcels.shippingMethod")}:
              </HeaderTitle>
              <ChangeButton
                color={ButtonColor.Primary}
                onClick={editDeliveryMethod}
                disabled={!showDestAddressSection}
              >
                {t("common.change")}
              </ChangeButton>
            </SectionHeader>
            {delivery && (
              <InnerSection>
                <SectionContent>
                  <ServiceLogo serviceKey={delivery} />
                  {delivery}
                </SectionContent>
              </InnerSection>
            )}
          </Section>
          <Section>
            <SectionHeader>
              <HeaderTitle>
                <IconPng type="tape" /> {t("parcels.packingGrade")}:
              </HeaderTitle>
              <ChangeButton
                color={ButtonColor.Primary}
                onClick={editPackingGrade}
                disabled={!showDestAddressSection || !delivery}
              >
                {t("common.change")}
              </ChangeButton>
            </SectionHeader>
            {packingCode && (
              <InnerSection>
                <SectionContent>
                  <ServiceLogo serviceKey={packingCode} />
                  {packingTitle}
                  {getAddonsIconsImage(
                    cart?.consolidation_addons,
                    consolidationAddonsMethods,
                  )}
                </SectionContent>
              </InnerSection>
            )}
          </Section>
          <Section>
            <SectionHeader>
              <HeaderTitle>
                <Icon type={IconType.Package} />
                {`${localizedTitle} (${itemsCount}):`}
                {isExcessItems ? <ExcessMarker /> : null}
              </HeaderTitle>
            </SectionHeader>
            <InnerSection>
              <SectionContent>
                <PackagesList
                  extended
                  items={items}
                  foundItemId={panelData.foundPackageId ?? 0}
                  handleClick={clickPackagesItem}
                />
              </SectionContent>
            </InnerSection>
          </Section>
        </Content>
        <Footer>
          <Button color={ButtonColor.Red} onClick={handleCancel}>
            {t("common.cancelAndDelete")}
          </Button>
          {disableContinueToCustomsData ? (
            <Tooltip
              body={
                <CustomSenderErrorMsg>
                  {t("parcels.notFinishedShowRequiredFieldsError")}
                </CustomSenderErrorMsg>
              }
            >
              <Button
                color={ButtonColor.Primary}
                variant={ButtonVariant.Filled}
                disabled
              >
                {t("common.continue")}
              </Button>
            </Tooltip>
          ) : (
            <Button
              color={ButtonColor.Primary}
              variant={ButtonVariant.Filled}
              onClick={detailsHandleContinue}
            >
              {t("common.continue")}
            </Button>
          )}
        </Footer>
      </TabMain>
    );
  },
);

export default DetailsPanel;
