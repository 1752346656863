import {
  DashboardResponseDto,
  FaqKBHelpersDto,
  KnowledgeDto,
} from "types/api/dashboard";

import axiosInstance from "./axios_instance";

const DashboardAPI = {
  getDashboard: async (): Promise<DashboardResponseDto> => {
    const res = await axiosInstance.get("/dashboard");
    return res.data;
  },
  getFAQ: async ({
    currentLocale,
  }: KnowledgeDto): Promise<FaqKBHelpersDto[]> => {
    const res = await axiosInstance.get(
      `/kb/${currentLocale.toLowerCase()}/faq?locale=${currentLocale.toLowerCase()}`,
    );

    return res.data;
  },
  getKnowledgeBaseHelpers: async ({
    currentLocale,
  }: KnowledgeDto): Promise<FaqKBHelpersDto[]> => {
    const res = await axiosInstance.get(
      `/kb/${currentLocale.toLowerCase()}/helpers?locale=${currentLocale.toLowerCase()}`,
    );

    return res.data;
  },
};

export default DashboardAPI;
