import { ChangeEvent, FC, memo, useCallback, useEffect, useState } from "react";

import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";

import { Input } from "@/components/Input";

import { StorageSearchValues } from "@/types/StorageForms/Search";

import { SearchFilter } from "../";
import { TooltipContent } from "../Search.styles";
import { TitleWrapper } from "./Filter.styles";

const FilterLocation: FC<FormikProps<StorageSearchValues>> = ({
  initialValues,
  setFieldValue,
  values,
  handleSubmit,
  ...otherProps
}) => {
  const { t } = useTranslation("common");
  const value = values.location;

  const [innerValue, setInnerValue] = useState(value);

  const initialValue = initialValues.location;
  const isLocationChanged = !value;
  const locationTitle = isLocationChanged
    ? t("parcels.search.location")
    : value;

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const dropInnerValue = useCallback(() => setInnerValue(value), [value]);

  const applyInnerValue = useCallback(
    () => setFieldValue("location", innerValue),
    [innerValue, setFieldValue],
  );

  const handleInnerChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInnerValue(event.target.value);
  };

  const setDefault = useCallback(() => {
    setFieldValue("location", initialValue);
    dropInnerValue();
  }, [dropInnerValue, initialValue, setFieldValue]);

  return (
    <SearchFilter
      applyInnerValue={applyInnerValue}
      dropInnerValue={dropInnerValue}
      handleSubmit={handleSubmit}
      setDefault={setDefault}
      title={<TitleWrapper>{locationTitle}</TitleWrapper>}
      innerTitle={t("parcels.search.selectLocation")}
      isActive={!isLocationChanged}
      {...otherProps}
    >
      <TooltipContent>
        <Input
          value={innerValue}
          onChange={handleInnerChange}
          placeholder={`${t("parcels.search.location")}:`}
        />
      </TooltipContent>
    </SearchFilter>
  );
};

export default memo(FilterLocation);
