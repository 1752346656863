import React, { FC, useCallback, useEffect } from "react";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/Button";
import { Checkbox } from "@/components/Checkbox";
import { Input } from "@/components/Input";
import { Link } from "@/components/Link";
import { Select } from "@/components/Select";

import { Fields, FieldWrapper, Form, TermsWrapper } from "@/styles/form";

import { useSignIn, useSignUp } from "@/hooks/react-query/userAuth";

import { useAuthStore } from "@/store";
import { ButtonColor, ButtonType, ButtonVariant } from "@/enums";
import { SignUpFormProps, SignUpFormValues } from "@/types/signUpForm";

import {
  getCountriesOptions,
  getLocalesOptions,
  signUpFormik,
} from "../helpers";
import PasswordValidation from "./PasswordValidation";
import {
  FieldWrapperTop,
  LeftPasswordSection,
  PasswordValidationWrapper,
} from "./SignUpComponents.styles";

const SignUpForm: FC<SignUpFormProps> = React.memo(({ currentLocale }) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const redirect = () => navigate("/");
  const {
    mutateAsync: handleSignIn,
    data: signInRes,
    isError: isErrorSignIn,
    isPending: isPendingSignIn,
  } = useSignIn();
  const {
    mutateAsync: handleSignUp,
    data: signUpRes,
    isSuccess: isSuccessSignUp,
    isError: isErrorSignUp,
    isPending: isPendingSignUp,
  } = useSignUp();

  const { updateUserAuth, updateIsLoadingAuth, resetAuthStore } =
    useAuthStore();

  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    submitCount,
  } = useFormik<SignUpFormValues>(
    signUpFormik(t, handleSignUp, handleSignIn, redirect),
  );

  useEffect(() => {
    if (isSuccessSignUp && signUpRes?.status === "Success" && signInRes?.data) {
      updateUserAuth(signInRes?.data);
    }
  }, [isSuccessSignUp, signUpRes]);

  useEffect(() => {
    if (isErrorSignIn || isErrorSignUp) {
      resetAuthStore();
    }
  }, [isErrorSignIn, isErrorSignUp]);

  useEffect(() => {
    updateIsLoadingAuth(isPendingSignIn || isPendingSignUp);
  }, [isPendingSignIn, isPendingSignUp]);

  const handleCountryChange = useCallback(
    (value: string) => setFieldValue("country", value),
    [setFieldValue],
  );
  const handleLocaleChange = useCallback(
    (value: string) => setFieldValue("locale", value),
    [setFieldValue],
  );
  const localesOption = getLocalesOptions(t);
  const showErrors = submitCount > 0;

  const showErrorForFieldValue = (key: keyof SignUpFormValues) => {
    const fieldError = errors[key];
    const fieldValue = values[key];
    return (
      !!fieldError &&
      (showErrors ||
        (fieldValue !== undefined && fieldValue !== null && fieldValue !== ""))
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Fields>
        <FieldWrapper>
          <Input
            autoFocus
            name="firstName"
            placeholder={t("auth.firstName")}
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            tooltip={errors.firstName}
            invalid={showErrorForFieldValue("firstName")}
          />
          <Input
            name="lastName"
            placeholder={t("auth.lastName")}
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            tooltip={errors.lastName}
            invalid={showErrorForFieldValue("lastName")}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Input
            name="email"
            placeholder={t("auth.email")}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            tooltip={errors.email}
            invalid={showErrorForFieldValue("email")}
          />
          <Input
            name="signupToken"
            placeholder={t("auth.signupToken")}
            value={values.signupToken}
            onChange={handleChange}
            onBlur={handleBlur}
            tooltip={errors.signupToken}
            invalid={showErrorForFieldValue("signupToken")}
          />
        </FieldWrapper>
        <FieldWrapperTop>
          <LeftPasswordSection>
            <Input
              type="password"
              name="password"
              placeholder={t("auth.password")}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              tooltip={errors.password}
              invalid={showErrorForFieldValue("password")}
            />
            <Input
              type="password"
              name="rePassword"
              placeholder={t("auth.rePassword")}
              value={values.rePassword}
              onChange={handleChange}
              onBlur={handleBlur}
              tooltip={errors.rePassword}
              invalid={showErrorForFieldValue("rePassword")}
            />
          </LeftPasswordSection>
          <PasswordValidationWrapper>
            <PasswordValidation value={values.password} />
          </PasswordValidationWrapper>
        </FieldWrapperTop>
        <FieldWrapper>
          <Select
            options={getCountriesOptions(currentLocale)}
            name="country"
            value={values.country.value ? values.country : null}
            onChange={handleCountryChange}
            onBlur={handleBlur}
            placeholder={t("auth.country")}
            invalid={showErrorForFieldValue("country")}
          />
          <Select
            options={localesOption}
            name="locale"
            value={values.locale}
            onChange={handleLocaleChange}
            onBlur={handleBlur}
            placeholder={t("auth.locale")}
          />
        </FieldWrapper>
        <TermsWrapper>
          <Checkbox
            label={
              <span>
                {t("auth.iAgreeTo")}
                <Link to={"/terms"}>{t("auth.termsAndConditions")}</Link>
              </span>
            }
            name="terms"
            checked={values.terms}
            onChange={handleChange}
            onBlur={handleBlur}
            invalid={!!errors.terms && !!showErrors}
          />
        </TermsWrapper>
      </Fields>
      <Button
        isLoading={isSubmitting}
        disabled={isSubmitting}
        color={ButtonColor.Primary}
        type={ButtonType.Submit}
        variant={ButtonVariant.Filled}
      >
        {t("auth.signUp")}
      </Button>
    </Form>
  );
});

export default SignUpForm;
