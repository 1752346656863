import React, { FC } from "react";

import { getAddonsIconsImage } from "@/containers/Delivery/helpers";

import packageIcon from "@/icons/package.svg";

import { requestNames } from "@/store/useServicesRequestStore";
import { AddonsMethod } from "@/store/useServicesStore";
import { IconType } from "@/enums";
import { CartItem } from "@/types/api/cart";

import {
  HeadTextCompact,
  ItemCompact,
  ItemIcon,
  ItemLogo,
  ListItemCompact,
  ServiceRequestIcon,
} from "../SideAddonsInfo.styles";

export interface ActiveItem {
  value: string | number;
  label?: string;
  image?: string;
  description?: string;
  serviceRequests?: any[];
}

interface SideAddonItemProps {
  addons: AddonsMethod[];
  cartItem: CartItem;
  activeItem: ActiveItem;
  onActiveItemChange: (data: ActiveItem) => void;
  itemAddons: (string | number)[];
}

const SideAddonInfoItem: FC<SideAddonItemProps> = ({
  itemAddons,
  addons,
  cartItem,
  activeItem,
  onActiveItemChange,
}) => {
  const filteredItemAddons = itemAddons.filter((x) =>
    addons.some((a) => a.id === x),
  );

  const logoUrl = cartItem.emblem_thumb_url || packageIcon;
  const serviceRequests = cartItem.service_requests || [];
  const isSelected = activeItem.value === cartItem.id;

  let mainDescription = cartItem.description;
  if (!mainDescription) {
    mainDescription = cartItem.shipment_tracking_number;
  }

  const handleCartItemClick = () => {
    const nextSelected = isSelected
      ? { value: "all" }
      : {
          value: cartItem.id,
          label: cartItem.shipment_tracking_number,
          image: cartItem.emblem_thumb_url,
          description: cartItem.customer_comment,
          serviceRequests: cartItem.service_requests,
        };
    onActiveItemChange(nextSelected);
  };

  return (
    <ItemCompact onClick={handleCartItemClick} isSelected={isSelected}>
      <ItemLogo image={logoUrl} size={20} />
      <HeadTextCompact>{mainDescription}</HeadTextCompact>
      {serviceRequests.map((request: any) => {
        const requestType =
          requestNames[
            request.service_request_type.type.toLowerCase() as keyof typeof requestNames
          ];

        return (
          <ListItemCompact key={request.id}>
            <ServiceRequestIcon type={requestType as IconType} />
          </ListItemCompact>
        );
      })}
      {filteredItemAddons.map((id: string | number) => (
        <ListItemCompact key={id}>
          <ItemIcon>{getAddonsIconsImage(id, addons, 22)}</ItemIcon>
        </ListItemCompact>
      ))}
    </ItemCompact>
  );
};

export default React.memo(SideAddonInfoItem);
