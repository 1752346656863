import { ProgressDeliveryAccess } from "@/hooks";

export const getLinkToStep = (access: ProgressDeliveryAccess) => {
  if (access.summary) return "/shipping/summary";
  else if (access.customInformation) return "/shipping/flow/customs-data";
  else if (access.addons) return "/shipping/flow/delivery/additional";
  else if (access.packing) return "/shipping/flow/delivery/packing";
  else if (access.delivery) return "/shipping/flow/delivery";
  else return "/shipping/flow";
};
