import styled from "styled-components";

import { AddressItem } from "@/components/Addresses/components";

import { ParcelCol } from "@/styles/parcels";

export const OutgoingCol = styled(ParcelCol)`
  ${(props) => props.theme.xs`
    &:nth-child(3), &:nth-child(5) {
      justify-content: flex-end;

      button:not(:first-child) {
        margin-left: 15px;
      }
    }

    &:first-child {
      word-break: normal;
    }
  `}
`;

export const Step = styled.div`
  color: ${(props) => props.theme.secondary};
  border: 1px solid ${(props) => props.theme.secondary};
  border-radius: 50%;
  min-width: 22px;
  min-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PanelAddressItem = styled(AddressItem)`
  width: 100%;
  padding: 8px 15px 0 25px;
  animation: none;
  border-bottom: none;

  p {
    white-space: pre;
  }
`;

export const Footer = styled.div`
  margin: 20px auto;

  ${(props) => props.theme.xs`
    button {
      padding: 9px 18px;
    }
  `}
`;
