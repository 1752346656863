import { create } from "zustand";

import { DeliveryShippingMethodsResponseDto } from "@/types/api/cart";
import { CustomsDeclaration } from "@/types/api/common";
import { Package } from "@/types/api/history";
import {
  OutgoingItem,
  OutgoingResponseDto,
  OutgoingShipmentResponseDto,
  OutgoingType,
} from "@/types/api/outgoing";

export interface OutgoingTypeDto {
  page?: number;
  type: OutgoingType;
  total?: number;
  totalPrice?: number;
  items: OutgoingResponseDto[];
  isLoading?: boolean;
}

export interface DetailedShipment {
  [key: number | string]: Partial<
    OutgoingShipmentResponseDto & {
      customer_comment: string;
      description?: string;
    }
  >;
}

export type DetailedItem = Partial<
  OutgoingResponseDto & {
    customs_declarations?: Partial<CustomsDeclaration>[];
    items: OutgoingItem[];
    optional_line_item_codes?: string[];
    packages?: Package[];
    country?: string;
    destination_address_id?: number;
    consolidations_addons?: string[];
    address_1?: string;
    first_name?: string;
    last_name?: string;
  }
>;
export interface DetailedItems {
  [key: number]: DetailedItem;
}

interface UseOutgoingStore {
  isLoadingDetailedItem: boolean;
  isLoadingDetailedShipment: boolean;
  isLoadingCustomsDeclaration: boolean;
  isLoadingUpdateInsurance: boolean;
  isLoadingOutgoing: {
    waiting_for_payment: boolean;
    waiting_for_information: boolean;
    packing_in_progress: boolean;
  };
  outgoingTypes: {
    waiting_for_payment: OutgoingTypeDto;
    waiting_for_information: OutgoingTypeDto;
    packing_in_progress: OutgoingTypeDto;
    paid: OutgoingTypeDto;
  };
  detailedItem: DetailedItems;
  detailedShipment: DetailedShipment;
  deliveryMethods: DeliveryShippingMethodsResponseDto[];
  tempDetailedItemId: number | null;
  updateOutgoingTypes: (
    type: OutgoingType,
    payload: Partial<OutgoingTypeDto>,
  ) => void;
  updateOutgoingTempDetailedItemId: (id: number | null) => void;
  updateIsLoadingOutgoing: (type: OutgoingType, bool: boolean) => void;
  updateIsLoadingDetailedShipment: (bool: boolean) => void;
  updateIsLoadingDetailedItem: (bool: boolean) => void;
  updateIsLoadingCustomsDeclarations: (bool: boolean) => void;
  updateIsLoadingUpdateInsurance: (bool: boolean) => void;
  updateDetailedItem: (item: DetailedItem) => void;
  updateDetailedShipment: (shipment: DetailedShipment) => void;
  movePackageFromWaitingPaymentToPaid: (itemId: number) => void;
  updateDeliveryMethods: (
    deliveryMethods: DeliveryShippingMethodsResponseDto[],
  ) => void;
  getOutgoingSelectLoading: () => boolean;
  removeItem: (itemId: number) => void;
  resetOutgoingStore: () => void;
}

const initialState = {
  isLoadingDetailedItem: false,
  isLoadingDetailedShipment: false,
  isLoadingCustomsDeclaration: false,
  isLoadingUpdateInsurance: false,
  isLoadingOutgoing: {
    waiting_for_payment: false,
    waiting_for_information: false,
    packing_in_progress: false,
  },
  outgoingTypes: {
    waiting_for_payment: { type: OutgoingType.WaitingForPayment, items: [] },
    waiting_for_information: {
      type: OutgoingType.WaitingForInformation,
      items: [],
    },
    packing_in_progress: { type: OutgoingType.PackingInProgress, items: [] },
    paid: { type: OutgoingType.Paid, items: [] },
  },
  detailedItem: {},
  detailedShipment: {},
  deliveryMethods: [],
  tempDetailedItemId: null,
};

const useOutgoingStore = create<UseOutgoingStore>((set, get) => ({
  ...initialState,
  updateIsLoadingOutgoing: (type, bool) => {
    const { isLoadingOutgoing } = get();

    return set(() => ({
      isLoadingOutgoing: {
        ...isLoadingOutgoing,
        [type]: bool,
      },
    }));
  },
  getOutgoingSelectLoading: () => {
    const { isLoadingOutgoing } = get();

    return !!Object.values(isLoadingOutgoing).find(
      (typeLoading) => !!typeLoading,
    );
  },
  updateOutgoingTypes: (type, payload) => {
    const { outgoingTypes } = get();

    return set(() => ({
      outgoingTypes: {
        ...outgoingTypes,
        [type]: { ...outgoingTypes[type], ...payload },
      },
    }));
  },
  updateDeliveryMethods: (deliveryMethods) =>
    set(() => ({
      deliveryMethods,
    })),
  updateIsLoadingDetailedShipment: (bool) =>
    set(() => ({
      isLoadingDetailedShipment: bool,
    })),
  updateIsLoadingUpdateInsurance: (bool) =>
    set(() => ({
      isLoadingUpdateInsurance: bool,
    })),
  updateIsLoadingDetailedItem: (bool) =>
    set(() => ({
      isLoadingDetailedItem: bool,
    })),
  updateIsLoadingCustomsDeclarations: (bool) =>
    set(() => ({
      isLoadingCustomsDeclaration: bool,
    })),
  updateDetailedItem: (item) =>
    set(() => ({
      detailedItem: item,
    })),
  updateOutgoingTempDetailedItemId: (id) =>
    set(() => ({
      tempDetailedItemId: id,
    })),

  updateDetailedShipment: (item) =>
    set(() => ({
      detailedShipment: item,
    })),

  movePackageFromWaitingPaymentToPaid: (itemId) => {
    const {
      updateOutgoingTypes,
      outgoingTypes,
      updateDetailedItem,
      detailedItem,
    } = get();
    const items = outgoingTypes.waiting_for_payment.items;
    const itemToPaid = items.find((item) => item.id === itemId);

    if (!itemToPaid) return;

    const responseItems = outgoingTypes.waiting_for_payment.items ?? [];
    const filteredItems = responseItems.filter(
      (item: OutgoingResponseDto) => item.id !== itemId,
    );

    if (responseItems.length !== filteredItems.length) {
      updateOutgoingTypes(OutgoingType.WaitingForPayment, {
        items: filteredItems,
        total: filteredItems.length,
        totalPrice:
          outgoingTypes.waiting_for_payment.totalPrice ??
          0 - itemToPaid.estimate.total,
      });
    }

    updateOutgoingTypes(OutgoingType.Paid, {
      items: [...outgoingTypes.paid.items, itemToPaid],
      total: outgoingTypes.paid.items.length + 1,
    });

    delete detailedItem[itemId];
    updateDetailedItem(detailedItem);
  },

  removeItem: (itemId) => {
    const { updateOutgoingTypes, outgoingTypes } = get();

    updateOutgoingTypes(OutgoingType.WaitingForPayment, {
      items: outgoingTypes.waiting_for_payment.items.filter(
        (item) => item.id !== itemId,
      ),
    });
    updateOutgoingTypes(OutgoingType.WaitingForInformation, {
      items: outgoingTypes.waiting_for_information.items.filter(
        (item) => item.id !== itemId,
      ),
    });
    updateOutgoingTypes(OutgoingType.PackingInProgress, {
      items: outgoingTypes.packing_in_progress.items.filter(
        (item) => item.id !== itemId,
      ),
    });
    updateOutgoingTypes(OutgoingType.Paid, {
      items: outgoingTypes.paid.items.filter((item) => item.id !== itemId),
    });
  },
  resetOutgoingStore: () =>
    set(() => ({
      ...initialState,
    })),
}));

export default useOutgoingStore;
