import { FC } from "react";

import { fadeIn } from "react-animations";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

import { ServicesLogo } from "@/containers/Delivery/components/ServicesLogo";
import { AddressItem } from "@/components/Addresses/components";
import CustomsDeclarationV2 from "@/components/CustomDeclaration/CustomsDeclarationV2";

export const TabMain = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;

export const CustomSenderErrorMsg = styled.div`
  color: ${({ theme }) => theme.red};
  font-size: 14px;
`;

export const SideDescription = styled.div`
  display: flex;
  align-items: center;
`;

interface LogoProps {
  logoUrl?: string;
}

const LogoComponent: FC<LogoProps> = (props) => (
  <ServicesLogo size={20} {...props} />
);

export const Logo = styled(LogoComponent)`
  margin-right: 5px;
`;

export const SideParagraph = styled.p`
  margin: 0;
  margin-left: 3px;
  animation: 0.8s ${keyframes`${fadeIn}`};
`;

export const ChangeLink = styled(Link)`
  color: ${({ theme }) => theme.primary};
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: ${({ theme }) => theme.primary05};
  }
`;

export const ChangeTab = styled.a`
  color: ${({ theme }) => theme.primary};
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: ${({ theme }) => theme.primary05};
  }
`;

export const CustomsDeclarationSummary = styled.div`
  display: flex;
  padding: 0;
  gap: 8px;
`;

export const TotalCustomsPrice = styled.strong`
  color: ${({ theme }) => theme.red};
`;

export const StatValue = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 20px;
`;

export const PanelAddressItem = styled(AddressItem)`
  width: 100%;
  padding: 8px 15px 0 25px;
  animation: none;
  border-bottom: none;

  p {
    white-space: pre;
  }
`;

export const Title = styled.div`
  width: 100%;
  font-size: 16px;
  word-break: break-word;
`;

export const Footer = styled.div`
  display: flex;
  margin: 20px auto;

  ${(props) => props.theme.xs`
    button {
      padding: 9px 18px;
    }
  `}
`;

export const DeclarationTotal = styled.div`
  padding: 5px;
  line-height: 30px;
  font-size: 16px;
  font-weight: bold;
`;

export const StyledCustomsDeclarationV2 = styled(CustomsDeclarationV2)`
  height: calc(100% - 40px);
  & > table {
    height: 100%;
  }
`;

export const SummaryForm = styled.form`
  height: 100%;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PartnerInputWrapper = styled.div`
  margin-top: 10px;
`;
