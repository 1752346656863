import React, { FC } from "react";

import { useFormik } from "formik";
import { Collapse } from "react-collapse";
import { Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "@/components/Button";
import { Input } from "@/components/Input";

import formatter from "@/utils/formatter";
import { toastResponseError } from "@/utils/responseMessageHelper";
import { useConsolidateCart, useSetPartnerId } from "@/hooks/react-query/cart";

import { useAuthStore, useCartStore } from "@/store";
import { ButtonColor, ButtonSize, ButtonType, ButtonVariant } from "@/enums";

import {
  Content,
  Heading,
  SideItem,
  SideItemHead,
  SubmitWrapper,
} from "../../styles";
import { TotalPackingCost } from "../TotalPackingCost";
import {
  BlockContent,
  ChangeLink,
  CustomsDeclarationSummary,
  HeadingWrapper,
  PartnerInputWrapper,
  StatValue,
  StyledForm,
  SummarySecond,
  TotalCustomsPrice,
  TwoColumnsLayout,
  Wrapper,
} from "./Summary.styles";
import { Summary } from "./SummaryForm.styles";

const SummaryForm: FC = React.memo(() => {
  const {
    userAuth: { extended_tools: allowSearchByLocation },
  } = useAuthStore();
  const { resetCartStore: dropCart, cart } = useCartStore();
  const { mutateAsync: setPartnerId } = useSetPartnerId();
  const { mutateAsync: consolidateCart } = useConsolidateCart();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const { values, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues: { partnerId: "" },
    onSubmit: (values, { setSubmitting }) => {
      const { partnerId } = values;

      const needToSendPartnerId =
        allowSearchByLocation && partnerId && partnerId.length > 0;
      const setPartnerIdIfNeed = needToSendPartnerId
        ? setPartnerId(partnerId)
        : null;

      Promise.all([setPartnerIdIfNeed, consolidateCart()])
        .then(() => {
          dropCart();
          navigate("/parcels/outgoing");
        })
        .catch(toastResponseError)
        .finally(() => setSubmitting(false));
    },
  });

  const renderSummary = () => {
    return (
      <>
        {cart?.sku}&nbsp;{t("shipping.summary")}:
      </>
    );
  };

  const renderPartnerId = () => {
    return (
      <HeadingWrapper>
        <PartnerInputWrapper>
          <Input
            name="partnerId"
            placeholder={"Partner ID"}
            value={values.partnerId}
            onChange={handleChange}
          />
        </PartnerInputWrapper>
        {renderSummary()}
      </HeadingWrapper>
    );
  };

  const customInformation = cart?.customs_informations ?? [];

  const SummaryCustomsDeclaration = () => {
    const totalQuantity = customInformation.reduce(
      (total: number, customsRow: any) => total + customsRow.quantity,
      0,
    );
    const totalValue = customInformation.reduce(
      (total: number, customsRow: any) => total + customsRow.value,
      0,
    );

    return (
      <CustomsDeclarationSummary>
        <StatValue>
          <strong>{customInformation.length}&nbsp;</strong>
          <span>
            {customInformation.length === 1
              ? t("shipping.singularCustomsSummary")
              : t("shipping.pluralCustomsSummary")}
          </span>
        </StatValue>
        <StatValue>
          <span>{t("shipping.quantityCustomsSummary")}</span>
          <strong>&nbsp;{totalQuantity}</strong>
        </StatValue>
        <TotalCustomsPrice>{`$${formatter.currency(
          totalValue,
        )}`}</TotalCustomsPrice>
      </CustomsDeclarationSummary>
    );
  };

  return (
    <Col lg={4} md={6} sm={12} offset={{ md: 3, lg: 4, sm: 12 }}>
      <Content>
        <Wrapper>
          <StyledForm onSubmit={handleSubmit}>
            <Heading>
              {allowSearchByLocation ? renderPartnerId() : renderSummary()}
            </Heading>
            <TwoColumnsLayout>
              <BlockContent>
                <Summary />
              </BlockContent>
              <BlockContent>
                <SummarySecond>
                  <Collapse isOpened>
                    <SideItem>
                      <SideItemHead>
                        {t("parcels.customsDeclarations")}:
                        <ChangeLink
                          to={"/shipping/flow/customs-data"}
                          state={{ prevPath: pathname }}
                        >
                          {t("common.change")}
                        </ChangeLink>
                      </SideItemHead>
                      <SummaryCustomsDeclaration />
                    </SideItem>
                    <TotalPackingCost />
                  </Collapse>
                  <SubmitWrapper>
                    <Button
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                      type={ButtonType.Submit}
                      size={ButtonSize.Large}
                      color={ButtonColor.Primary}
                      variant={ButtonVariant.Bordered}
                    >
                      {t("shipping.payAfterPacking")}
                    </Button>
                  </SubmitWrapper>
                </SummarySecond>
              </BlockContent>
            </TwoColumnsLayout>
          </StyledForm>
        </Wrapper>
      </Content>
    </Col>
  );
});

export default SummaryForm;
