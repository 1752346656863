import { SideDeliveryInfo } from "./components";
import { CancelConfirmation } from "./components/CancelConfirmation";
import { CheckCustomsData } from "./components/CheckCustomsData";
import {
  ChooseAddressForm,
  ChooseDeliveryForm,
  ChoosePackingForm,
} from "./components/ChooseForms";
import { ChooseAdditionalForm } from "./components/ChooseForms/ChooseAdditionalForm";
import { SummaryForm } from "./components/Summary";

export const deliveryRoutes = [
  {
    path: "flow",
    DeliveryComponent: ChooseAddressForm,
  },
  {
    path: "flow/delivery",
    key: "delivery",
    DeliveryComponent: ChooseDeliveryForm,
    redirect: "shipping/flow",
  },
  {
    path: "flow/delivery/packing",
    DeliveryComponent: ChoosePackingForm,
    key: "packing",
    redirect: "shipping/flow",
  },
  {
    path: "flow/delivery/additional",
    DeliveryComponent: ChooseAdditionalForm,
    key: "addons",
    redirect: "shipping/flow",
  },
  {
    path: "flow/customs-data",
    DeliveryComponent: CheckCustomsData,
    key: "customInformation",
    redirect: "shipping",
  },
  {
    path: "summary",
    DeliveryComponent: SummaryForm,
  },
  {
    path: "cancel",
    DeliveryComponent: CancelConfirmation,
  },
  {
    path: "/shipping/flow/customs-data",
    DeliveryComponent: SideDeliveryInfo,
  },
];
