import React, { FC, useCallback } from "react";

import { useFormik } from "formik";
import { Collapse } from "react-collapse";
import { useTranslation } from "react-i18next";

import { TotalPackingCost } from "@/containers/Delivery/components/TotalPackingCost";
import { SideItem, SideItemHead } from "@/containers/Delivery/styles";
import { Content } from "@/containers/RightPanel/RightPanel.styles";
import { Button } from "@/components/Button";
import { Input } from "@/components/Input";

import formatter from "@/utils/formatter";
import { useConsolidateCart, useSetPartnerId } from "@/hooks/react-query/cart";

import {
  useAuthStore,
  useCartStore,
  useRightPanelStore,
  useServicesStore,
} from "@/store";
import { ButtonColor, ButtonSize, ButtonType, ButtonVariant } from "@/enums";

import {
  ChangeTab,
  CustomsDeclarationSummary,
  Footer,
  HeadingWrapper,
  Logo,
  PartnerInputWrapper,
  SideDescription,
  SideParagraph,
  StatValue,
  SummaryForm,
  TabMain,
  TotalCustomsPrice,
} from "../NotFinishedPanel.styles";
import { indexToTitleMapping, summaryPanelFormik } from "./helpers";

interface SummaryPanelProps {
  onActiveTabChange: (tab: string) => void;
}

const SummaryPanel: FC<SummaryPanelProps> = React.memo(
  ({ onActiveTabChange }) => {
    const { t } = useTranslation("common");
    const {
      userAuth: { extended_tools: allowSearchByLocation },
    } = useAuthStore();
    const { resetCartStore: dropCart, cart } = useCartStore();
    const { mutateAsync: setPartnerId } = useSetPartnerId();
    const { mutateAsync: consolidateCart } = useConsolidateCart();
    const { closeRightPanel } = useRightPanelStore();
    const { allDeliveryMethods, packingMethods } = useServicesStore();

    const delivery = cart?.preferred_carrier;
    const packingCode = cart?.optional_line_item_codes?.[0];
    const customInformation = cart?.customs_informations ?? [];

    const { values, isSubmitting, handleChange, handleSubmit } = useFormik(
      summaryPanelFormik(
        allowSearchByLocation,
        closeRightPanel,
        setPartnerId,
        consolidateCart,
        dropCart,
      ),
    );

    const rendererDelivery = useCallback(() => {
      const deliveryMethod = allDeliveryMethods.find(
        (method) => method.name === delivery,
      );

      return deliveryMethod ? (
        <SideDescription>
          <Logo logoUrl={deliveryMethod.logo_url} />
          <SideParagraph>{deliveryMethod.name}</SideParagraph>
        </SideDescription>
      ) : null;
    }, [delivery, allDeliveryMethods]);

    const rendererPacking = useCallback(() => {
      const packingMethod = packingMethods.find(
        (method) => method.code === packingCode,
      );

      return packingMethod ? (
        <SideDescription>
          <Logo logoUrl={packingMethod.logo_url} />
          <SideParagraph>{packingMethod.title}</SideParagraph>
        </SideDescription>
      ) : null;
    }, [packingMethods, packingCode]);

    const rendererCustomsDeclarationSummary = () => {
      const totalQuantity = customInformation.reduce(
        (total: number, customsRow) => total + customsRow.quantity,
        0,
      );
      const totalValue = customInformation.reduce(
        (total: number, customsRow) => total + customsRow.value,
        0,
      );

      return (
        <CustomsDeclarationSummary>
          <StatValue>
            <strong>{customInformation.length}&nbsp;</strong>
            <span>
              {customInformation.length === 1
                ? t("shipping.singularCustomsSummary")
                : t("shipping.pluralCustomsSummary")}
            </span>
          </StatValue>
          <StatValue>
            <span>{t("shipping.quantityCustomsSummary")}</span>
            <strong>&nbsp;{totalQuantity}</strong>
          </StatValue>
          <TotalCustomsPrice>{`$${formatter.currency(
            totalValue,
          )}`}</TotalCustomsPrice>
        </CustomsDeclarationSummary>
      );
    };

    const rendererPartnerId = () => {
      return (
        <HeadingWrapper>
          <PartnerInputWrapper>
            <Input
              name="partnerId"
              placeholder={"Partner ID"}
              value={values.partnerId}
              onChange={handleChange}
            />
          </PartnerInputWrapper>
        </HeadingWrapper>
      );
    };

    return (
      <SummaryForm onSubmit={handleSubmit}>
        <TabMain>
          <Content>
            <Collapse isOpened>
              {delivery && (
                <SideItem $hideBorder>
                  <SideItemHead>
                    {t("shipping.delivery")}:
                    <ChangeTab
                      onClick={() =>
                        onActiveTabChange(t(indexToTitleMapping[0]))
                      }
                    >
                      {t("common.change")}
                    </ChangeTab>
                  </SideItemHead>
                  {rendererDelivery()}
                </SideItem>
              )}
              {packingCode && (
                <SideItem>
                  <SideItemHead>
                    {t("shipping.packing")}:
                    <ChangeTab
                      onClick={() =>
                        onActiveTabChange(t(indexToTitleMapping[0]))
                      }
                    >
                      {t("common.change")}
                    </ChangeTab>
                  </SideItemHead>
                  {rendererPacking()}
                </SideItem>
              )}
              <SideItem>
                <SideItemHead>
                  {t("parcels.customsDeclarations")}:
                  <ChangeTab
                    onClick={() => onActiveTabChange(t(indexToTitleMapping[1]))}
                  >
                    {t("common.change")}
                  </ChangeTab>
                </SideItemHead>
                {rendererCustomsDeclarationSummary()}
              </SideItem>
              {allowSearchByLocation && (
                <SideItem>{rendererPartnerId()}</SideItem>
              )}
              <TotalPackingCost />
            </Collapse>
          </Content>
          <Footer>
            <Button
              isLoading={isSubmitting}
              disabled={isSubmitting}
              type={ButtonType.Submit}
              size={ButtonSize.Large}
              color={ButtonColor.Primary}
              variant={ButtonVariant.Bordered}
            >
              {t("shipping.payAfterPacking")}
            </Button>
          </Footer>
        </TabMain>
      </SummaryForm>
    );
  },
);

export default SummaryPanel;
