import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import { getAddonsIconsImage } from "@/containers/Delivery/helpers";
import { Icon, IconPng } from "@/components/Icon";
import { ServiceLogo } from "@/components/ServiceLogo";

import {
  HeaderTitle,
  InnerSection,
  PayButton,
  Section,
  SectionContent,
  SectionHeader,
} from "@/styles/outgoing";

import { getFormattedDate } from "@/utils/formatDate";
import formatter from "@/utils/formatter";
import {
  useAddonsMethods,
  useConsolidationAddonsMethods,
  usePackingMethods,
} from "@/hooks/react-query/services";

import { RightPanelType } from "@/store/useRightPanelStore";
import {
  useAuthStore,
  useHistoryStore,
  useRightPanelStore,
  useServicesStore,
} from "@/store";
import { ButtonColor, ButtonVariant, IconType } from "@/enums";
import { getDayjsLocale } from "@/translations/dayjs-locale";

import {
  ParcelInfoTabPaginatedPackagesSection,
  ParcelInfoTabStandardPackageSection,
} from "./components";
import {
  ContentWrapper,
  DeliveryContent,
  Footer,
  PackageDimensions,
  PaidInfoSectionWrapper,
  PanelAddressItem,
  Spinner,
  StyledPackageSizes,
  TrackingNumber,
} from "./ParcelInfoTab.styles";

const ParcelInfoTab: FC = React.memo(() => {
  const { i18n, t } = useTranslation("common");
  const {
    userAuth: { display_weight_in: units },
  } = useAuthStore();
  const { panelData, openRightPanel } = useRightPanelStore();
  const {
    historyFullDetailedConsolidation: parcel,
    isLoadingHistoryDetailsItem,
  } = useHistoryStore();
  const { packingMethods, consolidationAddonsMethods, getSelectAddons } =
    useServicesStore();
  const addonsMethods = getSelectAddons();

  usePackingMethods({ enabled: !packingMethods.length });
  useAddonsMethods({ enabled: !addonsMethods.length });
  useConsolidationAddonsMethods({
    enabled: !consolidationAddonsMethods.length,
  });

  const locale = getDayjsLocale(i18n.language);
  const delivery = parcel?.preferred_carrier;
  const packingCode = parcel?.optional_line_item_codes[0];
  const packing = packingMethods.find(
    (packingMethod) => packingMethod.code === packingCode,
  );
  const packingTitle = packing?.title ?? "";
  const isLoadingServices = !!(
    !isLoadingHistoryDetailsItem &&
    addonsMethods.length &&
    packingMethods.length
  );

  const shipped = parcel?.order.shipped_on;

  const packages = parcel?.packages ?? [];

  const showPaginatedPackagesSection = !!packages?.length;

  const clickAlreadyPayed = () => {
    openRightPanel(RightPanelType.REVIEW_AND_PAY, {
      ...panelData,
      consolidation: parcel,
    });
  };

  if (!isLoadingServices) return <Spinner isActive />;

  return (
    <>
      <ContentWrapper>
        <Section>
          <SectionHeader>
            <HeaderTitle>
              <Icon type={IconType.Address} /> {t("parcels.shippingAddress")}:
            </HeaderTitle>
          </SectionHeader>
          {parcel && <PanelAddressItem address={parcel} isHideButtons />}
        </Section>
        <Section>
          <SectionHeader>
            <HeaderTitle>
              <IconPng type="turbine" /> {t("parcels.shippingMethod")}:
            </HeaderTitle>
          </SectionHeader>
          <InnerSection>
            <DeliveryContent>{`${delivery} - ${parcel?.weight.toFixed(0)} ${t(
              `units.${units}.primaryShort`,
            )} / $${parcel?.order.total_price}`}</DeliveryContent>
            <PackageDimensions>
              <StyledPackageSizes
                width={parcel?.width ?? 0}
                height={parcel?.height ?? 0}
                depth={parcel?.depth ?? 0}
                units={units}
              />
            </PackageDimensions>
          </InnerSection>
        </Section>
        <Section>
          <InnerSection>
            <TrackingNumber>
              {parcel?.order.tracking_number ?? t("parcels.numberNotFound")}
            </TrackingNumber>
            {shipped && (
              <>
                {t("parcels.shipped")} {getFormattedDate(shipped, locale)}
              </>
            )}
          </InnerSection>
        </Section>
        <Section>
          <SectionHeader>
            <HeaderTitle>
              <IconPng type="tape" /> {t("parcels.packingGrade")}:
            </HeaderTitle>
          </SectionHeader>
          <InnerSection>
            <SectionContent>
              <ServiceLogo serviceKey={packingCode ?? ""} />
              {packingTitle}
              {getAddonsIconsImage(
                parcel?.consolidation_addons,
                consolidationAddonsMethods,
              )}
            </SectionContent>
          </InnerSection>
        </Section>
        {!showPaginatedPackagesSection && (
          <ParcelInfoTabStandardPackageSection />
        )}
        {showPaginatedPackagesSection && (
          <ParcelInfoTabPaginatedPackagesSection
            packages={packages}
            hasFewPackages={showPaginatedPackagesSection}
          />
        )}
      </ContentWrapper>
      <Footer>
        <PaidInfoSectionWrapper>
          <PayButton
            color={ButtonColor.Secondary}
            onClick={() => clickAlreadyPayed()}
            variant={ButtonVariant.Filled}
          >
            {`${t("parcels.paymentPaid")} ($${formatter.currency(
              parcel?.estimate.total ?? 0,
            )})`}
          </PayButton>
        </PaidInfoSectionWrapper>
      </Footer>
    </>
  );
});

export default ParcelInfoTab;
