import React, { FC, lazy } from "react";

import { Route, Routes } from "react-router-dom";

import {
  BooksAddFunds,
  BooksRecipients,
  BooksTransactionsHistory,
} from "@/containers/Books";
import { deliveryRoutes } from "@/containers/Delivery/deliveryRoutes";
import Expected from "@/containers/Expected";
import History from "@/containers/History";
import Inventory from "@/containers/Inventory";
import Outgoing from "@/containers/Outgoing";
import Storage from "@/containers/Storage";

import { useProgressDelivery } from "@/hooks";

import { useAuthStore } from "@/store";

import { AuthorizedRoute, ConditionalRoute, PublicRoute } from "./components";

const Account = lazy(() => import("@/containers/Account"));
const SignIn = lazy(() => import("@/containers/SignIn"));
const PasswordReset = lazy(() => import("@/containers/PasswordReset"));
const PasswordChange = lazy(() => import("@/containers/PasswordChange"));
const Parcels = lazy(() => import("@/containers/Parcels"));
const LogOut = lazy(() => import("@/containers/LogOut"));
const Page404 = lazy(() => import("@/containers/Page404"));
const SignUp = lazy(() => import("@/containers/SignUp"));
const Delivery = lazy(() => import("@/containers/Delivery"));
const Dashboard = lazy(() => import("@/containers/Dashboard"));
const Impersonate = lazy(() => import("@/containers/Impersonate"));
const Books = lazy(() => import("@/containers/Books"));
const Tools = lazy(() => import("@/containers/Tools"));

const AppRoutes: FC = React.memo(() => {
  const {
    userAuth: { extended_tools: allowExtendedTools },
    getAuthSelectFfid,
  } = useAuthStore();

  const userFfid = getAuthSelectFfid();
  const [access, setAccess] = useProgressDelivery(userFfid);

  return (
    <Routes>
      <Route path="/">
        <Route element={<PublicRoute />}>
          <Route path="signin" element={<SignIn />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="password-reset" element={<PasswordReset />} />
          <Route path="password-change" element={<PasswordChange />} />
          <Route path="logout" element={<LogOut />} />
          <Route path="impersonate" element={<Impersonate />} />
        </Route>

        <Route element={<AuthorizedRoute />}>
          <Route index element={<Dashboard />} />
          <Route path="account" element={<Account />} />
          <Route path="parcels" element={<Parcels />}>
            <Route index element={<Storage />} />
            <Route path="inventory/:id" element={<Inventory />} />
            <Route path="expected" element={<Expected />} />
            <Route
              path="outgoing/:id?/:payment_result?"
              element={<Outgoing />}
            />
            <Route path="history" element={<History />} />
          </Route>
          <Route path="books" element={<Books />}>
            <Route index element={<BooksTransactionsHistory />} />
            <Route path="add-funds" element={<BooksAddFunds />} />
            <Route path="recipients" element={<BooksRecipients />} />
          </Route>
        </Route>

        <Route
          element={<AuthorizedRoute withMenu={false} redirect="/parcels" />}
        >
          <Route path="shipping" element={<Delivery />}>
            {deliveryRoutes.map(
              ({ DeliveryComponent, path, redirect, key }) => (
                <Route
                  key={path}
                  element={
                    <ConditionalRoute
                      condition={key ? access?.delivery : true}
                      redirect={redirect}
                    />
                  }
                >
                  <Route
                    path={path}
                    element={
                      <DeliveryComponent
                        setAccess={setAccess}
                        access={access}
                      />
                    }
                  />
                </Route>
              ),
            )}
          </Route>
        </Route>

        <Route element={<AuthorizedRoute condition={!allowExtendedTools} />}>
          <Route path="tools" element={<Tools />} />
        </Route>

        <Route path="*" element={<Page404 />} />
      </Route>
    </Routes>
  );
});

export default AppRoutes;
