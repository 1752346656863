export enum ButtonType {
  Submit = "submit",
  Button = "button",
}

export enum ButtonSize {
  Small = "small",
  Large = "large",
}

export enum ButtonVariant {
  Basic = "basic",
  Filled = "filled",
  Bordered = "bordered",
}

export enum ButtonColor {
  Primary = "primary",
  Secondary = "secondary",
  Red = "red",
  Black = "black",
  Black50 = "black50",
}
