import { AxiosResponse } from "axios";
import { FormikHelpers } from "formik";
import { TFunction } from "i18next";
import * as yup from "yup";

import { toastResponseError } from "@/utils/responseMessageHelper";

import { ExpectedItemPanelData } from "@/store/useRightPanelStore";
import {
  CreateExpectedShipmentDto,
  ExpectedShipmentResponseDto,
  UpdateExpectedShipmentDto,
} from "@/types/api/expected";
import { ShopsResponseDto } from "@/types/api/shipments";
import { ExpectedItemsPanelFormValues } from "@/types/ExpectedForms/ExpectedItemsPanel";

export const expectedItemsFormik = (
  t: TFunction,
  panelData: ExpectedItemPanelData,
  shops: ShopsResponseDto[],
  createExpectedItem: (
    data: CreateExpectedShipmentDto,
  ) => Promise<AxiosResponse<ExpectedShipmentResponseDto>>,
  updateExpectedItem: (
    data: UpdateExpectedShipmentDto,
  ) => Promise<AxiosResponse<ExpectedShipmentResponseDto>>,
) => {
  const getInitialValues = () => {
    const selectedExpectedItem = panelData?.selectedExpectedItem;
    const values = selectedExpectedItem;
    const getValue = (name: string) => (values ? values[name] : "");

    const shopOptions = shops.map((shop) => {
      const name = shop.name;
      const image = shop.thumb_image_url;
      return { label: name, value: name, image };
    });
    const storeName = shopOptions.find((x) => {
      return x.value === getValue("store_name");
    });

    return {
      id: getValue("id") as string,
      trackingNumber: getValue("tracking_number") as string,
      orderNumber: getValue("order_number") as string,
      storeName: storeName ? storeName : null,
      customerComment: getValue("customer_comment") as string,
    };
  };

  const initialValues = getInitialValues();

  return {
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: initialValues,
    validationSchema: () =>
      yup.object().shape({
        trackingNumber: yup
          .string()
          .required(
            t("error.required", { field: t("expectedParcel.trackingNumber") }),
          ),
        orderNumber: yup
          .string()
          .required(
            t("error.required", { field: t("expectedParcel.orderNumber") }),
          ),
        storeName: yup
          .object()
          .required(
            t("error.required", { field: t("expectedParcel.storeName") }),
          ),
        customerComment: yup.string(),
      }),
    onSubmit: (
      values: ExpectedItemsPanelFormValues,
      { resetForm, setSubmitting }: FormikHelpers<ExpectedItemsPanelFormValues>,
    ) => {
      const { id, trackingNumber, orderNumber, storeName, customerComment } =
        values;
      const isEditForm = panelData?.isEditForm;

      if (isEditForm) {
        updateExpectedItem({
          id: +id,
          data: {
            expected_shipment: {
              tracking_number: trackingNumber,
              store_name: storeName ? storeName.value : "",
              customer_comment: customerComment,
              order_number: +orderNumber,
            },
          },
        })
          .catch(toastResponseError)
          .finally(() => setSubmitting(false));
      } else {
        createExpectedItem({
          expected_shipment: {
            tracking_number: trackingNumber,
            store_name: storeName ? storeName.value : "",
            customer_comment: customerComment,
            order_number: +orderNumber,
          },
        })
          .then(() => resetForm())
          .catch(toastResponseError)
          .finally(() => setSubmitting(false));
      }
    },
  };
};
