import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";

import { AuthAPI } from "@/api";

import { AuthUserDto } from "@/types/api/auth";
import { UserAuthQueryKey as QueryKey } from "@/types";

export function useSignIn() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AuthAPI.signIn,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.UserAuthSignIn],
      });
    },
  });
}

export function useSignUp() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AuthAPI.signUp,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.UserAuthSignUp],
      });
    },
  });
}

export function useLogOut(
  options?: Omit<UseQueryOptions, "queryKey" | "queryFn">,
) {
  return useQuery({
    queryKey: [QueryKey.UserAuthLogOut],
    queryFn: AuthAPI.logOut,
    ...options,
  });
}

export function useGetProfile(
  options?: Omit<UseQueryOptions<AuthUserDto>, "queryKey" | "queryFn">,
  uniqueKey: string = "getProfile",
) {
  return useQuery({
    queryKey: [QueryKey.UserAuthGetProfile, uniqueKey],
    queryFn: AuthAPI.getProfile,
    ...options,
  });
}

export function usePasswordReset() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AuthAPI.requestPasswordReset,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.UserAuthPasswordReset],
      });
    },
  });
}

export function useChangePassword() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AuthAPI.changePassword,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.UserAuthChangePassword],
      });
    },
  });
}

export function useUpdateProfile() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AuthAPI.updateProfile,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.UserUpdateProfile],
      });
    },
  });
}
