import { AxiosResponse } from "axios";
import { FormikHelpers } from "formik";
import { TFunction } from "i18next";
import * as yup from "yup";

import {
  toastResponseError,
  toastResponseSuccess,
} from "@/utils/responseMessageHelper";

import { ItemsPutAwayDto } from "@/types/api/items";

export interface ToolsPutAwayFormValues {
  sku: string;
  location: string;
}

export const toolsPutAwayForm = (
  t: TFunction,
  handlePutAway: (data: ItemsPutAwayDto) => Promise<AxiosResponse>,
) => ({
  validateOnChange: false,
  validateOnBlur: false,
  enableReinitialize: true,
  initialValues: {
    sku: "",
    location: "",
  },
  validationSchema: () =>
    yup.object().shape({
      sku: yup.string().required(),
      location: yup.string().required(),
    }),
  onSubmit: (
    values: ToolsPutAwayFormValues,
    formikBag: FormikHelpers<ToolsPutAwayFormValues>,
  ) => {
    const { resetForm, setSubmitting } = formikBag;
    handlePutAway({ id: values.sku, data: { location: values.location } })
      .then(() => {
        setSubmitting(false);
        toastResponseSuccess(t("tools.putAwaySuccess"));
        resetForm();
      })
      .catch((error: Error) => {
        toastResponseError(error);
        setSubmitting(false);
      });
  },
});
