import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import {
  Content,
  ContentWrapper,
  Heading,
} from "@/containers/RightPanel/RightPanel.styles";
import { ImageViewer2 } from "@/components/ImageViewer2";

import { Warning } from "@/styles/outgoing";

import { RightPanelType } from "@/store/useRightPanelStore";
import { useRightPanelStore } from "@/store";

import { ImageSection, MoreInfoButton } from "./ProhibitedGoodsPanel.styles";

// fake images FIXME:
const images = [
  "https://timedotcom.files.wordpress.com/2015/05/471358344.jpg",
  "https://lh6.googleusercontent.com/-XqaFSgYUXLM/VWUu55u4nKI/AAAAAAAAEfw/vUem8jP_adA/w681-h508-no/smashedbox1.jpg",
  "https://www.bitnik.org/media/r/img/thumb/parcel_nike_01_905.jpg",
  "https://previews.123rf.com/images/om12/om121505/om12150500038/40686138-post-office-worker-weighs-the-parcel-before-sending-vertical-photo.jpg",
];

const ProhibitedGoodsPanel: FC = React.memo(() => {
  const { t } = useTranslation("common");
  const { openRightPanel } = useRightPanelStore();

  const clickMoreInfo = () =>
    openRightPanel(null, {
      returnPreviousPanel: RightPanelType.PROHIBITED_FOUND,
    });

  return (
    <ContentWrapper>
      <Heading $isBordered>{t("parcels.prohobitedGoods")}</Heading>
      <Content>
        <Warning>
          {t("parcels.prohobitedGoodsWarning")}
          <MoreInfoButton onClick={clickMoreInfo}>
            {t("common.moreOfficialInformation")} ›
          </MoreInfoButton>
        </Warning>
        {images.length && (
          <ImageSection>
            <ImageViewer2 images={images} />
          </ImageSection>
        )}
      </Content>
    </ContentWrapper>
  );
});

export default ProhibitedGoodsPanel;
