import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import { RightPanelType } from "@/store/useRightPanelStore";
import { useAuthStore, useCartStore, useRightPanelStore } from "@/store";
import { IconType } from "@/enums";

import {
  Container,
  OpenNotFinishedButton,
  RightArrowIcon,
  Wrapper,
} from "./CartInfo.styles";

const CartInfo: FC = React.memo(() => {
  const { t } = useTranslation("common");
  const {
    userAuth: { display_weight_in: units },
  } = useAuthStore();
  const { openRightPanel } = useRightPanelStore();
  const { getCartSelectForOutgoing } = useCartStore();
  const userCart = getCartSelectForOutgoing();

  const consolidationItem = userCart.items?.[0];
  const weight = consolidationItem.weight;
  const count = consolidationItem.items.length;

  const handleOpenNotFinished = () => {
    openRightPanel(RightPanelType.NOT_FINISHED, {
      detailedItemID: consolidationItem.id,
      parcel: consolidationItem,
      openedFromStorage: true,
    });
  };

  return (
    <Container>
      <OpenNotFinishedButton onClick={handleOpenNotFinished}>
        <span>{`PK${consolidationItem.id}`}</span>
        <RightArrowIcon type={IconType.RightArrow} />
      </OpenNotFinishedButton>
      <Wrapper>
        <span>
          {`${t("shipping.amountOfItemsInCart")}: `}
          <b>{count}</b>
        </span>
        <span>
          {`${t("parcels.weight")}: `}
          <b>{`${weight} ${t(`units.${units}.primaryShort`)}`}</b>
        </span>
      </Wrapper>
    </Container>
  );
});

export default CartInfo;
