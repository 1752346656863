import styled from "styled-components";

export const Wrapper = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 60px;

  span {
    margin-right: 4px;
  }

  i {
    margin-left: 4px;
  }
`;

export const TotalCostWrapper = styled.div`
  text-align: center;
  margin: 16px 0;
`;

export const TotalPrice = styled.span`
  color: ${(props) => props.theme.black50};
`;

export const DashConnector = styled.div`
  width: 100%;
  margin: 3px 8px;
  border-bottom: 1px dashed ${(props) => props.theme.lightgrey};
`;

export const PriceItem = styled.li`
  display: flex;
  justify-content: space-between;
  list-style: none;
  white-space: nowrap;
  margin: 8px 0;
  font-size: 14px;

  &:first-child {
    margin-top: 0;
  }
`;
