import { create } from "zustand";

import { FaqKBHelpersDto } from "@/types/api/dashboard";

export interface DashboardDto {
  delivered_count: number;
  new_count: number;
  next_end_time: string;
  next_start_time: string;
  pending_count: number;
  user_ffid: number;
  warehouse_address: string;
  warehouse_opened: boolean;
}

interface UseDashboardStore {
  dashboardData: DashboardDto | null;
  faqData: FaqKBHelpersDto[];
  kbHelpersData: FaqKBHelpersDto[];
  updateDashboardData: (dashboardDto: DashboardDto) => void;
  updateFAQData: (faqDto: FaqKBHelpersDto[]) => void;
  updateKbHelpersData: (KbHelpersDto: FaqKBHelpersDto[]) => void;
  resetDashboardStore: () => void;
}

const initialState = {
  dashboardData: null,
  faqData: [],
  kbHelpersData: [],
};

const useDashboardStore = create<UseDashboardStore>((set) => ({
  ...initialState,
  updateDashboardData: (dashboardDto) =>
    set(() => ({
      dashboardData: dashboardDto,
    })),
  updateFAQData: (faqDto) =>
    set(() => ({
      faqData: faqDto,
    })),
  updateKbHelpersData: (KbHelpersDto) =>
    set(() => ({
      kbHelpersData: KbHelpersDto,
    })),
  resetDashboardStore: () =>
    set(() => ({
      ...initialState,
    })),
}));

export default useDashboardStore;
