import styled from "styled-components";

export const Message = styled.p`
  margin: 17px 0;
  font-size: 26px;
  font-weight: bold;
  color: ${(props) => props.theme.black};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  button {
    margin: 5px 10px;
  }
`;
