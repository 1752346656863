import { create } from "zustand";

interface UsePurchaseStore {
  purchaseId: string | number | null;
  isLoadingPurchase: boolean;
  updatePurchaseId: (id: string | number) => void;
  updateIsLoadingPurchase: (bool: boolean) => void;
  resetPurchaseStore: () => void;
}

const initialState = {
  purchaseId: null,
  isLoadingPurchase: false,
};

const usePurchaseStore = create<UsePurchaseStore>((set) => ({
  ...initialState,
  updatePurchaseId: (id) =>
    set(() => ({
      purchaseId: id,
    })),
  updateIsLoadingPurchase: (bool) =>
    set(() => ({
      isLoadingPurchase: bool,
    })),
  resetPurchaseStore: () =>
    set(() => ({
      ...initialState,
    })),
}));

export default usePurchaseStore;
